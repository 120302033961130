import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { MazActions } from '../../_state/maz.actions';
import { MazSelectors } from '../../_state/maz.selectors';

@Component({
  selector: 'ninety-maz-common-questions',
  templateUrl: './maz-common-questions.component.html',
  styleUrls: ['./maz-common-questions.component.scss'],
})
export class MazCommonQuestionsComponent {
  protected readonly commonQuestions$ = this.store.select(MazSelectors.selectCommonQuestions);

  constructor(private store: Store) {}

  questionClicked(question: string): void {
    this.store.dispatch(MazActions.sendMessageToMaz({ userInput: question }));
  }
}
