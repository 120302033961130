import ObjectId from 'bson-objectid';

import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';

import { SeatMoveComponent } from './seat-move.component';

export type SeatMoveDialogData = Pick<SeatMoveComponent, 'nameOfSeatToMove' | 'seats'>;

export interface SeatToMoveToProjection {
  /** The ID of the seat which is being moved */
  seatId: string;
  /** The name of the seat which is being moved */
  seatName: string;
  /** Ordered array of all seat holder names (first + last) */
  seatHolderFullNames: string[];
}

export function createSeatToMoveToProjection(partial?: Partial<SeatToMoveToProjection>): SeatToMoveToProjection {
  const seatId = partial?.seatId ?? new ObjectId().toHexString();
  return {
    seatId: seatId,
    seatName: `Seat ${seatId}`,
    seatHolderFullNames: ['First Last', 'Frist2 Last2'],
    ...partial,
  };
}

/** @deprecated only in use by legacy change parent dialog */
export function legacyFilterToSeatsThisSeatCanMoveTo(
  seats: SeatModel[],
  seatToMove: SeatModel,
  childIdsArray: string[]
): SeatModel[] {
  return seats.filter(
    seat =>
      !seat.visionary && // Visionary seats are always the root and can only have an integrator as a child
      seat._id !== seatToMove.parentSeatId && // Can't move to current parent
      !childIdsArray.includes(seat._id) // Can't move to own child
  );
}

export function filterToSeatsThisSeatCanMoveTo(seats: SeatModel[], childIdsArray: string[]): SeatModel[] {
  return seats.filter(
    seat =>
      !seat.visionary && // Visionary seats are always the root and can only have an integrator as a child
      !childIdsArray.includes(seat._id) // Can't move to own child
  );
}

export function createMoveSeatParams(seats: SeatModel[], idToUser: Record<string, User>): SeatToMoveToProjection[] {
  return seats.map(seat => {
    const seatEntry: SeatToMoveToProjection = {
      seatName: seat.name,
      seatId: seat._id,
      seatHolderFullNames: seat.seatHolders
        .map(sh => idToUser[sh.userId]?.fullName)
        .filter(name => !!name)
        .sort(),
    };
    return seatEntry;
  });
}
