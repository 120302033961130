import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DestroyRef,
  Input,
  Optional,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { NinetyChipComponent } from '@ninety/ui/legacy/components/ninety-chip/ninety-chip.component';

import { HasErrorDirective } from '../../../directives/has-error.directive';
import { InputVariantDirective } from '../../../text-input/directives/input-variant.directive';
import { OptionListDirective } from '../../directives/option-list.directive';
import { SelectChipTemplateDirective } from '../../directives/select-chip-template.directive';
import { SelectLauncherDirective } from '../../directives/select-launcher.directive';
import { SelectClearButtonComponent } from '../select-clear-button/select-clear-button.component';
import { NinetySelectToggleButtonComponent } from '../select-toggle-button.component';

/** Overlay component used to emulate a select option list. */
@Component({
  selector: 'ninety-option-overlay',
  templateUrl: './option-overlay.component.html',
  styleUrls: ['./option-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TerraIconModule,
    A11yModule,
    NinetyChipComponent,
    SelectChipTemplateDirective,
    SelectClearButtonComponent,
    NinetySelectToggleButtonComponent,
    InputVariantDirective,
  ],
})
export class OptionOverlayComponent<T = unknown> implements AfterContentInit {
  @Input() chipTemplate: SelectChipTemplateDirective;

  @Input() private optionListInput: OptionListDirective<T>;

  @ContentChild(OptionListDirective) private optionListInContent: OptionListDirective;

  protected optionListInUse: OptionListDirective;

  constructor(
    protected launcher: SelectLauncherDirective,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    @Optional() protected hasError: HasErrorDirective
  ) {}

  ngAfterContentInit() {
    /* We want to support various ways of rendering this overlay. When it's rendered by the select component, then the
     * optionList is a content child of the select - not this component. In that case we pass it as an input. When this
     * overlay is instantiated by clients who use a different launcher (such as a button) then the directive is a
     * content child of this component. */
    if (this.optionListInput) this.optionListInUse = this.optionListInput;
    else if (this.optionListInContent) this.optionListInUse = this.optionListInContent;
    else throw new Error('No listbox found');

    /* I would love to not have to do this - without it, the selected chip list won't be rendered after selection */
    this.optionListInUse.valueChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }
}
