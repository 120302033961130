import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ImportUserForm } from '../../../models/import-user-form';

@Component({
  selector: 'ninety-teams-cell',
  template: `
    <div
      class="teams-cell"
      [formGroup]="user"
      *ngIf="{
        errors: user.controls.teams.errors,
        nonMatchingTeamNames: user.controls.nonMatchingTeamNames.value
      } as form">
      <ninety-inline-team-select-form formControlName="selectedTeams" [id]="id" />
      <ninety-error-overlay
        *ngIf="form.nonMatchingTeamNames?.length; else teamRequired"
        errorTitle="Team(s) not found"
        (acknowledged)="acknowledgeNonMatchingTeams()">
        <ng-container errorContent>
          <ul>
            <li>
              The following team(s) you entered do not exist:
              <ul>
                <li *ngFor="let team of form.nonMatchingTeamNames">{{ team }}</li>
              </ul>
            </li>
          </ul>
          <div *ngIf="form.errors?.['required']">
            Please assign the user to an existing team. You can visit the Teams page at a later time to create a new
            team.
          </div>
        </ng-container>
      </ninety-error-overlay>

      <ng-template #teamRequired>
        <ninety-error-overlay
          *ngIf="form.errors?.['required']"
          errorTitle="Team not found"
          errorDescription="You did not assign this user to a team. Please assign the user to an existing team." />
      </ng-template>
    </div>
  `,
  styles: [
    `
      .teams-cell {
        display: flex;
        align-items: center;
        gap: 8px;
        max-width: 200px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamsCellComponent {
  @Input() id: string;
  @Input() user: FormGroup<ImportUserForm>;

  acknowledgeNonMatchingTeams() {
    this.user.controls.nonMatchingTeamNames.setValue([]);
  }
}
