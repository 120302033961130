import { createReducer, on } from '@ngrx/store';

import { DetailViewActions } from './detail-view.actions';
import { DetailViewState, initialDetailViewState } from './detail-view.model';

export const detailViewReducer = createReducer<DetailViewState>(
  initialDetailViewState,
  on(
    DetailViewActions.enableStreaming,
    (state): DetailViewState => ({
      ...state,
      isStreamingEnabled: true,
    })
  ),
  on(
    DetailViewActions.disableStreaming,
    (state): DetailViewState => ({
      ...state,
      isStreamingEnabled: false,
    })
  ),
  on(
    DetailViewActions.enableScrollbar,
    (state): DetailViewState => ({
      ...state,
      disableScrollbar: false,
    })
  ),
  on(
    DetailViewActions.disableScrollbar,
    (state): DetailViewState => ({
      ...state,
      disableScrollbar: true,
    })
  )
);
