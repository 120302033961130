import { MockStoreConfig } from '@ngrx/store/testing/testing';

import { MockCustomLanguage } from '@ninety/ui/legacy/shared/models/language/mock-custom-language';
import {
  selectAllUsers,
  selectCurrentUserId,
  selectUserEntities,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import {
  getNinetyMockUsersOnlyPeople,
  getNinetyMockUsersOnlyPeopleDict,
} from '@ninety/web/pages/accountability-chart/components/search/_mock-data';

export function getMockSelectorsForChipUserSelectBox() {
  return [
    { selector: selectUserEntities, value: getNinetyMockUsersOnlyPeopleDict() },
    { selector: selectAllUsers, value: getNinetyMockUsersOnlyPeople() },
    { selector: selectCurrentUserId, value: '1' },
    { selector: selectLanguage, value: new MockCustomLanguage() },
  ];
}

export function getMockStateForChipUserSelectBox<T = any>(): MockStoreConfig<T> {
  return {
    selectors: getMockSelectorsForChipUserSelectBox(),
  };
}
