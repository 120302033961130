import { EntityState } from '@ngrx/entity';

import { Attachment } from '@ninety/ui/legacy/shared/models/_shared/attachment';
import { Accountability } from '@ninety/ui/legacy/shared/models/accountability-chart/accountability';
import { CompanyLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';

import { AccountabilityListItem } from '../../../../models/accountabilities';

export interface AttachmentListItem {
  attachment: Attachment;
  id: string;
  /**
   * Only applies to accountabilities that have been saved to the server (have an _id value).
   *
   * Accountabilities that are created and removed in the same dialog session are discarded,
   * no server updates required for them.
   *
   * Used to filter items for delete requests.
   */
  markedForRemoval: boolean;
  /**
   * Will be true for attachments added to the dialog.
   */
  markedForUpload: boolean;
  file?: File;
}

export enum SeatDetailsDialogMode {
  Create = 'create',
  Edit = 'edit',
  View = 'view',
}

export interface SeatDetailsDialogData {
  accountabilities: Accountability[];
  attachments: Attachment[];
  canEditChart: boolean;
  chartId: string;
  companyId: string;
  mode: SeatDetailsDialogMode;
  ordinal: number; // This is what the frontend expects the new seat ordinal to be. Should probably be moved to API side.
  parentSeatId: string;
  seatId: string | undefined;
  seatName: string | null;
}

export interface SeatDetailsDialogState {
  accountabilityListItems: EntityState<AccountabilityListItem>;
  attachmentListItems: EntityState<AttachmentListItem>;
  /**
   * Array of file references. Only used when mode='create', due to there not being a seat id to link attachments to.
   *
   * Requires sequencing of creating the seat, then uploading the attachments with the created seat id.
   */
  attachmentFiles: File[];
  actionBtnText: string;
  canEditChart: boolean;
  cardTitle: string;
  changedAccountabilityOrdinals: boolean;
  chartId: string;
  companyId: string;
  dialogData: SeatDetailsDialogData;
  language: CompanyLanguage | null;
  mode: SeatDetailsDialogMode;
  ordinal: number;
  parentSeatId: string;
  seatId: string | null;
  seatName: string | null;
  isLoading: boolean;
  touched: boolean;
}
