import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';

import { SubscriptionFrequencies } from '../../../_shared/models/billingv2/subscription-frequencies.enum';
import { BillingStateActions } from '../../../_state/app-global/billing/billing-state.actions';
import { NavMenuIntercomActions } from '../../../_state/app-global/intercom/intercom-state.actions';
import { ButtonComponent } from '../../buttons/button/button.component';
import { ButtonSwitchComponent } from '../../buttons/button-switch/button-switch.component';
import { CardModule } from '../../cards/card.module';

import { AllInDiscountComponent } from './all-in-discount/all-in-discount.component';
import { ComparePlansComponent } from './compare-plans/compare-plans.component';
import { PricingCardsComponent } from './pricing-cards/pricing-cards.component';

@Component({
  selector: 'ninety-pricing-tier-dialog',
  standalone: true,

  imports: [
    CommonModule,
    ButtonComponent,
    CardModule,
    TerraIconModule,
    PricingCardsComponent,
    AllInDiscountComponent,
    ComparePlansComponent,
    ButtonSwitchComponent,
  ],
  templateUrl: './pricing-tier-dialog.component.html',
  styleUrls: ['./pricing-tier-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingTierDialogComponent implements OnInit {
  protected subscriptionFrequency: SubscriptionFrequencies = SubscriptionFrequencies.Monthly;

  constructor(public dialogRef: MatDialogRef<PricingTierDialogComponent>, private readonly store: Store) {}

  ngOnInit() {
    this.store.dispatch(BillingStateActions.getCommercialModelSubscriptions());
  }

  cancel(): void {
    this.dialogRef.close();
  }

  talkToNinetyRep(): void {
    this.dialogRef.close();
    this.store.dispatch(NavMenuIntercomActions.showMessenger());
  }

  protected readonly SubscriptionFrequencies = SubscriptionFrequencies;
}
