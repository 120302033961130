import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ImportUserForm } from '../../../models/import-user-form';

@Component({
  selector: 'ninety-email-cell',
  template: ` <div class="email-cell" [formGroup]="user">
    <ninety-inline-editable-input formControlName="email" placeholder="Email..." [editing]="editing" />
    <ng-container *ngIf="user.controls.email.errors as errors">
      <ninety-error-overlay
        *ngIf="errors?.['emailTaken'] as email; else errorOverlay"
        errorTitle="User email already exists"
        [showOverlay]="showEmailTakenOverlay">
        <ul errorContent>
          <li>
            This email is already associated with an existing user. Please confirm if this was the intended email
            address.
          </li>
        </ul>
        <ng-container errorActions>
          <ninety-button (click)="delete()" flat>Delete user</ninety-button>
          <ninety-button (click)="edit()" primary stroked>Change email</ninety-button>
        </ng-container>
      </ninety-error-overlay>

      <ng-template #errorOverlay>
        <ninety-error-overlay errorTitle="A valid email is required" />
      </ng-template>
    </ng-container>
  </div>`,
  styles: [
    `
      .email-cell {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      ninety-inline-editable-input {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailCellComponent {
  @Input() user: FormGroup<ImportUserForm>;

  @Output() deleteUser = new EventEmitter<void>();

  editing = false;
  showEmailTakenOverlay: boolean;

  delete() {
    this.deleteUser.emit();
    this.showEmailTakenOverlay = false;
  }

  edit() {
    this.editing = true;
    this.showEmailTakenOverlay = false;
  }
}
