import { DetailType } from '../_shared/detail-type.enum';

export enum LinkedItemTypeEnum {
  todo = 'todo',
  issue = 'issue',
  headline = 'headline',
  rock = 'rock',
  cascadingMessage = 'cascadingMessage',
  learningTask = 'learningTask',
  learningTopic = 'learningTopic',
  milestone = 'milestone',
  goal = 'goal',
}

export const linkedItemTypeToDetailTypesMap: Record<LinkedItemTypeEnum, DetailType[]> = {
  [LinkedItemTypeEnum.todo]: [DetailType.todo],
  [LinkedItemTypeEnum.issue]: [DetailType.issue],
  [LinkedItemTypeEnum.headline]: [DetailType.headline],
  [LinkedItemTypeEnum.rock]: [DetailType.rock, DetailType.rockV3],
  [LinkedItemTypeEnum.cascadingMessage]: [DetailType.cascadingMessage, DetailType.cascadedHeadline],
  [LinkedItemTypeEnum.learningTask]: [DetailType.learningSubject, DetailType.learningModule],
  [LinkedItemTypeEnum.learningTopic]: [DetailType.learningTopic],
  [LinkedItemTypeEnum.milestone]: [DetailType.milestone, DetailType.milestoneV2],
  [LinkedItemTypeEnum.goal]: [DetailType.futureGoal],
};

export type LinkedItem = { _id: string; title: string };

export type LinkedItemsType = {
  todos?: LinkedItem[];
  issues?: LinkedItem[];
};

export type FromLinkedItem = {
  id: string;
  type: LinkedItemTypeEnum;
};

export enum ItemsToLinkedItemEnumMap {
  rock = 'rock',
  todo = 'todo',
  issue = 'issue',
  headline = 'headline',
  cascadedMessage = 'cascadingMessage',
  learningTopic = 'learningTopic',
  learningTask = 'learningTask',
  milestone = 'milestone',
  goal = 'goal',
}
