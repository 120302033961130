/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';

import {
  registerWindowBeforeUnloadConfirmDialog,
  unregisterWindowBeforeUnloadConfirmDialog,
} from '@ninety/ui/legacy/core/guards/pending-changes.guard';
import { ExcelExportType } from '@ninety/ui/legacy/core/services/_state/filter-service/excel-export-types.enum';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { FilterOptions } from '@ninety/ui/legacy/shared/models/_shared/filter-options';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';
import { PersonalTabTodosActions } from '@ninety/web/pages/todos/_state/personal/personal-todo.actions';

import { FilterBarActions, GridFilterBarActions } from './filterbar-state.actions';

@Injectable()
export class FilterBarStateEffects {
  startPrimarySpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpinnerActions.startPrimary),
      map(() => FilterBarActions.disabled())
    )
  );

  stopPrimarySpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpinnerActions.stopPrimary),
      map(() => FilterBarActions.enabled())
    )
  );

  setOptions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterBarActions.setOptions),
        tap(({ options }) => this.filterService.setOptions(options))
      ),
    { dispatch: false }
  );

  setOptionsExplicit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterBarActions.setExplicit),
        tap(({ options }) => this.filterService.setOptionsExplicit(options))
      ),
    { dispatch: false }
  );

  hideToolbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterBarActions.hide),
        tap(() => this.filterService.setOptions({ filtersToolbar: false }))
      ),
    { dispatch: false }
  );

  showToolbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterBarActions.show),
        tap(() => this.filterService.setOptions({ filtersToolbar: true }))
      ),
    { dispatch: false }
  );

  disabled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterBarActions.disabled),
        tap(() => this.filterService.setIsFetchingData(true))
      ),
    { dispatch: false }
  );

  enabled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterBarActions.enabled),
        tap(() => this.filterService.setIsFetchingData(false))
      ),
    { dispatch: false }
  );

  // TODO move these to their own effects class, probably in grid-layout
  enterLayoutMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridFilterBarActions.enterLayoutMode),
      map(() =>
        FilterBarActions.setOptions({
          options: {
            // Need to keep this as true, otherwise FiltersToolbarComponent.setTeam will
            // replace 'all' team to currentUser.lastAccessedTeamId
            allTeamsOption: true,
            editLayoutBtn: true,
            searchInput: false,
            teamDropdown: false,
            toolbarDescriptor: null,
          },
        })
      )
    )
  );

  leaveLayoutMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridFilterBarActions.leaveLayoutMode),
      map(() =>
        FilterBarActions.setOptions({
          options: {
            allTeamsOption: true,
            editLayoutBtn: true,
            searchInput: false,
            teamDropdown: true,
            toolbarDescriptor: FilterOptions.Default().toolbarDescriptor,
          },
        })
      )
    )
  );

  registerUnloadHandler$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridFilterBarActions.enterLayoutMode),
        tap(() => registerWindowBeforeUnloadConfirmDialog())
      ),
    { dispatch: false }
  );

  unregisterUnloadHandler$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridFilterBarActions.leaveLayoutMode),
        tap(() => unregisterWindowBeforeUnloadConfirmDialog())
      ),
    { dispatch: false }
  );

  setOptionsForPersonalTodosTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTabTodosActions.init),
      map(() => {
        this.filterService.setOptions({
          completeToggle: true,
          teamDropdown: false,
          printToPdfBtn: true,
          excelExportType: ExcelExportType.personalTodo,
        });
        return PersonalTabTodosActions.setFilterToolbarSuccess();
      })
    )
  );

  constructor(private actions$: Actions, private filterService: FilterService) {}
}
