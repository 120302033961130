import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

import { EmailErrors } from '../email-errors.enum';
import { RoleSelectComponent } from '../role-select/role-select.component';

import { DirectoryAddInviteDialogComponent } from './directory-add-invite.component';

interface DialogData {
  form: UntypedFormGroup;
  directoryOnly: boolean;
}

@Component({
  imports: [
    CommonModule,
    SharedModule,
    MaterialMdcModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    RoleSelectComponent,
  ],
  selector: 'ninety-directory-add-invite-mobile-modal',
  standalone: true,
  styleUrls: ['directory-add-invite-mobile-modal.component.scss'],
  templateUrl: 'directory-add-invite-mobile-modal.component.html',
})
export class DirectoryAddInviteMobileModalComponent {
  emailErrors = EmailErrors;
  teamsRequiredError = false;
  roleSelectOpened = false;

  constructor(
    public dialogRef: MatDialogRef<DirectoryAddInviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  updateUserTeams(teamIds: string[]): void {
    this.data.form.get('teams').setValue(teamIds.map(teamId => ({ teamId })));
  }

  updateUserRole(role: RoleSelectOption): void {
    this.data.form.get('role').setValue(role);
  }

  onClickCheckValidity(): void {
    this.data.form.get('role').updateValueAndValidity();
    this.data.form.get('teams').updateValueAndValidity();
    this.data.form.markAllAsTouched();
  }
}
