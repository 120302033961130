import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ninety-button-switch',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      class="slider"
      [style.transform]="selectedValue === leftButtonValue ? 'translateX(0)' : 'translateX(100%)'"></div>
    <button [class.active]="selectedValue === leftButtonValue" (click)="onToggle(leftButtonValue)">
      {{ leftLabel }}
    </button>
    <button [class.active]="selectedValue === rightButtonValue" (click)="onToggle(rightButtonValue)">
      {{ rightLabel }}
    </button>
  `,
  styleUrls: ['./button-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSwitchComponent<T = boolean> {
  @Input() leftLabel: string;
  @Input() rightLabel: string;

  @Input() leftButtonValue: T = true as T;
  @Input() rightButtonValue: T = false as T;
  @Input() selectedValue: T = this.leftButtonValue;

  @Output() selectedValueChange = new EventEmitter<T>();

  onToggle(value: T) {
    this.selectedValue = value;
    this.selectedValueChange.emit(value);
  }
}
