/** so we can more easily test which one is selected */
export enum SubscriptionPlanId {
  Free_Monthly = 'free-monthly',
  Free_Annual = 'free-annual',
  Essentials_Monthly = '67586d84843444302a673353',
  Essentials_Annual = '67586d83843444302a673352',
  Accelerate_Monthly = '67586d85843444302a673355',
  Accelerate_Annual = '67586d84843444302a673354',
  Thrive_Monthly = '67586d86843444302a673357',
  Thrive_Annual = '67586d86843444302a673356',
}
