import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { MazActions } from '../../../maz/_state/maz.actions';
import { GuideActions } from '../../_state/guide.actions';
import { PhaseStep } from '../../models/phase-step';

@Component({
  selector: 'ninety-phase-step',
  templateUrl: './phase-step.component.html',
  styleUrls: ['./phase-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhaseStepComponent {
  @Input() step: PhaseStep;
  @Input() mazChatEnabled: boolean;

  constructor(private store: Store) {}

  openLearnMoreLink(): void {
    this.store.dispatch(GuideActions.openResourceLink({ url: this.step.resource_link }));
  }

  suggestedPromptClick(): void {
    this.store.dispatch(MazActions.sendMessageToMaz({ userInput: this.step.suggested_prompt }));
  }
}
