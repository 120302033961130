import { InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * For each widget, this facade exposes the current expansion state, as well as methods to change it. Abstracted to an interface
 * to make it far easier to mock in tests & stories.
 *
 * @see DashboardExpandCandidateDirective
 */
export interface DashboardExpandCandidateStateFacade {
  /** A unique identifier for this widget. */
  readonly id: string;

  /** When true, this widget is being rendered in an expanded state. */
  isExpanded$: Observable<boolean>;

  /** Requests that a {@link DashboardExpandTargetDirective} expand this widget. */
  requestExpand(): void;
  /** Requests that a {@link DashboardExpandTargetDirective} collapse this widget. */
  requestCollapse(): void;
}

export const DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN = new InjectionToken<DashboardExpandCandidateStateFacade>(
  'DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN'
);

export interface DashboardExpandCandidateStateFacadeMock extends DashboardExpandCandidateStateFacade {
  isExpanded$: BehaviorSubject<boolean>;
}

export function createMockDashboardExpandCandidateStateFacade(
  inputSource?: BehaviorSubject<boolean> | boolean,
  id = 'example'
): DashboardExpandCandidateStateFacadeMock {
  const source =
    typeof inputSource === 'boolean'
      ? new BehaviorSubject(inputSource) //
      : inputSource ?? new BehaviorSubject(false);

  return {
    id,
    isExpanded$: source,
    requestExpand: () => {
      source.next(true);
    },
    requestCollapse: () => {
      source.next(false);
    },
  };
}
