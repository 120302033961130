import { componentWrapperDecorator } from '@storybook/angular';

/** Dashboard utility Decorator to wrap a widget story with gridstack and expand target */
export const expandedDashboardWidgetDecorator = componentWrapperDecorator(
  story => `
      <style>
        gridstack.expand-active gridstack-item {
          display: none;
        }
      </style>
      <gridstack
          [options]="{ column: 1, cellHeight: 400, staticGrid: true }"
          ninetyDashboardExpandTarget>
        <gridstack-item>${story}</gridstack-item>
      </gridstack>
  `
);
