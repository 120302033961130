import { Dictionary } from '@ngrx/entity';
import { cloneDeep } from 'lodash';

import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { UserListModel } from '@ninety/ui/legacy/state/app-entities/user-list/api/user-list.model';
import { ManagerPick } from '@ninety/web/pages/future-libs';

import { createSeat, createSeatHolderFromUser, createUser } from '../../../_testing/model-factories';
import { OrgChartSearchData } from '../user-seat-search/user-seat-search.component.model';

import RawBookData from './book-data.json';
import RawNinetyData from './ninety-output.json';

export const BookData = RawBookData;

export interface NinetySampleUser {
  first: string;
  last: string;
  full: string;
}

export interface NinetySampleSeatImport {
  _id: string;
  name: string;
  users?: NinetySampleUser[];
}

export interface NinetySampleSeatParsed {
  _id: string;
  key: string;
  name: string;
  users?: NinetySampleUser;
}

const NinetyRawInput: NinetySampleSeatImport[] = RawNinetyData;

function getKey(item: NinetySampleSeatImport, user?: NinetySampleUser): string {
  const { name } = item;
  return user ? `${user.first} ${user.last} - ${name}` : name;
}

export const NinetyInput: NinetySampleSeatParsed[] = Object.values(NinetyRawInput)
  .map((item: NinetySampleSeatImport): NinetySampleSeatParsed[] => {
    const { users } = item;
    if (users.length == 0) return [{ ...item, users: null, key: getKey(item) }];

    return users.map(
      (user: NinetySampleUser): NinetySampleSeatParsed => ({
        ...item,
        users: user,
        key: getKey(item, user),
      })
    );
  })
  .reduce((acc, val) => acc.concat(val), []);

export const NinetyOrgChartSearchData: OrgChartSearchData[] = NinetyInput.map((raw: NinetySampleSeatParsed) => ({
  // Kind a hack - we use a null user, but set the name of the seat to be the same key the normal selector would
  // generate
  name: raw.key,
  seatId: raw._id,
  user: null,
}));

export const NinetyMockUsers: NinetySampleUser[] = Array.from(
  NinetyInput.reduce((acc, val) => {
    if (val.users === null) return acc;
    return acc.add(val.users);
  }, new Set<NinetySampleUser>())
);

const filteredUsers: NinetySampleUser[] = [...NinetyMockUsers].filter(user => isNaN(Number(user.full.charAt(0))));
filteredUsers.sort((a, b) => a.full.localeCompare(b.full));
const uniqueUsersByFullName: NinetySampleUser[] = filteredUsers.reduce(
  (acc: NinetySampleUser[], val: NinetySampleUser) => {
    if (acc.find(user => user.full === val.full)) return acc;
    acc.push(val);
    return acc;
  },
  []
);
const mappedUsers = uniqueUsersByFullName.map(
  (user, index) =>
    ({
      _id: index.toString(),
      fullName: user.full,
      primaryEmail: `${user.first}.${user.last}@ninety.io`,
      metadata: {
        name: {
          first: user.first,
          last: user.last,
        },
        picture: {
          url: getHttpCatUrl(index),
        },
      },
    } as User)
);

export function getNinetyMockUsersOnlyPeople(): User[] {
  return cloneDeep(mappedUsers);
}

export function getHttpCatUrl(index: number): string {
  const validHttpCatCodes = [
    100, 101, 102, 103, 200, 201, 202, 203, 204, 205, 206, 207, 208, 226, 300, 301, 302, 303, 304, 307, 308, 400, 401,
    402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 420, 421, 422, 423, 424, 426,
    428, 429, 431, 444, 450, 451, 497, 498, 499, 500, 501, 502, 503, 504, 506, 507, 508, 509, 510, 511, 521, 522, 523,
    525, 530,
  ];
  const arrIndex = index % validHttpCatCodes.length;
  return `https://http.cat/images/${validHttpCatCodes[arrIndex]}.jpg`;
}

export function getNinetyManagerPick(): ManagerPick[] {
  return getNinetyMockUsersOnlyPeople().map((user: User, index) => ({
    _id: user._id,
    fullName: user.fullName,
    avatarUrl: getHttpCatUrl(index),
    initials: user.metadata.name.first.charAt(0) + user.metadata.name.last.charAt(0),
  }));
}

export function getNinetyUserListModels(): UserListModel[] {
  // This pick is already super close and is fine for all the current usages, so just cast
  return getNinetyManagerPick() as UserListModel[];
}

export function getNinetyUserListModelDict(): Dictionary<UserListModel> {
  const dict = getNinetyUserListModels().reduce((acc, val) => {
    acc[val._id] = val;
    return acc;
  }, {});
  return cloneDeep(dict);
}

export function getNinetyMockUsersOnlyPeopleDict(): Dictionary<User> {
  const dict = getNinetyMockUsersOnlyPeople().reduce((acc, val, index) => {
    val._id = index.toString();
    return { ...acc, [val._id]: val };
  }, {});
  return cloneDeep(dict);
}

export function getNinetyMockSeats(): SeatModel[] {
  const models: SeatModel[] = NinetyRawInput.map(({ _id, name, users }: NinetySampleSeatImport): SeatModel => {
    const parsedUsers = users.map((user: NinetySampleUser, index) =>
      createUser({
        _id: index.toString(),
        metadata: {
          name: {
            first: user.first,
            last: user.last,
          },
          picture: {
            url: null,
          },
        },
      })
    );
    const seatHolders = parsedUsers.map((user: User) => createSeatHolderFromUser(user));
    const sanitizedName = name.replace('\\n', '');
    return createSeat({
      partial: {
        _id,
        name: sanitizedName,
        seatHolders,
      },
    });
  });

  models.sort((a, b) => a.name.localeCompare(b.name));
  // Remove duplicate seats by name
  const uniqueSeats = models.reduce((acc, val) => {
    if (acc.find(seat => seat.name === val.name)) return acc;
    acc.push(val);
    return acc;
  }, []);

  return cloneDeep(uniqueSeats);
}
