import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { TerraIconModule } from '@ninety/terra';

import { PricingTier } from '../../../../_shared/models/company/company-pricing-tiers';
import { CardModule } from '../../../cards/card.module';

@Component({
  selector: 'ninety-compare-plans',
  standalone: true,
  imports: [CommonModule, CardModule, MatExpansionModule, TerraIconModule],
  templateUrl: './compare-plans.component.html',
  styleUrls: ['./compare-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: 0,
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('collapsed <=> expanded', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class ComparePlansComponent {
  @ViewChild('start') startElement: ElementRef;
  @ViewChild('build') buildElement: ElementRef;
  @ViewChild('customize') customizeElement: ElementRef;
  @ViewChild('analyze') analyzeElement: ElementRef;

  PricingTier = PricingTier;

  startOpen = true;
  buildOpen = true;
  customizeOpen = true;
  analyzeOpen = true;

  constructor(private cdr: ChangeDetectorRef) {}

  headerClick(elementId: string): void {
    this[`${elementId}Open`] = !this[`${elementId}Open`];

    if (this[`${elementId}Open`]) {
      this.cdr.detectChanges();

      const element = this[elementId + 'Element'];
      if (element) {
        element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }
}
