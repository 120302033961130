import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ElementRef, HostListener } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LetDirective, PushPipe } from '@ngrx/component';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';

import { WhatNextStore } from './_state/what-next.store';

@Component({
  selector: 'ninety-what-next',
  templateUrl: './what-next.component.html',
  styleUrls: ['./what-next.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatFormFieldModule,
    TerraIconModule,
    PushPipe,
    NgIf,
    NinetyTooltipDirective,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    AsyncPipe,
    LetDirective,
  ],
})
export class WhatNextComponent {
  constructor(public store: WhatNextStore, private _eref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.store.resetState(false);
    }
  }
}
