import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { map, Observable } from 'rxjs';

import {
  DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN,
  DashboardExpandCandidateStateFacade,
} from '../../providers/expand/dashboard-expand-candidate-state-facade';
import { DashboardWidgetComponent } from '../dashboard-widget/dashboard-widget.component';
import {
  DashboardWidgetMultiButtonAction,
  DashboardWidgetMultiButtonComponent,
} from '../dashboard-widget-multi-button/dashboard-widget-multi-button.component';

/** A header intended to be projected into a {@link DashboardWidgetComponent}. */
@Component({
  selector: 'ninety-dashboard-widget-header',
  standalone: true,
  imports: [CommonModule, DashboardWidgetMultiButtonComponent, LetDirective],
  template: `
    <div class="dashboard-widget-title">
      <ng-content select="[title]" />
    </div>
    <div class="dashboard-widget-subtitle">
      <ng-content select="[subtitle]" />
    </div>
    <div class="dashboard-widget-actions" *ngrxLet="nextAction$ as nextAction">
      <ninety-dashboard-widget-multi-button
        *ngIf="!hideButton"
        [action]="nextAction"
        (actionChanged)="changeActionTo($event)" />
      <span class="other-actions" *ngIf="nextAction !== DashboardWidgetMultiButtonAction.delete">
        <ng-content select="[actions]" />
      </span>
    </div>
  `,
  styleUrls: ['./dashboard-widget-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetHeaderComponent implements OnInit {
  /** Set to hide the expand/collapse button and prevent users from entering expanded mode. */
  @Input({ transform: coerceBooleanProperty }) hideButton = false;

  protected readonly DashboardWidgetMultiButtonAction = DashboardWidgetMultiButtonAction;
  protected nextAction$: Observable<DashboardWidgetMultiButtonAction>;

  constructor(
    @Inject(DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN)
    protected readonly facade: DashboardExpandCandidateStateFacade
  ) {}

  ngOnInit() {
    this.nextAction$ = this.facade.isExpanded$.pipe(
      map(isExpanded =>
        isExpanded ? DashboardWidgetMultiButtonAction.collapse : DashboardWidgetMultiButtonAction.expand
      )
    );
  }

  changeActionTo($event: DashboardWidgetMultiButtonAction) {
    switch ($event) {
      case DashboardWidgetMultiButtonAction.expand:
        this.facade.requestExpand();
        break;
      case DashboardWidgetMultiButtonAction.collapse:
        this.facade.requestCollapse();
        break;
      default:
        console.log('changed icon but didnt know event', $event);
        break;
    }
  }
}
