<!--Headers-->
<div class="accordion-list-header">
  <ninety-button
    flat
    secondary
    icon
    class="expand-collapse-all-btn"
    (click)="allCollapsed ? expandAll() : collapseAll()"
    [matTooltip]="allCollapsed ? 'Expand All' : 'Collapse All'">
    <terra-icon
      size="16"
      class="rotate-icon"
      [class.icon-down]="anyExpanded"
      [class.icon-right]="allCollapsed"
      icon="caret-right"
      variant="bold" />
  </ninety-button>
  <span class="header-label">{{ headerLabel }}</span>
  <terra-divider height="short" />
</div>

<!-- Accordion body-->
<cdk-accordion multi cdkDropList [cdkDropListDisabled]="disabled || readonly" (cdkDropListDropped)="reorder($event)">
  <cdk-accordion-item
    *ngFor="let item of valueDescriptions; let index = index; trackBy: trackByFn"
    #accordionItem="cdkAccordionItem"
    expanded
    cdkDrag
    [attr.data-id]="item._id"
    [attr.id]="'value-desc-row-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'value-desc-body-' + index"
    [ngClass]="{
      expanded: accordionItem.expanded,
      collapsed: !accordionItem.expanded
    }">
    <!-- Drag handle -->
    <terra-icon class="drag-btn" icon="drag" size="20" cdkDragHandle />

    <!-- Toggle -->
    <ninety-button
      flat
      secondary
      icon
      class="expand-collapse-btn"
      [attr.id]="'value-desc-row-toggle-' + index"
      [disabled]="false"
      (click)="toggle(accordionItem)">
      <terra-icon
        class="rotate-icon"
        size="16"
        [class.icon-down]="accordionItem.expanded"
        [class.icon-right]="!accordionItem.expanded"
        icon="caret-right"
        variant="bold" />
    </ninety-button>

    <!-- Always Visible Header -->
    <ninety-describable-input>
      <input
        attr.data-cy="seat-details_role-input-{{ index }}"
        #labelInput
        ninetyInput
        variant="inline"
        placeholder="Role (required)..."
        [hasError]="titleInputHasErrorAtIndex(index)"
        [readonly]="readonly"
        [disabled]="disabled"
        [attr.id]="'value-desc-header-input-' + index"
        [ngModel]="item.value"
        (ngModelChange)="setValueFromInput($event, index)"
        (blur)="titleInputOnBlur(index)" />
    </ninety-describable-input>

    <!-- Expandable Description - visibility set by styles & ngClass on the cdk-accordion-item element -->
    <div
      class="description"
      attr.data-cy="seat-details_description-input-{{ index }}"
      role="region"
      [attr.id]="'value-desc-body-' + index"
      [attr.aria-labelledby]="'value-desc-header-input-' + index">
      <textarea
        variant="inline"
        ninetyInput
        placeholder="Responsibility description..."
        ninetyAutoHeight
        [tabIndex]="accordionItem.expanded ? 0 : -1"
        [readonly]="readonly"
        [disabled]="disabled"
        [ngModel]="item.description"
        (ngModelChange)="setDescriptionFromInput($event, index)">
      </textarea>
    </div>

    <!-- Delete Button -->
    <ninety-button
      class="delete-icon-button"
      attr.data-cy="seat-details_delete-role-{{ index }}"
      flat
      secondary
      icon
      *ngIf="!(disabled || readonly)"
      [attr.id]="'value-desc-delete-row-' + index"
      (click)="removeAt(index)">
      <terra-icon icon="delete" />
    </ninety-button>

    <terra-divider height="short" />
  </cdk-accordion-item>
</cdk-accordion>

<!-- Footer (add)-->
<div class="accordion-list-footer" *ngIf="!readonly">
  <ninety-button
    flat
    primary
    icon
    id="{{ id }}-add-new"
    (click)="createNewValueDescription()"
    [disabled]="$any(disabled || readonly)">
    <terra-icon icon="add" data-cy="seat-details-add-seat-roles-and-responsibilities" />
    {{ addLabel }}
  </ninety-button>
</div>
