/**
 * Function to expect an action of a certain type to be dispatched.
 * @example ```ts
 *
 * const dispatchSpy = spyOn(mockStore, 'dispatch');
 *
 * // Act
 *
 * expectActionOfType(dispatchSpy, NotificationActions.notifyError.type);
 * ```
 */
export function expectActionOfType(dispatchSpy: jasmine.Spy<jasmine.Func>, type: string) {
  const calls = dispatchSpy.calls.all();
  const actions = calls.map(call => call.args[0]?.type);
  expect(actions).toContain(type);
}

/**
 * Function to expect no action of a certain type to be dispatched.
 *
 * @example ```ts
 * const dispatchSpy = spyOn(mockStore, 'dispatch');
 *
 * // Act
 *
 * expectNoActionOfType(dispatchSpy, NotificationActions.notifyError.type);
 * ```
 */
export function expectNoActionOfType(dispatchSpy: jasmine.Spy<jasmine.Func>, type: string) {
  const calls = dispatchSpy.calls.all();
  const actions = calls.map(call => call.args[0]?.type);
  expect(actions).not.toContain(type);
}
