import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import {
  DeleteTeamCustomAgenda,
  MeetingAgendasComponent,
  TeamMeetingAgendaUpdateByType,
} from '@ninety/ui/legacy/shared/index';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import { AgendaV2Service } from '@ninety/web/pages/meetings/_services/agenda-v2.service';
import { MeetingsAgendasV2Actions } from '@ninety/web/pages/meetings/_state/meetings.actions';
import { MeetingsStateSelectors } from '@ninety/web/pages/meetings/_state/meetings.selectors';

@Component({
  selector: 'ninety-agenda-v2',
  standalone: true,
  imports: [CommonModule, SharedModule, PushPipe],
  templateUrl: './agenda-v2.component.html',
  styleUrls: ['./agenda-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgendaV2Component implements OnInit {
  @ViewChild(MeetingAgendasComponent) meetingAgendasComponent!: MeetingAgendasComponent;

  private store = inject(Store);
  public agendaV2Service = inject(AgendaV2Service);
  private destroyRef = inject(DestroyRef);

  selectedTeam$ = this.store.select(MeetingsStateSelectors.selectSelectedTeam);
  agendaTypeOrIdOrNew$ = this.store.select(MeetingsStateSelectors.selectAgendaTypeOrIdOrNew);

  ngOnInit(): void {
    this.agendaV2Service.saveNewCustomAgendaEvent$
      .pipe(
        tap(() => {
          this.saveNewCustomAgendaEvent();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.agendaV2Service.updateAgendaEvent$
      .pipe(
        tap(() => {
          this.updateAgendaEvent();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.agendaV2Service.deleteCustomAgendaEvent$
      .pipe(
        tap(() => {
          this.deleteCustomAgendaEvent();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.agendaV2Service.discardChangesEvent$
      .pipe(
        tap(() => {
          this.discardChanges();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  saveNewCustomAgendaEvent() {
    this.meetingAgendasComponent.save(true);
  }

  updateAgendaEvent() {
    this.meetingAgendasComponent.save();
  }

  deleteCustomAgendaEvent() {
    this.meetingAgendasComponent.deleteAgenda(false);
  }

  discardChanges() {
    this.meetingAgendasComponent.cancel();
  }

  updateAgendaByType(update: TeamMeetingAgendaUpdateByType): void {
    const { newAgenda } = update;
    if (newAgenda) {
      this.store.dispatch(MeetingsAgendasV2Actions.saveNewCustomAgenda({ update }));
    } else {
      this.store.dispatch(MeetingsAgendasV2Actions.updateAgenda({ update }));
    }
  }

  deleteCustomAgenda({ teamId, agendaId }: DeleteTeamCustomAgenda): void {
    this.store.dispatch(MeetingsAgendasV2Actions.deleteCustomAgenda({ teamId, agendaId }));
  }

  validForm(isValid: boolean) {
    this.agendaV2Service.validForm(isValid);
  }

  newChanges(newChanges: boolean) {
    this.agendaV2Service.newChanges(newChanges);
  }
}
