import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

import type { SegmentContactTraits } from './models/segment-contact-traits';
import type { SegmentGroupTraits, SegmentReservedGroupTraits } from './models/segment-group-traits'; // Global variable injected by analytics script

// Global variable injected by analytics script
// https://segment.com/docs/getting-started/
// @ts-ignore
declare let analytics: SegmentAnalytics.AnalyticsJS;

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  private isLoaded$ = new BehaviorSubject<boolean>(false);
  private renderer = this.rendererFactory.createRenderer(this.document.head, null);
  private pastFirstPageCall = false;

  constructor(
    // Can't use Renderer2 inside a service
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loadScript();
  }

  loaded(): Observable<boolean> {
    return this.isLoaded$.asObservable();
  }

  private loadScript(): void {
    if (window.hasOwnProperty('analytics')) {
      this.isLoaded$.next(true);
      return;
    }

    const script: HTMLScriptElement = this.renderer.createElement('script');

    script.onload = () => this.isLoaded$.next(true);
    this.document.addEventListener('pageOnLoad', () => (this.pastFirstPageCall = true));

    // eslint-disable-next-line max-len
    script.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
      analytics.load('${environment.segmentApiKey}');

      // See DEV-13164 for context
      if ('/login' === window.location.pathname) {
        console.debug('[Segment] ignored login path, waiting to call page after successful login')
      } else {
        document.dispatchEvent(new CustomEvent('pageOnLoad'));
        console.debug('[Segment] found non-login path, calling page');
        analytics.page();
      }
      }}();
    `;

    this.renderer.appendChild(this.document.head, script);
  }

  /**
   * Called when a navigation event occurs
   *
   * Note: Conditionally called by segment on initial script load. See [DEV-13164](https://traxion.atlassian.net/browse/DEV-13164)
   *
   * Reference:
   * https://segment.com/docs/connections/spec/page/
   */
  page(): void {
    try {
      analytics.page();
    } catch (error) {
      console.error('[Segment] analytics.page error: ', error);
    }
  }

  /**
   * Called when the company user changes (IE when a user logs in). Used to properly identify the user to segment and its dependencies. For
   * context into why page is called here, see DEV-13164.
   */
  onUserChange(contactTraits: SegmentContactTraits, groupTraits: SegmentGroupTraits): void {
    if (!this.pastFirstPageCall) {
      this.pastFirstPageCall = true;
      this.page();
    }

    this.identify(contactTraits);
    this.group(groupTraits);
  }

  /**
   * Allows Segment to tie a user to their actions and record traits about
   * them. It includes a unique User ID and any optional traits you know
   * about the user, like their email, name, and more.
   *
   * Reference:
   * https://segment.com/docs/connections/spec/identify/
   */
  identify(contact: SegmentContactTraits): void {
    try {
      analytics.identify(contact.id, contact);
    } catch (e) {
      console.error(`[Segment] Error creating contact association for contact id ${contact?.id}`, e);
    }
  }

  group(group: SegmentReservedGroupTraits | SegmentGroupTraits): void {
    try {
      analytics.group(group.id, group);
    } catch (e) {
      console.error(`[Segment] Error creating group association for group id ${group?.id}`, e);
    }
  }

  /**
   * Reference:
   * https://segment.com/docs/connections/spec/track/#properties
   */
  track<T>(event: string, fields?: T) {
    try {
      analytics.track(event, fields);
    } catch (error) {
      console.error(`[Segment] Error tracking ${event} event: `, error);
    }
  }
}
