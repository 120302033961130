import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LetDirective } from '@ngrx/component';

import { TerraFormFieldModule, TerraIconModule, TerraTextInputModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { FileDragDropComponent } from '@ninety/ui/legacy/components/files/file-drag-drop/file-drag-drop.component';
import { FileUploadStatusComponent } from '@ninety/ui/legacy/components/files/file-drag-drop/file-upload-status.component';
import { FileListComponent } from '@ninety/ui/legacy/shared/components/file-attachments/file-list.component';
import { AttachmentEvent } from '@ninety/ui/legacy/shared/models/_shared/attachment-event';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

import {
  AccountabilityDragDropEvent,
  AccountabilityListItemTextChange,
  AccountabilityRemoveEvent,
  Identifiable,
} from '../../../models/accountabilities';
import { AccountabilityListComponent } from '../accountability-list/accountability-list.component';

import { SeatDetailsDialogData } from './models';
import { SeatDetailsDialogStore } from './seat-details-dialog.store';

@Component({
  selector: 'ninety-seat-details-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AccountabilityListComponent,
    ButtonComponent,
    CardModule,
    CommonModule,
    DragDropModule,
    FileListComponent,
    FileUploadStatusComponent,
    FileDragDropComponent,
    FormsModule,
    LetDirective,
    MatDialogModule,
    MatListModule,
    MatProgressSpinnerModule,
    TerraIconModule,
    SharedModule,
    TerraFormFieldModule,
    TerraTextInputModule,
  ],
  providers: [SeatDetailsDialogStore],
  templateUrl: './seat-details-dialog.component.html',
  styleUrls: ['./seat-details-dialog.component.scss'],
})
export class SeatDetailsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: SeatDetailsDialogData,
    protected store: SeatDetailsDialogStore
  ) {}

  ngOnInit(): void {
    this.store.init(this.dialogData);
  }

  cancel() {
    this.store.cancel();
  }

  updateSeatName(name: string) {
    this.store.updateSeatName(name);
  }

  onPrimaryAction() {
    this.store.onPrimaryAction();
  }

  //=======================
  // Accountabilities
  //=======================

  addAccountability() {
    this.store.addAccountabilityToList();
  }

  removeAccountability(event: AccountabilityRemoveEvent) {
    this.store.removeAccountability(event);
  }

  dragDroppedAccountability(event: AccountabilityDragDropEvent) {
    this.store.moveAccountability(event);
  }

  enterEditAccountability(event: Identifiable) {
    this.store.enterEditAccountability(event);
  }

  exitEditAccountability(event: Identifiable) {
    this.store.exitEditAccountability(event);
  }

  saveAccountability(event: AccountabilityListItemTextChange) {
    this.store.saveAccountability(event);
  }

  //=======================
  // Attachments
  //=======================

  onAttachmentChange(event: AttachmentEvent) {
    this.store.onAttachmentChange(event);
  }
}
