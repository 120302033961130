/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, forwardRef, Input, OnInit } from '@angular/core';
import { IFuseOptions } from 'fuse.js';
import { Observable } from 'rxjs';

import { UserListModel } from '@ninety/ui/legacy/state/app-entities/user-list/api/user-list.model';

import { AUTO_COMPLETE_CALLBACK_HANDLER } from '../directives/auto-complete.directive';

import { FUSE_PROVIDER_INJECTION_TOKEN, FuseSearchProvider } from './fuse-provider';
import { FuseSearchService } from './fuse-search.service';

/**
 * This is a trimmed down model of the User object that is returned by the Feedback insights API. While it was purpose built for that, it
 * is a pick of the modern trimmed down user model {@link UserListModel}. As such, this model can be selected from the store and used in
 * other contexts than the Feedback insights API.
 */
export type ManagerPick = Pick<UserListModel, 'avatarUrl' | '_id' | 'initials' | 'fullName'>;

@Directive({
  selector: '[ninetyFuseManagerPick]',
  standalone: true,
  providers: [
    FuseSearchService,
    {
      provide: AUTO_COMPLETE_CALLBACK_HANDLER,
      useExisting: FuseSearchService,
    },
    {
      provide: FUSE_PROVIDER_INJECTION_TOKEN,
      useExisting: forwardRef(() => FuseManagerPickDirective),
    },
  ],
})
export class FuseManagerPickDirective implements OnInit, FuseSearchProvider<ManagerPick> {
  @Input({ alias: 'ninetyFuseUser', required: true }) data$: Observable<ManagerPick[]>;

  @Input({ alias: 'ninetyFuseSeatOptions' }) fuseOptions: IFuseOptions<ManagerPick> = {
    keys: ['fullName'],
    includeMatches: true,
  };

  constructor(public fuse: FuseSearchService<ManagerPick>) {}

  ngOnInit() {
    if (!this.data$) throw new Error('Must provide stream of ManagerPick');

    this.fuse.init({
      allOptionsDataSource$: this.data$,
      fuseOptions: this.fuseOptions,
    });
  }
}
