import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HeadlinesStateEffects } from '@ninety/web/pages/headlines/_state/headlines/headlines-state.effects';
import { IntegrationService } from '@ninety/web/pages/settings/user/integration/_api/integration.service';

import { TodoDetailEffects } from './detail/todo-detail.effects';
import { PersonalTodoEffects } from './personal/personal-todo.effects';
import { PubnubTodoEffects } from './pubnub/pubnub-todo.effects';
import { TeamTodoEffects } from './team/team-todo.effects';
import { TeamTodoFacade } from './team/team-todo.facade';

import { todoReducerMap, TodoRootStateKey } from './index';

/**
 * Configures NGRX for the todos module.
 *
 * Why separate and not in TodoModule? Need to import it in My90, but don't want the baggage of the TodoModule
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(TodoRootStateKey, todoReducerMap),
    EffectsModule.forFeature([
      TeamTodoEffects,
      PersonalTodoEffects,
      PubnubTodoEffects,
      TodoDetailEffects,
      HeadlinesStateEffects,
    ]),
    MatDialogModule,
  ],
  providers: [IntegrationService, TeamTodoFacade],
})
export class TodoStateModule {}
