import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ResponsibilityChartModel } from '../../models/responsibility-chart.model';

import { SharedResponsibilityChartsActions } from './shared-responsibility-charts.actions';
import {
  createdByMeAdapter,
  createInitialSharedWithMeStateModel,
  SharedResponsibilityChartsStateModel,
  sharedWithMeAdapter,
} from './shared-responsibility-charts.model';

export const sharedResponsibilityChartsReducers = createReducer<SharedResponsibilityChartsStateModel>(
  createInitialSharedWithMeStateModel(),
  on(SharedResponsibilityChartsActions.fetchMACSuccess, (state, { response }): SharedResponsibilityChartsStateModel => {
    const sharedWithMe = sharedWithMeAdapter.setAll(response.sharedWithMe, state.sharedWithMe);
    const myCharts = createdByMeAdapter.setAll(response.myCharts, state.createdByMe);

    return {
      ...state,
      sharedWithMe: sharedWithMe,
      createdByMe: myCharts,
      doneLoading: true,
      isSaving: false,
    };
  }),
  on(
    SharedResponsibilityChartsActions.createMAC,
    SharedResponsibilityChartsActions.updateMAC,
    (state): SharedResponsibilityChartsStateModel => ({
      ...state,
      isSaving: true,
    })
  ),
  on(
    SharedResponsibilityChartsActions.createMACSuccess,
    (state, { chart }): SharedResponsibilityChartsStateModel => ({
      ...state,
      createdByMe: createdByMeAdapter.addOne(chart, state.createdByMe),
      isSaving: false,
    })
  ),
  on(
    SharedResponsibilityChartsActions.updateMACSuccess,
    (state, { chart }): SharedResponsibilityChartsStateModel => ({
      ...state,
      createdByMe: createdByMeAdapter.updateOne({ id: chart._id, changes: chart }, state.createdByMe),
      sharedWithMe: sharedWithMeAdapter.updateOne({ id: chart._id, changes: chart }, state.sharedWithMe),
      isSaving: false,
    })
  ),

  on(
    SharedResponsibilityChartsActions.createMACFailure,
    SharedResponsibilityChartsActions.updateMACFailure,
    (state): SharedResponsibilityChartsStateModel => ({
      ...state,
      isSaving: false,
    })
  ),
  on(
    SharedResponsibilityChartsActions.openUpdateMACDialog,
    SharedResponsibilityChartsActions.setSelectedChartId,
    (state, { chartId }): SharedResponsibilityChartsStateModel => ({
      ...state,
      selectedChartId: chartId,
    })
  ),
  on(
    SharedResponsibilityChartsActions.setPrimarySuccess,
    (state, { newPrimaryChartId, oldPrimaryChartId }): SharedResponsibilityChartsStateModel => {
      const newPrimaryChart: Update<ResponsibilityChartModel> = {
        id: newPrimaryChartId,
        changes: {
          isPrimaryCompanyChart: true,
        },
      };
      const oldPrimaryChart: Update<ResponsibilityChartModel> = {
        id: oldPrimaryChartId,
        changes: {
          isPrimaryCompanyChart: false,
        },
      };
      return {
        ...state,
        createdByMe: createdByMeAdapter.updateMany([newPrimaryChart, oldPrimaryChart], state.createdByMe),
        sharedWithMe: sharedWithMeAdapter.updateMany([newPrimaryChart, oldPrimaryChart], state.sharedWithMe),
      };
    }
  ),
  on(
    SharedResponsibilityChartsActions.cloneMACSuccess,
    (state, { chart }): SharedResponsibilityChartsStateModel => ({
      ...state,
      createdByMe: createdByMeAdapter.addOne(chart, state.createdByMe),
    })
  ),
  on(
    SharedResponsibilityChartsActions.deleteMACSuccess,
    (state, { chartId }): SharedResponsibilityChartsStateModel => ({
      ...state,
      createdByMe: createdByMeAdapter.removeOne(chartId, state.createdByMe),
    })
  )
);
