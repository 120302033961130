import { DragAndDropEvent } from '@ninety/ui/legacy/shared/models/_shared/drag-and-drop';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';

import { TodoStateBase } from './todo-state.shared.model';

export function createOrdinalUpdate(
  event: DragAndDropEvent,
  { ordinalKey, pageIndex, pageSize, todos }: Pick<TodoStateBase, 'pageSize' | 'pageIndex' | 'todos' | 'ordinalKey'>
): Todo[] {
  let start: number, stop: number;

  if (event.previousIndex != null && event.currentIndex != null) {
    start = Math.min(event.previousIndex, event.currentIndex) + pageIndex * pageSize;
    stop = Math.max(event.previousIndex, event.currentIndex) + pageIndex * pageSize;
  }

  return start == null
    ? Object.values(todos.entities)
    : Object.values(todos.entities).filter(t => t[ordinalKey] >= start && t[ordinalKey] <= stop);
}
