import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JWT_OPTIONS, JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Store, StoreModule } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { GoogleAnalyticsModule } from '@ninety/ui/legacy/core/analytics/google-analytics/google-analytics.module';
import { CoreModule } from '@ninety/ui/legacy/core/core.module';
import { HostInterceptor } from '@ninety/ui/legacy/core/interceptors/host.interceptor';
import { ErrorInterceptor } from '@ninety/ui/legacy/core/interceptors/http-error.interceptor';
import { LegacyScorecardApiInterceptor } from '@ninety/ui/legacy/core/interceptors/legacy-scorecard-api.interceptor';
import { OriginInterceptor } from '@ninety/ui/legacy/core/interceptors/origin.interceptor';
import { RefreshTokenInterceptor } from '@ninety/ui/legacy/core/interceptors/refresh-token.interceptor';
import { RequestIdInterceptor } from '@ninety/ui/legacy/core/interceptors/request-id.interceptor';
import { AppLoadService } from '@ninety/ui/legacy/core/services/app-load.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { StorageService } from '@ninety/ui/legacy/core/services/storage.service';
import { tokenGetter } from '@ninety/ui/legacy/core/services/token.service';
import { TranslocoRootModule } from '@ninety/ui/legacy/core/transloco/transloco-root.module';
import { DatadogLoggerModule } from '@ninety/ui/legacy/core/vendor/datadog/datadog-logger.module';
import { OpenTelemetryModule, TelemetrySessionService } from '@ninety/ui/legacy/core/vendor/opentelemetry';
import { SpanService } from '@ninety/ui/legacy/core/vendor/opentelemetry/span.service';
import { provideDefaultTippyConfig } from '@ninety/ui/legacy/core/vendor/tippy.config';
import { BrandingThemeDirective } from '@ninety/ui/legacy/shared/directives/branding-theme.directive';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import { AppEntitiesStateModule } from '@ninety/ui/legacy/state/app-entities/app-entities-state.module';
import { AppGlobalStateModule } from '@ninety/ui/legacy/state/app-global/app-global-state.module';
import { globalReducers, globalStateEffects } from '@ninety/ui/legacy/state/state-setup';
import { ENVIRONMENT, environment } from '@ninety/ui/web/environments';
import { AnalyticsModule } from '@ninety/web/pages/analytics/analytics.module';
import { LinkedItemsStateModule } from '@ninety/web/pages/detail-view/_shared/linked-items/linked-items-ngrx.module';
import { DetailViewStateModule } from '@ninety/web/pages/detail-view/_state/detail-view-state.module';
import { FeedbackModule } from '@ninety/web/pages/feedback/feedback.module';
import { LayoutModule } from '@ninety/web/pages/layouts/layout.module';
import { LoginSharedStateFacade } from '@ninety/web/pages/login/_state/shared/login-shared-state.facade';
import { MeetingsV2Module } from '@ninety/web/pages/meetings/_state/meetings-v2.module';
import { TodoStateModule } from '@ninety/web/pages/todos/_state/todo-ngrx.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* DO NOT import Lazy Loaded Modules...It will cause a run time error */

export function jwtOptionsFactory() {
  const options: JwtModuleOptions = {
    config: {
      tokenGetter: tokenGetter,
      allowedDomains: [/^.*?app\.ninety\.io$/, /^localhost:(3001|3002|4200)$/],
      disallowedRoutes: [/\/v4\/assets\/*/],
    },
  };
  return options.config;
}

export function initializeCompanyUser(appLoadService: AppLoadService) {
  return () => appLoadService.initializeCompanyUser();
}

export const TooltipOptions: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    DatadogLoggerModule.forRoot({
      name: 'AppModule',
      globalContext: {
        env: `${environment.env}:${environment.region}`,
        isLocal: environment.hostedEnvironment === 'localhost',
        service: environment.service,
        version: environment.appVersion,
      },
      initConf: {
        // Return false to omit log
        // Mutate to modify, see redact example
        beforeSend: log => {
          /** DO NOT LOG TO CONSOLE IN THIS METHOD, IT WILL CAUSE AN INFINITE LOOP */
          if (!environment.production) {
            if (log.message?.startsWith('Angular is running in development mode')) {
              return false;
            }

            if (log.message?.startsWith('@ngrx/store: The feature name')) {
              return false;
            }

            if (log.message?.includes('[webpack-dev-server]')) {
              return false;
            }
          }

          // We want to use level for consistency
          log.level = log.status;
          delete log.status;

          // Eg. of redaction
          // log.view.url = log.view.url.replace(/email=[^&]*/, 'email=REDACTED');
        },
        clientToken: environment.dataDogClientToken,
        env: environment.env,
        forwardConsoleLogs: 'all',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        site: 'datadoghq.com',
        // opt out of telemetry
        telemetrySampleRate: 0,
        version: environment.appVersion,
      },
    }),
    BrowserModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      countDuplicates: true,
      closeButton: true,
      resetTimeoutOnDuplicate: true,
    }),
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      },
    }),
    FormsModule,
    // Feature Modules
    AnalyticsModule,
    CoreModule,
    SharedModule,
    // AppRouting module must be declared last in order to prevent the ** from catching
    AppRoutingModule,
    StoreModule.forRoot(globalReducers),
    EffectsModule.forRoot(globalStateEffects),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument({ name: 'Ninety', maxAge: 50 }) : [],
    AppGlobalStateModule,
    AppEntitiesStateModule,
    LayoutModule,
    TodoStateModule,
    LinkedItemsStateModule,
    MeetingsV2Module,
    DetailViewStateModule,
    GoogleAnalyticsModule,
    BrandingThemeDirective,
    TranslocoRootModule,
    OpenTelemetryModule,
  ],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    AppLoadService,
    CookieService,
    HelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeCompanyUser,
      deps: [AppLoadService],
      multi: true,
    },
    { provide: StorageService, useFactory: () => new StorageService(localStorage) },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HostInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OriginInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LegacyScorecardApiInterceptor,
      multi: true,
    },
    {
      provide: 'oldVersionUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = window.location.origin + (route.data as any).oldVersionUrl;
      },
    },
    {
      provide: LOCALE_ID,
      deps: [AppLoadService],
      useFactory: (appLoadService: AppLoadService) => appLoadService.getLocale(),
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipOptions },
    DatePipe,
    LoginSharedStateFacade,
    provideDefaultTippyConfig(),
    SpanService,
    TelemetrySessionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private store: Store) {
    /**
      Make the store available to cypress tests
      specifically for updating feature flags
     */
    if (!environment.production) {
      window['ninety'] = {
        store: this.store,
      };
    }
  }
}
