import { createReducer, on } from '@ngrx/store';
import { merge } from 'lodash';

import { GridWidgetById } from '../models/grid-widget-by-id';

import { GridLayoutActions } from './grid-layout-state.actions';
import { GridLayoutStateModel, GridState, INITIAL_GRID_LAYOUT_STATE_MODEL } from './grid-layout-state.model';

export const gridLayoutStateReducers = createReducer(
  INITIAL_GRID_LAYOUT_STATE_MODEL,
  on(
    GridLayoutActions.initialized,
    (state, { gridState }): GridLayoutStateModel => ({ ...state, currentGrid: gridState })
  ),
  on(GridLayoutActions.destroyed, (state, { clearState }): GridLayoutStateModel => {
    if (!clearState) return updateCurrentGrid(state, { isInitialized: false });
    return { ...state, currentGrid: null };
  }),
  on(
    GridLayoutActions.modifiedEditState,
    (state, { editLayoutEnabled }): GridLayoutStateModel => updateCurrentGrid(state, { editLayoutEnabled })
  ),
  on(
    GridLayoutActions.loadedLayout,
    (state, { viewModel }): GridLayoutStateModel => updateCurrentGrid(state, { viewModel })
  ),
  on(GridLayoutActions.userUpdatedLayout, (state, { models }): GridLayoutStateModel => {
    const vmOfUpdatedModels = GridWidgetById.from(models);
    const mergedVM = merge({}, state.currentGrid.viewModel, vmOfUpdatedModels);

    return updateCurrentGrid(state, { viewModel: mergedVM });
  }),
  on(
    GridLayoutActions.showSingletonSuccess,
    (state, { id }): GridLayoutStateModel => updateCurrentGrid(state, { singletonTemplate: id })
  ),
  on(
    GridLayoutActions.clearSingletonSuccess,
    (state): GridLayoutStateModel => updateCurrentGrid(state, { singletonTemplate: null })
  ),
  on(
    GridLayoutActions.successfullyEnteredOneColumnMode,
    (state): GridLayoutStateModel => updateCurrentGrid(state, { inOneColumnMode: true })
  ),
  on(
    GridLayoutActions.successfullyExitedOneColumnMode,
    (state): GridLayoutStateModel => updateCurrentGrid(state, { inOneColumnMode: false })
  ),
  on(
    GridLayoutActions.firstPaintComplete,
    (state): GridLayoutStateModel => updateCurrentGrid(state, { firstPaintComplete: true })
  )
);

function updateCurrentGrid(state: GridLayoutStateModel, currentGrid: Partial<GridState>): GridLayoutStateModel {
  return {
    ...state,
    currentGrid: {
      ...state.currentGrid,
      ...currentGrid,
    },
  };
}
