import { createReducer, on } from '@ngrx/store';

import { PageStateActions } from './page-state.actions';
import { PageStateModel, initialPageState } from './page-state.models';

export const pageStateReducer = createReducer(
  initialPageState,
  on(
    PageStateActions.setLayoutType,
    (state, { layoutType }): PageStateModel => ({
      ...state,
      layoutType,
    })
  )
);
