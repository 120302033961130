export enum Cadence {
  none = 'none',
  weekly = 'weekly',
  biWeekly = 'biWeekly',
  monthly = 'monthly',
}

export enum CadenceFormOptions {
  none = 'none',
  weekly = 'weekly',
  biWeekly = 'biWeekly',
  monthly = 'monthly',
  monthlyLastWeek = 'monthlyLastWeek',
}
