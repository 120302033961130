import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { OneSchemaTemplateKey } from '../../models/one-schema-template-key';
import { IssueRecordsData } from '../../models/records';
import { IssueRecord } from '../../models/records/issue-record';
import { DataImportService } from '../../services/data-import.service';
import { DataImportActions } from '../data-import.actions';
import { DataImportSelectors } from '../data-import.selectors';

@Injectable()
export class IssueImportEffects {
  constructor(private actions$: Actions, private store: Store, private dataImportService: DataImportService) {}

  handleIssuesFromImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleDataFromOneSchema),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.issues),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTeamId)),
      map(([{ data }, teamId]: [IssueRecordsData, string]) => {
        const issues = IssueRecord.toIssues(data.records, teamId);
        return DataImportActions.createIssues({ issues });
      })
    )
  );

  createIssues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createIssues),
      switchMap(({ issues }) =>
        this.dataImportService.createIssues(issues).pipe(
          map(() => DataImportActions.success({ key: 'issue' })),
          catchError((error: unknown) => of(DataImportActions.error({ error })))
        )
      )
    )
  );
}
