import { GridStackOptions } from 'gridstack/dist/types';

import { DashboardConstants } from '@ninety/web/pages/future-libs';

/**
 * Options to configure GridStack.
 *
 * TODO Lots of classes modify GridStack options not Ninety options, thus exposing GridStack to the outside world.
 *  This could use better encapsulation - dont extend, pick only the valid options, thus being explicit about what the
 *  current implementation supports.
 * @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket.
 */
export interface GridOptions extends GridStackOptions {
  /**
   * Adds width to the one column breakpoint observer, which handles updating the grid when it begins in one column mode
   * and moves to three column mode. In pixels.
   */
  oneColumnExtraPadding?: number;
  resizeDebounce: number;

  /** @deprecated Use the modern gridstack columnOpts instead. */
  useNinetyOneColumnMode?: boolean;

  /** @deprecated Use the modern gridstack columnOpts instead. */
  ninetyOneColumnBreakpoint?: number;
}

/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
export namespace GridOptions {
  export function defaults(options?: Partial<GridOptions>): GridOptions {
    const column: GridStackOptions['column'] = options?.column ?? 3; // Only allow 3 columns exactly, not variable up to 12 like default
    const columnMax: number = column === 'auto' ? undefined : column;

    const opts: GridOptions = {
      resizeDebounce: 100,
      column,
      cellHeight: 300, // Min cell height
      margin: 8, // Between cards
      columnOpts: { breakpoints: [{ w: 975, c: 1 }], columnMax: columnMax },
      float: false, // Sections "float" by not compacting vertically when there is space above them
      resizable: { autoHide: true, handles: 'all' }, // By default, only show handle in SE corner - override to all
      useNinetyOneColumnMode: false,
      ninetyOneColumnBreakpoint: DashboardConstants.ONE_COL_BREAKPOINT,
      ...options,
    };

    if (opts?.useNinetyOneColumnMode) delete opts['columnOpts'];

    return opts;
  }
}
