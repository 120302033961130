import ObjectId from 'bson-objectid';
import { FuseResult } from 'fuse.js';
import { merge } from 'lodash';
import { PartialDeep } from 'type-fest';

import { PersonMetadata } from '@ninety/ui/legacy/shared/models/_shared/person-metadata';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import {
  Accountability,
  AccountabilityCreatePick,
  AccountabilityUpdatePick,
} from '@ninety/ui/legacy/shared/models/accountability-chart/accountability';
import { SeatHolder } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-holder';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';

import { OrgChartSearchData } from '../components/search/user-seat-search/user-seat-search.component.model';
import { SeatFormPatch } from '../logic/seat-form-patch.logic';
import { PatchResponse } from '../services/seat-updater.service';

export function createSeatHolderFromUser(user: User, partial?: Partial<SeatHolder>): SeatHolder {
  const _default: SeatHolder = {
    _id: new ObjectId().toHexString(),
    chartId: new ObjectId().toHexString(),
    companyId: '',
    deleted: false,
    managerSeatId: '',
    ordinal: 0,
    seatId: '',
    userId: user._id,
    user,
  };
  return merge(_default, partial);
}

/** Create a user designed for the UserSelect and UserAvatar components */
export function createUser(partial?: PartialDeep<User>): User {
  const metadata: PersonMetadata = {
    country: undefined,
    preferredPronouns: '',
    name: {
      first: 'John',
      last: 'Doe',
    },
    picture: {
      url: '/stories/assets/images/newman-profile.png',
    },
  };

  const user: User = {
    _id: new ObjectId().toHexString(),
    preImplementer: undefined,
    settings: undefined,
    sms: undefined,
    metadata,
  };

  return merge({}, user, partial);
}

/** Create a seat designed for the Seat component */
export function createSeat(opts?: {
  partial?: Partial<SeatModel>;
  user?: User;
  nAccountabilities?: number;
  nSeatHolders?: number;
}): SeatModel {
  const chartId = opts?.partial?.chartId ?? new ObjectId().toHexString();
  const user = opts?.user ?? createUser();
  const seatHolders: SeatHolder[] =
    opts?.partial?.seatHolders ??
    Array.from({ length: opts?.nSeatHolders ?? 1 }, () =>
      createSeatHolderFromUser(user, {
        ordinal: 0,
        chartId,
      })
    );

  const accountabilities: Accountability[] =
    opts?.partial?.accountabilities ??
    Array.from({ length: opts?.nAccountabilities ?? 3 }, (_, i) =>
      Accountability.from({ name: `Accountability ${i + 1}`, chartId, ordinal: i })
    );

  const seat: SeatModel = {
    _id: new ObjectId().toHexString(),
    companyId: new ObjectId().toHexString(),
    name: 'Seat Name',
    visionary: false,
    integrator: false,
    ordinal: 0,
    seatHolders,
    accountabilities,
    chartId,
    attachments: [],
  };

  return merge({}, seat, opts?.partial);
}

export function createMockFuseResult(
  partial?: PartialDeep<FuseResult<OrgChartSearchData>>
): FuseResult<OrgChartSearchData> {
  const _default: FuseResult<OrgChartSearchData> = {
    item: {
      name: 'Seat Name',
      seatId: new ObjectId().toHexString(),
      user: createUser(),
    },
    refIndex: 0,
    score: 0,
    matches: [
      {
        indices: [[0, 3]],
      },
    ],
  };

  return merge({}, _default, partial);
}

export function createPatchResponse(partial: Partial<PatchResponse>): PatchResponse {
  const _default: PatchResponse = {
    seat: undefined,
    seatHolders: undefined,
    accountabilities: undefined,
  };

  return merge(_default, partial);
}

export function createMockSeatFormPatch(partial?: PartialDeep<SeatFormPatch>): SeatFormPatch {
  const _default: SeatFormPatch = {
    seatId: 'seatId',
    changeset: null,
    seat: null,
    accountabilities: {},
    seatHolders: {},
  };

  return merge(_default, partial);
}

export function createMockAccountabilityCreatePick(
  partial?: PartialDeep<AccountabilityCreatePick>
): AccountabilityCreatePick {
  const _default: AccountabilityCreatePick = {
    name: 'Accountability Name',
    ordinal: 0,
  };

  return merge(_default, partial);
}

export function createMockAccountabilityUpdatePick(
  partial?: PartialDeep<AccountabilityUpdatePick>
): AccountabilityUpdatePick {
  const _default: AccountabilityUpdatePick = {
    _id: new ObjectId().toHexString(),
  };

  return merge(_default, partial);
}
