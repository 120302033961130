/* eslint-disable @ngrx/prefix-selectors-with-select */
import { UrlTree } from '@angular/router';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';
import { selectCurrentUserIsManagerOrAbove } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import {
  selectIsV2DetailRoute,
  selectQueryParam,
  selectRouteData,
  selectRouteIsOfDetailType,
  selectRouteParam,
  selectUrlTree,
} from '@ninety/ui/legacy/state/route.selectors';

import { getPathRelativeToRoot } from '../../routes/org-chart-routing.logic';
import {
  ChartPageReferrer,
  ResponsibilityChartTopRoute,
  ResponsibilityChildRouteData,
  ResponsibilityChildRoutes,
} from '../../routes/org-chart-routing.model';

export namespace ResponsibilityNavigationSelectors {
  export const currentChildRoute: MemoizedSelector<any, ResponsibilityChildRoutes> = createSelector(
    selectRouteData,
    (data: ResponsibilityChildRouteData) => data?.tab
  );

  export const chartIdRouteParam = createSelector(selectRouteParam('chartId'), (id): string | null => (id ? id : null));

  export const routeDataRequestsHidingTabs = createSelector(
    selectRouteData,
    data => 'hideTabs' in data && data.hideTabs
  );

  export const shouldShowTabsOnChartPage = createSelector(
    routeDataRequestsHidingTabs,
    chartIdRouteParam,
    (routeDataRequestsHidingTabs, chartId): boolean => {
      // Allow clients to disable tabs via route params
      if (routeDataRequestsHidingTabs) return false;
      // Otherwise, only show tabs if we have a chartId (IE not viewing primary chart)
      return !chartId;
    }
  );

  /** This is the name of the primary company chart, for now, it is always 'Primary Chart' */
  export const primaryRouteTitle = createSelector(selectLanguage, language => `Primary ${language.acChart.chart}`);

  export const isPrimaryRouteActive = createSelector(
    currentChildRoute,
    (current: ResponsibilityChildRoutes) => current === ResponsibilityChildRoutes.chart
  );

  export const onChartPageInEditMode = createSelector(chartIdRouteParam, (chartId): boolean => !!chartId);

  export const onChartPageInViewOnlyMode = createSelector(
    isPrimaryRouteActive,
    chartIdRouteParam,
    (onPrimary, chartId): boolean => onPrimary && !chartId
  );

  export const shouldShowOrgChartTopToolbarOnChartPage = onChartPageInEditMode;

  /** Returns true when the {@link ResponsibilitiesDetailComponent} is rendered in the context of a chart. */
  export const isChartDetailRouteActive = createSelector(
    selectIsV2DetailRoute,
    selectRouteIsOfDetailType(DetailType.chart),
    (isV2DetailRoute, isChart): boolean => isV2DetailRoute && isChart
  );

  export const sharedRouteTitle = createSelector(selectCurrentUserIsManagerOrAbove, isManagerOrAbove =>
    isManagerOrAbove ? 'Drafts' : 'Shared With Me'
  );
  export const isSharedRouteActive = createSelector(
    currentChildRoute,
    (current: ResponsibilityChildRoutes) => current === ResponsibilityChildRoutes.shared
  );

  export const chartReferrerKey = createSelector(
    selectQueryParam('referrer'),
    (referrer: string | undefined | ChartPageReferrer): ChartPageReferrer => {
      switch (referrer) {
        case ResponsibilityChildRoutes.shared:
        case ResponsibilityChildRoutes.primary:
          return referrer;
        default:
          return ResponsibilityChildRoutes.primary;
      }
    }
  );

  export const breadcrumbsOnChartPage = createSelector(
    chartReferrerKey,
    selectLanguage,
    (referrer: ChartPageReferrer, language: CustomLanguage): string =>
      referrer === ResponsibilityChildRoutes.shared
        ? 'Back to ' + language.acChart.charts
        : 'Back to Primary ' + language.acChart.chart
  );

  export const breadcrumbAndReferrer = createSelector(
    breadcrumbsOnChartPage,
    chartReferrerKey,
    (breadcrumb, referrer): { breadcrumb: string; referrer: ChartPageReferrer } => ({ breadcrumb, referrer })
  );

  export const seatIdInRoute = createSelector(
    selectUrlTree,
    parsedUrl => parsedUrl.root.children.detail?.segments.at(-1)?.path
  );

  export const seatDetailType = createSelector(
    selectUrlTree,
    parsedUrl => parsedUrl.root.children.detail?.segments.at(-2)?.path
  );

  export const seatDetailIsCreate = createSelector(seatDetailType, type => type === 'create');
  export const seatDetailIsView = createSelector(seatDetailType, type => type === 'view');

  export const seatIdInRouteForTreeComponent = createSelector(seatDetailIsView, seatIdInRoute, (isView, id) =>
    isView ? id : null
  );

  export function getRouteSegmentsForPrimaryOrgChart(urlTree: UrlTree): string[] {
    const segments = getPathRelativeToRoot(ResponsibilityChartTopRoute)(urlTree);
    const parts = segments.map(segment => segment.path);
    parts.push(ResponsibilityChildRoutes.primary);
    return parts;
  }

  export const routeSegmentsForPrimaryOrgChart = createSelector(selectUrlTree, (urlTree: UrlTree): string[] =>
    getRouteSegmentsForPrimaryOrgChart(urlTree)
  );
}
