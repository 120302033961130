import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { ActiveMeetingResponse } from '@ninety/ui/legacy/shared/models/meetings-v2/active-meetings-v2-response';
import { GetPastMeetingsQueryParams } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-query-params';
import { PastMeetingsV2Response } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-response';

import { AddMeetingScheduleModel } from '../_models/add-meeting-schedule.model';
import { MeetingSchedule, MeetingScheduleResponse } from '../_models/meeting-schedule.model';

@Injectable({
  providedIn: 'root',
})
export class MeetingsV2Service {
  private meetingsApi = '/api/v4/Meetings';
  private meetingsV2Api = '/api/v4/meetings-v2';
  private schedulesApi = '/api/v4/schedules';

  http = inject(HttpClient);
  errorService = inject(ErrorService);
  store = inject(Store);

  private meetingSchedulesB$ = new BehaviorSubject<MeetingScheduleResponse[]>([]);
  meetingSchedules$ = this.meetingSchedulesB$.asObservable();

  getActiveMeetingInfo(companyId: string, teamId: string): Observable<ActiveMeetingResponse> {
    return this.http.get<ActiveMeetingResponse>(
      `${this.meetingsV2Api}/company/${companyId}/team/${teamId}/meetings/active-info`
    );
  }

  getPastMeetings(
    companyId: string,
    teamId: string,
    options: GetPastMeetingsQueryParams
  ): Observable<PastMeetingsV2Response> {
    return this.http.get<PastMeetingsV2Response>(
      `${this.meetingsV2Api}/company/${companyId}/team/${teamId}/meetings/past`,
      {
        params: QueryParamsService.build(options, true),
      }
    );
  }

  startMeeting(meetingId: string): Observable<Meeting> {
    return this.http.get<Meeting>(`${this.meetingsApi}/${meetingId}/Start`);
  }

  deleteMeeting(meetingId: string, companyId: string, teamId: string): Observable<void> {
    return this.http.delete<void>(`${this.meetingsV2Api}/company/${companyId}/team/${teamId}/meetings/${meetingId}`);
  }

  addMeetingSchedule(companyId: string, schedule: AddMeetingScheduleModel) {
    return this.http.post<AddMeetingScheduleModel>(`${this.schedulesApi}/company/${companyId}`, schedule);
  }

  getMeetingSchedules(options: { teamId: string; companyId: string }): Observable<MeetingScheduleResponse[]> {
    const { teamId, companyId } = options;
    return this.http.get<MeetingScheduleResponse[]>(`${this.schedulesApi}/company/${companyId}/team/${teamId}`);
  }

  setMeetingSchedules(schedules: MeetingScheduleResponse[]): void {
    this.meetingSchedulesB$.next(schedules);
  }

  deleteMeetingSchedule(
    scheduleId: string,
    options: { teamId: string; companyId: string; deleteAll: boolean; isRepeating: boolean; canceledForDate: string }
  ): Observable<void> {
    const { teamId, companyId, deleteAll, isRepeating, canceledForDate } = options;
    const params = isRepeating ? { deleteAll, ...(canceledForDate ? { canceledForDate } : {}) } : {};
    return this.http.delete<void>(`${this.schedulesApi}/company/${companyId}/team/${teamId}/schedules/${scheduleId}`, {
      params,
    });
  }

  updateMeetingSchedule(
    scheduleId: string,
    update: Pick<MeetingSchedule, 'scheduledDate' | 'presenterId'>,
    options: { teamId: string; companyId: string; updateAll: boolean; isRepeating: boolean }
  ): Observable<MeetingScheduleResponse> {
    const { teamId, companyId, updateAll, isRepeating } = options;
    const params = isRepeating ? { updateAll } : {};

    return this.http.patch<MeetingScheduleResponse>(
      `${this.schedulesApi}/company/${companyId}/team/${teamId}/schedules/${scheduleId}`,
      update,
      { params }
    );
  }

  downloadExcelPastMeetings(
    companyId: string,
    teamId: string,
    options: GetPastMeetingsQueryParams
  ): Observable<ArrayBuffer> {
    const url = `${this.meetingsV2Api}/company/${companyId}/team/${teamId}/meetings/past/excel`;
    return this.http.get(url, {
      params: QueryParamsService.build(options, true),
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });
  }
}
