import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { UserInviteStatus } from '@ninety/ui/legacy/shared/models/_shared/user-invite-status';
import { selectAnonymousPartnerConfiguration } from '@ninety/ui/legacy/state/app-global/anonymous/anonymous-state.selectors';
import { PoliciesActions } from '@ninety/ui/legacy/state/app-global/policies/policies.actions';

import { LoginSharedStateActions, UserStatusDialogActions } from './login-shared-state.actions';
import * as loginSelectors from './login-shared-state.selectors';
import * as selectors from './login-shared-state.selectors';

@Injectable()
export class LoginSharedStateFacade {
  loginSplash$ = this.store.select(selectors.selectLoginSplash);
  loginLogo$ = this.store.select(selectors.selectLoginLogo);
  isPartnerBrand$ = this.store.select(selectors.selectIsPartnerBrand);
  isNinetyBrand$ = this.store.select(selectors.selectIsNinetyBrand);
  bos$ = this.store.select(selectAnonymousPartnerConfiguration);
  prefillFields$ = this.store.select(loginSelectors.selectPrefillFields);

  constructor(private store: Store) {}

  openTermsConditionsDialog() {
    this.store.dispatch(PoliciesActions.displayTermsOfService());
  }

  openPrivacyPolicyDialog() {
    this.store.dispatch(PoliciesActions.displayPrivacyPolicy());
  }

  navigateToLogin(event: MouseEvent) {
    this.store.dispatch(LoginSharedStateActions.navigateToLogin({ event }));
  }

  redirectToLogin(userInviteStatus: UserInviteStatus) {
    this.store.dispatch(UserStatusDialogActions.dialogClosed({ userInviteStatus }));
  }
}
