import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

import { PersonMetadata } from '@ninety/ui/legacy/shared/models/_shared/person-metadata';

@Component({
  selector: 'ninety-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialComponent implements OnInit {
  @Input() metadata: PersonMetadata;

  @Output() updateMetadata = new EventEmitter<Partial<PersonMetadata>>();

  updateMetadata$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.updateMetadata$.pipe(debounceTime(1000)).subscribe({ next: () => this.updateMetadata.emit(this.metadata) });
  }

  debounceUpdate() {
    this.updateMetadata$.next();
  }

  openLink(inUrl: string): void {
    window.open(inUrl, '_blank');
  }
}
