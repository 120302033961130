import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { InlineTeamsSelectComponent } from '@ninety/web/pages/directory-v2/selects/inline-teams-select/inline-teams-select.component';

@Component({
  selector: 'ninety-inline-team-select-form',
  standalone: true,
  imports: [CommonModule, InlineTeamsSelectComponent],
  template: ` <ninety-inline-teams-select (saveTeams)="writeValue($event)" [selectedTeams]="_teams" [id]="id" /> `,
  styles: [
    `
      :host {
        max-width: 200px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineTeamSelectFormComponent),
      multi: true,
    },
  ],
})
export class InlineTeamSelectFormComponent implements ControlValueAccessor {
  @Input() id: string;
  protected _teams: Team[];

  /** ControlValueAccessor implementation */
  onChange: (value: Team[]) => void = teams => {
    if (!teams) return;
    this._teams = teams;
  };
  onTouched: () => void = () => {};

  writeValue(value: Team[]): void {
    if (!value) return;
    this._teams = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: Team[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
}
