// TODO: Work with data team to normalize event names
export enum SegmentTrackEvent {
  ADDED_PERSON_TO_DIRECTORY = 'Added Person To Directory',
  AGREEMENT_BASED_TODOS_CHANGED = 'AB Todos Changed',
  CASCADED_CREATED = 'Cascaded Message Created',
  CASCADED_SET_COMPLETED = 'Cascaded Message Set Completed',
  DATA_IMPORT_RECEIVED_DATA_FROM_ONE_SCHEMA = 'Data Import Received Data From One Schema',
  DATA_IMPORT_CANCELED = 'Data Import Canceled',
  DATA_IMPORT_CREATE_USERS = 'Data Import Create Users',
  DATA_IMPORT_CREATE_ISSUES = 'Data Import Create Issues',
  DATA_IMPORT_CREATE_ROCKS = 'Data Import Create Rocks',
  DATA_IMPORT_CREATE_TODOS = 'Data Import Create Todos',
  GETTING_STARTED_GUIDE_FORCED_OPEN = 'Getting Started Guide Forced Open',
  HEADLINE_CREATED = 'Headline Created',
  HEADLINE_SET_COMPLETED = 'Headline Set Completed',
  INS_CONVERSATION_TYPE_CHANGE = 'Insights Conversation Type Change',
  INS_PERIOD_CHANGE = 'Insights Period Change',
  INS_LAC_COMMITMENT_TYPE_CHANGE = 'Insights LAC Commitment Type Change',
  INS_MANAGER_CHANGE = 'Insights Selected Manager Change',
  INS_ROCK_TYPE_CHANGE = 'Insights Selected Rock Type Change',
  INS_ROCK_PERIOD_CHANGE = 'Insights Selected Rock Period Change',
  INS_RPRS = 'Insights Generated RPRS Report',
  INS_WIDGET_EXPANDED = 'Insights Widget Expanded',
  INS_WIDGET_COLLAPSED = 'Insights Widget Collapsed',
  INS_TEAM_CHANGE = 'Insights Team Change',
  INVITED_USER = 'Invited A User',
  ISSUE_CREATED = 'Issue Created',
  ISSUE_SHORT_TERM_SET_COMPLETED = 'Issue Short Term Set Completed',

  /* ========== KPI Detail ============ */
  KPI_CREATED = 'KPI Created',
  KPI_UPDATED = 'KPI Updated',
  KPI_ADDED_ATTACHMENT = 'KPI Added Attachment',
  KPI_GROUP_ADDED_KPIS = 'Added KPIs to KPI Group',
  KPI_GROUP_CREATED = 'KPI Group Created',
  KPI_SCORE_CHANGED = 'KPI Score Changed',
  KPI_OPENED_CREATE_DETAIL = 'KPI Opened Create Detail',

  /* ========= KPI Manager =============== */
  KPI_MANAGER_FILTER_ARCHIVE_CHANGED = 'KPI Manager Filter Archive Viewed',
  KPI_MANAGER_FILTER_KPI_TYPE_CHANGED = 'KPI Manager Filter KPI Type Changed',
  KPI_MANAGER_FILTER_OWNER_CHANGED = 'KPI Manager Filter Owner Changed',
  KPI_MANAGER_FILTER_TEXT_CHANGED = 'KPI Manager Search Text Changed',
  KPI_MANAGER_KPIS_BULK_ARCHIVED = 'KPI Manager KPIs Bulk Archived',
  KPI_MANAGER_KPIS_BULK_DELETED = 'KPI Manager KPIs Bulk Deleted',
  KPI_MANAGER_KPIS_BULK_DUPLICATED = 'KPI Manager KPIs Bulk Duplicated',
  KPI_MANAGER_KPIS_BULK_REASSIGNED = 'KPI Manager KPIs Bulk Reassigned',
  KPI_MANAGER_KPIS_CSV_EXPORTED = 'KPI Manager KPIs CSV Exported',
  KPI_MANAGER_KPIS_BULK_UNARCHIVED = 'KPI Manager KPIs Bulk Unarchived',
  KPI_MANAGER_PAGE_SIZE_CHANGED = 'KPI Manager Page Size Changed',
  KPI_MANAGER_TABLE_SORT_CHANGED = 'KPI Manager Table Sort Changed',

  LINKED_ITEM_CREATED = 'Linked Item Created',
  LINKED_ITEM_OPENED = 'Linked Item Opened',
  LOGIN = 'Logged In',
  MAC_CLONED = 'MAC Cloned',
  MAC_CREATED = 'MAC Created',
  MAC_SET_PUBLIC_CHART = 'MAC Set Public Chart',
  MAZ_CHAT_TOGGLED = 'Maz Chat Window Toggled',
  MAZ_CHAT_OPT_IN = 'Maz Chat User Opted In',
  MAZ_CHAT_OPT_OUT = 'Maz Chat User Opted Out',
  MAZ_CHAT_SENT_MESSAGE = 'User Sent Message in Maz Chat',
  /** @deprecated */
  MEASURABLE_CREATED = 'Measurable Created',
  MEETING_CREATE = 'Meeting Created',
  MEETING_SCHEDULE_CREATE = 'Meeting Schedule Created',
  ORG_ATTACHMENT_EVENT = 'ORG Attachment Event',
  ORG_CREATE_SEAT = 'ORG Create Seat',
  ORG_LAUNCH_SEAT_DETAILS = 'ORG Launch Seat Details',
  ORG_MANAGER_CHANGED = 'ORG Manager Changed',
  ORG_UPDATE_SEAT = 'ORG Update Seat',
  PAYMENT_ADDED = 'Payment Method Added',
  REGISTER_COMPANY = 'Company Registered',
  REGISTER_COMPANY_APPLY_COUPON_FAILED = 'register-company-auto-discount-failed',
  REGISTER_COMPANY_FAIL = 'Company Registration Failed',
  ROCK_CONTEXT_LINKED_ITEM_CREATED = 'Created linked item from rocks context menu',
  ROCK_CREATED = 'Rock Created',
  ROCK_STATUS_UPDATED = 'Rock Status Updated',
  SUBSCRIPTION_CANCELLED = 'Subscription Cancelled',
  THEME_CHANGED = 'Theme Changed',
  TEAM_ARCHIVED = 'Team Archived',
  TODO_CREATED = 'Todo Created',
  TODO_SET_COMPLETED = 'Todo Set Completed',
  TODOS_TABLE_CONTEXT_MENU = 'Todos Table Opened Context Menu',
  TRIAL_STARTED = 'Trial Started',
  UPDATED_STRIPE_PAYMENT_METHOD = 'Updated Stripe Payment Method',
  USER_EVENT_NOTIFICATION_CHANGED = 'Event Notification Settings Changed',
  GOOGLE_TASK_INTEGRATION_CHANGED = 'Google Task Integration Changed',
  MICROSOFT_TASK_INTEGRATION_CHANGED = 'Microsoft Task Integration Changed',

  ERROR = 'Error',
}
