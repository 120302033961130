import { GridWidgetById } from '../models/grid-widget-by-id';
import { GridOptions } from '../models/grid.options';

export const GRID_LAYOUT_STATE_KEY = 'grid-layout';

/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
export interface GridState {
  /**
   * When true, drag/drop/resize are all possible.
   *
   * Note, it is possible to enable only one or the other, but we have no uses for that, so we abstract over that
   * GridStack implementation detail here.
   */
  editLayoutEnabled: boolean;

  /**
   * Options to configure GridStack. Can't be modified once the grid is created.
   */
  opts: Readonly<GridOptions>;

  /**
   * Record of the current widgets in the grid, accessible by id.
   */
  viewModel: GridWidgetById;

  /**
   * When this value is non-null, the grid is hidden and only the template corresponding to this key is shown.
   *
   * Used to hide the grid when the detail view is active and only show the template that corresponds to the detail
   * view.
   */
  singletonTemplate: string | null;

  /**
   * When true, the grid is not displayed and widgets are rendered as a single column. Note, we have taken over the 1
   * column flow and no longer defer to GridStack's built in 1 column mode in My90 (VTO still uses GridStack). This property is valid regardless of which version we use.
   */
  inOneColumnMode?: boolean;

  /**
   * When true, the grid has been rendered in either 1 column or grid mode. This is used to determine when to remove the
   * grid skeleton from the DOM.
   */
  firstPaintComplete?: boolean;

  /**
   * True when GridStack has been initalized.
   *
   * There are cases when there is grid state (IE this model exists in NGRX) but this property is false. This allows us
   * to preserve the state of GridStack between destroys of the underlying GridComponent. This happens when we move
   * between tabs on the VTO page. There, we need to preserve the state of the grid so we can preserve isEditingLayout
   * during tab changes.
   */
  isInitialized: boolean;
}

/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
export interface GridLayoutStateModel {
  /**
   * The current grid being displayed
   */
  currentGrid: GridState | null;
}

/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
export const INITIAL_GRID_LAYOUT_STATE_MODEL: GridLayoutStateModel = {
  currentGrid: null,
};
