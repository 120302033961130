import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { AcceptInviteStateKey, AcceptInviteStateModel } from './accept-invite/accept-invite-state.model';
import { acceptInviteStateReducer } from './accept-invite/accept-invite-state.reducer';
import { LoginSharedStateModel, LoginSharedStateKey } from './shared/login-shared-state.model';
import { loginSharedStateReducer } from './shared/login-shared-state.reducer';
import { SignupStateKey, SignupStateModel } from './sign-up/signup-state.model';
import { signupStateReducer } from './sign-up/signup-state.reducer';

export const LoginStateFeatureKey = 'login';

export interface LoginFeatureState {
  [LoginSharedStateKey]: LoginSharedStateModel;
  [SignupStateKey]: SignupStateModel;
  [AcceptInviteStateKey]: AcceptInviteStateModel;
}

export const loginReducers: ActionReducerMap<LoginFeatureState> = {
  [LoginSharedStateKey]: loginSharedStateReducer,
  [SignupStateKey]: signupStateReducer,
  [AcceptInviteStateKey]: acceptInviteStateReducer,
};

export const selectLoginFeature = createFeatureSelector<LoginFeatureState>(LoginStateFeatureKey);
