import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';

import { OptionListDirective } from '../directives/option-list.directive';

@Component({
  selector: 'ninety-select-toggle-button',
  standalone: true,
  imports: [ButtonComponent, NgIf, TerraIconModule],

  template: `
    <ninety-button flat icon *ngIf="optionList?.showToggleButton" [disabled]="optionList?.disabled">
      <terra-icon [icon]="isOpen ? 'caret-up' : 'caret-down'" size="20" />
    </ninety-button>
  `,
  styles: [
    `
      @import '../styles';

      :host {
        @include style-select-button;
      }
    `,
  ],
})
export class NinetySelectToggleButtonComponent<T = unknown> {
  @Input() isOpen: boolean;
  @Input() optionList: OptionListDirective<T>;
}
