import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, first, map, switchMap } from 'rxjs';

import {
  Accountability,
  AccountabilityCreatePick,
  AccountabilityUpdatePick,
} from '@ninety/ui/legacy/shared/models/accountability-chart/accountability';

import { ResponsibilityChartSelectors } from '../_state/chart/responsibility-chart.selectors';

import { WithChart } from './org-chart-api-path.builder';

@Injectable({
  providedIn: 'root',
})
export class AccountabilityApiService {
  constructor(private store: Store, private http: HttpClient) {}

  createMany(seatId: string, createPicks: AccountabilityCreatePick[]): Observable<Accountability[]> {
    return this.getAccountabilityUrl(seatId).pipe(
      switchMap((url: string) => this.http.post<Accountability[]>(url, { accountabilities: createPicks }))
    );
  }

  updateMany(seatId: string, updatePicks: AccountabilityUpdatePick[]): Observable<null> {
    return this.getAccountabilityUrl(seatId).pipe(
      switchMap((url: string) => this.http.patch(url, { accountabilities: updatePicks })),
      map(() => null)
    );
  }

  removeMany(seatId: string, ids: string[]): Observable<null> {
    return this.getAccountabilityUrl(seatId).pipe(
      switchMap((url: string) => this.http.delete(url, { body: { ids } })),
      map(() => null)
    );
  }

  private getAccountabilityUrl(seatId: string): Observable<string> {
    return this.store.select(ResponsibilityChartSelectors.apiPathBuilderForChart).pipe(
      first(),
      map((builder: WithChart): string => builder.seats(seatId).accountabilities().toUrl())
    );
  }
}
