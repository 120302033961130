<div
  id="{{ select.id }}-trigger"
  class="select-pill"
  [ngClass]="{
    disabled: select.disabled,
    readonly: select.readonly
  }"
  [ninetySelectLauncher]="selectOverlay"
  #selectLauncher="ninetySelectLauncher"
  positioningStrategy="below"
  [disabled]="select.disabled || select.readonly"
  (isOpenChange)="onOpenChange($event)">
  <span class="pill-label">Team:</span>
  <span class="pill-text">
    <ng-container
      *ngIf="
        shouldShowTeamAll &&
          (select.selected?.length === 0 || select.selected?.[0]._id === teamAll._id);
        else teamSelected
      ">
      <span id="{{ select.id }}-indicator-for-all">All</span>
    </ng-container>
    <ng-template #teamSelected>
      <span *ngrxLet="$any(select.selected[0]) as selected" id="{{ select.id }}-indicator-for-{{ selected._id }}">
        {{ selected.name }}
      </span>
    </ng-template>
  </span>
  <terra-icon
    class="pill-expand-collapse"
    icon="caret-down"
    variant="bold"
    size="16"
    [ngClass]="{
      'icon-down': !selectLauncher.isOpen,
      'icon-up': selectLauncher.isOpen
    }" />
</div>

<ng-template #selectOverlay>
  <div class="select-pill-overlay" id="{{ select.id }}-overlay" *ngrxLet="fuseProvider.fuse.managedOptions$ as teams">
    <ninety-input-wrapper variant="outline" *ngIf="teams?.length > 5">
      <terra-icon ninetyInputPrefix icon="search" />
      <input
        ninetyAutoComplete
        [ninetyNotifyIntersect]="true"
        (isIntersecting)="input.focus()"
        #input
        #autoComplete="ninetyAutoComplete"
        id="{{ select.id + '-input' }}"
        [variant]="null"
        placeholder="Search teams..." />
      <ninety-button
        *ngIf="fuseProvider.fuse.hasSearchTerm$ | ngrxPush"
        flat
        icon
        ninetyInputSuffix
        [attr.id]="select.id + '-clear-btn'"
        (keyup.space)="search(null); autoComplete.clear()"
        (keyup.enter)="search(null); autoComplete.clear()"
        (click)="search(null); autoComplete.clear()">
        <terra-icon icon="x" size="20" />
      </ninety-button>
    </ninety-input-wrapper>

    <div *ngIf="fuseProvider.fuse.hasNoResults$ | ngrxPush; else optionList" class="empty-state">
      {{ select.noResultsText }}
    </div>

    <ng-template #optionList>
      <div
        ninetyOptionList
        [ngModel]="select.selected"
        (ngModelChange)="onSelectValue($event)"
        id="{{ select.id }}-option-list">
        <div
          *ngIf="shouldShowTeamAll && !(fuseProvider.fuse.hasSearchTerm$ | ngrxPush)"
          ninetyOption
          [value]="teamAll"
          class="all-option"
          id="{{ select.id }}-option-all"
          data-option-name="all">
          {{ teamAll.name }}
        </div>
        <div
          ninetyOption
          *ngFor="let option of teams; trackBy: trackBy"
          id="{{ select.id }}-option-{{ option.value._id }}"
          class="team-option"
          [style.display]="option.visible ? '' : 'none'"
          [value]="option.value"
          [attr.data-option-name]="option.value.name">
          <span
            *ngIf="option.match?.matches; else inline"
            [innerHTML]="option.value.name | boldSearchResults : option.match.matches">
          </span>
          <ng-template #inline>{{ option.value.name }}</ng-template>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
