import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, of, Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { EMAIL_REGEX } from '@ninety/ui/legacy/core/services/helper.service';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { UserTeam } from '@ninety/ui/legacy/shared/models/directory/user-team';
import { selectIsEmailAvailable } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

import { ImportUserForm, ImportUserFormArray } from '../models/import-user-form';
import { ImportUserRecord } from '../models/import-user-record';
import { UserBillingCounts } from '../models/user-billing-counts';

@Injectable({
  providedIn: 'root',
})
export class UserImportFormService {
  /** full exposure for reactive form mutability */
  form: ImportUserFormArray;
  private readonly destroy$ = new Subject<void>();
  /** for knowing when the form has been mutated */
  private readonly _formChanged = new Subject<void>();
  readonly formChanged$ = this._formChanged.asObservable();

  constructor(private store: Store, private fb: FormBuilder) {}

  getUsersFromForm(): InviteUserPayload[] {
    return this.form.getRawValue().map(ImportUserRecord.fromFormData);
  }

  getUserBillingCounts(): UserBillingCounts {
    const userForms = this.form.getRawValue();
    const paidUsers = userForms.filter(u => u.roleCode !== RoleCode.observer && !u.isImplementer).length;
    return {
      totalUsers: userForms.length,
      paidUsers,
      freeUsers: userForms.length - paidUsers,
    };
  }

  destroy() {
    this.form = null;
    this.destroy$.next();
    this.destroy$.complete();
  }

  deleteUser(index: number) {
    this.form.removeAt(index);
    this.form.markAsTouched();
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
    this._formChanged.next();
  }

  buildImportUserFormArray(users: ImportUserRecord[]): void {
    this.form = users.reduce((form: FormArray, user: ImportUserRecord) => {
      form.push(this.createUserFormGroup(user));
      return form;
    }, this.fb.array([]));
  }

  createUserFormGroup(user: ImportUserRecord): FormGroup<ImportUserForm> {
    const group = this.fb.group({
      firstName: new FormControl<string>(user.firstName, [Validators.required]),
      lastName: new FormControl<string>(user.lastName, [Validators.required]),
      email: new FormControl<string>(user.email, {
        validators: [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEX)],
        asyncValidators: (control: AbstractControl) =>
          of(control.value).pipe(
            distinctUntilChanged(),
            debounceTime(500),
            concatLatestFrom(email => this.store.select(selectIsEmailAvailable(email))),
            map(([email, emailAvailable]) => (emailAvailable ? null : { emailTaken: email }))
          ),
      }),
      selectedTeams: new FormControl<Team[]>(user.selectedTeams, [Validators.required]),
      role: new FormControl<RoleSelectOption>(user.role, [Validators.required]),

      /** Hidden controls */
      teams: new FormControl<UserTeam[]>(user.teams, [Validators.required]),
      roleCode: new FormControl<RoleCode>(user.roleCode),
      isImplementer: new FormControl<boolean>(user.isImplementer),
      companyId: new FormControl<string>(user.companyId),
      hasBeenInvited: new FormControl<boolean>(user.hasBeenInvited),
      active: new FormControl<boolean>(user.active),
      nonMatchingTeamNames: new FormControl<string[]>(user.nonMatchingTeamNames),
    });

    /** listen for changes to role and selected teams controls
     * and update the hidden controls we care about to match the signature needed for InviteUserPayload */

    group
      .get('role')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((role: RoleSelectOption) => {
        if (role) {
          group.get('roleCode')?.setValue(role.roleCode, { emitEvent: false });
          group.get('isImplementer')?.setValue(role.isImplementer, { emitEvent: false });
          this.setTeamsRequiredForNonCoaches(group, role.isImplementer);
        }
      });

    group
      .get('selectedTeams')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((teams: Team[]) => {
        if (teams.length) {
          group.get('teams')?.setValue(teams.map(({ _id: teamId }) => ({ teamId })));
        }
      });

    return group;
  }

  setTeamsRequiredForNonCoaches(group: FormGroup<ImportUserForm>, isCoach: boolean) {
    if (isCoach) {
      group.get('teams').clearValidators();
      group.get('selectedTeams').clearValidators();
    } else {
      group.get('teams').setValidators([Validators.required]);
      group.get('selectedTeams').setValidators([Validators.required]);
    }
    group.get('teams').updateValueAndValidity();
    group.get('selectedTeams').updateValueAndValidity();
  }
}
