import { Parameters } from '@storybook/types';
import { merge } from 'lodash';

export function withStoryDescription(message: string, partial?: Partial<Parameters>): Parameters {
  return merge({}, partial, {
    docs: {
      description: {
        story: message,
      },
    },
  });
}
