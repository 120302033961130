import { PartialDeep } from 'type-fest';

import { CustomLanguage } from './custom-language';

export class MockCustomLanguage implements CustomLanguage {
  dashboard = {
    route: 'Dashboard',
  };
  my90 = {
    route: 'My 90',
    //v2
    teamTodos: 'Team Todos',
    personalTodos: 'Private To-Dos',
    rocksAndMilestones: 'Rocks & Milestones',
    scorecard: 'Scorecard',
    todoChart: 'To-Dos Created Last 90 Days',
    rockChart: 'Rock Statuses',
    measurableChart: 'Measurables',
  };
  scorecard = {
    route: 'Data',
    item: 'Scorecard',
    items: 'Scorecards',
  };
  measurable = {
    item: 'Measurable',
    items: 'Measurables',
    goal: 'Goal',
    goals: 'Goals',
  };
  rock = {
    route: 'Rocks',
    item: 'Rock',
    items: 'Rocks',
    company: 'Company',
    departmental: 'Departmental',
  };
  todo = {
    route: 'To-Dos',
    item: 'To-Do',
    items: 'To-Dos',
    team: 'Team',
    personal: 'Private',
  };
  issue = {
    route: 'Issues',
    item: 'Issue',
    items: 'Issues',
    shortTerm: 'Short-Term',
    longTerm: 'Long-Term',
  };
  milestone = {
    item: 'Milestone',
    items: 'Milestones',
  };
  headline = {
    item: 'Headline',
    items: 'Headlines',
  };
  cascadingMessage = {
    item: 'Cascading Message',
    items: 'Cascading Messages',
    action: 'Cascade',
    actionPast: 'Cascaded',
  };
  meeting = {
    route: 'Meetings',
    item: 'Meeting',
    items: 'Meetings',
    levelTen: 'Level 10 Meeting™',
    levelTens: 'Level 10 Meetings™',
    quarterly: 'Quarterly',
    quarterlySession: 'Quarterly Session',
    quarterlySessions: 'Quarterly Sessions',
    annual: 'Annual',
    annualSession: 'Annual Session',
    annualSessions: 'Annual Sessions',
    focusDay: 'Focus Day',
    visionBuildingDayOne: 'Vision Building Day 1',
    visionBuildingDayTwo: 'Vision Building Day 2',
  };
  vto = {
    route: 'V/TO™',
    item: 'V/TO™',
    items: 'V/TOs™',
    vision: 'Vision',
    traction: 'Traction',
    longTermIssues: 'Long-Term Issues',
    swot: 'SWOT',
  };
  acChart = {
    route: 'Accountability Chart',
    seat: 'Seat',
    seats: 'Seats',
    seatHolder: 'Seat Holder',
    seatHolders: 'Seat Holders',
    rolesAndResponsibilities: 'Roles and Responsibilities',
    visionary: 'Visionary',
    integrator: 'Integrator',
    chart: 'Chart',
    charts: 'Charts',
  };
  feedback = {
    route: '1 on 1s',
    item: 'Discussion',
    items: 'Discussions',
    quarterlyItem: 'Quarterly Discussion',
    quarterlyItems: 'Quarterly Discussions',
    annualItem: 'Annual Review',
    annualItems: 'Annual Reviews',
    managee: 'Managee',
    manager: 'Manager',
    meeting: 'Meeting',
    peopleAnalyzer: 'Fit Check',
    CCC: 'CCC',
    competency: 'Competency',
    commitment: 'Commitment',
    capacity: 'Capacity',
    LAC: 'LAC',
    leadershipCommitments: 'Leadership Commitments',
    coachingCommitments: 'Coaching Commitments',
  };
  process = {
    route: 'Process',
    item: 'Process',
    items: 'Processes',
    coreProcess: 'Core Process',
    step: 'Step',
    steps: 'Steps',
    subStep: 'Sub-Step',
    subSteps: 'Sub-Steps',
    documented: 'Documented',
    followedByAll: 'Followed By All',
    effective: 'Effective',
  };
  directory = {
    route: 'Directory',
    owner: 'Owner',
    admin: 'Admin',
    manager: 'Manager',
    managee: 'Managee',
    observer: 'Observer',
    implementer: 'Implementer',
  };

  mastery = {
    route: 'Mastery',
    item: 'Resource',
    items: 'Resources',
  };
  survey = {
    route: 'Survey',
    item: 'Survey',
    items: 'Surveys',
    admin: 'Admin',
    mySurveys: 'My Surveys',
    recipient: 'recipient',
    recipients: 'recipients',
  };

  constructor(overrides?: PartialDeep<CustomLanguage>) {
    Object.assign(this, overrides);
  }
}
