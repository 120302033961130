import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * For compatibility, we need to pass a flag to the legacy scorecard endpoints. This is used to determine whether
 * we need to source data from `teams.measurables` or the new mongo collections.
 *
 * This can be removed after the Strategic Scorecard has went to GA and we are sure we won't revert.
 */
@Injectable()
export class LegacyScorecardApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.url.includes('/Measurables')) {
      return next.handle(req);
    }

    const newReq = req.clone({
      params: req.params.append('isInBeta', true),
    });

    return next.handle(newReq);
  }
}
