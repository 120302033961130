import { NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';

import { DownloadButtonStateEnum } from '../../models/pdf-loading-state.enum';

/**
 * A button with a print, loading, and download state. Typically used in conjunction with {@link ArPdfGeneratorDirective} to download
 * a PDF. Intended to be used on the far right of a component - as such, it hugs the right of the element and preserves the maximum space
 * it will need. This prevents layout shifts as the button moves through its states.
 */
@Component({
  selector: 'ninety-report-download-button',
  standalone: true,
  imports: [ButtonComponent, TerraIconModule, NgSwitchCase, NgSwitch],
  templateUrl: './report-download-button.component.html',
  styleUrls: ['./report-download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportDownloadButtonComponent {
  protected readonly DownloadButtonStateEnum = DownloadButtonStateEnum;

  @Input({ required: true }) state: DownloadButtonStateEnum;

  /** Emits when the "Generate PDF" button is clicked. */
  @Output() createPdf = new EventEmitter<void>();

  /** Emits when the "View Report" button is clicked. */
  @Output() openPdf = new EventEmitter<void>();
}
