import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { GuideState, INITIAL_GUIDE_STATE } from '../guide/_state/guide.model';
import { GuideReducer } from '../guide/_state/guide.reducer';
import { INITIAL_MAZ_STATE, MazState } from '../maz/_state/maz.model';
import { MazReducer } from '../maz/_state/maz.reducer';

export const GETTING_STARTED_STATE_KEY = 'getting-started';
export const GUIDE_STATE_KEY = 'guide';
export const MAZ_STATE_KEY = 'maz';

export const selectGettingStartedState = createFeatureSelector<GettingStartedState>(GETTING_STARTED_STATE_KEY);

export const selectGuideState = createSelector(selectGettingStartedState, gettingStartedState =>
  gettingStartedState ? gettingStartedState[GUIDE_STATE_KEY] : INITIAL_GUIDE_STATE
);

export const selectMazState = createSelector(selectGettingStartedState, gettingStartedState =>
  gettingStartedState ? gettingStartedState[MAZ_STATE_KEY] : INITIAL_MAZ_STATE
);

export interface GettingStartedState {
  [GUIDE_STATE_KEY]: GuideState;
  [MAZ_STATE_KEY]: MazState;
}

export const GettingStartedReducers: ActionReducerMap<GettingStartedState> = {
  [GUIDE_STATE_KEY]: GuideReducer,
  [MAZ_STATE_KEY]: MazReducer,
};
