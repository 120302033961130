import { OneSchemaData } from '../one-schma-data';

import { IssueRecord } from './issue-record';
import { RockRecord } from './rock-record';
import { TodoRecord } from './todo-record';
import { UserRecord } from './user-record';

export * from './issue-record';
export * from './rock-record';
export * from './todo-record';
export * from './user-record';

export type IssueRecordsData = { data: OneSchemaData<IssueRecord> };
export type RockRecordsData = { data: OneSchemaData<RockRecord> };
export type TodoRecordsData = { data: OneSchemaData<TodoRecord> };
export type UserRecordsData = { data: OneSchemaData<UserRecord> };
