import { Update } from '@ngrx/entity';

import { TreeNodeInput } from '@ninety/ui/legacy/components/tree/models/ninety-hierarchy-point-node.model';
import { Accountability } from '@ninety/ui/legacy/shared/models/accountability-chart/accountability';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { ImplementationError } from '@ninety/ui/legacy/shared/models/errors/implementation-error';
import { ValueDescription } from '@ninety/web/pages/future-libs';

import { SeatDetailFormValueReadonly } from '../components/seat-detail-form/seat-detail-form.component.model';

/**
 * A type which requires a parentSeatId, does not allow an _id, and allows all other properties to be optional
 */
export type CreateSeatModel = Required<Pick<SeatModel, 'parentSeatId'>> & Omit<SeatModel, '_id'>;

export type CreateSeatModelDefaults = Pick<SeatModel, 'companyId' | 'ordinal' | 'chartId'>;

function mapValueDescriptionToAccountability(
  acc: ValueDescription,
  extraProps: Omit<CreateSeatModelDefaults, 'ordinal'>
): Accountability {
  return {
    name: acc.value,
    description: acc.description,
    ordinal: acc.ordinal,
    deleted: false,
    companyId: extraProps.companyId,
    chartId: extraProps.chartId,
  };
}

export function mapFormToCreateSeatModel(
  form: SeatDetailFormValueReadonly,
  extraProps: CreateSeatModelDefaults
): CreateSeatModel {
  const { companyId, ordinal, chartId } = extraProps;
  const parentSeatId: string = form.parentSeatId[0];
  const accountabilities = form.accountabilities.map((acc: ValueDescription) =>
    mapValueDescriptionToAccountability(acc, { companyId, chartId })
  );

  return {
    companyId,
    ordinal,
    chartId,
    parentSeatId,
    accountabilities,
    name: form.seatName,
    visionary: false,
    integrator: false,
  };
}

export type UpdateSeatModel = Update<Pick<SeatModel, 'name' | 'parentSeatId' | 'ordinal'>>;

export function extractTreeInputFromSeat(node: SeatModel): TreeNodeInput {
  return {
    _id: node._id,
    parentId: node.parentSeatId,
    ordinal: node.ordinal,
  };
}

/**
 * Clone an existing seat an insert it at the specified new ordinal. Does NOT copy seat holders by design. Expected
 * that a new clone has no seat holders, regardless of the original seat.
 *
 * Note, the API will take care of duplicating any attachments and ensuring the accountabilities get attached to the new
 * seat properly. Seat Holders are not cloned.
 */
export function cloneSeat(seat: SeatModel, ordinal: number): CreateSeatModel {
  const { parentSeatId, companyId, name, attachments, accountabilities, visionary, integrator, chartId } = seat;
  if (visionary) throw new ImplementationError('Visionary seats cannot be cloned');
  if (integrator) throw new ImplementationError('Integrator seats cannot be cloned');

  const clone: CreateSeatModel = {
    name,
    companyId,
    parentSeatId,
    attachments,
    ordinal,
    chartId,
    visionary: false,
    integrator: false,
  };

  if (accountabilities) {
    clone.accountabilities = accountabilities.map(acc => {
      const { seatId, _id, ...clonedAccountability } = acc;
      return clonedAccountability;
    });
  }

  return clone;
}

export interface ChartPreferences {
  [key: string]: { lastTopSeatId: string };
}
