import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, exhaustMap } from 'rxjs';

import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';

import { ConversationSettingsApiService } from '../../_api/conversation-settings-api.service';
import { ConversationSettingsResponseModel } from '../../_api/models/conversation-settings-response.model';

import { FormalConversationState, defaultFormalConversationState } from './formal-conversation-settings.model';

@Injectable()
export class FormalConversationSettingsStore extends ComponentStore<FormalConversationState> {
  readonly annualQuestions$ = this.select(state => state.oneOnOneSettings.annualQuestions);
  readonly quarterlyQuestions$ = this.select(state => state.oneOnOneSettings.quarterlyQuestions);

  readonly getSettings = this.effect((settings$: Observable<void>) =>
    settings$.pipe(
      exhaustMap(_ => this.settingsApi.get()),
      tapResponse<ConversationSettingsResponseModel>(
        settings => {
          this.updateSettingsFromDb(settings);
        },
        e => this.logError(e)
      )
    )
  );

  readonly updateSettingsFromDb = this.updater(
    (state, settings: ConversationSettingsResponseModel): FormalConversationState => {
      const newState: FormalConversationState = cloneDeep(state);
      newState.oneOnOneSettings.leadershipCommitments = settings.leadershipCommitments;
      newState.oneOnOneSettings.coachingCommitments = settings.coachingCommitments;
      newState.oneOnOneSettings.annualQuestions = settings.annualReviewQuestions;
      newState.oneOnOneSettings.quarterlyQuestions = settings.quarterlyReviewQuestions || [];
      return newState;
    }
  );

  constructor(private settingsApi: ConversationSettingsApiService, private store: Store) {
    super(defaultFormalConversationState);
  }

  logError(error: unknown) {
    this.store.dispatch(
      NotificationActions.notifyError({
        error,
        message: 'Could not fetch default conversation settings.  Please try again.',
      })
    );
  }
}
