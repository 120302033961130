<div *ngIf="selectedTimeZone">
  <terra-form-field class="timezone-select-field">
    <terra-select
      data-cy="timezone-select_menu"
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="selectedTimeZone.name"
      [disabled]="shouldAutoSelectTimeZoneFromBrowser"
      [maxHeight]="300">
      <terra-option
        *ngFor="let timezone of timezoneList"
        attr.data-cy="timezone-select_option-{{ timezone.name }}"
        value="{{ timezone.name }}">
        ({{ timezone.fullUtcOffset }}) {{ timezone.name }}
      </terra-option>
    </terra-select>
  </terra-form-field>
</div>
