import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';

import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';

import { ResponsibilityChartSelectors } from '../_state/chart/responsibility-chart.selectors';

@Pipe({
  name: 'selectSeatById',
  standalone: true,
})
export class SelectSeatByIdPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(id: string): Observable<SeatModel> {
    // Filter out null values to prevent console errors after hiding visionary seat.
    return this.store.select(ResponsibilityChartSelectors.seatById(id)).pipe(filter(seat => !!seat));
  }
}
