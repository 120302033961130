/* eslint-disable @angular-eslint/no-outputs-metadata-property,@angular-eslint/no-output-rename */
import { CdkListbox } from '@angular/cdk/listbox';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { distinctUntilChanged, filter, skip } from 'rxjs';

import { TerraAvatarModule, TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { SkeletonComponent } from '@ninety/ui/legacy/components/skeletons/skeleton.component';
import { SkeletonTheme } from '@ninety/ui/legacy/components/skeletons/types';
import { NotifyIntersectDirective } from '@ninety/ui/legacy/components/tree/directives/notify-intersect.directive';
import { UserIdToAvatarPipe } from '@ninety/ui/legacy/shared/pipes/user/user-id-to-avatar.pipe';
import { UserInitialsPipe } from '@ninety/ui/legacy/shared/pipes/user-initials.pipe';
import { BoldSearchResultsPipe } from '@ninety/web/pages/accountability-chart/pipes/bold-search-results.pipe';

import { AutoCompleteDirective } from '../chip-select/directives/auto-complete.directive';
import { OptionListDirective } from '../chip-select/directives/option-list.directive';
import { OptionDirective } from '../chip-select/directives/option.directive';
import { SelectImplementationDirective } from '../chip-select/directives/select-implementation.directive';
import { SelectLauncherDirective } from '../chip-select/directives/select-launcher.directive';
import { FuseManagerPickDirective, ManagerPick } from '../chip-select/services/fuse-manager-pick.directive';
import { ManagedOptions } from '../chip-select/services/fuse-search.service.model';
import { TextInputWrapperComponent } from '../text-input/components/text-input-wrapper/text-input-wrapper.component';

export type ManagerSelectCandidate = ManagerPick | 'all';

/**
 * User select styled after the "quick filter" component in the ninety design system. Supports fuzzy search and selection of a single user.
 * Based off a pick of the {@link UserListModel} type.
 *
 * @see {@link FuseManagerPickDirective}
 * @see {@link ManagerPick}
 */
@Component({
  selector: 'ninety-pill-user-select',
  standalone: true,
  imports: [
    CommonModule,
    CdkListbox,
    OptionListDirective,
    OptionDirective,
    PushPipe,
    SelectLauncherDirective,
    UserIdToAvatarPipe,
    TerraAvatarModule,
    UserInitialsPipe,
    TerraIconModule,
    AutoCompleteDirective,
    ButtonComponent,
    TextInputWrapperComponent,
    NotifyIntersectDirective,
    BoldSearchResultsPipe,
    FormsModule,
    LetDirective,
    SkeletonComponent,
  ],
  templateUrl: './pill-user-select.component.html',
  styleUrls: ['./pill-user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: SelectImplementationDirective,
      inputs: ['readonly', 'disabled', 'id', 'noResultsText', 'selected: selectedUser'],
      outputs: ['selectedChange: selectedUserChange'],
    },
    {
      directive: FuseManagerPickDirective,
      inputs: ['ninetyFuseUser: users$', 'ninetyFuseSeatOptions: fuseOptions'],
    },
  ],
})
export class PillUserSelectComponent implements OnInit, AfterViewInit {
  protected readonly trackBy: TrackByFunction<ManagedOptions<ManagerPick>> = (_index, item) => item.value._id;

  protected readonly skeletonTheme: SkeletonTheme = {
    height: '34px',
    width: '168px',
    borderRadius: '320px',
    'margin-bottom': '0px',
  };

  @ViewChild(SelectLauncherDirective) protected selectLauncher: SelectLauncherDirective;
  @ViewChild(OptionListDirective, { read: ElementRef<HTMLElement> })
  protected optionListElement: ElementRef<HTMLElement>;

  @Input() loading = false;

  constructor(
    protected readonly select: SelectImplementationDirective<ManagerSelectCandidate>,
    protected readonly fuseProvider: FuseManagerPickDirective,
    private renderer: Renderer2,
    private host: ElementRef,
    private destroyRef: DestroyRef
  ) {
    select.multiple = false;
  }

  ngOnInit() {
    this.renderer.setAttribute(this.host.nativeElement, 'id', this.select.id);
  }

  ngAfterViewInit() {
    this.fuseProvider.fuse.managedOptions$
      .pipe(
        distinctUntilChanged(),
        skip(1),
        // The option list is removed from the DOM when there is no data to display. Also, due to the observable nature of the data source,
        // the list might emit while the select is closed.
        filter(() => Boolean(this.optionListElement)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.optionListElement.nativeElement.scrollTo({ top: 0, behavior: 'smooth' }));
  }

  protected search(query: string) {
    this.fuseProvider.fuse.search({ query });
  }

  protected onSelectValue($event: ManagerSelectCandidate[]) {
    this.select.updateObserversOnValueChange($event);
    this.selectLauncher.destroyOverlay();
  }

  protected onOpenChange(isOpen: boolean) {
    if (!isOpen) this.fuseProvider.fuse.search({ query: null });
  }
}
