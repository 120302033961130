import ObjectId from 'bson-objectid';
import { merge } from 'lodash';

export class Accountability {
  _id?: string; // TODO not optional
  name = '';
  description = '';
  deleted = false;
  ordinal: number;
  seatId?: string; // TODO not optional
  companyId: string;
  chartId: string;

  // TODO called in app only by seat-details and forces the ids to be optional. Need a better data model
  constructor(seatId: string, companyId: string, ordinal: number) {
    this.seatId = seatId;
    this.companyId = companyId;
    this.ordinal = ordinal;
  }
}

export namespace Accountability {
  // TODO this is only for testing, move to _testing
  export function from(partial?: Partial<Accountability>): Accountability {
    const _default: Accountability = {
      _id: new ObjectId().toHexString(),
      chartId: new ObjectId().toHexString(),
      seatId: new ObjectId().toHexString(),
      companyId: '',
      deleted: false,
      description: '',
      name: '',
      ordinal: 0,
    };

    return merge(_default, partial);
  }
}

export type AccountabilityCreatePick = Pick<Accountability, 'name' | 'ordinal'> &
  Partial<Pick<Accountability, 'description'>>;

/** A type which requires an _id field to be present and includes all fields that can be updated. */
export type AccountabilityUpdatePick = Pick<Accountability, '_id'> &
  Partial<Pick<Accountability, 'name' | 'description' | 'ordinal'>>;
