import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import _cloneDeep from 'lodash/cloneDeep';

import { TerraSwitchModule } from '@ninety/terra';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { ColorBranding } from '@ninety/ui/legacy/shared/models/company/company';

import { ColorPickerComponent } from './color-picker/color-picker.component';

@Component({
  selector: 'ninety-custom-colors',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    NinetyTooltipDirective,
    ReactiveFormsModule,
    FormsModule,
    ColorPickerComponent,
    TerraSwitchModule,
  ],
  template: ` <div class="custom-colors">
    <terra-switch
      data-cy="custom-colors_toggle"
      [ngModel]="branding.active"
      (change)="toggleColorBranding($event)"
      [disabled]="readonly"
      [ninetyTooltip]="tooltip">
      Custom Colors
    </terra-switch>

    <ng-container *ngIf="branding?.active">
      <ninety-color-picker
        data-cy="custom-colors_primary-input"
        [color]="branding.primaryAccent"
        colorKey="primary"
        [disabled]="readonly"
        (colorChange)="colorChange($event, 'primaryAccent')" />

      <ninety-color-picker
        data-cy="custom-colors_secondary-input"
        [color]="branding.secondaryAccent"
        colorKey="secondary"
        [disabled]="readonly"
        (colorChange)="colorChange($event, 'secondaryAccent')" />
    </ng-container>
  </div>`,
  styles: [
    `
      .custom-colors {
        display: flex;
        margin-top: 1.5em;
        gap: 32px;
      }
    `,
  ],
})
export class CustomColorsComponent {
  branding: ColorBranding;

  @Input() set colorBranding(branding: ColorBranding) {
    this.branding = _cloneDeep(branding);
  }
  @Input() readonly: boolean;
  @Input() tooltip?: string;

  @Output() colorBrandingChange = new EventEmitter<ColorBranding>();

  toggleColorBranding(toggle: boolean): void {
    this.branding.active = toggle;
    this.updateColorBranding();
  }

  colorChange(color: string, colorKey: 'primaryAccent' | 'secondaryAccent'): void {
    if (color === this.branding[colorKey]) return;
    this.branding[colorKey] = color;
    this.updateColorBranding();
  }

  updateColorBranding(): void {
    this.colorBrandingChange.emit(this.branding);
  }
}
