import { ActionReducerMap, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { merge } from 'lodash';

import { createInitialCurrentChartModel, CurrentChartModel } from './chart/responsibility-chart.model';
import { chartReducers } from './chart/responsibility-chart.reducers';
import {
  createInitialSharedWithMeStateModel,
  SharedResponsibilityChartsStateModel,
} from './shared-with-me/shared-responsibility-charts.model';
import { sharedResponsibilityChartsReducers } from './shared-with-me/shared-responsibility-charts.reducers';

export const ResponsabilitiesRootStateKey = 'page-responsibility';

export const ResponsibilityChartStateKey = 'chart';
export const ResponsibilitySharedStateKey = 'shared';

export interface ResponsibilitiesState {
  [ResponsibilityChartStateKey]: CurrentChartModel;
  [ResponsibilitySharedStateKey]: SharedResponsibilityChartsStateModel;
}

export function createInitialResponsibilitiesState(partial?: Partial<ResponsibilitiesState>): ResponsibilitiesState {
  const _default: ResponsibilitiesState = {
    [ResponsibilityChartStateKey]: createInitialCurrentChartModel(),
    [ResponsibilitySharedStateKey]: createInitialSharedWithMeStateModel(),
  };
  return merge({}, _default, partial);
}

export const reducers: ActionReducerMap<ResponsibilitiesState> = {
  [ResponsibilityChartStateKey]: chartReducers,
  [ResponsibilitySharedStateKey]: sharedResponsibilityChartsReducers,
};

export const selectResponsibilitiesState: MemoizedSelector<any, ResponsibilitiesState> =
  createFeatureSelector(ResponsabilitiesRootStateKey);
