<div class="verify-password__container" [class.completed-step]="passedPasswordStep">
  <h2 data-cy="create-temp-cognito-user-dialog_title">To start, please verify your password.</h2>
  <p>In order to change your <b>Ninety Primary Email address</b>, please verify your password below.</p>
  <div class="form-input__container">
    <mat-form-field class="outline-field _terra-migration-approved-override--mat-form-field" appearance="outline">
      <input
        matInput
        data-cy="create-temp-cognito-user-dialog_password-input"
        placeholder="Password"
        (change)="disableIncorrectPasswordState()"
        [formControl]="passwordControl"
        type="password"
        class="fs-mask"
        ninetyInputAutofocus
        (keyup.enter)="verifyPassword()" />
      <mat-spinner *ngIf="spinner" matSuffix [diameter]="18" color="accent" class="checking-spinner"></mat-spinner>
      <terra-icon matSuffix *ngIf="passedPasswordStep" icon="check" class="progress-label" />
    </mat-form-field>
    <div *ngIf="passwordControl.hasError('incorrectPassword')" class="invalid-password">
      {{ passwordErrorMessage }}
    </div>
  </div>
</div>
<div mat-dialog-actions *ngIf="!passedPasswordStep" fxLayout="row" fxLayoutAlign="center" class="top-space-15">
  <ng-container [ngTemplateOutlet]="closeButton"></ng-container>
  <button
    mat-raised-button
    data-cy="create-temp-cognito-user-dialog_verify-password-button"
    color="accent"
    [disabled]="passwordControl.invalid || (spinnerService.primary$ | async)"
    (click)="verifyPassword()">
    Verify Password
  </button>
</div>

<div *ngIf="passedPasswordStep">
  <div class="change-email__container" [class.completed-step]="newPrimaryEmailControl.disabled">
    <h2>Great! Now, please enter your new Primary Email address.</h2>
    <p>Take note, <strong>this will be the email you sign into Ninety with!</strong></p>
    <div class="form-input__container">
      <mat-form-field class="outline-field _terra-migration-approved-override--mat-form-field" appearance="outline">
        <input
          data-cy="create-temp-cognito-user-dialog_email-address-input"
          matInput
          placeholder="Email Address"
          [formControl]="newPrimaryEmailControl"
          type="email"
          autocomplete="email"
          ninetyInputAutofocus
          (keyup.enter)="sendVerificationEmailToNewEmail()" />
        <mat-spinner *ngIf="spinner" matSuffix [diameter]="18" color="accent" class="checking-spinner"></mat-spinner>
        <terra-icon matSuffix *ngIf="passedEmailStep" icon="check" class="progress-label" />
      </mat-form-field>
      <div *ngIf="passedEmailStep" class="progress-label">Confirmation code sent to new email address!</div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center" class="top-space-15">
    <ng-container [ngTemplateOutlet]="closeButton"></ng-container>
    <button
      data-cy="create-temp-cognito-user-dialog_email-confirmation-code-button"
      mat-raised-button
      color="accent"
      [disabled]="
        newPrimaryEmailControl.invalid || newPrimaryEmailControl.disabled || (spinnerService.primary$ | async)
      "
      (click)="sendVerificationEmailToNewEmail()">
      <terra-icon icon="envelope" />
      Email Confirmation Code
    </button>
  </div>
</div>

<ng-template #closeButton>
  <button
    mat-raised-button
    data-cy="create-temp-cognito-user-dialog_cancel-button"
    (click)="dialogRef.close()"
    [disabled]="newPrimaryEmailControl.disabled || (spinnerService.primary$ | async)">
    Cancel
  </button>
  <div class="fill-remaining-space"></div>
</ng-template>
