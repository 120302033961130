<div
  #selectLauncher="ninetySelectLauncher"
  [disabled]="disabled"
  [ninetySelectLauncher]="selectOverlay"
  class="directory-role-select"
  positioningStrategy="below">
  <div *ngIf="selectedRole; else placeholder" class="role-name">
    {{ selectedRole.title }}
  </div>
  <ng-template #placeholder>Select&nbsp;role</ng-template>
  <terra-icon
    *ngIf="!disabled"
    [class.icon-up]="selectLauncher.isOpen"
    class="role-select-expand-collapse"
    icon="caret-down"
    size="12"
    variant="bold" />
</div>

<ng-template #selectOverlay>
  <div
    (cdkListboxValueChange)="selectRole($event.value[0])"
    [selected]="[selectedRole]"
    class="roles-list"
    ninetyOptionList>
    <ng-container *ngFor="let role of roles$ | ngrxPush">
      <terra-divider *ngIf="role.isImplementer" />
      <div [disabled]="role.isItemDisabled" [value]="role" class="role-option" ninetyOption>
        {{ role.title }}<span class="role-subtitle">{{ role.subtitle }}</span>
      </div>
    </ng-container>
  </div>
</ng-template>
