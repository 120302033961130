import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Rdl as ARJS } from '@grapecity/activereports/core';
import isString from 'lodash/isString';
import { Observable, map } from 'rxjs';

export const REPORTS_API_URL = '/api/v4/reports';

@Injectable({ providedIn: 'root' })
export class ReportViewerApiService {
  public constructor(private http: HttpClient) {}

  public getReportDef(reportName: string, httpParams?: Record<string, string>): Observable<ARJS.Report> {
    const params = new HttpParams({ fromObject: httpParams });
    return this.http
      .get<ARJS.Report>(`${REPORTS_API_URL}/${reportName}/def`, { params })
      .pipe(map(response => (isString(response) ? JSON.parse(response) : response)));
  }
}
