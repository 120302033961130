<!--TODO Use new components like button and card, remove references to dialog -->
<h1 mat-dialog-title>Move {{ language.acChart.seat }}</h1>
<mat-dialog-content>
  <div class="legacy-material-card-subtitle">
    Select a new manager {{ language.acChart.seat }} for: {{ nameOfSeatToMove }}
  </div>
  <terra-form-field>
    <terra-form-label>New Manager {{ language.acChart.seat }}</terra-form-label>
    <terra-select
      [(ngModel)]="selectedSeatId"
      required="true"
      data-cy="seat-move_new-manager-dropdown"
      maxWidth="400"
      maxHeight="350">
      <terra-option
        *ngFor="let seat of seats"
        [value]="seat.seatId"
        id="seat-option-{{ seat.seatId }}"
        attr.data-cy="seat-move_new-manager-option-{{ seat.seatName }}">
        {{ seat.seatName }}
        <terra-description>
          {{ seat.seatHolderFullNames.join(', ') }}
        </terra-description>
      </terra-option>
    </terra-select>
  </terra-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <ninety-button stroked class="cancel-button" (click)="cancel.emit()">Cancel</ninety-button>
  <ninety-button
    filled
    primary
    class="save-button"
    (click)="move.emit(selectedSeatId)"
    [disabled]="!selectedSeatId"
    data-cy="seat-move_save-button">
    Save
  </ninety-button>
</mat-dialog-actions>
