import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DiscoEmptyStateComponent } from '@ninety/web/pages/insights/disco-visualizations/charts/disco-empty-state/disco-empty-state.component';

/** Simple container for a drill-in. Adds border radius and shadow. Supports rendering an empty state with input. */
@Component({
  selector: 'ninety-dashboard-widget-drill-in-container',
  standalone: true,
  imports: [CommonModule, DiscoEmptyStateComponent],
  template: `
    <ng-container *ngIf="!isEmpty; else empty">
      <ng-content></ng-content>
    </ng-container>

    <ng-template #empty>
      <ninety-disco-empty-state />
    </ng-template>
  `,
  styleUrls: ['./dashboard-widget-drill-in-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetDrillInContainerComponent {
  /**
   * When true, empty state should be shown instead of the projected content. Example - when the content is a table and there
   * are no rows
   */
  @Input({ transform: coerceBooleanProperty }) isEmpty: BooleanInput = false;
}
