import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { map, merge, Observable, startWith, Subject, takeUntil, tap } from 'rxjs';

import { HostFormControlPassthroughDirective } from '../../directives/hostFormControlPassthrough.directive';
import { TerraInputBoolean } from '../../models/terra-input-boolean.models';
import { TerraErrorStateMatcher } from '../forms/terra-error-state-matcher';
import { TerraCounterModule } from '../terra-counter';
import { TerraIconModule, TerraIconName } from '../terra-icon';
import { TERRA_OPTION_BASE, TerraOptionBase } from '../terra-option/terra-option.interface';
import { TerraSelectComponent, TerraSelectModule } from '../terra-select';
import { TerraSelectBaseClass } from '../terra-select/terra-select-base/terra-select-base';

@Component({
  selector: 'terra-quick-filter',
  standalone: true,
  exportAs: 'terraQuickFilter',
  imports: [CommonModule, TerraIconModule, TerraCounterModule, TerraSelectModule, ReactiveFormsModule],
  hostDirectives: [HostFormControlPassthroughDirective],
  templateUrl: './terra-quick-filter.component.html',
  styleUrls: ['./terra-quick-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraQuickFilterComponent extends TerraSelectBaseClass implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(TerraSelectComponent, { static: true }) private _select!: TerraSelectComponent;
  @ContentChildren(TERRA_OPTION_BASE, { descendants: true }) private _options!: QueryList<TerraOptionBase>;

  private _componentHasInitialized = false;
  private _destroyed$ = new Subject<void>();

  /**
   * Allow multiple selections, can't be changed after initialization
   * @default false
   */
  @Input({ required: false }) get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: TerraInputBoolean) {
    if (this._componentHasInitialized === false) {
      this._multiple = coerceBooleanProperty(value);
      this._changeDetectorRef.markForCheck();
    } else {
      throw new Error('Multiple mode cannot be changed after it has been set');
    }
  }
  private _multiple = false;

  /**
   * Disabled state of the quick filter
   * @default false
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this._hostFormControl.control?.disable() : this._hostFormControl.control?.enable();
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * Icon displayed in the quick filter
   * @default n/a
   */
  @Input() get icon(): TerraIconName | undefined {
    return this._icon;
  }
  set icon(value: TerraIconName | undefined) {
    this._icon = value;
    this._changeDetectorRef.markForCheck();
  }
  private _icon: TerraIconName | undefined;

  /**
   * Key to be displayed in the quick filter
   * @default n/a
   */
  @Input() get key(): string | undefined {
    return this._key;
  }
  set key(value: string | undefined) {
    this._key = value;
    this._changeDetectorRef.markForCheck();
  }
  private _key: string | undefined;

  protected _selectedLabel$!: Observable<string>;
  constructor(
    protected override _changeDetectorRef: ChangeDetectorRef,
    protected override _terraErrorStateMatcher: TerraErrorStateMatcher,
    protected readonly _hostFormControl: HostFormControlPassthroughDirective
  ) {
    super(_changeDetectorRef, _terraErrorStateMatcher);
  }

  ngOnInit() {
    this._componentHasInitialized = true;
  }

  ngAfterContentInit(): void {
    const options$ = this._options.changes.pipe(startWith(this._options));

    options$.pipe(takeUntil(this._destroyed$)).subscribe(data => {
      this._select._backdoorOptions.next(data);
    });

    // Stream of when options change, returns the current formControl value when it happens
    const optionsChangedWithLatestFormValue$ = options$.pipe(
      map(() => {
        return this._hostFormControl.control.value;
      })
    );

    this._selectedLabel$ = merge(this._hostFormControl.control?.valueChanges, optionsChangedWithLatestFormValue$).pipe(
      startWith(this._hostFormControl.control.value),
      map(selected => {
        const selectedValue = Array.isArray(selected) ? selected : [selected];
        const inOrderOfSelected = selectedValue
          .map(value => this._options.find(option => this.compareWith(option.value, value))?.getLabel() || '')
          .filter(label => label !== '')[0];
        return inOrderOfSelected;
      }),
      tap(() => {
        this._changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  protected _isValueSelected(): boolean {
    return this._hostFormControl.control?.value?.length > 0 || this.placeholder !== undefined;
  }

  protected _openedChanged(): void {
    this._isSelectOpen = !this._isSelectOpen;
    this.openedChange.emit(this._isSelectOpen);
  }

  protected _selectionChanged(): void {
    this.selectionChange.emit(this._select.value);
  }

  /** Focuses the select */
  focus(): void {
    this._select.focus();
  }

  /** Blurs the select */
  blur(): void {
    this._select.blur();
  }

  /** Open the select if able */
  open(): void {
    this._select.open();
  }

  /** Close the select if open */
  close(): void {
    this._select.close();
  }

  /** Toggle the current state open/close */
  toggle(): void {
    this._select.toggle();
  }
}
