import { ArgTypes } from '@storybook/angular';

/**
 * Utility to configure actions for a component. Configures the action handler and prevents the action from showing
 * up in the args table.
 */
export function configureActions<T>(outputs: (keyof T)[]): Partial<ArgTypes<T>> {
  return outputs.reduce((acc: Partial<ArgTypes<T>>, key) => {
    acc[key] = { action: key, table: { disable: true } };
    return acc;
  }, {});
}
