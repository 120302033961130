import { Injectable, inject } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';

import { QuickFilterOption } from '@ninety/ui/legacy/components/index';
import { LocalStorageService, PrintMeetingAgendaSortOptions } from '@ninety/ui/legacy/core/index';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import {
  CascadingMessagesSort,
  HeadlinesSort,
  IssuesSort,
  MeetingAgenda,
  MeetingType,
  TodoSort,
} from '@ninety/ui/legacy/shared/index';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import {
  selectCurrentUserIsManageeOrAbove,
  selectFeatureEnabled,
  selectWeeklyMeetingsOnlyFeature,
} from '@ninety/ui/legacy/state/index';
import { CascadingMessageStateKey } from '@ninety/web/pages/headlines/_state/cascading-messages/cascading-messages-state.model';
import { HeadlineStateKey } from '@ninety/web/pages/headlines/_state/headlines/headlines-state.model';
import { TodoRootStateKey, TeamTodosChildStateKey } from '@ninety/web/pages/todos/_state';

import { MeetingDialogActions, MeetingsAgendasV2Actions, PastMeetingsPageActions } from './_state/meetings.actions';
import { MeetingsPageActions } from './_state/meetings.actions';
import { MeetingsStateSelectors } from './_state/meetings.selectors';

@Injectable({
  providedIn: 'root',
})
export class MeetingsFacade {
  private readonly store = inject(Store);
  private readonly stateService = inject(StateService);
  private readonly localStorageService = inject(LocalStorageService);

  teamsOptions$ = this.store.select(MeetingsStateSelectors.selectTeamFilterOptions);
  language$ = this.store.select(selectLanguage);
  loading$ = this.store.select(MeetingsStateSelectors.selectLoading);
  pastMeetings$ = this.store.select(MeetingsStateSelectors.selectAll);
  totalCount$ = this.store.select(MeetingsStateSelectors.selectTotalCount);
  templateType$ = this.store.select(MeetingsStateSelectors.selectTemplateType);
  page$ = this.store.select(MeetingsStateSelectors.selectPage);
  selectSortForTable$ = this.store.select(MeetingsStateSelectors.selectSortForTable);
  selectedId$ = this.store.select(MeetingsStateSelectors.selectSelectedId);
  meetingTypeOptions$ = this.store.select(MeetingsStateSelectors.selectCombinedAgendaOptionsWithAll);
  weeklyMeetingOnlyFeature$ = this.store.select(selectWeeklyMeetingsOnlyFeature);
  allMeetingsFeature$ = this.store.select(selectFeatureEnabled(NinetyFeatures.allMeetings));
  isManageeOrAbove$ = this.store.select(selectCurrentUserIsManageeOrAbove);

  dateFormat = this.stateService.dateFormat;

  selectedTeam$ = this.store.select(MeetingsStateSelectors.selectSelectedTeam);
  selectedMeetingType$ = this.store.select(MeetingsStateSelectors.selectSelectedMeetingType);

  reset() {
    this.store.dispatch(MeetingsPageActions.reset());
  }

  teamSelected(team: Team | Team[]): void {
    this.store.dispatch(MeetingsPageActions.selectTeam({ team: team as Team }));
  }

  teamAgendaSelected(team: Team | Team[]): void {
    this.store.dispatch(MeetingsAgendasV2Actions.selectTeam({ team: team as Team }));
  }

  pastMeetingsTeamSelected(team: Team | Team[]): void {
    this.store.dispatch(PastMeetingsPageActions.selectTeam({ team: team as Team }));
  }

  pastMeetingsMeetingTypeSelected(type: QuickFilterOption<MeetingAgenda>): void {
    this.store.dispatch(PastMeetingsPageActions.selectMeetingType({ meetingAgenda: type }));
  }

  printAgenda(weeklyOnly: boolean) {
    if (weeklyOnly) {
      this.printWeeklyMeetingAgenda();
    } else {
      this.openPrintAgendaDialog();
    }
  }

  openPrintAgendaDialog() {
    this.store.dispatch(MeetingsPageActions.openPrintAgendaDialog());
  }

  printWeeklyMeetingAgenda() {
    this.store.dispatch(
      MeetingDialogActions.printMeetingAgenda({
        meetingType: MeetingType.weekly,
        sortOptions: this.getPrintMeetingAgendaSortOptions(),
      })
    );
  }

  paginate($event: PageEvent) {
    this.store.dispatch(PastMeetingsPageActions.paginationChange({ index: $event.pageIndex, size: $event.pageSize }));
  }

  sortChange(sort: Sort) {
    this.store.dispatch(PastMeetingsPageActions.sortChange({ sort }));
  }

  onMeetingSelected(meeting: Meeting) {
    this.store.dispatch(MeetingsPageActions.selectMeeting({ meeting }));
  }

  downloadExcel() {
    this.store.dispatch(PastMeetingsPageActions.downloadExcel());
  }

  getPrintMeetingAgendaSortOptions(): PrintMeetingAgendaSortOptions | null {
    let sortOptions: PrintMeetingAgendaSortOptions = {};

    const sortHeadlines: HeadlinesSort = this.localStorageService.getAndParse<HeadlinesSort>(
      `${HeadlineStateKey}.sort`
    );
    const sortCascadingMessages = this.localStorageService.getAndParse<CascadingMessagesSort>(
      `${CascadingMessageStateKey}.sort`
    );
    const sortTodos = this.localStorageService.getAndParse<TodoSort>(
      `${TodoRootStateKey}.${TeamTodosChildStateKey}.sort`
    );
    const sortIssues = this.localStorageService.getAndParse<IssuesSort>(`issues.sort`); //Issues not in state yet

    sortOptions = {
      ...(sortHeadlines ? { headlines: sortHeadlines } : null),
      ...(sortCascadingMessages ? { cascadingMessages: sortCascadingMessages } : null),
      ...(sortTodos ? { todos: sortTodos } : null),
      ...(sortIssues ? { issues: sortIssues } : null),
    };

    return isEmpty(sortOptions) ? null : sortOptions;
  }
}
