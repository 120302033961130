import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { SubscriptionFrequencies } from '../../../../_shared/models/billingv2/subscription-frequencies.enum';
import { selectPricingPlans } from '../../../../_state';
import { FilterPlansByFrequency } from '../../pricing-tier-dialog/pricing-cards/pipes/filter-plans-by-frequency';

import { PricingCardComponent } from './components/pricing-card/pricing-card.component';

@Component({
  selector: 'ninety-pricing-cards',
  standalone: true,
  imports: [CommonModule, PricingCardComponent, PushPipe, FilterPlansByFrequency],
  templateUrl: './pricing-cards.component.html',
  styleUrls: ['./pricing-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingCardsComponent {
  @Input() subscriptionFrequency: SubscriptionFrequencies;

  protected readonly plans$ = this.store.select(selectPricingPlans);

  constructor(private readonly store: Store) {}
}
