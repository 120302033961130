/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput } from '@angular/cdk/coercion';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, Optional } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { OptionDirective } from '../../directives/option.directive';

/**
 * This component simulates a checkbox while using custom HTML and CSS to avoid the default browser checkbox. Contains
 * a hidden input for accessibility.
 *
 * Designed to be used with {@link SelectBoxComponent}, but can be used independently. Note, "selected" state and click
 * events are expected to be handled by the parent. This ensures that {@link CdkOption} handles selection state and
 * click events.
 */
@Component({
  selector: 'ninety-fake-checkbox',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TerraIconModule, NgIf, AsyncPipe],
  host: {
    '[class.ninety-fake-checkbox__selected]': 'isSelected',
    '[class.ninety-fake-checkbox__disabled]': 'disabled',
    '[attr.id]': 'id',
  },
  template: `
    <terra-icon icon="check" variant="bold" size="16" />
    <input type="checkbox" hidden />
  `,
  styles: [
    `
      @use 'terra';

      :host {
        display: block;

        width: 18px;
        height: 18px;
        box-sizing: border-box;

        border: 1px solid terra.color(border);
        color: terra.color(white);

        &:not(.ninety-fake-checkbox__disabled) {
          cursor: pointer;
        }

        @include terra.radius(small);

        terra-icon {
          display: none;
        }

        &.ninety-fake-checkbox__selected {
          background-color: terra.color(brand);
          border-color: transparent;

          terra-icon {
            display: block;
          }
        }
      }
    `,
  ],
})
export class FakeCheckboxComponent implements OnInit {
  private static NEXT_ID = 0;

  @Input() isSelected = false;
  @Input() id: string;

  @Input() checkboxDisabled: BooleanInput = false;

  constructor(@Optional() private option: OptionDirective) {}

  get disabled(): boolean {
    return this.option?.disabled ?? Boolean(this.checkboxDisabled) ?? false;
  }

  ngOnInit() {
    if (!this.id) {
      this.id = `fake-checkbox-${FakeCheckboxComponent.NEXT_ID++}`;
    }
  }
}
