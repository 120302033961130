import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { map, of, switchMap, tap } from 'rxjs';

import {
  SignupStateActions,
  SignupTeammatesActions,
} from '@ninety/web/pages/login/_state/sign-up/signup-state.actions';

import { HubspotService } from '../hubspot.service';

import { HubspotActions } from './hubspot.actions';

@Injectable()
export class HubspotEffects {
  checkForHubstopCookie$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignupStateActions.checkEmail),
      switchMap(({ email }) => {
        return of(this.cookieService.get('hubspotutk')).pipe(
          map(hubspotutk => (hubspotutk?.length > 0 ? HubspotActions.createHubspotAccount({ email }) : null))
        );
      })
    )
  );

  createHubspotAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HubspotActions.createHubspotAccount),
        tap(action => {
          const { email } = action;
          this.hubspotService.identifyContact(email);
        })
      ),
    { dispatch: false }
  );

  setHubspotPath$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HubspotActions.setHubspotPath),
        tap(action => {
          const { path } = action;
          this.hubspotService.setPath(path);
        })
      ),
    { dispatch: false }
  );

  finishAndInviteTeammates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignupTeammatesActions.finishAndInviteTeammates),
        tap(() => {
          this.hubspotService.setPath('Signup-AddTeammates');
        })
      ),
    { dispatch: false }
  );

  addTeammatesLater$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignupTeammatesActions.addTeammatesLater),
        tap(() => {
          this.hubspotService.setPath('Signup-AddTeammatesLater');
        })
      ),
    { dispatch: false }
  );

  setCompanyDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignupStateActions.setCompanyDetails),
        tap(() => {
          this.hubspotService.setPath('Signup-CompanyDetails');
        })
      ),
    { dispatch: false }
  );

  setAccountDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignupStateActions.setAccountDetails),
        tap(() => {
          this.hubspotService.setPath('Signup-AccountDetails');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private cookieService: CookieService,
    private hubspotService: HubspotService
  ) {}
}
