import { createSelector } from '@ngrx/store';

import { selectEntitiesState, TeamListStateKey } from '..';
import { selectCompany } from '../..';

import { teamAll, TeamListModel, teamNone } from './api/team-list.model';
import { teamListInitialState, teamListStateAdapter } from './team-list-state.model';

const { selectAll: adapterSelectAll, selectEntities } = teamListStateAdapter.getSelectors();

const selectTeamListState = createSelector(selectEntitiesState, appEntities => {
  return appEntities ? appEntities[TeamListStateKey] : teamListInitialState;
});

export namespace TeamSelectors {
  export const selectAll = createSelector(selectTeamListState, adapterSelectAll);

  export const selectAllDictionary = createSelector(selectTeamListState, selectEntities);
  export const selectAllIds = createSelector(selectAll, teams => teams.map(team => team._id));
  export const selectById = (teamId: string) => createSelector(selectAllDictionary, state => state[teamId]);
  export const selectNameById = (teamId: string) =>
    createSelector(selectAllDictionary, state => state[teamId]?.name || '');
  export const selectFilterBarTeam = createSelector(selectTeamListState, state => state.filterBarTeam);
  export const selectFilterBarTeamId = createSelector(selectFilterBarTeam, team => team?._id);
  export const selectFilterBarTeamIsSlt = createSelector(
    selectFilterBarTeam,
    selectCompany,
    (team, company) => team?._id === company.seniorLeadershipTeamId
  );

  export const selectFilterBarTeamLite = createSelector(selectAllDictionary, selectFilterBarTeamId, (teams, teamId) => {
    if (teamId === teamNone._id) return teamNone;
    else if (teamId === teamAll._id) return teamAll;
    else return teams[teamId];
  });
  export const selectArchivedTeamsLoaded = createSelector(selectTeamListState, state => state.archivedTeamsLoaded);

  export const selectTeamsByIds = (teamIds: string[]) =>
    createSelector(selectAllDictionary, state => {
      return teamIds.reduce((teams: TeamListModel[], teamId: string) => {
        if (state[teamId]) teams.push(state[teamId]);
        return teams;
      }, []);
    });
}

export namespace EditedTeamSelectors {
  export const selectId = createSelector(selectTeamListState, state => state.editedTeamId);
  export const selectTeam = createSelector(TeamSelectors.selectAll, selectId, (teams, editedTeamId) =>
    teams.find(team => team._id === editedTeamId)
  );
  export const selectName = createSelector(selectTeam, team => team?.name || '');
  export const selectFetchInProgress = createSelector(selectTeamListState, state => state.fetchingTeams);
}

export namespace FilterTeamSelectors {
  export const selectTeamId = createSelector(selectTeamListState, state => state.filterBarTeam?._id);
  export const selectTeam = createSelector(selectTeamListState, state => state.filterBarTeam);
}
