import { Component, Input } from '@angular/core';

export const DISCO_EMPTY_STATE_MESSAGE = 'No data to display';

/**
 * A basic presentational component that renders the AG-Charts default empty state message in the center of some parent. Useful
 * in non-AG-Chart components that need to display an empty state message in the same style as AG-Chart.
 */
@Component({
  selector: 'ninety-disco-empty-state',
  standalone: true,
  template: `{{ message }}`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    // See apps/ng-ninety/src/styles/ag-charts.scss
    class: 'ag-charts-no-data-override',
  },
})
export class DiscoEmptyStateComponent {
  @Input() message = DISCO_EMPTY_STATE_MESSAGE;
}
