export class NgModelExample<T> {
  constructor(private _value: T) {}

  get value(): T {
    console.log('ngModel get isSelected', this._value);
    return this._value;
  }

  set value(value: T) {
    console.log('ngModel set isSelected', value);
    this._value = value;
  }
}
