import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs';

import { DataLayerService } from '@ninety/ui/legacy/core/analytics/google-analytics/services/data-layer.service';
import { ErrorTagService } from '@ninety/ui/legacy/core/analytics/google-analytics/services/error-tag.service';
import { NavigationTagService } from '@ninety/ui/legacy/core/analytics/google-analytics/services/navigation-tag.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';

// froala options used by the froala-options class
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/files_manager.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/line_breaker.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/third_party/embedly.min.js';

@Component({
  selector: 'ninety-root',
  template: `<router-outlet ninetyTheme></router-outlet>`,
})
export class AppComponent {
  constructor(
    public spinnerService: SpinnerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public stateService: StateService,
    private dataLayerService: DataLayerService,
    private navigationTagService: NavigationTagService,
    private errorTagService: ErrorTagService
  ) {
    this.setupDataLayer();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        tap(data => this.stateService.onRouteChange(data))
      )
      .subscribe();
  }

  // TODO: Move to ngrx
  private setupDataLayer() {
    this.dataLayerService.init();
    this.errorTagService.init();
    this.navigationTagService.init();
  }
}
