export enum RockLevelCode {
  user = '0000',
  companyAndDepartment = '0001',
  company = '0100',
  department = '0200',
}

export const RockLevelCodeV2DisplayMap: Partial<Record<RockLevelCode, string>> = {
  [RockLevelCode.user]: 'Team',
  [RockLevelCode.companyAndDepartment]: 'Company and Department',
};

export const RockLevelCodeV3DisplayMap: Partial<Record<RockLevelCode, string>> = {
  [RockLevelCode.user]: 'Team',
  [RockLevelCode.company]: 'Company',
  [RockLevelCode.department]: 'Department',
  [RockLevelCode.companyAndDepartment]: 'Company and Department',
};
