<ninety-card class="pricing-tier-dialog">
  <div class="header-actions">
    <ninety-button flat icon (click)="cancel()">
      <terra-icon icon="x" size="20" />
    </ninety-button>
  </div>

  <div class="header">
    <div class="terra-display">Choose the Right Plan <span class="hide-mobile">to Achieve Your Goals</span></div>

    <div class="headline-description hide-mobile">
      Whether you're just starting out, building a team, scaling operations, or driving your entire organization
      forward, Ninety has a plan designed for your needs. Explore our plans and find the perfect fit for your company.
    </div>
  </div>

  <div class="billing-toggle-container">
    <ninety-button-switch
      leftLabel="Monthly billing"
      rightLabel="Annual billing"
      [leftButtonValue]="SubscriptionFrequencies.Monthly"
      [rightButtonValue]="SubscriptionFrequencies.Annual"
      [(selectedValue)]="subscriptionFrequency" />
  </div>

  <ninety-pricing-cards [subscriptionFrequency]="subscriptionFrequency" />

  <ninety-all-in-discount (talkToNinetyRep)="talkToNinetyRep()" />

  <ninety-compare-plans />
</ninety-card>
