import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** A simple, card like component that supports a title, an optional subtitle, and a content projection slot. */
@Component({
  selector: 'ninety-report-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './report-banner.component.html',
  styleUrls: ['./report-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBannerComponent {
  @Input({ required: true }) title: string;
  @Input() subtitle: string;
}
