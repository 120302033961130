<div
  *ngrxLet="{
    language: facade.language$,
    totalCount: facade.totalCount$,
    templateType: facade.templateType$,
    weeklyMeetingsOnly: facade.weeklyMeetingOnlyFeature$,
  } as vm">
  <div class="card-wrp meetings-v2-card-wrp terrafy-main-content">
    <ninety-card class="past-meetings-card">
      <ninety-widget-skeleton
        *ngIf="vm.templateType === WidgetTemplateType.loading"
        [tableBodyCount]="LOADING_SKELETON_ROWS"></ninety-widget-skeleton>

      <ninety-card-header header *ngIf="vm.templateType !== WidgetTemplateType.loading">
        <ninety-card-title-with-count
          title="{{ vm.language.meeting.item }} History"
          [count]="vm.totalCount"></ninety-card-title-with-count>
        <span class="description">Your team's {{ vm.language.meeting.items }}, in one place.</span>
      </ninety-card-header>

      <ng-container [ngSwitch]="vm.templateType">
        <div *ngSwitchCase="WidgetTemplateType.noQueryResults" id="meetings-v2-{{ WidgetTemplateType.noQueryResults }}">
          <ninety-empty-list-view
            title="You have no {{ vm.language.meeting.items }}."
            iconName="search"
            customMessage="Please try a different search.">
          </ninety-empty-list-view>
        </div>

        <div *ngSwitchCase="WidgetTemplateType.error" id="meetings-v2-{{ WidgetTemplateType.error }}">
          <ninety-empty-list-view [widgetErrorState]="true"> </ninety-empty-list-view>
        </div>

        <ninety-meetings-table
          *ngSwitchCase="WidgetTemplateType.data"
          [dataSource]="facade.pastMeetings$"
          [sort]="facade.selectSortForTable$ | async"
          [selectedId]="facade.selectedId$ | async"
          [dateFormat]="facade.dateFormat"
          (sortChange)="facade.sortChange($event)"
          (meetingSelected)="facade.onMeetingSelected($event)"></ninety-meetings-table>

        <ng-container
          *ngrxLet="{
            page: facade.page$,
            totalCount: facade.totalCount$,
          } as vmFooter">
          <ninety-card-footer
            footer
            *ngIf="vm.templateType === WidgetTemplateType.data && vmFooter.totalCount > vmFooter.page.size"
            class="full-width flex-justify-space-between flex-wrap">
            <ninety-pagination
              *ngrxLet="facade.page$ as page"
              class="flex flex-1 top-space-15"
              #pager
              [length]="vmFooter.totalCount"
              [pageIndex]="vmFooter.page.index"
              [pageSize]="vmFooter.page.size"
              (page)="facade.paginate($event)">
            </ninety-pagination>
          </ninety-card-footer>
        </ng-container>
      </ng-container>
    </ninety-card>
  </div>
</div>
