import { format, getWeekOfMonth } from 'date-fns';

import { CalendarEvent, CalendarEventOrganizer, Google, MsTeams, NormalizedCalendarEvent, Outlook } from './interfaces';
import { calculateTimes, formatTimes, stringify } from './utils';

export const eventify = (event: CalendarEvent): NormalizedCalendarEvent => {
  const { startDate, endDate } = calculateTimes(event);

  let rRule;
  if (event.cadence && event.cadence.interval) {
    const weekOfMonth = getWeekOfMonth(startDate);
    // Example format FREQ=WEEKLY;INTERVAL=1;BYDAY=2MO.  https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
    rRule = `FREQ=${event.cadence.frequency.toUpperCase()};INTERVAL=${event.cadence.interval.toString()};BYDAY=${weekOfMonth}${format(
      startDate,
      'EEEEEE'
    ).toUpperCase()}`;
  } else {
    rRule = undefined;
  }

  return {
    ...event,
    rRule,
    startDate: startDate,
    endDate: endDate,
  };
};

export const google = (calEvent: CalendarEvent): string => {
  const event = eventify(calEvent);
  const { startDate, endDate } = formatTimes(event, event.allDay ? 'allDay' : 'dateTimeUTC', true);

  const details: Google = {
    action: 'TEMPLATE',
    text: event.title,
    dates: `${startDate}/${endDate}`,
    details: event.description,
    location: event.location,
    trp: event.busy,
    sprop: event.url,
    recur: event.rRule ? `RRULE:${event.rRule}` : undefined,
  };

  if (event.guests) {
    details.add = event.guests.join();
  }

  return `https://calendar.google.com/calendar/render?${stringify(details)}`;
};

// Microsoft doesn't support recurring events
// Microsoft has to return datetime local
export const outlook = (calendarEvent: CalendarEvent): string => {
  const event = eventify(calendarEvent);
  const { startDate, endDate } = formatTimes(event, 'dateTimeLocal');
  const details: Outlook = {
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt: startDate,
    enddt: endDate,
    subject: event.title,
    body: event.description,
    location: event.location,
    allday: event.allDay || false,
  };

  if (event.guests) {
    details.to = event.guests.join();
  }

  return `https://outlook.live.com/calendar/0/action/compose?${stringify(details)}`;
};

// Microsoft doesn't support recurring events
// Microsoft has to return datetime local
export const office365 = (calendarEvent: CalendarEvent): string => {
  // Recurring events are not supported by Office 365 URL
  const event = eventify(calendarEvent);
  const { startDate, endDate } = formatTimes(event, 'dateTimeLocal');
  const details: Outlook = {
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt: startDate,
    enddt: endDate,
    subject: event.title,
    body: event.description,
    location: event.location,
    allday: event.allDay || false,
  };

  if (event.guests) {
    details.to = event.guests.join();
  }

  return `https://outlook.office.com/calendar/0/action/compose?${stringify(details)}`;
};

// Microsoft doesn't support recurring events
// Microsoft has to return datetime local
export const office365Mobile = (calendarEvent: CalendarEvent): string => {
  const event = eventify(calendarEvent);
  const { startDate, endDate } = formatTimes(event, 'dateTimeLocal');
  const details: Outlook = {
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt: startDate,
    enddt: endDate,
    subject: event.title,
    body: event.description,
    location: event.location,
    allday: event.allDay || false,
  };

  if (event.guests) {
    details.to = event.guests.join();
  }

  return `https://outlook.office.com/calendar/0/deeplink/compose?${stringify(details)}`;
};

// MSTeams doesn't support AllDay, recurring events
export const msTeams = (calendarEvent: CalendarEvent): string => {
  const event = eventify(calendarEvent);
  const { startDate, endDate } = formatTimes(event, event.allDay ? 'allDayMsTeams' : 'dateTimeUTCMsTeams', true);
  const details: MsTeams = {
    subject: event.title,
    content: event.description,
    startTime: startDate,
    endTime: endDate,
  };
  if (event.guests && event.guests.length) {
    details.attendees = event.guests.join();
  }
  return `https://teams.microsoft.com/l/meeting/new?${stringify(details)}`;
};

export const ics = (calendarEvent: CalendarEvent): string => {
  const event = eventify(calendarEvent);
  const formattedDescription: string = (event.description || '')
    .replace(/,/gm, ',')
    .replace(/;/gm, ';')
    .replace(/\r\n/gm, '\n')
    .replace(/\n/gm, '\\n')
    .replace(/(\\n)[\s\t]+/gm, '\\n');

  const formattedLocation: string = (event.location || '')
    .replace(/,/gm, ',')
    .replace(/;/gm, ';')
    .replace(/\r\n/gm, '\n')
    .replace(/\n/gm, '\\n')
    .replace(/(\\n)[\s\t]+/gm, '\\n');

  const { startDate, endDate } = formatTimes(event, event.allDay ? 'allDay' : 'dateTimeUTC', true);

  const calendarChunks = [
    {
      key: 'BEGIN',
      value: 'VCALENDAR',
    },
    {
      key: 'VERSION',
      value: '2.0',
    },
    {
      key: 'PRODID',
      value: event.title,
    },
    {
      key: 'BEGIN',
      value: 'VEVENT',
    },
    {
      key: 'URL',
      value: event.url,
    },
    {
      key: 'DTSTART',
      value: startDate,
    },
    {
      key: 'DTEND',
      value: endDate,
    },
    {
      key: 'DTSTAMP',
      value: new Date().toISOString(),
    },
    {
      key: 'RRULE',
      value: event.rRule,
    },
    {
      key: 'SUMMARY',
      value: event.title,
    },
    {
      key: 'DESCRIPTION',
      value: formattedDescription,
    },
    {
      key: 'LOCATION',
      value: formattedLocation,
    },
    {
      key: 'ORGANIZER',
      value: event.organizer,
    },
    {
      key: 'UID',
      value: Math.floor(Math.random() * 100000)
        .toString()
        .replace('.', ''),
    },
    {
      key: 'END',
      value: 'VEVENT',
    },
    {
      key: 'END',
      value: 'VCALENDAR',
    },
  ];

  let calendarUrl = '';

  calendarChunks.forEach(chunk => {
    if (chunk.value) {
      if (chunk.key == 'ORGANIZER') {
        const value = chunk.value as CalendarEventOrganizer;
        calendarUrl += `${chunk.key};${encodeURIComponent(`CN=${value.name}:MAILTO:${value.email}\r\n`)}`;
      } else {
        calendarUrl += `${chunk.key}:${encodeURIComponent(`${chunk.value}\r\n`)}`;
      }
    }
  });

  return `data:text/calendar;charset=utf8,${calendarUrl}`;
};
