import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  Attachment,
  AttachmentEvent,
  AttachmentMessageType,
  Company,
  DetailType,
  RockConstants,
  RockSortField,
  RockTypes,
  Team,
} from '@ninety/ui/legacy/shared/index';
import { DragAndDropEvent } from '@ninety/ui/legacy/shared/models/_shared/drag-and-drop';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { Milestone } from '@ninety/ui/legacy/shared/models/rocks/milestone';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { OrdinalType, RockSort } from '@ninety/ui/legacy/shared/models/rocks/rock-sort';
import { RockStatusCode } from '@ninety/ui/legacy/shared/models/rocks/rock-status-code';
import { RocksAndMilestonesResponse } from '@ninety/ui/legacy/shared/models/rocks/rocks-and-milestones-response';
import { RockStatusChangeEvent } from '@ninety/ui/legacy/shared/models/rocks/update-rock-event';
import { UserListModel } from '@ninety/ui/legacy/state/app-entities/user-list/api/user-list.model';

export const RocksStateActions = createActionGroup({
  source: 'Rocks',
  events: {
    'Page Init': emptyProps(),
    'Page Destroy': emptyProps(),

    'Hydrate Page Size': props<{ pageSize: number }>(),

    'Get Rocks And Milestones Success': props<{ rocksAndMilestones: RocksAndMilestonesResponse }>(),
    'Get Rocks By Team Success': props<{ rocks: Rock[]; users: UserListModel[]; team: Team; company: Company }>(),
    'Get Rocks By Team Failed': props<{ error: unknown }>(),
    'Get Rocks And Milestones Failed': props<{ error: unknown }>(),
    'Get Archived Rocks': props<{
      pageIndex: number;
      pageSize: number;
      sortField: RockSortField;
      direction: SortDirection;
    }>(),
    'Get Archived Rocks Success': props<{ rocks: Rock[]; totalCount: number }>(),
    'Get Archived Rocks Failed': props<{ error: unknown }>(),
    'Get Company Rocks Success': props<{ rocks: Rock[] }>(),

    'Update Rock': props<{ update: Update<Rock>; rock?: Rock }>(),
    'Update Rock Local': props<{ update: Update<Rock>; isUserRock: boolean }>(),
    'Update Rock Success': emptyProps(),
    'Update Rock Failed': props<{ error: unknown }>(),
    'Update Rock And Remove From Company Rock List': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Update Rock And Remove From Department Rock List': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Update Rock And Add To Company Rock List': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Update Rock And Add To Department Rock List': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Update Rock And Remove From Company Rock List Local': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Update Rock And Remove From Department Rock List Local': props<{
      update: Update<Rock>;
      event: DragAndDropEvent;
    }>(),
    'Update Rock And Add To Company Rock List Local': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Update Rock And Add To Department Rock List Local': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Update Rock And Sort': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Update Rock And Sort Local': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),

    'Toggle Archived': props<{ rock: Rock }>(),
    'Toggle Archived Success': props<{ rockId: string }>(),
    'Toggle Archived Failed': props<{ error: unknown }>(),

    Delete: props<{ rock: Rock }>(),
    'Delete Success': props<{ rockId: string }>(),
    'Delete Failed': props<{ error: unknown }>(),
    'Delete Local': props<{ rockId: string }>(),

    'Update User Success': props<{
      rockId: string;
      userId: string;
      /** only remove rock from list and close detail view if there are no milestones for the current user of this rock */
      removeFromList: boolean;
    }>(),
    'Update User Failed': props<{ error: unknown }>(),

    'Update Status Code': props<{ event: RockStatusChangeEvent }>(),

    'Open Create Dialog': emptyProps(),
    'Open Milestone Create Dialog': props<{ rock: Rock }>(),

    'Add Rock': props<{ rock: Rock }>(),
    'Add Rock V2': props<{ rock: Rock }>(),
    'Add Rock V2 Failed': props<{ error: unknown }>(),

    'Open Rock In Detail View': props<{ rockId: string; detailType?: DetailType }>(),
    'Open Rock V2 In Detail View': props<{ rockId: string }>(),
    'Open Rock V3 In Detail View': props<{ rockId: string }>(),

    'Filter By User': props<{ userId: string }>(),
    'Filter By Team': props<{ teamId: string }>(),
    'Set Filter By Team': props<{ teamId: string }>(),
    'Filter By Status': props<{ statusCode: RockStatusCode }>(),

    'Pagination Change': props<{ index: number; size: number }>(),
    'Sort Change': props<{ field: RockSort; direction: SortDirection }>(),
    'Sort User Rocks': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Sort Company Rocks': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Sort Department Rocks': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Sort User Rocks Local': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Sort Company Rocks Local': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),
    'Sort Department Rocks Local': props<{ update: Update<Rock>; event: DragAndDropEvent }>(),

    'Update Pagination On User Settings': props<{ index: number; size: number }>(),

    'Update User Ordinals': props<DragAndDropEvent>(),
    'Update Ordinals': props<{ rocks: Rock[]; ordinalType: OrdinalType }>(),
    'Update Ordinals Local': props<{ rocks: Rock[]; ordinalType: OrdinalType }>(),
    'Update Ordinals Success': emptyProps(),
    'Update Ordinals Failed': props<{ error: unknown }>(),
    'Update User Ordinals Local': props<DragAndDropEvent>(),

    //Removes from list if rock status is updated from detail view but rock not in filtered list
    'Remove From List': props<{ rockId: string; closeDetailView?: boolean }>(),
    'Remove Completed': emptyProps(),
    //Adds to list if rock status is updated from detail view but rock not in filtered list
    'Add To List': props<{ rock: Rock }>(),

    //on component destroy reset rocksModule state
    'Reset Store': emptyProps(),

    'Clear Sort': emptyProps(),
    'Clear Selected Rock': emptyProps(),
    'Clear Selected Milestone': emptyProps(),

    'Create Issue': props<{ rock: Rock }>(),
    'Create Todo': props<{ rock: Rock }>(),
    'Create Rock From Context Menu': props<{ rock: Rock }>(),
    'Create Headline': props<{ rock: Rock }>(),

    Print: props<{ rock: Rock }>(),
    Notify: props<{ message: string }>(),

    //Milestones

    'Open Milestone Card V2 In Detail View': props<{ milestoneId: string }>(),
    'Toggle Milestone Complete': props<{ update: Update<Milestone> }>,

    //these actions also call the service
    'Update Milestone': props<{ update: Update<Milestone> }>(),
    'Update Milestone From List': props<{ rockId: string; update: Update<Milestone> }>(),
    'Update Milestone Success': emptyProps(),
    'Update Milestone Failed': props<{ error: unknown }>(),

    'Delete Milestone': props<{ milestoneId: string; rockId?: string }>(),
    'Delete Milestone Success': emptyProps(),
    'Delete Milestone Failed': props<{ error: unknown }>(),

    //updates only the store, a request was prev sent from the component
    //TODO NEXT: when the detail view refactor is done, we should only have one action to update, delete, add etc
    'Add Milestone To Store': props<{ milestone: Milestone }>(),
    'Update Milestone In Store': props<{ update: Update<Milestone> }>(),
    'Update Milestone In Store Success': emptyProps(),
    'Update Milestone In Store Failed': props<{ error: unknown }>(),
    'Delete Milestone From Store': props<{ milestoneId: string }>(),
    'Add Milestone To Rock Local': props<{
      rock: Rock;
      milestone: Milestone;
      editableRockType?: Exclude<RockTypes, RockConstants.companyRocks>;
    }>(),
    'Create Milestone From Rock': props<{ rock: Rock; milestone: Milestone }>(),
    'Delete Milestone From Rock Local': props<{ milestone: Milestone }>(),
    'Create Milestone From Rock Failed': props<{ error: unknown }>(),

    'Create Issue From Milestone': props<{ milestone: Milestone }>(),
    'Create Todo From Milestone': props<{ milestone: Milestone }>(),
    'Create Rock From Milestone': props<{ milestone: Milestone }>(),
    'Create Headline From Milestone': props<{ milestone: Milestone }>(),

    'Add Milestone Attachment': props<{ id: string; attachment: Attachment }>(),
    'Remove Milestone Attachment': props<{ id: string; attachmentId: string }>(),

    'Add Attachment': props<{ id: string; attachment: Attachment }>(),
    'Remove Attachment': props<{ id: string; attachmentId: string }>(),
    'Attachment Uploaded': props<{ event: AttachmentEvent & { meetingMessageType?: AttachmentMessageType } }>(),
    'Attachment Removed': props<{ event: AttachmentEvent & { meetingMessageType?: AttachmentMessageType } }>(),

    'Set RocksV3': props<{ rocksV3: boolean }>(),
    'Archive All Rocks': emptyProps(),
  },
});
