import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

/**
 * Directive used to set the height of an element to its scroll height. This is useful for text areas.
 */
@Directive({
  selector: '[ninetyAutoHeight]',
  standalone: true,
})
export class AutoHeightDirective implements OnDestroy, OnInit {
  private unListenToInput: () => void;

  @Input() atInit = true;

  @Input() set onType(registerInputEventWatcher: boolean) {
    if (registerInputEventWatcher) {
      this.unListenToInput = this.renderer.listen(this.host.nativeElement, 'input', () => this.setHeight());
    } else if (this.unListenToInput) {
      this.unListenToInput();
    }
  }

  constructor(private renderer: Renderer2, private host: ElementRef) {}

  ngOnInit() {
    if (this.atInit) {
      // Must be run in setTimeout :/
      // https://medium.com/@chandrahasstvs/building-your-own-text-area-auto-resize-directive-in-angular-bbe3e5144e97
      setTimeout(() => this.setHeight());
    }
  }

  ngOnDestroy() {
    if (this.unListenToInput) this.unListenToInput();
  }

  /**
   * Sets the height of the element to its current scroll height. Must be run after the browser has
   * finished rendering the element.
   */
  private setHeight() {
    const scrollHeight = this.host.nativeElement.scrollHeight;
    this.renderer.setStyle(this.host.nativeElement, 'min-height', `${scrollHeight}px`);
  }
}
