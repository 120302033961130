<ng-container [ngSwitch]="state">
  <ninety-button
    *ngSwitchCase="DownloadButtonStateEnum.print"
    class="generate-btn"
    stroked
    primary
    (click)="createPdf.emit()"
    (keydown.space)="openPdf.emit()"
    (keydown.enter)="openPdf.emit()">
    <terra-icon icon="file-pdf" />
    Generate Report
  </ninety-button>

  <ninety-button *ngSwitchCase="DownloadButtonStateEnum.loading" class="loading-btn" filled primary [disabled]="true">
    <terra-icon class="loading-icon spin-indefinitely-1s" icon="spinner-gap" data-chromatic="ignore" />
    Generating...
  </ninety-button>

  <ninety-button
    *ngSwitchCase="DownloadButtonStateEnum.download"
    class="download-btn"
    filled
    positive
    (click)="openPdf.emit()"
    (keydown.space)="openPdf.emit()"
    (keydown.enter)="openPdf.emit()">
    <terra-icon icon="arrow-square-out" />
    View Report
  </ninety-button>

  <ninety-button *ngSwitchCase="DownloadButtonStateEnum.disabled" class="disabled-btn" filled primary [disabled]="true">
    <terra-icon icon="file-pdf" />
    Generate Report
  </ninety-button>
</ng-container>
