import { createSelector } from '@ngrx/store';

import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

import { selectGuideState } from '../../_state';

export namespace GuideSelectors {
  export const selectIsGuideEnabled = createSelector(
    selectFeatureFlag(FeatureFlagKeys.gettingStartedGuide),
    selectCurrentUser,
    (flag, { guideEnabled, guideDismissed }) => flag && guideEnabled && !guideDismissed
  );

  export const selectGuideViewed = createSelector(selectCurrentUser, user => user.guideViewed);

  export const selectIsGuideOpened = createSelector(selectGuideState, state => state.guideOpened);
  export const selectTitle = createSelector(selectGuideState, state => state.title);
  export const selectSubtitle = createSelector(selectGuideState, state => state.subtitle);
  export const selectCompletionPercentage = createSelector(selectGuideState, state => state.completionPercentage);

  export const selectPhases = createSelector(selectGuideState, state => state.phases);
  export const selectSelectedPhase = createSelector(selectGuideState, state => state.selectedPhase);
}
