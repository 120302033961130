import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { PricingTier } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { selectLanguage } from '@ninety/ui/legacy/state/index';
import { FilterBarActions } from '@ninety/web/pages/layouts/_state/filterbar/filterbar-state.actions';

@Component({
  standalone: true,
  imports: [CommonModule, LetDirective, TerraIconModule, NinetyCMModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ninety-cm-tiered-page *ngrxLet="language$ as language">
      <div headline>Align Your Team With Clear {{ language.measurable.items }}</div>
      <div description>
        Unlock the {{ language.scorecard.item }} tool to track {{ language.measurable.items }} and targets. Ensure
        everyone is aligned on what success looks like, and drive strong performance across your team.
      </div>
      <button ninety-cm-button>
        <terra-icon icon="lightning" variant="fill" />
        Explore {{ pricingTierEnum.Essentials }} Plan
      </button>
    </ninety-cm-tiered-page>
  `,
})
export class ScorecardTieredPageComponent {
  language$ = this.store.select(selectLanguage);
  pricingTierEnum = PricingTier;

  constructor(private readonly store: Store) {
    this.store.dispatch(FilterBarActions.hide());
  }
}
