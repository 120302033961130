import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TippyDirective } from '@ngneat/helipopper';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ImageCropperModule } from 'ngx-image-cropper';

import {
  TerraAvatarModule,
  TerraCheckboxModule,
  TerraDividerModule,
  TerraFormFieldModule,
  TerraFormLabelModule,
  TerraIconModule,
  TerraSearchInputModule,
  TerraSelectModule,
  TerraSwitchModule,
  TerraTextInputModule,
} from '@ninety/terra';

import { AngularMaterialModule } from '../_angular-material/angular-material.module';
import { ButtonComponent } from '../_components/buttons/button/button.component';
import { ButtonGroupComponent } from '../_components/buttons/button-group/button-group.component';
import { FroalaEditorModule, FroalaViewModule } from '../_components/froala-wysiwyg';
import { IntlTelInputComponent } from '../_components/inputs/intl-tel-input/intl-tel-input.component';
import { NinetyTooltipDirective } from '../_directives';

import { AddItemButtonComponent } from './components/add-item-button/add-item-button.component';
import { AlertComponent } from './components/alert/alert.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { IconWithCountComponent } from './components/attachments-icon/attachments-icon.component';
import { ClockTimerComponent } from './components/clock-timer/clock-timer.component';
import { ContactCardHoverDirective } from './components/contact-card-container/contact-card-hover.directive';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { ForgotPasswordSubmitDialogComponent } from './components/forgot-password-submit-dialog/forgot-password-submit-dialog.component';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { InlineEditableDueDateComponent } from './components/inline-editable-due-date/inline-editable-due-date.component';
import { InlineEditableTitleComponent } from './components/inline-editable-title/inline-editable-title.component';
import { ItemAdditionalDetailsComponent } from './components/item-additional-details/item-additional-details.component';
import { ItemCardComponent } from './components/item-card/item-card.component';
import { ItemCommentsComponent } from './components/item-comments/item-comments.component';
import { MeetingAgendasComponent } from './components/meeting-agendas/meeting-agendas.component';
import { MetricCardComponent } from './components/metric-card/metric-card.component';
import { PasswordDialogComponent } from './components/password-dialog/password-dialog.component';
import { PrintDialogComponent } from './components/print-dialog/print-dialog.component';
import { TeamsSelectComponent } from './components/teams-select/teams-select.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { TextEditorFroalaComponent } from './components/text-editor-froala/text-editor-froala.component';
import { TimeSelectComponent } from './components/time-select/time-select.component';
import { TimezoneSelectComponent } from './components/timezone-select/timezone-select.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ElementResizeAwareModule } from './directives/element-resize-aware/element-resize-aware.module';
import { EllipsifyDirective } from './directives/ellipsify.directive';
import { InputAutofocusDirective } from './directives/input-autofocus.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { StopRippleDirective } from './directives/stop-ripple.directive';
import { ValidateMinMaxDirective } from './directives/validate-min-max.directive';
import { FroalaOptions, FroalaTextEditorOptions } from './models/_shared/froala-options';
import { TextEditorOptions } from './models/_shared/text-editor-options';
import { AddFullNamePipe } from './pipes/add-full-name.pipe';
import { AsItemPipe } from './pipes/as-item.pipe';
import { ConversationStatusPipe } from './pipes/conversation-status-pipe';
import { DateCustomFormatPipe } from './pipes/date-custom.pipe';
import { DayAtTimePipe } from './pipes/dates/day-at-time.pipe';
import { ItemDueStatusPipe } from './pipes/dates/item-due-status.pipe';
import {
  MinutesPastMidnightToMeridiemPipe,
  MinutesPastMidnightToTwelveHourPipe,
  MinutesPastMidnightToTwentyFourHourPipe,
} from './pipes/dates/time-conversion-pipes';
import { DisplayFullNamePipe } from './pipes/display-full-name.pipe';
import { EmailAddressSearchPipe } from './pipes/email-address-search.pipe';
import { EmptyStringPipe } from './pipes/empty-string.pipe';
import { FalseyMapperPipe } from './pipes/falsey-mapper.pipe';
import { FilterByIdPipe } from './pipes/filter-by-id.pipe';
import { FilterByRocksTeamIdPipe } from './pipes/filter-by-rocks-team-id.pipe';
import { FilterByTeamIdPipe } from './pipes/filter-by-team-id.pipe';
import { FilterMilestonesPipe } from './pipes/filter-milestones.pipe';
import { FilterOutItemByIdPipe } from './pipes/filter-out-item-by-id.pipe';
import { FilterOutProjectTeamsPipe } from './pipes/filter-out-project-teams.pipe';
import { FilterOutTeamPipe } from './pipes/filter-out-team.pipe';
import { FilterOutUsedDefaultSectionsPipe } from './pipes/filter-out-used-default-sections.pipe';
import { FilterOutPipe } from './pipes/filter-out.pipe';
import { FilterReducerPipe } from './pipes/filter-reducer.pipe';
import { FilterReferredCompaniesPipe } from './pipes/filter-referred-companies.pipe';
import { FilterUsersByRolePipe } from './pipes/filter-users-by-role.pipe';
import { FilterUsersByTeamPipe } from './pipes/filter-users-by-team.pipe';
import { GetStartOrContinueMeetingTextPipe } from './pipes/get-start-or-continue-meeting-text';
import { GetTeamNameByIdPipe } from './pipes/get-team-name-by-id.pipe';
import { GetUserNamePipe } from './pipes/get-user-name.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { IsNotEmptyObjectPipe } from './pipes/is-not-empty-object.pipe';
import { JoinPropertiesPipe } from './pipes/join-properties.pipe';
import { KeyValuePipe } from './pipes/key-value.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { LogPipe } from './pipes/log.pipe';
import { NameSearchPipe } from './pipes/name-search.pipe';
import { NameValuePipe } from './pipes/name-value.pipe';
import { NotDoneByDueDatePipe } from './pipes/not-done-by-due-date.pipe';
import { OrderAlphabeticallyPipe } from './pipes/order-alphabetically.pipe';
import { OrderByTitlePipe } from './pipes/order-by-title.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { RockStatusFilterPipe } from './pipes/rock-status-filter-pipe';
import { RoundDownPipe } from './pipes/round-down.pipe';
import { SearchCompanyFilterPipe } from './pipes/search-company-filter.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SearchUserFilterPipe } from './pipes/search-user-filter.pipe';
import { SimpleFilterPipe } from './pipes/simple-filter.pipe';
import { SortByCompanyNamePipe } from './pipes/sort-by-company-name.pipe';
import { SortByNamePipe } from './pipes/sort-by-name.pipe';
import { SortByUserNamePipe } from './pipes/sort-by-user-name.pipe';
import { StringDatePipe } from './pipes/string-date.pipe';
import { TitleFilterPipe } from './pipes/title-filter.pipe';
import { UniqueByPipe } from './pipes/unique-by.pipe';
import { UnitNamePipe } from './pipes/unit-name.pipe';
import { UnsafeHtmlPipe } from './pipes/unsafe-html.pipe';
import { UnsafeUrlPipe } from './pipes/unsafe-url.pipe';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { GetValidRatingsPipe } from './pipes/valid-ratings.pipe';
import { YesNoMaybeClassPipe } from './pipes/yes-no-maybe-css-class.pipe';
import { TemplateSelectModule } from './template-select/template-select.module';

@NgModule({
  declarations: [
    StopRippleDirective,
    UserSelectComponent,
    SearchFilterPipe,
    SortByUserNamePipe,
    SearchUserFilterPipe,
    KeysPipe,
    KeyValuePipe,
    NameValuePipe,
    AlertComponent,
    ForgotPasswordDialogComponent,
    PasswordDialogComponent,
    EmptyStringPipe,
    SimpleFilterPipe,
    StringDatePipe,
    AttachmentsComponent,
    InputAutofocusDirective,
    OrderByTitlePipe,
    TitleFilterPipe,
    FilterOutPipe,
    RoundDownPipe,
    ClockTimerComponent,
    ValidateMinMaxDirective,
    FilterOutProjectTeamsPipe,
    SearchCompanyFilterPipe,
    SortByCompanyNamePipe,
    OrderAlphabeticallyPipe,
    FilterOutTeamPipe,
    FilterByTeamIdPipe,
    FilterByRocksTeamIdPipe,
    ImageDialogComponent,
    TeamsSelectComponent,
    RockStatusFilterPipe,
    FilterUsersByTeamPipe,
    FilterByIdPipe,
    NameSearchPipe,
    RockStatusFilterPipe,
    FilterMilestonesPipe,
    SortByNamePipe,
    UnsafeUrlPipe,
    YesNoMaybeClassPipe,
    LogPipe,
    ForgotPasswordSubmitDialogComponent,
    UniqueByPipe,
    MinutesPastMidnightToTwelveHourPipe,
    MinutesPastMidnightToTwentyFourHourPipe,
    MinutesPastMidnightToMeridiemPipe,
    ConversationStatusPipe,
    GetUserNamePipe,
    NotDoneByDueDatePipe,
    IsNotEmptyObjectPipe,
    PrintDialogComponent,
    FilterOutItemByIdPipe,
    FilterUsersByRolePipe,
    GetTeamNameByIdPipe,
    TimezoneSelectComponent,
    TimeSelectComponent,
    AsItemPipe,
    AddFullNamePipe,
    TextEditorComponent,
    TextEditorFroalaComponent,
    GetStartOrContinueMeetingTextPipe,
    JoinPropertiesPipe,
    EmailAddressSearchPipe,
    GetValidRatingsPipe,
    ItemCardComponent,
    IconWithCountComponent,
    FilterReferredCompaniesPipe,
    MeetingAgendasComponent,
    ItemAdditionalDetailsComponent,
    ItemCommentsComponent,
    EllipsifyDirective,
    InitialsPipe,
    UnitNamePipe,
    FilterOutUsedDefaultSectionsPipe,
    FilterReducerPipe,
    FalseyMapperPipe,
    MetricCardComponent,
    DayAtTimePipe,
    DateCustomFormatPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ImageCropperModule,
    AngularMaterialModule,
    TippyDirective,
    ClipboardModule,
    ElementResizeAwareModule,
    TemplateSelectModule,
    ButtonComponent,
    StopPropagationDirective,
    ItemDueStatusPipe,
    ContactCardHoverDirective,
    InlineEditableTitleComponent,
    AddItemButtonComponent,
    IntlTelInputComponent,
    ClickOutsideDirective,
    PushPipe,
    OrderByPipe,
    UserInitialsPipe,
    DisplayFullNamePipe,
    LetDirective,
    TerraDividerModule,
    TerraIconModule,
    InlineEditableDueDateComponent,
    TerraSwitchModule,
    TerraCheckboxModule,
    TerraAvatarModule,
    TerraFormFieldModule,
    TerraFormLabelModule,
    TerraSelectModule,
    TerraTextInputModule,
    TerraSearchInputModule,
    FormsModule,
    NinetyTooltipDirective,
    ButtonGroupComponent,
    TerraFormFieldModule,
    TerraTextInputModule,
    UnsafeHtmlPipe,
  ],
  exports: [
    AlertComponent,
    StopPropagationDirective,
    ContactCardHoverDirective,
    StopRippleDirective,
    UserSelectComponent,
    SearchFilterPipe,
    SortByUserNamePipe,
    KeysPipe,
    KeyValuePipe,
    NameValuePipe,
    ForgotPasswordDialogComponent,
    EmptyStringPipe,
    SimpleFilterPipe,
    OrderByPipe,
    StringDatePipe,
    AttachmentsComponent,
    InputAutofocusDirective,
    FilterOutPipe,
    RoundDownPipe,
    ClockTimerComponent,
    ValidateMinMaxDirective,
    FilterOutProjectTeamsPipe,
    SearchCompanyFilterPipe,
    SortByCompanyNamePipe,
    OrderAlphabeticallyPipe,
    FilterOutTeamPipe,
    FilterByTeamIdPipe,
    FilterByRocksTeamIdPipe,
    SearchUserFilterPipe,
    ImageDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    TeamsSelectComponent,
    RockStatusFilterPipe,
    FilterUsersByTeamPipe,
    FilterByIdPipe,
    FilterMilestonesPipe,
    SortByNamePipe,
    UnsafeHtmlPipe,
    UnsafeUrlPipe,
    LogPipe,
    UniqueByPipe,
    MinutesPastMidnightToTwelveHourPipe,
    MinutesPastMidnightToTwentyFourHourPipe,
    MinutesPastMidnightToMeridiemPipe,
    DisplayFullNamePipe,
    ConversationStatusPipe,
    GetUserNamePipe,
    UserInitialsPipe,
    NotDoneByDueDatePipe,
    IsNotEmptyObjectPipe,
    PrintDialogComponent,
    ItemDueStatusPipe,
    ClickOutsideDirective,
    FilterOutItemByIdPipe,
    FilterUsersByRolePipe,
    GetTeamNameByIdPipe,
    TimezoneSelectComponent,
    TimeSelectComponent,
    AsItemPipe,
    FroalaEditorModule,
    FroalaViewModule,
    TextEditorComponent,
    TextEditorFroalaComponent,
    GetStartOrContinueMeetingTextPipe,
    JoinPropertiesPipe,
    EmailAddressSearchPipe,
    ClipboardModule,
    GetValidRatingsPipe,
    ItemCardComponent,
    AddItemButtonComponent,
    IconWithCountComponent,
    FilterReferredCompaniesPipe,
    InlineEditableTitleComponent,
    MeetingAgendasComponent,
    ElementResizeAwareModule,
    EllipsifyDirective,
    InitialsPipe,
    FilterOutUsedDefaultSectionsPipe,
    FilterReducerPipe,
    FalseyMapperPipe,
    UnitNamePipe,
    MetricCardComponent,
    AddFullNamePipe,
    YesNoMaybeClassPipe,
    ItemCommentsComponent,
    ItemAdditionalDetailsComponent,
    TippyDirective,
    DayAtTimePipe,
    DateCustomFormatPipe,
    InlineEditableDueDateComponent,
  ],
  providers: [
    OrderByPipe,
    SearchFilterPipe,
    SortByUserNamePipe,
    CurrencyPipe,
    DecimalPipe,
    OrderByTitlePipe,
    FilterOutProjectTeamsPipe,
    FilterOutTeamPipe,
    FilterByTeamIdPipe,
    FilterMilestonesPipe,
    FilterByRocksTeamIdPipe,
    OrderAlphabeticallyPipe,
    SortByNamePipe,
    MinutesPastMidnightToTwelveHourPipe,
    MinutesPastMidnightToTwentyFourHourPipe,
    MinutesPastMidnightToMeridiemPipe,
    FilterOutItemByIdPipe,
    SortByCompanyNamePipe,
    JoinPropertiesPipe,
    FilterOutUsedDefaultSectionsPipe,
    FilterReducerPipe,
    UnitNamePipe,
    DisplayFullNamePipe,
    UserInitialsPipe,
    DateCustomFormatPipe,
    // TODO move to _components
    {
      provide: TextEditorOptions,
      useFactory: () => new TextEditorOptions<FroalaOptions>(new FroalaTextEditorOptions()),
    },
    {
      provide: FroalaTextEditorOptions,
      useFactory: () => new FroalaTextEditorOptions(),
    },
  ],
})
export class SharedModule {}
