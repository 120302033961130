import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { merge } from 'lodash';

import { ZoomScale } from '@ninety/ui/legacy/components/tree/models/zoom-scale.model';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { mongoIdAdapter } from '@ninety/ui/legacy/shared/ngrx/mongo-id-adapter';

import { ResponsibilityChartModel } from '../../models/responsibility-chart.model';

export interface CurrentChartModel extends EntityState<SeatModel> {
  /** The current MAC being displayed */
  chart?: ResponsibilityChartModel;

  /** When true, the primary or MAC is waiting on render/fetch */
  loading: boolean;

  /** The current SVG zoom */
  zoomScale: ZoomScale;

  /**
   * An array of seatIds containing the node & all descendents of the detail seat. Enables the detail to properly filter
   * the set of all seats to a set representing all valid, possible "manager" seats. Expected to set while navigating
   * and cleared after detail is closed.
   */
  descendantsOfDetailSeat: string[] | null;

  /** The seatId of the seat currently being rendered in the details panel */
  detailSeatId: string | null;

  /**
   * The count of seats currently visible in the tree
   * @deprecated supports a feature no longer in use
   */
  visibleSeatCount: number;

  /**
   * The current top seat ID
   * @deprecated supports a feature no longer in use
   */
  topSeatId?: string;
}

export const seatStateAdapter = createEntityAdapter<SeatModel>({ selectId: mongoIdAdapter<SeatModel>() });

export const INITIAL_ACC_ZOOM_SCALE = 0.85;

/** Create a new CurrentChartModel with default values */
export function createInitialCurrentChartModel(partial?: Partial<CurrentChartModel>): CurrentChartModel {
  const _default: CurrentChartModel = {
    ...seatStateAdapter.getInitialState(), // Have to do this here, not in merge, to satisfy type
    loading: false,
    zoomScale: new ZoomScale(INITIAL_ACC_ZOOM_SCALE),
    chart: undefined,
    visibleSeatCount: 0,
    descendantsOfDetailSeat: null,
    detailSeatId: null,
  };
  return merge({}, _default, partial);
}
