import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import partition from 'lodash/partition';
import { map, Observable } from 'rxjs';

import { Commitment } from '@ninety/ui/legacy/shared/models/feedback/commitments';
import {
  DefaultConversationQuestion,
  FormalConversationQuestion,
  getDefaultConversationQuestions,
  getDefaultQuarterlyQuestions,
} from '@ninety/ui/legacy/shared/models/feedback/formal-conversation-question';
import { OneOnOneSettings, RatingsSettings } from '@ninety/ui/legacy/shared/models/feedback/one-on-one-settings';
import { Template } from '@ninety/ui/legacy/shared/models/partner-hub/template';

import { FeedbackService } from '../_shared/feedback.service';

import { ConversationSettingsApi } from './conversation-settings-api';
import { FeedbackApiService } from './feedback-api.service';
import { ConversationSettingsResponseModel } from './models/conversation-settings-response.model';

const companyBase = 'api/v4/Companies';
const commitmentsApi = `${companyBase}/Commitments`;

@Injectable()
export class ConversationSettingsApiService implements ConversationSettingsApi {
  constructor(
    private http: HttpClient,
    private feedbackService: FeedbackService,
    private feedbackApiService: FeedbackApiService
  ) {}

  get(): Observable<ConversationSettingsResponseModel> {
    return this.http.get<ConversationSettingsResponseModel>(`${companyBase}/OneOnOneSettings`).pipe(
      map((o: OneOnOneSettings) => ({
        leadershipCommitments: o.leadershipCommitments,
        coachingCommitments: o.coachingCommitments,
        annualReviewQuestions: o.annualQuestions,
        quarterlyReviewQuestions: o.quarterlyQuestions,
        ratingsSettings: o.ratingsSettings,
      }))
    );
  }

  save(
    annualReviewQuestions: FormalConversationQuestion[],
    commitments: Commitment[],
    template: Template = null,
    quarterlyReviewQuestions: FormalConversationQuestion[],
    ratingsSettings: RatingsSettings
  ): Observable<any> {
    const [leadershipCommitments, coachingCommitments] = partition(commitments, c => c.commitmentType === 'leadership');
    return this.http.put<ConversationSettingsResponseModel>(`${companyBase}/Settings/OneOnOne`, {
      leadershipCommitments: leadershipCommitments,
      coachingCommitments: coachingCommitments,
      annualQuestions: annualReviewQuestions,
      quarterlyQuestions: quarterlyReviewQuestions,
      ratingsSettings: ratingsSettings,
    });
  }

  resetCommitments(): Observable<Commitment[]> {
    return this.http.post<Commitment[]>(`${commitmentsApi}/Reset`, null);
  }

  resetAnnualQuestions() {
    return this.updateAnnualQuestions(getDefaultConversationQuestions());
  }

  resetQuarterlyQuestions(
    leadershipCommitments: Commitment[],
    coachingCommitments: Commitment[],
    annualReviewQuestions: FormalConversationQuestion[],
    ratingsSettings: RatingsSettings
  ) {
    const model: ConversationSettingsResponseModel = {
      leadershipCommitments: leadershipCommitments,
      coachingCommitments: coachingCommitments,
      annualReviewQuestions: annualReviewQuestions,
      quarterlyReviewQuestions: getDefaultQuarterlyQuestions(),
      ratingsSettings: ratingsSettings,
    };
    return this.updateQuarterlyQuestions(model);
  }

  updateAnnualQuestions(questions: DefaultConversationQuestion[]): Observable<void> {
    return this.feedbackService.updateDefaultQuestions(questions);
  }

  updateQuarterlyQuestions(payload: ConversationSettingsResponseModel): Observable<Action> {
    return this.save(
      payload.annualReviewQuestions,
      payload.leadershipCommitments.concat(payload.coachingCommitments),
      null,
      payload.quarterlyReviewQuestions,
      payload.ratingsSettings
    );
  }
}
