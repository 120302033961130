import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { NinetyChipComponent } from '../../../../../../_components/ninety-chip/ninety-chip.component';
import { NinetyTooltipDirective } from '../../../../../../_directives';
import { PricingPlan } from '../../../../../../_shared/models/billing-v3/pricing-plan';
import { PricingTier } from '../../../../../../_shared/models/company/company-pricing-tiers';
import { ButtonComponent } from '../../../../../buttons/button/button.component';
import { CardModule } from '../../../../../cards/card.module';

@Component({
  selector: 'ninety-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss'],
  imports: [NinetyTooltipDirective, CommonModule, CardModule, TerraIconModule, ButtonComponent, NinetyChipComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingCardComponent implements OnInit {
  @Input() plan: PricingPlan;

  protected extendedListOpen = false;

  constructor(private readonly hostElement: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(this.hostElement.nativeElement, `${this.plan.name.toLowerCase()}-card`);
  }

  selectPlan() {
    //TODO redirect to strip
  }

  protected readonly PricingTier = PricingTier;
}
