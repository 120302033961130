import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GuideEffects } from '../guide/_state/guide.effects';
import { StepCompletionChecksEffects } from '../guide/_state/step-completion-checks.effects';
import { MazAnalyticsEffects } from '../maz/_state/maz-analytics.effects';
import { MazEffects } from '../maz/_state/maz.effects';

import { GETTING_STARTED_STATE_KEY, GettingStartedReducers } from './index';

@NgModule({
  imports: [
    StoreModule.forFeature(GETTING_STARTED_STATE_KEY, GettingStartedReducers),
    EffectsModule.forFeature([GuideEffects, MazEffects, StepCompletionChecksEffects, MazAnalyticsEffects]),
  ],
})
export class GettingStartedStateModule {}
