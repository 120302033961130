import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { selectLanguage } from '@ninety/ui/legacy/state/index';
import { FilterBarActions } from '@ninety/web/pages/layouts/_state/filterbar/filterbar-state.actions';

@Component({
  standalone: true,
  imports: [CommonModule, TerraIconModule, NinetyCMModule, LetDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ninety-headlines-tiered-page',
  template: `<ninety-cm-tiered-page *ngrxLet="language$ as language">
    <div headline>Seamless Communication Across Your Teams</div>
    <div description>
      The {{ language.headline.items }} tool lets you easily share news, access archives, and manage announcements. Keep
      everyone informed and aligned, wherever they work.
    </div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" />
      Explore Essentials Plan
    </button>
    <a href="https://help.ninety.io/introduction-to-headlines" rel="external" target="_blank"
      >Learn more about {{ language.headline.items }}</a
    >
  </ninety-cm-tiered-page>`,
})
export class HeadlinesTieredPageComponent implements OnInit {
  private readonly store = inject(Store);
  language$ = this.store.select(selectLanguage);

  ngOnInit(): void {
    this.store.dispatch(FilterBarActions.hide());
  }
}
