<ng-container>
  <div class="dialog-top">
    <div class="flex flex-between">
      <div class="title" data-cy="address-validator_modal-title">Upgrade to a paid subscription</div>
      <ninety-button flat icon mat-dialog-close class="close" (click)="this.dialogRef.close()">
        <terra-icon icon="x" />
      </ninety-button>
    </div>
    <p>
      You are subscribing to the Monthly Ninety Standard Plan. You will be charged
      <br />
      <span class="charges" *ngrxLet="invoice$ as invoice">
        {{ invoice.total / 100 | currency }} a month plus tax.</span
      >
      To continue, please enter your billing address.
    </p>
  </div>
  <mat-card class="mat-elevation-z0">
    <div class="address-dialog-modal">
      <form [formGroup]="form" id="billing-address-entry-form" autocomplete="on" (ngSubmit)="onAddressComplete()">
        <span>
          <terra-form-field class="full-width">
            <terra-form-label>Country</terra-form-label>
            <terra-select
              data-cy="address-validator_country-dropdown"
              name="country"
              id="country"
              formControlName="country"
              (selectionChange)="onCountryChange($event)"
              autocomplete="country">
              <terra-option
                attr.data-cy="address-validator_country-{{ country.text }}"
                *ngFor="let country of countries"
                [value]="country.value">
                {{ country.text }}
              </terra-option>
            </terra-select>
          </terra-form-field>
        </span>
        <ng-container>
          <span>
            <terra-form-field class="full-width">
              <terra-form-label>Address</terra-form-label>
              <terra-text-input
                data-cy="address-validator_address-input-field"
                name="address1"
                autocomplete="address-line1"
                formControlName="line1" />
              <terra-description>Street address, P.O. box, etc.</terra-description>
            </terra-form-field>
          </span>
          <span>
            <terra-form-field class="full-width">
              <terra-form-label>Address2</terra-form-label>
              <terra-text-input
                data-cy="address-validator_address2-input-field"
                name="address2"
                autocomplete="address-line2"
                formControlName="line2" />
              <terra-description>Apartment, Suite, etc.</terra-description>
            </terra-form-field>
          </span>
          <div class="row">
            <span class="col" style="padding-right: 10px">
              <terra-form-field floatLabel="always">
                <terra-form-label>City</terra-form-label>
                <terra-text-input
                  data-cy="address-validator_city-field"
                  formControlName="city"
                  name="city"
                  autocomplete="home city" />
              </terra-form-field>
            </span>
            <span class="col">
              <terra-form-field floatLabel="always">
                <terra-form-label *ngIf="selectedCountry === 'US'">State</terra-form-label>
                <terra-form-label *ngIf="selectedCountry === 'CA'">Province</terra-form-label>
                <terra-form-label *ngIf="selectedCountry !== 'US' && selectedCountry !== 'CA'"
                  >Region/Province</terra-form-label
                >
                <terra-select
                  data-cy="address-validator_state-dropdown"
                  name="state"
                  id="state"
                  formControlName="state"
                  autocomplete="address-level1"
                  maxHeight="250">
                  <terra-option
                    attr.data-cy="address-validator_state-{{ state.text }}"
                    *ngFor="let state of countryStatesProvinces"
                    [value]="state.value">
                    {{ state.text }}
                  </terra-option>
                </terra-select>
              </terra-form-field>
            </span>
          </div>
          <span>
            <terra-form-field floatLabel="always" style="width: 49%">
              <terra-form-label *ngIf="selectedCountry === 'US'">Zip code</terra-form-label>
              <terra-form-label *ngIf="selectedCountry !== 'US'">Postal code</terra-form-label>
              <terra-text-input
                data-cy="address-validator_postalCode-field"
                name="postalCode"
                autocomplete="postal-code"
                formControlName="postalCode" />
            </terra-form-field>
            <terra-divider height="short" />
          </span>
          <span>
            <br />
            <terra-form-field class="full-width">
              <terra-form-label>Billing email address</terra-form-label>
              <terra-text-input
                data-cy="address-validator_email-field"
                name="email"
                autocomplete="email"
                formControlName="email" />
            </terra-form-field>
          </span>
          <span style="display: block" class="next-button">
            <ninety-button
              data-cy="address-validator_next-button"
              filled
              primary
              [disabled]="form.invalid"
              type="submit">
              Next
            </ninety-button>
          </span>
        </ng-container>
      </form>
    </div>
  </mat-card>
</ng-container>
