import { NgModule } from '@angular/core';
import { Core } from '@grapecity/activereports';

import { environment } from '@ninety/ui/web/environments';

/**
 * Configures the environment for Active Reports. All components and modules that use AR should import this module.
 */
@NgModule()
export class NinetyActiveReportsModule {
  public constructor() {
    Core.setLicenseKey(environment.ACTIVE_REPORTS_KEY);
    Core.FontStore.registerFonts('assets/arjs/fontsConfig.json');
  }
}
