import { SortDirection } from '@angular/material/sort';
import { DefaultUrlSerializer } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import ObjectId from 'bson-objectid';
import { cloneDeep as _cloneDeep, orderBy } from 'lodash';

import { QuickFilterOption } from '@ninety/ui/legacy/components/inputs/quick-filter/models/quick-filter-item.model';
import { MeetingSection, RoleCode } from '@ninety/ui/legacy/shared/index';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { MeetingRoutes } from '@ninety/ui/legacy/shared/models/meetings/meeting-routes';
import {
  MeetingType,
  MeetingTypeToMeetingAgendaLanguage,
  MeetingTypeToMeetingAgendaType,
} from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { AgendaTableDataV2 } from '@ninety/ui/legacy/shared/models/meetings-v2/agenda-table-data-v2';
import { RecentRunMeetings } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-response';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import {
  CurrentUserSelectors,
  selectCompany,
  selectUrl,
  TeamSelectors,
  selectUsersForFilterBarTeam,
  selectIsHandsetPortrait,
  selectActiveUsers,
  selectWeeklyMeetingsOnlyFeature,
  selectFeatureFlag,
  FeatureFlagKeys,
  selectCurrentUserId,
} from '@ninety/ui/legacy/state/index';
import { WidgetTemplateType } from '@ninety/web/pages/my-ninety/_models/widget-template-type';

import { MeetingsFeatureStateKey, MeetingsStateModel } from './meetings.model';
import { meetingsStateAdapter } from './meetings.reducer';

export namespace MeetingsStateSelectors {
  export const selectFeature = createFeatureSelector<MeetingsStateModel>(MeetingsFeatureStateKey);

  export const { selectEntities, selectAll } = meetingsStateAdapter.getSelectors(selectFeature);

  export const selectLoading = createSelector(selectFeature, state => state.loading);
  export const selectError = createSelector(selectFeature, state => state.error);
  export const selectTotalCount = createSelector(selectFeature, state => state.totalCount);
  export const selectSelectedId = createSelector(selectFeature, state => state.selectedId);
  export const selectPage = createSelector(selectFeature, state => state.page);
  export const selectSort = createSelector(selectFeature, state => state.sort);
  export const selectAttendees = createSelector(selectFeature, state => state.attendees);
  export const selectFilters = createSelector(selectFeature, state => state.filters);

  export const selectSelectedMeeting = createSelector(selectSelectedId, selectEntities, (selectedId, entities) =>
    selectedId ? entities[selectedId] : null
  );

  export const selectTeamId = createSelector(selectFeature, state => {
    return state.filters.teamId;
  });

  export const selectSelectedTeam = createSelector(TeamSelectors.selectFilterBarTeam, team => team);

  //in some cases the component initializes before the team is fully loaded with settings, etc
  export const selectAreTeamSettingsLoaded = createSelector(
    TeamSelectors.selectFilterBarTeam,
    state => !!state.settings
  );

  export const selectSortForTable = createSelector(selectSort, sort => ({
    active: sort.field as string,
    direction: sort.direction.toLowerCase() as SortDirection,
  }));

  export const selectTemplateType = createSelector(
    selectLoading,
    selectError,
    selectTotalCount,
    selectFilters,
    (loading, error, totalCount, filters) => {
      if (loading && totalCount === 0) {
        return WidgetTemplateType.loading;
      }
      if (error) {
        return WidgetTemplateType.error;
      }
      if (filters.meetingType && totalCount === 0) {
        return WidgetTemplateType.noQueryResults;
      }
      if (totalCount === 0) {
        return WidgetTemplateType.empty;
      }
      return WidgetTemplateType.data;
    }
  );

  /** Sorts by meeting date desc and return the top 2 most recent */
  export const selectRecentMeetingsForStartDialog = createSelector(selectAll, meetings => {
    const sortedMeetings = orderBy(meetings, meeting => new Date(meeting.date).getTime(), 'desc');
    return sortedMeetings.reduce((acc: RecentRunMeetings[], meeting) => {
      if (acc.length < 2 && !acc.find(m => m.agendaName === meeting.agendaName)) {
        acc.push({
          agendaName: meeting.agendaName,
          //list shows day two, we remap to day one for annual to start the correct meeting
          type: meeting.type === MeetingType.annualDayTwo ? MeetingType.annualDayOne : meeting.type,
          date: meeting.date,
          agendaId: meeting.agendaId,
        });
      }
      return acc;
    }, []);
  });

  /** Selects the custom agendas from a team's settings */
  export const selectCustomMeetingSettings = createSelector(
    TeamSelectors.selectFilterBarTeam,
    team =>
      team?.settings?.custom?.map(agenda => ({
        id: agenda._id,
        displayText: agenda.name,
        item: agenda,
      })) || []
  );

  export const selectSltMeetingOptions = createSelector(selectLanguage, language => [
    { id: MeetingType.focusDay, displayText: language.meeting.focusDay, selected: false },
    { id: MeetingType.visionBuildingDayOne, displayText: language.meeting.visionBuildingDayOne, selected: false },
    { id: MeetingType.visionBuildingDayTwo, displayText: language.meeting.visionBuildingDayTwo, selected: false },
  ]);

  export const selectDefaultMeetingOptions = createSelector(
    selectLanguage,
    selectCompany,
    TeamSelectors.selectFilterBarTeam,
    selectSltMeetingOptions,
    selectFeatureFlag(FeatureFlagKeys.weeklyOneOnOneMeetingEnabled),
    (language, company, team, sltMeetingOptions, weeklyOneOnOneEnabled) => {
      const sltOptions = company.seniorLeadershipTeamId === team?._id ? sltMeetingOptions : [];
      const weeklyOneOnOne = weeklyOneOnOneEnabled
        ? [{ id: MeetingType.weeklyOneOnOne, displayText: 'Weekly 1-on-1', selected: false }]
        : [];

      return [
        { id: MeetingType.weekly, displayText: language.meeting.levelTen, selected: false },
        { id: MeetingType.quarterly, displayText: language.meeting.quarterlySession, selected: false },
        { id: MeetingType.annualDayOne, displayText: language.meeting.annualSession, selected: false },
        ...weeklyOneOnOne,
        ...sltOptions,
      ];
    }
  );

  export const selectAgendas = createSelector(
    selectLanguage,
    selectCompany,
    selectSelectedTeam,
    selectFeatureFlag(FeatureFlagKeys.weeklyOneOnOneMeetingEnabled),
    (language, company, team, weeklyOneOnOneEnabled) => {
      if (!team) return []; //selected team is null

      let sltAgendas: AgendaTableDataV2[] = [];
      let customAgendas: AgendaTableDataV2[] = [];
      let allAgendas: AgendaTableDataV2[] = [];
      let weeklyOneOnOneAgenda: AgendaTableDataV2[] = [];

      if (weeklyOneOnOneEnabled) {
        weeklyOneOnOneAgenda = [
          {
            id: MeetingType.weeklyOneOnOne,
            title: 'Weekly 1-on-1',
            type: company.bos,
            totalDuration: calculateDurationFromAgenda(team.settings?.weeklyOneOnOne?.agenda),
          },
        ];
      }

      if (company.seniorLeadershipTeamId === team._id) {
        sltAgendas = [
          {
            id: MeetingType.focusDay,
            title: language.meeting.focusDay,
            type: company.bos,
            totalDuration: calculateDurationFromAgenda(team.settings?.focusDay?.agenda),
          },
          {
            id: MeetingType.visionBuildingDayOne,
            title: language.meeting.visionBuildingDayOne,
            type: company.bos,
            totalDuration: calculateDurationFromAgenda(team.settings?.visionBuildingDayOne?.agenda),
          },
          {
            id: MeetingType.visionBuildingDayTwo,
            title: language.meeting.visionBuildingDayTwo,
            type: company.bos,
            totalDuration: calculateDurationFromAgenda(team.settings?.visionBuildingDayTwo?.agenda),
          },
        ];
      }

      if (team.settings?.custom.length) {
        customAgendas = team.settings.custom.map(agenda => ({
          id: agenda._id,
          title: agenda.name,
          type: 'Custom',
          totalDuration: calculateDurationFromAgenda(agenda?.agenda),
        }));
      }

      allAgendas = [
        {
          id: MeetingType.weekly,
          title: language.meeting.levelTen,
          type: company.bos,
          totalDuration: calculateDurationFromAgenda(team.settings?.weeklyMeetings?.agenda),
        },
        {
          id: MeetingType.quarterly,
          title: language.meeting.quarterlySession,
          type: company.bos,
          totalDuration: calculateDurationFromAgenda(team.settings?.quarterlyMeetings?.agenda),
        },
        {
          id: MeetingType.annualDayOne,
          title: `${language.meeting.annualSession} - Day One`,
          type: company.bos,
          totalDuration: calculateDurationFromAgenda(team.settings?.annualDayOne?.agenda),
        },
        {
          id: MeetingType.annualDayTwo,
          title: `${language.meeting.annualSession} - Day Two`,
          type: company.bos,
          totalDuration: calculateDurationFromAgenda(team.settings?.annualDayTwo?.agenda),
        },
        ...weeklyOneOnOneAgenda,
        ...sltAgendas,
        ...customAgendas,
      ];

      return allAgendas;
    }
  );

  export const selectMeetingStatus = createSelector(selectFeature, state => state.meetingStatus);
  /** checks if there is a running or suspended meeting for the team */
  export const selectMeetingInProgress = createSelector(selectFeature, state => !!state.meetingStatus);

  export const selectMeetingStatusPresenterUserId = createSelector(
    selectFeature,
    state => state.meetingStatus?.presenterUserId
  );

  export const selectCurrentMeeting = createSelector(selectFeature, state => state.currentMeeting);

  export const selectCurrentMeetingInProgress = createSelector(
    selectFeature,
    state => !!state.currentMeeting?.inProgress
  );

  export const selectCurrentSection = createSelector(selectFeature, state => state.currentMeeting?.currentSection);

  export const selectMeetingName = createSelector(
    selectMeetingStatus,
    selectLanguage,
    selectFeatureFlag(FeatureFlagKeys.weeklyOneOnOneMeetingEnabled),
    (meeting, { meeting: language }, weeklyOneOnOneEnabled) => {
      let weeklyOneOnOne = [];

      if (!meeting) return null;
      if (meeting.type === MeetingType.custom) return meeting.name;
      if (weeklyOneOnOneEnabled) weeklyOneOnOne = [{ [MeetingType.weeklyOneOnOne]: 'Weekly 1-on-1' }];

      const meetingTypeToLanguage = {
        [MeetingType.weekly]: language.levelTen,
        [MeetingType.quarterly]: language.quarterly,
        [MeetingType.annualDayOne]: language.annualSession,
        [MeetingType.annualDayTwo]: language.annualSession,
        [MeetingType.focusDay]: language.focusDay,
        [MeetingType.visionBuildingDayOne]: language.visionBuildingDayOne,
        [MeetingType.visionBuildingDayTwo]: language.visionBuildingDayTwo,
        ...weeklyOneOnOne,
      };

      if (meeting.type === MeetingType.annualDayTwo) return meetingTypeToLanguage[meeting.type] + ' (Day 2)';

      return meetingTypeToLanguage[meeting.type];
    }
  );

  export const selectMeetingById = (id: string) => createSelector(selectFeature, state => state.entities[id]);

  export const selectMeetingDetailViewData = (id: string) =>
    createSelector(selectMeetingById(id), meeting => {
      const meetingToOpen = {
        type: DetailType.meeting,
        input: { meeting: _cloneDeep(meeting) }, //_cloneDeep needed so we can update from detail view
      };

      return meetingToOpen;
    });

  /** Selector for filter bar options. Defaults to filter bar or user last accessed team if teamId doesn't exist in state yet.  */
  export const selectTeamFilterOptions = createSelector(
    selectSelectedTeam,
    CurrentUserSelectors.selectTeams,
    (selectedTeam, teams): QuickFilterOption<Team>[] => {
      if (selectedTeam._id) {
        return teams.map(team => ({
          displayText: team.name,
          id: team._id,
          item: team,
          selected: team._id === selectedTeam._id,
        }));
      }
    }
  );

  export const selectSelectedTeamName = createSelector(selectSelectedTeam, team => team.name);
  export const selectSelectedMeetingType = createSelector(selectFilters, filters => filters.meetingType);

  export const selectAgendaTypeOrAgendaIdOrNewFromUrl = createSelector(selectUrl, url => {
    const parsedUrl = new DefaultUrlSerializer().parse(url);
    const [meetingsSegment, agendasSegment, meetingTypeOrAgendaIdOrNew] = parsedUrl.root.children.primary?.segments;

    if (
      !meetingTypeOrAgendaIdOrNew ||
      meetingsSegment.path !== MeetingRoutes.home ||
      agendasSegment.path !== MeetingRoutes.agendas
    )
      return null;

    return meetingTypeOrAgendaIdOrNew.path;
  });

  export const selectAgendaTypeOrIdOrNew = createSelector(
    selectAgendaTypeOrAgendaIdOrNewFromUrl,
    meetingTypeOrAgendaIdOrNew => {
      const meetingTypeToMeetingAgendaType = MeetingTypeToMeetingAgendaType;
      return meetingTypeToMeetingAgendaType[meetingTypeOrAgendaIdOrNew] || meetingTypeOrAgendaIdOrNew;
    }
  );

  export const selectSelectedTeamAgenda = createSelector(
    selectSelectedTeam,
    selectAgendaTypeOrIdOrNew,
    (team, agendaTypeOrIdOrNew) => {
      if (!agendaTypeOrIdOrNew) return null;

      if (ObjectId.isValid(agendaTypeOrIdOrNew)) {
        return team.settings.custom.find(agenda => agenda._id === agendaTypeOrIdOrNew);
      } else if (agendaTypeOrIdOrNew === MeetingRoutes.newCustomAgenda) {
        return null;
      } else {
        return team.settings[agendaTypeOrIdOrNew];
      }
    }
  );

  export const selectSelectedTeamAgendaName = createSelector(
    selectSelectedTeamAgenda,
    selectAgendaTypeOrAgendaIdOrNewFromUrl,
    selectLanguage,
    (agenda, meetingTypeOrAgendaIdOrNew, language) => {
      if (ObjectId.isValid(meetingTypeOrAgendaIdOrNew)) {
        return agenda?.name;
      } else {
        const meetingTypeToMeetingAgendaLanguage = MeetingTypeToMeetingAgendaLanguage;
        return language.meeting[meetingTypeToMeetingAgendaLanguage[meetingTypeOrAgendaIdOrNew]];
      }
    }
  );

  export const selectSelectedIsCustom = createSelector(selectSelectedTeamAgenda, agenda => agenda?.isCustom);

  export const selectCombinedAgendaOptions = createSelector(
    selectCustomMeetingSettings,
    selectDefaultMeetingOptions,
    (customAgendas, defaultAgendas) => {
      return [
        ...defaultAgendas.map(meetingOption => ({
          ...meetingOption,
          //remap day one to day two, we only filter by day two
          id: meetingOption.id === MeetingType.annualDayOne ? MeetingType.annualDayTwo : meetingOption.id,
        })),
        ...customAgendas,
      ];
    }
  );

  export const selectCombinedAgendaOptionsTiered = createSelector(
    selectWeeklyMeetingsOnlyFeature,
    selectCombinedAgendaOptions,
    (weeklyMeetingsOnlyFeature, combinedAgendaOptions) => {
      if (weeklyMeetingsOnlyFeature) {
        return [{ id: combinedAgendaOptions[0].id, displayText: combinedAgendaOptions[0].displayText }];
      }
      return combinedAgendaOptions.map(agenda => ({ id: agenda.id, displayText: agenda.displayText }));
    }
  );

  export const selectCombinedAgendaOptionsWithAll = createSelector(selectCombinedAgendaOptions, agendas => {
    return [{ id: null, displayText: 'all', selected: true }, ...agendas];
  });

  export const selectTeamSelectList = createSelector(selectUsersForFilterBarTeam, users =>
    users.filter(u => u.roleCode !== RoleCode.observer).map(user => ({ value: user._id, name: user.fullName }))
  );

  export const selectTeamHasNonObserverUsers = createSelector(selectTeamSelectList, users => users.length > 0);

  export const selectNonObserverUsersForTeamAndCoaches = createSelector(
    selectActiveUsers,
    TeamSelectors.selectFilterBarTeamId,
    (users, teamId) =>
      users
        .filter(
          u => (u.roleCode !== RoleCode.observer && u.teams.some(team => team.teamId === teamId)) || u.isImplementer
        )
        .map(user => ({ value: user._id, name: user.fullName }))
  );

  export const selectScheduledMeetingsLoading = createSelector(selectFeature, state => state.meetingSchedulesLoading);

  export const selectScheduledMeetingsVisibleAndNonVisibleAttendees = createSelector(
    selectIsHandsetPortrait,
    selectTeamSelectList,
    selectAttendees,
    (isMobile, teamList, attendeesList) => {
      //max index for visible attendees on mobile 3
      //max index for visible attendees on desktop 7
      const maxIndex = isMobile ? 3 : 7;

      const { attendees, nonVisibleAttendees } = attendeesList.reduce(
        (acc, userId, index) => {
          const { value: id, name } = teamList.find(member => member.value === userId);
          const userName = name ? name : 'Unknown';

          if (index < maxIndex) {
            acc.attendees.push(id);
          } else {
            acc.nonVisibleAttendees.push(userName);
          }
          return acc;
        },
        { attendees: [], nonVisibleAttendees: [] }
      );

      return { attendees, nonVisibleAttendees };
    }
  );

  export const selectFilteredMeetingAttendees = createSelector(
    selectCurrentUserId,
    selectCurrentMeeting,
    (currentUserId, currentMeeting) => {
      if (!currentMeeting) {
        return { attendees: [] };
      }

      let attendees = currentMeeting.presentUsers || [];

      if (currentMeeting.presenterUserId) {
        attendees = [currentMeeting.presenterUserId, ...attendees.filter(id => id !== currentMeeting.presenterUserId)];
      }

      if (currentUserId !== currentMeeting.presenterUserId) {
        //remove current user from attendee list if they are not the presenter
        attendees = attendees.filter(id => id !== currentUserId);
      }

      return { attendees };
    }
  );
}

const calculateDurationFromAgenda = (agenda: MeetingSection[]) => agenda?.reduce((acc, a) => acc + a.duration, 0) || 0;
