import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { from, Observable } from 'rxjs';

export interface OutletRouteParams<P> {
  pathParts: string[];
  queryParams?: P;
}

/*
  Relevant Github issues for difficulties around closing named outlets
  and solutions provided by Angular Team:
    - https://github.com/angular/angular/issues/13523#issuecomment-728359595
    - https://github.com/angular/angular/pull/40272
*/

@Injectable({
  providedIn: 'root',
})
export class AuxiliaryRouterOutletService {
  private command = { outlets: {} };

  constructor(private router: Router) {}

  open<P>(params: OutletRouteParams<P>, outlet = 'detail'): Observable<boolean> {
    params.pathParts.unshift(outlet);

    this.command.outlets[outlet] = params.pathParts;

    let config: NavigationExtras = {};
    if (params.queryParams) {
      config = {
        queryParams: params.queryParams,
        queryParamsHandling: 'merge',
      };
    }

    return this.navigate(config);
  }

  close(outlet = 'detail'): Observable<boolean> {
    this.command.outlets[outlet] = null;

    return this.navigate();
  }

  closeDetailBeforeNavigate(activatedRoute: ActivatedRoute, pathParts: string[], extras: NavigationExtras = {}) {
    return this.router
      .navigate([{ outlets: { detail: null } }])
      .then(() => this.router.navigate(pathParts, { ...extras, relativeTo: activatedRoute }));
  }

  private navigate(extras?: NavigationExtras): Observable<boolean> {
    const navigation = this.router.navigate([this.command], { queryParamsHandling: 'merge', ...extras });

    return from(navigation);
  }
}
