import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { provideDefaultTippyConfig } from '@ninety/ui/legacy/core/vendor/tippy.config';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';

/** The possible icons for the multi button */
export enum DashboardWidgetMultiButtonAction {
  /** Expand the widget into singleton mode **/
  expand = 'arrows-out-simple',
  /** Exit singleton mode */
  collapse = 'arrows-in-simple',
  /** Remove widget from dashboard **/
  delete = 'delete',
}

/**
 * Button useful in dashboards/grids to delete in layout mode or enter/exit singleton mode
 *
 * Note, this component expects the parent to transition between the action variants. It will emit the current action, but will
 * not change the action itself.
 */
@Component({
  selector: 'ninety-dashboard-widget-multi-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ButtonComponent, TerraIconModule, NinetyTooltipDirective],
  styles: [],
  providers: [
    provideDefaultTippyConfig({
      theme: 'ninety-tippy-theme',
      delay: 0,
      offset: [0, 26 /* Chosen to push tooltip ~2px away from top of button */],
    }),
  ],
  template: `
    <ninety-button
      *ngIf="action !== DashboardWidgetMultiButtonAction.delete; else deleteBtn"
      flat
      icon
      (click)="emit()"
      [ninetyTooltip]="action === DashboardWidgetMultiButtonAction.collapse ? 'Collapse details' : 'Expand details'">
      <terra-icon [icon]="action" />
    </ninety-button>
    <ng-template #deleteBtn>
      <ninety-button flat icon negative (click)="emit()">
        <terra-icon icon="delete" />
      </ninety-button>
    </ng-template>
  `,
})
export class DashboardWidgetMultiButtonComponent {
  protected readonly DashboardWidgetMultiButtonAction = DashboardWidgetMultiButtonAction;

  /** The action/variant of the button */
  @Input() action: DashboardWidgetMultiButtonAction;
  @Output() actionChanged = new EventEmitter<DashboardWidgetMultiButtonAction>();

  emit() {
    this.actionChanged.emit(this.action);
  }
}
