<div class="compare-plans">
  <ng-template #row let-title="title" let-icons="icons">
    <tr>
      <td>{{ title }}</td>
      <td><terra-icon icon="check" variant="bold" size="32" *ngIf="icons > 3" /></td>
      <td><terra-icon icon="check" variant="bold" size="32" *ngIf="icons > 2" /></td>
      <td><terra-icon icon="check" variant="bold" size="32" *ngIf="icons > 1" /></td>
      <td><terra-icon icon="check" variant="bold" size="32" /></td>
    </tr>
  </ng-template>

  <div class="sticky-wrapper">
    <div class="title">Compare Plans</div>
    <div class="column-header-wrapper">
      <span class="free-span">{{ PricingTier.Free }}</span>
      <span class="essential-span">{{ PricingTier.Essentials }}</span>
      <span class="scale-span">{{ PricingTier.Accelerate }}</span>
      <span class="thrive-span">{{ PricingTier.Thrive }}</span>
    </div>
  </div>

  <div class="expansion-panel-header head-1" (click)="headerClick('start')">
    <div class="title-content" [class.open]="startOpen">
      <terra-icon [icon]="startOpen ? 'caret-up' : 'caret-down'" variant="bold" size="24" class="header-caret" />
      Get started with 90
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-1" />
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-2" />
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-3" />
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-4" />
    </div>
  </div>
  <div [@expandCollapse]="startOpen ? 'expanded' : 'collapsed'">
    <table #start>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Baseline + Org Fit Assessments', icons: 4 }">
      </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Directory', icons: 4 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'KST: Mastery', icons: 4 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Maz for Onboarding', icons: 4 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Org Chart', icons: 4 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Vision + Strategy', icons: 4 }"> </ng-container>
    </table>
  </div>

  <div class="expansion-panel-header head-2" (click)="headerClick('build')">
    <div class="title-content" [class.open]="buildOpen">
      <terra-icon [icon]="buildOpen ? 'caret-up' : 'caret-down'" variant="bold" size="24" class="header-caret" />
      Build up with a BOS toolbox
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-2" />
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-3" />
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-4" />
    </div>
  </div>
  <div [@expandCollapse]="buildOpen ? 'expanded' : 'collapsed'">
    <table #build>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Headlines', icons: 3 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Issues', icons: 3 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Milestones', icons: 3 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'My 90', icons: 3 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Rocks', icons: 3 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Scorecard', icons: 3 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'To-Dos', icons: 3 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Weekly Meetings', icons: 3 }"> </ng-container>
    </table>
  </div>

  <div class="expansion-panel-header head-3" (click)="headerClick('customize')">
    <div class="title-content" [class.open]="customizeOpen">
      <terra-icon [icon]="customizeOpen ? 'caret-up' : 'caret-down'" variant="bold" size="24" class="header-caret" />
      Customize and integrate
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-3" />
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-4" />
    </div>
  </div>
  <div [@expandCollapse]="customizeOpen ? 'expanded' : 'collapsed'">
    <table #customize>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Annual Meetings', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Cascading Messages', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Custom Meetings', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Google Integrations', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Implementation Meetings', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Legacy Process Tool', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Microsoft Integrations', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Quarterly Discussions', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Quarterly Meetings', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Reporting', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Scorecard Integrations and API', icons: 2 }">
      </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Weekly Discussions', icons: 2 }"> </ng-container>
    </table>
  </div>
  <div class="expansion-panel-header head-4" (click)="headerClick('analyze')">
    <div class="title-content" [class.open]="analyzeOpen">
      <terra-icon [icon]="analyzeOpen ? 'caret-up' : 'caret-down'" variant="bold" size="24" class="header-caret" />
      Analyze and grow
      <terra-icon icon="check" variant="bold" size="32" class="title-icon-4" />
    </div>
  </div>
  <div [@expandCollapse]="analyzeOpen ? 'expanded' : 'collapsed'">
    <table class="last-table" #analyze>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Benchmarking', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Board Meetings', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Custom Assessments', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Exit Planning', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'General Purpose APIs', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'iPaaS Integrations', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'KST: Authoring', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Maz for KPIs', icons: 1 }"></ng-container>
      <ng-container
        *ngTemplateOutlet="row; context: { title: 'Maz for Personal Development', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Portfolio Views', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Predictive Analytics', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Project Views', icons: 1 }"></ng-container>
      <ng-container
        *ngTemplateOutlet="row; context: { title: 'Teams/Slack/Zoom Integrations', icons: 1 }"></ng-container>
    </table>
  </div>
</div>
