import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of } from 'rxjs';

import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { Commitment } from '@ninety/ui/legacy/shared/models/feedback/commitments';
import { Conversation, ListViewConversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { ConversationManagee } from '@ninety/ui/legacy/shared/models/feedback/conversation-managee';
import {
  FormalConversationQuestion,
  DefaultConversationQuestion,
} from '@ninety/ui/legacy/shared/models/feedback/formal-conversation-question';
import { NewConversation } from '@ninety/ui/legacy/shared/models/feedback/new-conversation';

import { FeedbackApiActions as actions } from './feedback-api.actions';
import { ConversationEditRequest } from './models/conversation-edit-request';
import { ConversationListRequestParams } from './models/conversation-list-request.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackApiService {
  conversationsApi = '/api/v4/Conversations';
  companies = '/api/v4/Companies';

  constructor(private http: HttpClient, public stateService: StateService, private filterService: FilterService) {}

  getConversation(id: string) {
    return this.http.get<Conversation>(`${this.conversationsApi}/${id}`);
  }

  getConversations(config: ConversationListRequestParams): Observable<Action> {
    const params = QueryParamsService.build(
      {
        isCompleted: config.isCompleted,
        searchText: config.searchText,
        page: config.page,
        take: config.take,
        sortField: config.sort.active,
        sortDirection: config.sort.direction === 'asc' ? 'ASC' : 'DESC',
        type: config.conversationType,
        selectedUserId: config.selectedUserId ?? 'all',
      },
      true
    );
    return this.http.get<PagedResponse<ListViewConversation>>(this.conversationsApi, { params }).pipe(
      map(conversations => actions.conversationListReceived({ conversations })),
      catchError((error: unknown) =>
        of(
          actions.unableToRetrieveConversationList({
            error,
            customMessage: `Could not get ${this.stateService.language.feedback.items}. Please try again.`,
          })
        )
      )
    );
  }

  createConversations(
    managees: ConversationManagee[],
    conversation: Conversation,
    selectedUserId: string,
    createTodos?: boolean
  ): Observable<Action> {
    const body: NewConversation = new NewConversation(conversation, managees, createTodos);
    return this.http.post<Conversation[]>(this.conversationsApi, body).pipe(
      map(newConversations => {
        const newConversationsForUser = newConversations?.filter(
          (c: Conversation) =>
            selectedUserId === c.manageeUserId || selectedUserId === c.managerUserId || selectedUserId === 'all'
        );
        return actions.conversationCreated({ conversations: [...newConversationsForUser], createTodos });
      }),
      catchError((error: unknown) =>
        of(
          actions.unableToCreateConversation({
            error,
            customMessage: `Could not create all ${this.stateService.language.feedback.items}.Please try again.`,
          })
        )
      )
    );
  }

  updateConversationStatus(conversationId: string, isCompleted: boolean): Observable<Action> {
    return this.patchConversation(conversationId, { isCompleted }).pipe(
      map(conversation => actions.conversationStatusUpdated({ conversation })),
      catchError((error: unknown) =>
        of(
          actions.unableToUpdateConversationStatus({
            error,
            customMessage: `Could not update ${this.stateService.language.feedback.item} status. Please try again.`,
          })
        )
      )
    );
  }

  deleteConversation(conversationId: string): Observable<Action> {
    return this.patchConversation(conversationId, { isDeleted: true }).pipe(
      map(conversation => actions.conversationDeleted({ conversation })),
      catchError((error: unknown) =>
        of(
          actions.unableToDeleteConversation({
            error,
            customMessage: `Could not delete ${this.stateService.language.feedback.item}. Please try again.`,
          })
        )
      )
    );
  }

  editConversation(conversationId: string, update: ConversationEditRequest): Observable<Action> {
    return this.patchConversation(conversationId, update).pipe(
      map(conversation => actions.conversationSuccessfullyEdited({ conversation })),
      catchError((error: unknown) =>
        of(
          actions.unableToEditConversation({
            error,
            customMessage: `Could not edit ${this.stateService.language.feedback.item}. Please try again.`,
          })
        )
      )
    );
  }

  updateQuarterlyQuestions(
    quarterlyQuestions: DefaultConversationQuestion[],
    annualQuestions: FormalConversationQuestion[],
    leadershipCommitments: Commitment[],
    coachingCommitments: Commitment[]
  ): Observable<Action> {
    return this.patchConversationSettings(
      quarterlyQuestions,
      annualQuestions,
      leadershipCommitments,
      coachingCommitments
    ).pipe(
      map(quarterlyQuestions => actions.quarterlyQuestionsSuccesfullyUpdated({ quarterlyQuestions })),
      catchError((error: unknown) =>
        of(
          actions.unableToUpdateQuarterlyQuestions({
            error,
            customMessage: 'Could not update quarterly questions. Please try again.',
          })
        )
      )
    );
  }

  // deprecated method
  patchConversation(conversationId: string, update: Partial<Conversation>) {
    return this.http.patch<Conversation>(`${this.conversationsApi}/${conversationId}`, update);
  }

  private patchConversationSettings(
    quarterlyQuestions: FormalConversationQuestion[],
    annualQuestions: FormalConversationQuestion[],
    leadershipCommitments: Commitment[],
    coachingCommitments: Commitment[]
  ) {
    const update = {
      quarterlyQuestions: quarterlyQuestions,
      annualQuestions: annualQuestions,
      leadershipCommitments: leadershipCommitments,
      coachingCommitments: coachingCommitments,
    };
    return this.http.put<FormalConversationQuestion[]>(`${this.companies}/Settings/OneOnOne`, update);
  }
}
