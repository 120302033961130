import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { merge } from 'lodash';
import { concatMap, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { PartialDeep } from 'type-fest';

import { HierarchyTreeComponentActions } from '@ninety/ui/legacy/components/tree/_state/hierarchy-tree.component.actions';
import { SegmentTrackEvent } from '@ninety/ui/legacy/core/analytics/segment/models/segment-track-event.enum';
import { SegmentActions } from '@ninety/ui/legacy/core/analytics/segment/segment.actions';
import { NotifyV2Params } from '@ninety/ui/legacy/core/services/notify.service';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { Accountability } from '@ninety/ui/legacy/shared/models/accountability-chart/accountability';
import { SeatDetailComponentViewType } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-detail.model';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { selectCompanyId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';
import { DetailViewActions } from '@ninety/web/pages/detail-view/_state/detail-view.actions';
import { CreateDialogService } from '@ninety/web/pages/layouts/services/create-dialog.service';

import { SeatDetailsDialogData, SeatDetailsDialogMode } from '../../../components/_legacy/seat-details-dialog/models';
import { SeatDetailsDialogComponent } from '../../../components/_legacy/seat-details-dialog/seat-details-dialog.component';
import { SeatMoveDialogComponent } from '../../../components/seat-move-dialog/seat-move-dialog.component';
import { SeatMoveDialogData } from '../../../components/seat-move-dialog/seat-move.component.model';
import { cloneSeat } from '../../../models/seat.model';
import { SeatApiService } from '../../../services/seat-api.service';
import { ResponsibilitiesNavigationActions } from '../../navigation/responsibility-navigation.actions';
import { OrgChartAnalyticsActions } from '../actions/org-chart-analytics.actions';
import { ErrorProps, SeatActions } from '../actions/seat.actions';
import { ResponsibilityChartSelectors } from '../responsibility-chart.selectors';

export function getDefaultActionParams(partial?: PartialDeep<NotifyV2Params>): NotifyV2Params {
  const _default: NotifyV2Params = { actionText: 'X', config: { duration: 4_000, panelClass: 'plain-text-action' } };
  return merge(_default, partial);
}

export function countRoles(accountabilities: Accountability[]): number {
  return accountabilities?.length ?? 0;
}

export function countRolesWithDescription(accountabilities: Accountability[]): number {
  return accountabilities?.filter(a => a.description?.trim().length).length ?? 0;
}

@Injectable()
export class SeatEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dialog: MatDialog,
    private seatApiService: SeatApiService,
    private createDialogService: CreateDialogService
  ) {}

  // Change Parents Flow

  openMoveDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.openChangeParentDialog),
      concatLatestFrom(({ seat, childIds }) => [
        this.store.select(ResponsibilityChartSelectors.moveSeatParams(seat, childIds)),
      ]),
      switchMap(([{ seat }, moveSeatParams]) => {
        const data: SeatMoveDialogData = {
          nameOfSeatToMove: seat.name,
          seats: moveSeatParams,
        };

        return this.dialog
          .open(SeatMoveDialogComponent, { data })
          .afterClosed()
          .pipe(
            map((newParentSeatId: string) =>
              newParentSeatId
                ? SeatActions.changeParent({ idToMove: seat._id, newParentId: newParentSeatId })
                : SeatActions.cancelChangeParentDialog()
            )
          );
      })
    )
  );

  /**
   * Persists the changed parent to the API. Its important this happens before any ordinal update or tree render to
   * ensure we properly notify the user of API failures. See DEV-8810.
   */
  persistChangedParent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.changeParent),
      concatMap(action =>
        this.seatApiService
          .updateSeatModel({ id: action.idToMove as string, changes: { parentSeatId: action.newParentId } })
          .pipe(
            map(() => SeatActions.changeParentSuccess(action)),
            catchError((error: HttpErrorResponse) =>
              this.handleErrorWithCustomLang(error, (_, lang) => {
                let message =
                  error.status === 400 || error.status === 404
                    ? 'Chart out of date.'
                    : `Failed to change parent ${lang}.`;

                message += ` Please refresh the page and try again.`;
                return SeatActions.changeParentFailure({ error, message });
              })
            )
          )
      )
    )
  );

  /**
   * Implement hook to update feature state after the tree component updates ordinals after the change parent flow. This
   * effect causes state to be updated, but does not persist any changes.
   */
  updateStateAfterChangeParent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyTreeComponentActions.updateAfterChangeParent),
      map(({ newParentUpdate, otherOrdinalUpdates }) => {
        const updatedParentSeat: Update<SeatModel> = {
          id: newParentUpdate.id as string,
          changes: { ordinal: newParentUpdate.changes.ordinal, parentSeatId: newParentUpdate.changes.parentId },
        };
        return SeatActions.updateMany({ updates: [...otherOrdinalUpdates, updatedParentSeat] });
      })
    )
  );

  /** Forward tree hook actions to the feature-specific actions that persist changes to the API. */
  forwardOrdinalUpdatesToApiActionsAfterChangeParent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyTreeComponentActions.updateAfterChangeParent),
      map(({ otherOrdinalUpdates, newParentUpdate }) => {
        const newParentUpdateWithOrdinal: Update<Pick<SeatModel, 'ordinal'>> = {
          id: newParentUpdate.id as string,
          changes: { ordinal: newParentUpdate.changes.ordinal },
        };

        const updates = [...otherOrdinalUpdates, newParentUpdateWithOrdinal];
        return SeatActions.patchOrdinalUpdates({ updates });
      })
    )
  );

  forwardChangeParentToAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.changeParent),
      concatLatestFrom(() => this.store.select(ResponsibilityChartSelectors.currentChartId)),
      map(([{ idToMove }, chartId]) =>
        OrgChartAnalyticsActions.trackSeatReportsToChange({
          params: {
            chartId,
            seatId: idToMove,
            source: 'legacy',
          },
        })
      )
    )
  );

  // Swap Siblings Flow

  /**
   * Implement hook to update feature state after the tree component updates ordinals after the swap siblings flow. This
   * effect causes state to be updated, but does not persist any changes.
   */
  updateOrdinalsInStateAfterSwapSiblings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyTreeComponentActions.updateOrdinalsAfterSwapSiblings),
      map(({ updates }) => SeatActions.updateMany({ updates }))
    )
  );

  /** Forward tree hook actions to the feature-specific actions that persist changes to the API. */
  forwardOrdinalUpdatesToApiActionsAfterSwapSiblings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyTreeComponentActions.updateOrdinalsAfterSwapSiblings),
      map(({ updates }) =>
        SeatActions.patchOrdinalUpdates({
          updates,
        })
      )
    )
  );

  // Create Seat Flow

  /** @deprecated - v2 seat form logic only, replaced by {@link SeatDetailComponentStore} */
  openCreateDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.openCreateDialog),
      concatLatestFrom(({ parentSeatId }) => [
        this.store.select(ResponsibilityChartSelectors.childrenOfSeatCount(parentSeatId)),
        this.store.select(ResponsibilityChartSelectors.currentChartId),
        this.store.select(selectCompanyId),
        this.store.select(ResponsibilityChartSelectors.canEditCurrentChart),
        this.store.select(ResponsibilityChartSelectors.useV3SeatDetails),
      ]),
      switchMap(([{ parentSeatId }, ordinal, chartId, companyId, canEditChart, seatsDetailV3]) => {
        const data: SeatDetailsDialogData = {
          accountabilities: [],
          attachments: [],
          canEditChart,
          chartId,
          companyId,
          mode: SeatDetailsDialogMode.Create,
          ordinal,
          parentSeatId,
          seatId: null,
          seatName: '',
        };

        if (seatsDetailV3) {
          return of(
            ResponsibilitiesNavigationActions.createSeatInDetails({
              params: { type: SeatDetailComponentViewType.create, seatId: parentSeatId },
            })
          );
        }
        return this.openSeatDetailsDialog(data).pipe(
          map((newSeat: SeatModel) =>
            newSeat ? SeatActions.create({ seat: newSeat }) : SeatActions.cancelCreateDialog()
          )
        );
      })
    )
  );

  /** Updates global state with the new node after a seat is created */
  updateStateAfterCreateSeat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.createSuccess),
      map(({ seat }) => SeatActions.insertOne({ seat }))
    )
  );

  navigateAfterCreateSeat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.createSuccess),
      map(({ seat }) =>
        ResponsibilitiesNavigationActions.viewSeatInDetails({
          params: { seatId: seat._id, type: SeatDetailComponentViewType.view },
        })
      )
    )
  );

  renderToastAfterSuccessfulCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.createSuccess),
      concatLatestFrom(() => this.store.select(ResponsibilityChartSelectors.seatSingularLanguage)),
      map(([_, lang]) =>
        NotificationActions.notifyV2({
          message: lang + ' created successfully',
          params: getDefaultActionParams(),
        })
      )
    )
  );

  dispatchTrackingEventAfterCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.createSuccess),
      map(({ seat }) =>
        OrgChartAnalyticsActions.trackCreateSeat({
          params: {
            chartId: seat.chartId,
            seatId: seat._id,
            rolesCount: countRoles(seat.accountabilities),
            rolesWithDescriptionCount: countRolesWithDescription(seat.accountabilities),
            from: 'create',
          },
        })
      )
    )
  );

  // Clone Seat Flow

  cloneSeat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.clone),
      concatLatestFrom(({ id }) => [this.store.select(ResponsibilityChartSelectors.seatById(id))]),
      concatLatestFrom(([_, seat]) => [
        this.store.select(ResponsibilityChartSelectors.childrenOfSeatCount(seat.parentSeatId)),
      ]),
      switchMap(([[_, seat], newOrdinal]) => {
        const clone = cloneSeat(seat, newOrdinal);
        return this.seatApiService.createSeatModel(clone);
      }),
      map((seat: SeatModel) => SeatActions.cloneSuccess({ seat })),
      catchError((error: unknown) =>
        this.handleErrorWithCustomLang(error, (error, lang) =>
          SeatActions.cloneFailure({
            error,
            message: `Failed to clone ${lang}. Please try again`,
          })
        )
      )
    )
  );

  forwardCloneToAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.cloneSuccess),
      concatLatestFrom(() => this.store.select(ResponsibilityChartSelectors.currentChartId)),
      map(([{ seat }, chartId]) =>
        OrgChartAnalyticsActions.trackCreateSeat({
          params: {
            chartId,
            seatId: seat._id,
            rolesCount: countRoles(seat.accountabilities),
            rolesWithDescriptionCount: countRolesWithDescription(seat.accountabilities),
            from: 'clone',
          },
        })
      )
    )
  );

  insertSeatAfterPersist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.cloneSuccess),
      map(({ seat }) => SeatActions.insertOne({ seat }))
    )
  );

  // Details Flow (edit/view)

  /** @deprecated replaced by {@link SeatDetailComponentStore} */
  legacyOpenDetailsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsLaunchFromNode),
      concatLatestFrom(({ id }) => [
        this.store.select(ResponsibilityChartSelectors.seatById(id)),
        this.store.select(ResponsibilityChartSelectors.currentChartId),
        this.store.select(selectCompanyId),
        this.store.select(ResponsibilityChartSelectors.editingAllowed),
        this.store.select(ResponsibilityChartSelectors.useV3SeatDetails),
      ]),
      filter(([, , , , , seatsDetailV3]) => !seatsDetailV3),
      switchMap(([_, seat, chartId, companyId, canEditChart]) => {
        const data: SeatDetailsDialogData = {
          accountabilities: seat.accountabilities,
          attachments: seat.attachments,
          canEditChart,
          chartId,
          companyId,
          mode: canEditChart ? SeatDetailsDialogMode.Edit : SeatDetailsDialogMode.View,
          ordinal: seat.ordinal, // TODO has no use in the dialog when not creating a seat
          parentSeatId: seat.parentSeatId,
          seatId: seat._id,
          seatName: seat.name,
        };

        // Legacy pattern
        return this.openSeatDetailsDialog(data).pipe(
          map((changes: Pick<SeatModel, '_id' | 'name' | 'accountabilities' | 'attachments'>) =>
            changes
              ? SeatActions.detailDialogClosedWithUpdates({ seat: { ...seat, ...changes } })
              : SeatActions.detailDialogClosed()
          )
        );
      })
    )
  );

  openDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsLaunchFromNode),
      concatLatestFrom(() => [this.store.select(ResponsibilityChartSelectors.useV3SeatDetails)]),
      filter(([_, seatsDetailV3]) => seatsDetailV3),
      map(([{ id }]) =>
        ResponsibilitiesNavigationActions.viewSeatInDetails({
          params: { seatId: id, type: SeatDetailComponentViewType.view },
        })
      )
    )
  );

  /** @deprecated legacy effect flow, deprecated by v3 seat details */
  legacyUpdateStateAfterDetailsDialogClosedWithUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailDialogClosedWithUpdates),
      map(({ seat }) => SeatActions.updateOne({ update: { id: seat._id, changes: seat } }))
    )
  );

  updateStateAfterDetailsDialogClosedWithUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsUpdatedSuccessfully, SeatActions.detailsClosed),
      filter(({ update }) => Boolean(update)),
      map(({ update }) => SeatActions.updateOne({ update }))
    )
  );

  notifyIfClosedButStillUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsClosed),
      filter(({ update }) => Boolean(update)),
      concatLatestFrom(() => this.store.select(ResponsibilityChartSelectors.seatSingularLanguage)),
      map(([_, lang]) =>
        NotificationActions.notifyV2({
          message: lang + ' had changed attachments after close. Changes persisted.',
          params: getDefaultActionParams(),
        })
      )
    )
  );

  closeDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsClosed),
      map(() => DetailViewActions.close())
    )
  );

  renderToastAfterSuccessfulUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsUpdatedSuccessfully),
      concatLatestFrom(() => this.store.select(ResponsibilityChartSelectors.seatSingularLanguage)),
      map(([_, lang]) =>
        NotificationActions.notifyV2({
          message: lang + ' updated successfully',
          params: getDefaultActionParams(),
        })
      )
    )
  );

  renderChangedParentAfterSuccessfulUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsUpdatedSuccessfully),
      filter(({ update }) => Boolean(update.changes.parentSeatId)),
      map(({ update }) =>
        SeatActions.changeParentSuccess({ idToMove: update.id, newParentId: update.changes.parentSeatId })
      )
    )
  );

  notifyOnFailuresToLoadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsFailedToFetchSeat),
      concatLatestFrom(() => this.store.select(ResponsibilityChartSelectors.seatSingularLanguage)),
      map(([{ error }, lang]) =>
        NotificationActions.notifyError({
          message: 'Failed to load ' + lang + '. Please check your URL and try again.',
          error,
        })
      )
    )
  );

  closeDetailsOnFailureToLoadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsFailedToFetchSeat),
      map(() => SeatActions.detailsClosed({}))
    )
  );

  forwardUpdateToAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.detailsUpdatedSuccessfully),
      concatLatestFrom(() => this.store.select(ResponsibilityChartSelectors.currentChartId)),
      map(([{ update, patch }, chartId]) => {
        const changedProperties: (keyof SeatModel)[] = [];
        if (patch.seat?.value?.seatName) changedProperties.push('name');
        if (patch.seat?.value?.parentSeatId) changedProperties.push('parentSeatId');
        if (patch.accountabilities ? Object.keys(patch.accountabilities).length : false) {
          changedProperties.push('accountabilities');
        }
        if (patch.seatHolders ? Object.keys(patch.seatHolders).length : false) {
          changedProperties.push('seatHolders');
        }

        const rolesCount = countRoles(update.changes.accountabilities);
        const rolesWithDescriptionCount = countRolesWithDescription(update.changes.accountabilities);

        return OrgChartAnalyticsActions.trackUpdateSeat({
          params: {
            chartId: chartId,
            seatId: update.id,
            changedProperties,
            currentState: { rolesCount, rolesWithDescriptionCount },
          },
        });
      })
    )
  );

  forwardManagerChangeToAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgChartAnalyticsActions.trackUpdateSeat),
      filter(({ params }) => params.changedProperties.includes('parentSeatId')),
      map(({ params }) =>
        OrgChartAnalyticsActions.trackSeatReportsToChange({
          params: {
            chartId: params.chartId,
            seatId: params.seatId,
            source: 'detail',
          },
        })
      )
    )
  );

  // Delete Seat

  openDeleteDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.openDeleteDialog),
      concatLatestFrom(({ seatId }) => [
        this.store.select(ResponsibilityChartSelectors.deleteSeatConfirmationDialogParams(seatId)),
      ]),
      switchMap(([{ seatId }, dialogParams]) =>
        this.dialog
          .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
            data: dialogParams,
          })
          .afterClosed()
          .pipe(
            map((confirmed: object) => {
              if (!confirmed) {
                return SeatActions.cancelDeleteDialog();
              }

              return SeatActions.delete({ seatId });
            })
          )
      )
    )
  );

  removeDescendantsFromStateAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyTreeComponentActions.updateAfterDelete),
      map(action => SeatActions.removeMany({ ids: action.updates.idsToRemove }))
    )
  );

  updateOrdinalsInStateAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyTreeComponentActions.updateAfterDelete),
      filter(({ updates }) => updates.ordinals.length > 0),
      map(action => SeatActions.updateMany({ updates: action.updates.ordinals }))
    )
  );

  patchOrdinalUpdatesAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyTreeComponentActions.updateAfterDelete),
      filter(({ updates }) => updates.ordinals.length > 0),
      map(action => SeatActions.patchOrdinalUpdates({ updates: action.updates.ordinals }))
    )
  );

  // Transform seat to issue/_todo

  transformSeat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SeatActions.transformSeat),
        concatLatestFrom(({ seatId }) => [this.store.select(ResponsibilityChartSelectors.seatById(seatId))]),
        concatMap(([{ itemType }, seat]) => {
          const data: Partial<Item> = {
            title: seat.name,
            description: seat.accountabilities?.length
              ? `<ul><li>${seat.accountabilities.map(a => a.name).join('</li><li>')}</li></ul>`
              : '',
            itemType,
            attachments: seat.attachments,
          };

          if (seat.seatHolders.length === 1) data.userId = seat.seatHolders[0].userId;

          return this.createDialogService.open({
            item: data,
            itemType,
          });
        })
      ),
    { dispatch: false }
  );

  // Analytics
  trackOrgChartEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrgChartAnalyticsActions.trackCreateSeat,
        OrgChartAnalyticsActions.trackUpdateSeat,
        OrgChartAnalyticsActions.trackSeatReportsToChange,
        OrgChartAnalyticsActions.trackSeatAttachmentEvent,
        OrgChartAnalyticsActions.trackLaunchSeatDetails
      ),
      map(action => {
        const ActionToTrackingEvent = {
          [OrgChartAnalyticsActions.trackCreateSeat.type]: SegmentTrackEvent.ORG_CREATE_SEAT,
          [OrgChartAnalyticsActions.trackUpdateSeat.type]: SegmentTrackEvent.ORG_UPDATE_SEAT,
          [OrgChartAnalyticsActions.trackSeatReportsToChange.type]: SegmentTrackEvent.ORG_MANAGER_CHANGED,
          [OrgChartAnalyticsActions.trackSeatAttachmentEvent.type]: SegmentTrackEvent.ORG_ATTACHMENT_EVENT,
          [OrgChartAnalyticsActions.trackLaunchSeatDetails.type]: SegmentTrackEvent.ORG_LAUNCH_SEAT_DETAILS,
        };

        return SegmentActions.track({ event: ActionToTrackingEvent[action.type], params: action.params });
      })
    )
  );

  // API Updates

  deleteSeat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.delete),
      concatMap(({ seatId }) =>
        this.seatApiService.delete(seatId).pipe(
          map(() => SeatActions.deleteSuccess({ seatId })),
          catchError((error: HttpErrorResponse) =>
            error.status === 404
              ? this.handleErrorWithCustomLang(error, (error, lang) =>
                  SeatActions.deleteFailure({
                    error,
                    message: `${lang} is already deleted. Please refresh the page and try again.`,
                  })
                )
              : this.handleErrorWithCustomLang(error, (error, lang) =>
                  SeatActions.deleteFailure({
                    error,
                    message: `Failed to delete ${lang}. Please try again`,
                  })
                )
          )
        )
      )
    )
  );

  /** Persist ordinal updates to the API. */
  persistOrdinalChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.patchOrdinalUpdates),
      filter(({ updates }) => updates.length > 0),
      concatMap(({ updates }) =>
        this.seatApiService.updateSeatOrdinals(updates).pipe(
          map(() => SeatActions.patchOrdinalUpdatesSuccess()),
          catchError((error: unknown) =>
            this.handleErrorWithCustomLang(error, (error, lang) =>
              SeatActions.patchOrdinalUpdatesFailure({
                error,
                message: `Failed to update ${lang} ordinals. Please try again`,
              })
            )
          )
        )
      )
    )
  );

  /**
   * Persist updates to the API. Ordinal updates should not run through this flow.
   *
   * TODO when we work the API ticket to reject updates of ordinals that, after application, produce bad data, we
   *  probably will want to find a way to sequence the parent update and the other ordinal updates
   */
  persistUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.patchUpdates),
      concatMap(({ updates }) =>
        this.seatApiService.updateSeatModel(updates).pipe(
          map(() => SeatActions.patchUpdatesSuccess()),
          catchError((error: unknown) =>
            this.handleErrorWithCustomLang(error, (error, lang) =>
              SeatActions.patchUpdatesFailure({
                error,
                message: `Failed to update ${lang}. Please try again`,
              })
            )
          )
        )
      )
    )
  );

  // Toggle Showing Visionary Seat
  hideVisionary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.hideVisionary),
      concatLatestFrom(() => [
        this.store.select(ResponsibilityChartSelectors.visionarySeat),
        this.store.select(ResponsibilityChartSelectors.integratorSeat),
      ]),
      switchMap(([_, visionary, integrator]) =>
        this.seatApiService.toggleVisionary().pipe(
          map(resp =>
            SeatActions.hideVisionarySuccess({
              apiResponse: resp,
              integratorSeatId: integrator._id,
              visionarySeatId: visionary._id,
            })
          ),
          catchError((error: unknown) =>
            this.handleErrorWithCustomLang(error, (error, lang) =>
              SeatActions.hideVisionaryFailure({ error, message: `Failed to hide ${lang}. Please try again` })
            )
          )
        )
      )
    )
  );

  showVisionary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeatActions.showVisionary),
      concatLatestFrom(() => [this.store.select(ResponsibilityChartSelectors.integratorSeat)]),
      switchMap(([_, integrator]) =>
        this.seatApiService.toggleVisionary().pipe(
          map(resp => SeatActions.showVisionarySuccess({ apiResponse: resp, integratorSeatId: integrator._id })),
          catchError((error: unknown) =>
            this.handleErrorWithCustomLang(error, (error, lang) =>
              SeatActions.showVisionaryFailure({ error, message: `Failed to show ${lang}. Please try again` })
            )
          )
        )
      )
    )
  );

  // Helpers/Utilities

  startSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SeatActions.patchOrdinalUpdates,
        SeatActions.patchUpdates,
        SeatActions.clone,
        SeatActions.delete,
        SeatActions.changeParent,
        // Visionary Toggle
        SeatActions.showVisionary,
        SeatActions.hideVisionary
      ),
      map(() => SpinnerActions.startPrimary({}))
    )
  );

  stopSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SeatActions.patchOrdinalUpdatesSuccess,
        SeatActions.patchOrdinalUpdatesFailure,
        SeatActions.patchUpdatesSuccess,
        SeatActions.patchUpdatesFailure,
        SeatActions.cloneSuccess,
        SeatActions.cloneFailure,
        SeatActions.deleteSuccess,
        SeatActions.deleteFailure,
        SeatActions.changeParentSuccess,
        SeatActions.changeParentFailure,
        // Visionary Toggle
        SeatActions.showVisionarySuccess,
        SeatActions.showVisionaryFailure,
        SeatActions.hideVisionarySuccess,
        SeatActions.hideVisionaryFailure,
        // Seat Details v3
        SeatActions.detailsUpdatedSuccessfully,
        SeatActions.createSuccess
      ),
      map(action => SpinnerActions.stopPrimary({ source: action.type }))
    )
  );

  handleErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SeatActions.patchOrdinalUpdatesFailure,
        SeatActions.patchUpdatesFailure,
        SeatActions.cloneFailure,
        SeatActions.deleteFailure,
        SeatActions.changeParentFailure
      ),
      map(({ error, message }: ErrorProps) => NotificationActions.notifyError({ error, message }))
    )
  );

  /**
   * Response types:
   * Create Seat - SeatModel
   * Edit Seat - Pick<Seat>
   * canceled - null
   * @deprecated - v2 seat form logic only, replaced by {@link SeatDetailComponentStore} TODO DEV-10862
   */
  private openSeatDetailsDialog(
    data: SeatDetailsDialogData
  ): Observable<SeatModel | Pick<SeatModel, '_id' | 'name' | 'accountabilities' | 'attachments'> | null> {
    return this.dialog
      .open(SeatDetailsDialogComponent, {
        data,
        panelClass: 'seat-details-dialog-container',
        disableClose: true,
      })
      .afterClosed();
  }

  private handleErrorWithCustomLang(
    error: unknown,
    actionSupplier: (error: unknown, seatHolderLang: string) => Action
  ): Observable<Action> {
    return this.store.select(ResponsibilityChartSelectors.seatSingularLanguage).pipe(
      take(1),
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map(language => actionSupplier(error, language))
    );
  }
}
