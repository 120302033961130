import { DefaultUrlSerializer } from '@angular/router';
import { createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { TodoSortFieldEnum } from '@ninety/ui/legacy/shared/models/todos/todo-sort-field';
import { selectCompanySettingsAgreementBasedTodos } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectUrl } from '@ninety/ui/legacy/state/route.selectors';
import { GridLayoutSelectors } from '@ninety/web/pages/layouts/grid-layout/_state/grid-layout-state.selectors';
import { WidgetTemplateType } from '@ninety/web/pages/my-ninety/_models/widget-template-type';

import { selectTeamTodoState } from '../../_state';
import { todosStateAdapter } from '../_shared/todo-state.shared.model';

export namespace TeamTodoSelectors {
  export const selectState = createSelector(selectTeamTodoState, state => state);
  export const { selectAll: selectAllTodos } = todosStateAdapter.getSelectors();
  export const selectTodos = createSelector(
    selectTeamTodoState,
    state => state?.todos ?? todosStateAdapter.getInitialState()
  );
  export const selectTeamTodos = createSelector(selectTodos, selectAllTodos);
  export const selectTeamTodoCount = createSelector(selectTeamTodoState, state => state.todoCount);
  export const selectTeamTodosLoading = createSelector(selectTeamTodoState, state => state.loading);
  export const selectShowEmptyState = createSelector(
    selectTeamTodoState,
    selectTeamTodos,
    (state, todos) => !state.loading && !todos?.length && !state.userPendingAgreements
  );
  export const selectShowCard = createSelector(
    selectTeamTodoState,
    selectTeamTodos,
    //when there are no pending ab todos for the user show an in table empty state
    //this might change when the new top bar is implemented
    (state, todos) => todos?.length || state.userPendingAgreements
  );

  export const selectShowUserPendingAgreementsTodosEmptyState = createSelector(
    selectTeamTodoState,
    selectTeamTodos,
    (state, todos) => !state.loading && !todos?.length && state.userPendingAgreements
  );

  export const selectBroadcastTeamEvents = createSelector(selectTeamTodoState, state => state.shouldBroadcast);
  export const selectTeamPageIndex = createSelector(selectTeamTodoState, state => state.pageIndex);
  export const selectTeamPageSize = createSelector(selectTeamTodoState, state => state.pageSize);
  export const selectTeamPagination = createSelector(
    selectTeamPageIndex,
    selectTeamPageSize,
    (pageIndex, pageSize) => ({
      pageIndex,
      pageSize,
    })
  );
  export const selectTeamSortField = createSelector(selectTeamTodoState, state => state.sortField);
  export const selectTeamSortDirection = createSelector(selectTeamTodoState, state => state.sortDirection);
  export const selectTeamSort = createSelector(
    selectTeamSortField,
    selectTeamSortDirection,
    (sortField, sortDirection) => ({
      sortField,
      sortDirection,
    })
  );
  export const selectUserPendingAgreementsFilter = createSelector(
    selectTeamTodoState,
    state => state.userPendingAgreements
  );
  export const selectTeamError = createSelector(selectTeamTodoState, state => state.error);
  export const selectOrdinalKey = createSelector(selectTeamTodoState, state => state.ordinalKey);
  export const selectSelectedTodoId = createSelector(selectTeamTodoState, state => state.selectedTodoId);
  export const selectSelectedTodo = createSelector(selectTeamTodos, selectSelectedTodoId, (todos, selectedTodoId) =>
    todos.find(t => t._id === selectedTodoId)
  );
  export const selectCompletionTooltip = createSelector(selectTeamTodoState, state => state.completionTooltip);
  // ********** Team Selectors Only ********** //

  export const selectShowIntegration = createSelector(selectTeamTodoState, state => state.showIntegrations);
  export const selectShowTeamArchived = createSelector(selectTeamTodoState, state => state.showArchived);
  export const selectTodoResponse = createSelector(selectTeamTodos, selectTeamTodoCount, (todos, count) => ({
    todos,
    count,
  }));
  export const selectTeamId = createSelector(selectTeamTodoState, state => state.teamId);
  export const selectListControlsDisabled = createSelector(selectTeamTodoState, state => state.listControlsDisabled);
  export const selectFocusOnInlineAddTodo = createSelector(selectTeamTodoState, state => state.focusOnInlineAddTodo);
  export const selectHasCompletedTodos = createSelector(selectTeamTodos, todos => todos.some(t => t.completed));
  export const selectCanArchiveCompleted = createSelector(
    selectTeamTodoCount,
    selectHasCompletedTodos,
    (count, hasAtleastOneCompleted) => count > 0 && hasAtleastOneCompleted
  );

  export const selectUserIdFilter = createSelector(selectTeamTodoState, state => state.userIdFilter);

  export const selectTeamTodoWidgetTemplateType = createSelector(
    selectTeamTodosLoading,
    selectTeamError,
    selectTeamTodoCount,
    (loading, error, count) => {
      if (loading) return WidgetTemplateType.loading;
      if (error) return WidgetTemplateType.error;
      if (count === 0) return WidgetTemplateType.empty;
      return WidgetTemplateType.data;
    }
  );

  /** TODO: Remove once migrated to the Todo Card V2 - used by detail-item.resolver to load detail card via resolver  */
  export const selectDetailView = createSelector(selectTeamTodoState, selectTeamTodos, (state, todos) => ({
    type: DetailType.todo,
    input: {
      item: cloneDeep(todos.find(t => t._id === state.selectedTodoId)),
      itemType: ItemType.todo,
      itemUrl: `${location.origin}/todos/${state.selectedTodoId}`,
    },
  }));

  // Separate selector for easier future refactoring
  export const selectShouldDisableDragAndDropOnly = GridLayoutSelectors.selectIsOneColumnMode;

  export const selectTodoIdFromRoute = createSelector(selectUrl, url => {
    const parsedUrl = new DefaultUrlSerializer().parse(url);
    if (parsedUrl.root.children.detail?.segments[1].path === DetailType.todo)
      return parsedUrl.root.children.detail.segments[2].path;
    return null;
  });

  /** As this is feature flagged and a company setting, get a combination of both to see if it's enabled  */
  export const selectIsAgreementsBasedTodosCompany = createSelector(
    selectCompanySettingsAgreementBasedTodos,
    agreementBasedCompany => agreementBasedCompany
  );

  export const selectTodoQueryParams = createSelector(selectTeamTodoState, state => ({
    pageIndex: state.pageIndex,
    pageSize: state.pageSize,
    archived: state.showArchived,
    userId: state.userIdFilter,
    teamId: !state.teamId || state.teamId === 'all' ? null : state.teamId,
    sortField: TodoSortFieldEnum[state.sortField],
    sortDirection: state.sortField ? state.sortDirection : null,
    searchText: state.searchText,
    userPendingAgreements: state.userPendingAgreements,
  }));
}
