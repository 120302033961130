import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OneSchemaService } from '@oneschema/angular';
import { Observable } from 'rxjs';

import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { Issue } from '@ninety/ui/legacy/shared/models/issues/issue';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { CreateTodoResponse } from '@ninety/web/pages/todos/services/models/create-todo-response';

import { DataImportActions } from '../_state/data-import.actions';
import { DataImportToken } from '../models/data-import-token';
import { OneSchemaData } from '../models/one-schma-data';

@Injectable({
  providedIn: 'root',
})
export class DataImportService {
  private readonly dataImportApi = '/api/v4/DataImport';

  /** these are going to change soon to keep data uploads compartmentalized since it's going to be client facing */
  private todosApi = '/api/v4/Todos';
  private issuesApi = '/api/v4/Issues';
  private rocksApi = '/api/v4/Rocks';

  constructor(private http: HttpClient, private oneSchema: OneSchemaService, private store: Store) {}

  getOneSchemaToken(): Observable<DataImportToken> {
    return this.http.get<DataImportToken>(`${this.dataImportApi}/GetAuthToken`);
  }

  /** make sure to set iframe before launching oneschema */
  setIframe(element: HTMLIFrameElement): void {
    this.oneSchema.setIframe(element);
    this.oneSchema.on('success', this.handleDataFromOneSchema);
    this.oneSchema.on('error', this.onError);
    this.oneSchema.on('cancel', this.onCancel);
    /** figure out how to listen for file uploaded */
  }

  launchOneSchema(templateKey: string, userJwt: string): void {
    this.oneSchema.close();
    this.oneSchema.launch({
      templateKey,
      userJwt,
      customizationOverrides: { modalFullscreen: true },
    });
  }

  handleDataFromOneSchema = (data: OneSchemaData) => {
    this.store.dispatch(DataImportActions.handleDataFromOneSchema({ data }));
  };

  onError = (message: string) => {
    this.store.dispatch(NotificationActions.notifyError({ error: null, message }));
  };

  onCancel = () => {
    this.oneSchema.close();
  };

  destroy() {
    this.oneSchema.ngOnDestroy();
    this.oneSchema.close(true);
  }

  createIssues(issues: Issue[]): Observable<Issue[]> {
    return this.http.post<Issue[]>(`${this.issuesApi}/Import`, { issues });
  }

  createRocks(rocks: Rock[]): Observable<Rock[]> {
    return this.http.post<Rock[]>(`${this.rocksApi}/Import`, { rocks });
  }

  createTodos(todos: Todo[]): Observable<CreateTodoResponse> {
    return this.http.post<CreateTodoResponse>(`${this.todosApi}/Import`, { todos });
  }

  createUsers(users: InviteUserPayload[]): Observable<InviteUserPayload[]> {
    return this.http.post<InviteUserPayload[]>('/api/v4/ImportUsers', { usersImport: users });
  }
}
