import { createReducer, on } from '@ngrx/store';

import { FilterServiceActions } from '@ninety/ui/legacy/core/services/_state/filter-service/filter.service.actions';

import { FeedbackApiActions } from '../../_api/feedback-api.actions';
import { CreateConversationDialogActions } from '../../_shared/create-conversation-dialog/_state/create-conversation-dialog.actions';
import {
  ConversationListEffectActions,
  ConversationListFacadeActions,
  ConversationListFilterEffectActions,
} from '../conversation-list/conversation-list.actions';
import {
  FitCheckComponentActions,
  FitCheckEffectActions,
  FitCheckFilterEffectActions,
} from '../fit-check-list/fit-check.actions';

import { FeedbackPageState, initialFeedbackRootState } from './feedback-page.model';

export const feedbackPageReducer = createReducer<FeedbackPageState>(
  initialFeedbackRootState,

  on(
    ConversationListEffectActions.setConversationType,
    FitCheckEffectActions.setCurrentUser,
    (state, { language }) => ({ ...state, language })
  ),

  on(
    ConversationListFacadeActions.conversationListRequested,
    ConversationListEffectActions.conversationListRequested,
    ConversationListFilterEffectActions.conversationListRequested,
    FilterServiceActions.showCompletedConversationsToggled,
    ConversationListFacadeActions.conversationListPagerChanged,
    CreateConversationDialogActions.createConversationDialogSaved,
    FitCheckEffectActions.usersAndCoreValuesRequested,
    FitCheckFilterEffectActions.usersAndCoreValuesRequested,
    FitCheckComponentActions.pagerChanged,
    state => ({ ...state, isWaiting: true })
  ),

  on(
    FeedbackApiActions.conversationListReceived,
    FeedbackApiActions.unableToRetrieveConversationList,
    FeedbackApiActions.conversationCreated,
    FeedbackApiActions.unableToCreateConversation,
    FitCheckEffectActions.usersAndCoreValuesReceived,
    FitCheckEffectActions.unableToRetreiveUsersAndCoreValues,
    state => ({ ...state, isWaiting: false })
  )
);
