import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { LoggingActions } from '@ninety/ui/legacy/state/app-logging/app-logging.actions';
import { environment } from '@ninety/ui/web/environments';

declare let _hsq: any;

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  private isLoaded$ = new BehaviorSubject<boolean>(false);
  private renderer = this.rendererFactory.createRenderer(this.document.head, null);

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private store: Store
  ) {
    this.loadScript();
  }

  loaded(): Observable<boolean> {
    return this.isLoaded$.asObservable();
  }

  private loadScript(): void {
    if (window.hasOwnProperty('_hsq')) {
      this.isLoaded$.next(true);
      return;
    }

    const script: HTMLScriptElement = this.renderer.createElement('script');

    script.onload = () => this.isLoaded$.next(true);

    script.src = `https://js.hs-scripts.com/${environment.HUBSPOT_API_KEY}.js`;
    this.renderer.appendChild(this.document.head, script);
  }

  identifyContact(email: string): void {
    try {
      _hsq.push(['identify', { email }]);
      this.trackPageView();
    } catch (e) {
      this.store.dispatch(
        LoggingActions.error({
          log: {
            message: '[Hubspot] Error identifying contact',
            error: e,
            data: {
              hubspot_hsq: _hsq ?? 'not loaded',
            },
          },
        })
      );
    }
  }

  setPath(path: string): void {
    try {
      _hsq.push(['setPath', { path }]);
      this.trackPageView();
    } catch (e) {
      this.store.dispatch(
        LoggingActions.error({
          log: {
            message: '[Hubspot] Error setting path',
            error: e,
            data: {
              hubspot_hsq: _hsq ?? 'not loaded',
            },
          },
        })
      );
    }
  }

  trackPageView(): void {
    try {
      _hsq.push(['trackPageView']);
    } catch (e) {
      this.store.dispatch(
        LoggingActions.error({
          log: {
            message: '[Hubspot] Error tracking page view',
            error: e,
            data: {
              hubspot_hsq: _hsq ?? 'not loaded',
            },
          },
        })
      );
    }
  }
}
