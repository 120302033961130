import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { TimePeriod } from '../../../_models';

@Pipe({
  name: 'customAmPmFormatTimePipe',
  standalone: true,
})
/**
  format is h:mm a which is hours:minutes am/pm space after minutes
  updating date pipe to transform am and pm to a.m. p.m.
 */
export class CustomAmPmFormatTimePipe implements PipeTransform {
  private datePipe = new DatePipe('en-US');
  transform(
    value: string | number | Date | null,
    format = 'h:mm a',
    timezone?: string,
    locale?: string
  ): string | null {
    const date = this.datePipe.transform(value, format, timezone, locale);
    if (date) {
      return date.replace('AM', TimePeriod.AM).replace('PM', TimePeriod.PM);
    }
    return date;
  }
}
