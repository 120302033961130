import { Injectable, OnDestroy } from '@angular/core';
import { Span } from '@opentelemetry/api';

import { LoggingService } from './logging.service';
import { SpanService } from './span.service';

@Injectable({
  providedIn: 'root',
})
export class TelemetrySessionService implements OnDestroy {
  private sessionSpan: Span | null = null;

  constructor(private spanService: SpanService, private loggingService: LoggingService) {
    window.addEventListener('beforeunload', () => {
      this.endSession();
    });
  }

  startSession(userId: string): void {
    const startAttributes = {
      'session.start': new Date().toISOString(),
      'user.id': userId,
      'session.id': crypto.randomUUID(),
    };

    console.log('[OpenTelemetry] Starting session with attributes:', startAttributes);
    this.sessionSpan = this.spanService.startSpan('UserSession', startAttributes);
    this.loggingService.logEvent(this.sessionSpan, 'Session started', startAttributes);
  }

  endSession(): void {
    if (this.sessionSpan) {
      const endAttributes = {
        'session.end': new Date().toISOString(),
      };
      console.log('[OpenTelemetry] Ending session with attributes:', endAttributes);
      this.loggingService.logEvent(this.sessionSpan, 'Session ended', endAttributes);
      this.spanService.closeSpan(this.sessionSpan);
      this.sessionSpan = null;
    } else {
      console.log('[OpenTelemetry] Attempted to end session but no active session exists');
    }
  }

  getSessionSpan(): Span | null {
    if (!this.sessionSpan) {
      console.warn('OpenTelemetry: Attempted to get session span, but no active session exists');
    }
    return this.sessionSpan;
  }

  ngOnDestroy(): void {
    this.endSession();
  }
}
