import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { OutletRouteParams } from '@ninety/ui/legacy/core/services/auxiliary-router-outlet.service';
import { DetailItem } from '@ninety/ui/legacy/shared/models/_shared/detail-item.type';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { CommentDeleteEvent } from '@ninety/ui/legacy/shared/models/_shared/detail-view-input';
import { Milestone } from '@ninety/ui/legacy/shared/models/rocks/milestone';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { RockStatusChangeEvent } from '@ninety/ui/legacy/shared/models/rocks/update-rock-event';

import { TeamChangeDetailEvent } from '../_services/detail.service';

interface DetailViewActionBase {
  itemType: DetailType;
}

interface DetailViewActionWithItem extends DetailViewActionBase {
  item: DetailItem;
}

export const DetailViewActions = createActionGroup({
  source: 'Detail Service',
  events: {
    Opened: props<{ config: OutletRouteParams<any>; source: string }>(),
    Close: emptyProps(),
    Closed: props<DetailViewActionBase>(),

    'Enable Streaming': emptyProps(),
    'Disable Streaming': emptyProps(),

    'Enable Scrollbar': emptyProps(),
    'Disable Scrollbar': emptyProps(),

    'Attachment Changed': props<DetailViewActionWithItem>(),
    Completed: props<DetailViewActionWithItem>(),
    Deleted: props<DetailViewActionWithItem>(),
    'Toggled Archived': props<DetailViewActionWithItem>(),
    Updated: props<DetailViewActionWithItem>(),

    'Team Changed': props<DetailViewActionBase & { changes: TeamChangeDetailEvent }>(),
  },
});

export const DetailServiceRockActions = createActionGroup({
  source: 'Detail Service - Rock',
  events: {
    Updated: props<{ update: Partial<Rock> }>(),
    'Updated User': props<{ userId: string }>(),
    'Updated Status Code': props<{ event: RockStatusChangeEvent }>(),
    'Delete Comment': props<{ event: CommentDeleteEvent }>(),
  },
});

export const DetailServiceMilestoneActions = createActionGroup({
  source: 'Detail Service - Milestone',
  events: {
    //actions that should only update the store,
    //no request handling until the milestone list refactored
    Added: props<{ milestone: Milestone }>(),
    Updated: props<{ update: Partial<Milestone> }>(),
    Deleted: props<{ milestoneId: string }>(),
  },
});
