/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Injectable } from '@angular/core';
import type { Rdl as ARJS } from '@grapecity/activereports/core';
import { ViewerComponent } from '@grapecity/activereports-angular';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, filter, from, merge, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { FileService } from '@ninety/ui/legacy/core/services/file.service';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { LoggingActions } from '@ninety/ui/legacy/state/app-logging/app-logging.actions';
import { ReportViewerApiService } from '@ninety/web/pages/report-viewer/_api/report-viewer.api.service';

import { SimpleReportPdfParams } from '../directives/ar-pdf-generator.directive';
import { PdfLoadingStateEnum, pdfLoadingStateToDownloadButtonState } from '../models/pdf-loading-state.enum';

interface ActiveReportsFacadeState {
  initialized: boolean;
  reportReady: boolean;
  documentReady: boolean;

  state: PdfLoadingStateEnum;
  blob: Blob | undefined;
}

function getInitialPrintToPdfButtonComponentStoreState(
  partial?: Partial<ActiveReportsFacadeState>
): ActiveReportsFacadeState {
  return {
    initialized: false,
    reportReady: false,
    documentReady: false,
    state: PdfLoadingStateEnum.nodata,
    blob: undefined,
    ...partial,
  };
}

const CLEAR_READY_STATE_PROPS: Readonly<Partial<ActiveReportsFacadeState>> = {
  reportReady: false,
  documentReady: false,
  blob: undefined,
  state: PdfLoadingStateEnum.print,
};

/**
 * Manages an ActiveReports viewer and the process of generating a PDF from a report. Encapsulates the complex logic to query the API,
 * generate a report, and export it in a new tab (and thus implements the GOF facade pattern).
 *
 * Implemented as a directive for wide reuse. The {@link ReportDownloadButtonComponent} is the recommended host of this directive, but is
 * entirely decoupled from it.
 */
@Injectable()
export class ActiveReportsFacade extends ComponentStore<ActiveReportsFacadeState> {
  private viewer: ViewerComponent; // Not appropriate to store in reactive state, hold as internal reference

  private readonly cancelBus = new Subject<void>();

  constructor(private api: ReportViewerApiService, private fileService: FileService, private store: Store) {
    super(getInitialPrintToPdfButtonComponentStoreState());
  }

  /**
   * After you load a document in AR, you must wait until it's ready before exporting. Explicitly creates a new observable each time this
   * getter is invoked.
   */
  get waitUntilDocumentReady$() {
    return this.select(state => state.documentReady).pipe(filter(Boolean), take(1));
  }

  readonly buttonState$ = this.select(state => pdfLoadingStateToDownloadButtonState(state.state));

  public readonly setButtonState = this.updater(
    (state: ActiveReportsFacadeState, newState: PdfLoadingStateEnum): ActiveReportsFacadeState => ({
      ...state,
      state: newState,
    })
  );
  private readonly setInitialized = this.updater(
    (state): ActiveReportsFacadeState => ({ ...state, initialized: true })
  );
  private readonly setReportReady = this.updater(
    (state: ActiveReportsFacadeState, ready: boolean): ActiveReportsFacadeState => ({
      ...state,
      reportReady: ready,
    })
  );
  private readonly setDocumentReady = this.updater(
    (state: ActiveReportsFacadeState, ready: boolean): ActiveReportsFacadeState => ({
      ...state,
      documentReady: ready,
    })
  );

  /** Initialize the service with the viewer component. */
  readonly init = this.effect((viewer$: Observable<ViewerComponent>) =>
    viewer$.pipe(
      tap(viewer => (this.viewer = viewer)),
      switchMap(viewer =>
        merge(
          viewer.init.pipe(tap(() => this.setInitialized())),
          viewer.documentLoaded.pipe(tap(() => this.setDocumentReady(true))),
          viewer.reportLoaded.pipe(tap(() => this.setReportReady(true)))
        )
      )
    )
  );

  /** Produce a PDF from the report data. Does not open or download the file. */
  readonly generatePdf = this.effect((params$: Observable<SimpleReportPdfParams>) =>
    params$.pipe(
      filter(() => this.rejectIfNotInitialized()),
      tap(() => this.setButtonState(PdfLoadingStateEnum.querying)),
      switchMap(({ reportName, reportData }) =>
        this.api
          .getReportDef(reportName, reportData)
          // Component store expects that you catch errors inside the switchMap - you can't simply add this at the end of the top level pipe
          .pipe(
            catchError((err: unknown) => this.handleError('Error generating PDF', 'API error.', err)),
            // We also need to cancel here during reset to stop any in-fight API requests
            takeUntil(this.cancelBus)
          )
      ),
      tap(() => this.setButtonState(PdfLoadingStateEnum.opening)),
      switchMap((reportDef: ARJS.Report) =>
        from(this.viewer.open(reportDef)).pipe(
          switchMap(() => this.waitUntilDocumentReady$),
          tap(() => this.setButtonState(PdfLoadingStateEnum.exporting)),
          switchMap(() => from(this.viewer.export('pdf', { author: 'Ninety.IO', title: reportDef.Name }))),
          // Generally, you want the takeUntil to be at the end of the stream. In this case, we want to stop the flow from
          // proceeding past this point if we are trying to cancel the inflight request.
          // eslint-disable-next-line rxjs/no-unsafe-takeuntil
          takeUntil(this.cancelBus),
          tap(exportResult => this.patchState({ blob: exportResult.data as Blob, state: PdfLoadingStateEnum.ready })),
          take(1),
          catchError((err: unknown) =>
            this.handleError('Error generating PDF', 'Please try again or reach out to support.', err)
          )
        )
      )
    )
  );

  /** Open the PDF in a new tab. Resets state to initial. */
  readonly openPdf = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      filter(() => this.rejectIfNotInitialized()),
      concatLatestFrom(() => this.select(state => state.blob)),
      switchMap(([_, blob]) => {
        if (!blob) return this.handleError('Error opening PDF', 'No PDF generated'); // This should be impossible (no UI flow allows this)

        return this.fileService.openTab().pipe(
          tap(tab => {
            this.fileService.openBlob(tab.newTab, blob, 'SurveyReport.pdf');
            this.clearReadyState();
          }),
          catchError((err: unknown) =>
            this.handleError('Error opening PDF', 'Please try again or reach out to support.', err)
          )
        );
      })
    )
  );

  readonly reset = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      tap(() => {
        this.cancelBus.next();
        this.clearReadyState();
      })
    )
  );

  private handleError(title: string, message?: string, error?: unknown): Observable<unknown> {
    this.store.dispatch(NotificationActions.showError({ title, message }));
    this.store.dispatch(LoggingActions.error({ log: { message: `${title} - ${message}`, error } }));
    this.clearReadyState();
    return EMPTY;
  }

  private clearReadyState() {
    this.patchState(CLEAR_READY_STATE_PROPS);
  }

  private rejectIfNotInitialized(): boolean {
    if (this.viewer) return true;

    this.handleError('Viewer not initialized');
    return false;
  }
}
