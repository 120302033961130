import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { CompanyUsersStateModel } from './company-users/company-users-state.model';
import { CompanyUsersStateReducer } from './company-users/company-users-state.reducer';
import { FeatureFlagStateEffects } from './feature-flag/feature-flag-state.effects';
import { FeatureFlagStateModel } from './feature-flag/feature-flag-state.model';
import { featureFlagStateReducer } from './feature-flag/feature-flag-state.reducer';
import { TeamListStateEffects } from './team-list/team-list-state.effects';
import { TeamListStateModel } from './team-list/team-list-state.model';
import { teamListStateReducer } from './team-list/team-list-state.reducer';
import { UserListStateEffects } from './user-list/user-list-state.effects';
import { UserListStateModel } from './user-list/user-list-state.model';
import { UserListStateReducer } from './user-list/user-list-state.reducer';
import { UsersStateEffects } from './users/users-state.effects';
import { UsersStateModel } from './users/users-state.model';
import { UsersStateReducer } from './users/users-state.reducer';

import {
  CompanyUsersStateKey,
  FeatureFlagStateKey,
  TeamListStateKey,
  UserListStateKey,
  UsersStateKey,
  appEntitiesStateKey,
} from './index';

export interface AppEntitiesState {
  [FeatureFlagStateKey]: FeatureFlagStateModel;
  [UsersStateKey]: UsersStateModel;
  [CompanyUsersStateKey]: CompanyUsersStateModel;
  [TeamListStateKey]: TeamListStateModel;
  [UserListStateKey]: UserListStateModel;
}

export const appEntitiesStateReducers: ActionReducerMap<AppEntitiesState> = {
  [FeatureFlagStateKey]: featureFlagStateReducer,
  [UsersStateKey]: UsersStateReducer,
  [CompanyUsersStateKey]: CompanyUsersStateReducer,
  [TeamListStateKey]: teamListStateReducer,
  [UserListStateKey]: UserListStateReducer,
};

@NgModule({
  imports: [
    StoreModule.forFeature(appEntitiesStateKey, appEntitiesStateReducers),
    EffectsModule.forFeature([FeatureFlagStateEffects, UsersStateEffects, TeamListStateEffects, UserListStateEffects]),
  ],
})
export class AppEntitiesStateModule {}
