import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

import { TerraIconModule } from '@ninety/terra';

import { CardModule } from '../../../cards/card.module';
import { NinetyCMModule } from '../../../commercial-model/cm.module';

@Component({
  selector: 'ninety-all-in-discount',
  standalone: true,
  imports: [CommonModule, MatCardModule, TerraIconModule, NinetyCMModule, CardModule],
  templateUrl: './all-in-discount.component.html',
  styleUrls: ['./all-in-discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllInDiscountComponent {
  @Output() talkToNinetyRep = new EventEmitter<void>();
}
