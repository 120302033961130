import { DefaultUrlSerializer } from '@angular/router';
import { createSelector } from '@ngrx/store';

import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { selectUrl } from '@ninety/ui/legacy/state/route.selectors';
import { WidgetTemplateType } from '@ninety/web/pages/my-ninety/_models/widget-template-type';

import { selectHeadlinesModule } from '..';
import { headlinesInitialState } from '../headlines/headlines-state.model';

import { CascadingMessageStateKey, cascadingMessagesStateAdapter } from './cascading-messages-state.model';

const { selectAll } = cascadingMessagesStateAdapter.getSelectors();
export const selectCascadingMessagesStateModel = createSelector(selectHeadlinesModule, state =>
  state && state[CascadingMessageStateKey] ? state[CascadingMessageStateKey] : headlinesInitialState
);

export const selectCascadingMessages = createSelector(selectCascadingMessagesStateModel, selectAll);
export const selectFilterBy = createSelector(selectCascadingMessagesStateModel, state => state.filters);
export const selectIsSearching = createSelector(selectCascadingMessagesStateModel, state => !!state.filters.searchText);
export const selectFilterByArchived = createSelector(
  selectCascadingMessagesStateModel,
  state => state.filters.archived
);
export const selectFilterByTeamId = createSelector(selectCascadingMessagesStateModel, state => state.filters.teamId);

export const selectCascadingMessageById = (id: string) =>
  createSelector(selectCascadingMessagesStateModel, state => state.entities[id]);

export const selectSelectedId = createSelector(selectCascadingMessagesStateModel, state => state.selectedId);

export const selectSelectedCascadingMessage = createSelector(
  selectCascadingMessagesStateModel,
  selectSelectedId,
  (state, selectedId) => state.entities[selectedId]
);

export const selectTotalCount = createSelector(selectCascadingMessagesStateModel, state => state.totalCount);
export const selectPagination = createSelector(selectCascadingMessagesStateModel, state => state.pagination);

/** @deprecated - use selectSortOrNull, rename to selectSort when all selectSort references are removed*/
export const selectSort = createSelector(selectCascadingMessagesStateModel, state => state.sort);
export const selectSortOrNull = createSelector(selectCascadingMessagesStateModel, state =>
  state.sort.direction ? state.sort : null
);
export const selectLoading = createSelector(selectCascadingMessagesStateModel, state => state.loading);
export const selectError = createSelector(selectCascadingMessagesStateModel, state => state.error);

export const selectGetCascadingMessageQueryParams = createSelector(
  selectFilterBy,
  selectPagination,
  selectSort,
  (filterBy, pagination, sort) => ({
    pageIndex: pagination.index,
    pageSize: pagination.size,
    ...(sort.direction != null ? { sortField: sort.field, sortDirection: sort.direction } : null),
    ...(filterBy.teamId && filterBy.teamId !== 'all' ? { teamId: filterBy.teamId } : null),
    archived: filterBy.archived,
    includeDiscussed: filterBy.includeDiscussed,
    //TODO NEXT: when old headlines are removed, remove includeDiscussed and only rely on inMeetingId, unless we find another use case
    //inMeetingId = _id, load not discussed + discussed in meetingId
    //inMeetingId = null, load including discussed
    inMeetingId: filterBy.inMeetingId,
    searchText: filterBy.searchText,
  })
);

export const selectTemplateType = createSelector(
  selectLoading,
  selectError,
  selectTotalCount,
  selectFilterBy,
  (loading, error, totalCount, filterBy) => {
    if (loading) {
      return WidgetTemplateType.loading;
    }
    if (error) {
      return WidgetTemplateType.error;
    }
    if (filterBy.searchText && totalCount === 0) {
      return WidgetTemplateType.noQueryResults;
    }
    if (totalCount === 0 && filterBy.archived) {
      return WidgetTemplateType.archive;
    }
    if (totalCount === 0) {
      return WidgetTemplateType.empty;
    }
    return WidgetTemplateType.data;
  }
);

export const selectCascadingMessageIdFromDetailRoute = createSelector(selectUrl, url => {
  const parsedUrl = new DefaultUrlSerializer().parse(url);
  if (parsedUrl.root.children.detail?.segments[1].path === DetailType.cascadingMessage)
    return parsedUrl.root.children.detail.segments[2].path;
  return null;
});
