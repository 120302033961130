import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraIconModule, TerraDividerModule } from '@ninety/terra';
import { FeatureFlagKeys, selectFeatureFlag, UserModelState } from '@ninety/ui/legacy/state/index';

import { MeetingScheduleResponse } from '../../../_models';
import { MeetingSchedulingActions } from '../../../_state/meetings.actions';

@Component({
  selector: 'ninety-meeting-schedules-context-menu',
  template: `
    <button
      [matMenuTriggerFor]="contextMenu"
      [matMenuTriggerData]="schedule"
      class="schedules-context-menu-trigger"></button>
    <mat-menu class="schedules-context-menu" #contextMenu="matMenu">
      <ng-template matMenuContent *ngrxLet="calendarUrlFlag$ as cal">
        <ninety-button
          *ngIf="cal && currentUser._id === schedule.createdByUserId"
          data-cy="schedules-context-add-to-calendar-button"
          data-product="schedules-context-add-to-calendar-button"
          mat-menu-item
          id="schedules-add-calendar-btn"
          (click)="onAddToCalendar(schedule)">
          <terra-icon icon="add" size="20" class="mat-menu-icon" />
          Add to calendar
        </ninety-button>
        <ninety-button
          data-cy="schedules-context-menu_edit-button"
          mat-menu-item
          id="schedules-edit-btn"
          (click)="onEdit(schedule)">
          <terra-icon icon="edit" size="20" class="mat-menu-icon" />
          Edit schedule
        </ninety-button>
        <terra-divider />
        <ninety-button
          data-cy="schedules-context-menu_delete-button"
          mat-menu-item
          id="schedules-delete-btn"
          (click)="onDelete(schedule)">
          <terra-icon icon="delete" size="20" class="mat-menu-icon" />
          Delete
        </ninety-button>
      </ng-template>
    </mat-menu>
  `,
  styles: [
    `
      .schedules-context-menu-trigger {
        position: fixed;
        width: 0;
        height: 0;
        visibility: hidden;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, MatMenuModule, TerraIconModule, LetDirective, TerraDividerModule],
})
export class MeetingSchedulesContextMenuComponent {
  private store = inject(Store);
  @Output() delete = new EventEmitter<{ schedule: MeetingScheduleResponse }>();
  @Output() edit = new EventEmitter<{ schedule: MeetingScheduleResponse }>();

  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef }) triggerElement: ElementRef;
  @ViewChild(MatMenu) menu: MatMenu;

  schedule: MeetingScheduleResponse;
  currentUser: UserModelState;
  calendarUrlFlag$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.calendarUrl));

  openMenu(event: MouseEvent, schedule: MeetingScheduleResponse, currentUser: UserModelState) {
    event.preventDefault();
    event.stopPropagation();

    this.triggerElement.nativeElement.style.top = `${event.pageY}px`;
    this.triggerElement.nativeElement.style.left = `${event.pageX}px`;

    this.schedule = schedule;
    this.currentUser = currentUser;
    this.trigger.openMenu();
  }

  onDelete(schedule: MeetingScheduleResponse) {
    this.delete.emit({ schedule });
  }

  onEdit(schedule: MeetingScheduleResponse) {
    this.trigger.closeMenu();
    this.edit.emit({ schedule });
  }

  onAddToCalendar(schedule: MeetingScheduleResponse) {
    this.store.dispatch(MeetingSchedulingActions.openMeetingConfirmationDialog({ schedule }));
  }
}
