import { Spectator } from '@ngneat/spectator';

import { SelectLauncherDirective } from '../../directives/select-launcher.directive';
import { ChipUserSelectBoxComponent } from '../../implementations/chip-user-select-box/chip-user-select-box.component';

import { SelectBoxComponent, SelectBoxViewRenderType } from './select-box.component';

export class SelectBoxComponentHarness<T = ChipUserSelectBoxComponent> {
  constructor(protected spectator: Spectator<T>) {}

  /* Selectors */
  public static hostSelector = 'ninety-select-box';
  public static overlaySelector = '.select-overlay';
  public static viewSelector = '.view';

  /* Interactions */

  launchSelect(): void {
    if (this.isOpen()) return;

    const view = this.getSelectBoxView();
    view.click();
  }

  closeSelect(): void {
    if (!this.isOpen()) return;

    const directive = this.getSelectLauncherDirective();
    directive.destroyOverlay();
  }

  /* Relevant Test Questions */

  isOpen(): boolean {
    const launcher = this.getSelectLauncherDirective();
    return launcher?.isOpen ?? false;
  }

  /* Element Fetchers */

  getSelectBox(): SelectBoxComponent | null {
    return this.spectator.query(SelectBoxComponentHarness.hostSelector, { read: SelectBoxComponent });
  }

  getSelectBoxView(): HTMLElement | null {
    return this.spectator.query(SelectBoxComponentHarness.viewSelector);
  }

  getPlaceholder(): string | null {
    const selectBox = this.getSelectBox();
    return selectBox?.['placeholder'];
  }

  getSelectBoxViewRenderType(): SelectBoxViewRenderType | null {
    const selectBox = this.getSelectBox();
    return selectBox?.['viewToRender'];
  }

  getSelectLauncherDirective(): SelectLauncherDirective | null {
    return this.spectator.query(SelectLauncherDirective);
  }
}
