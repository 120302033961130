import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/index';
import { AddItemButtonComponent, MeetingType, Team } from '@ninety/ui/legacy/shared/index';
import { MeetingRoutes } from '@ninety/ui/legacy/shared/models/meetings/meeting-routes';
import { AgendaTableDataV2 } from '@ninety/ui/legacy/shared/models/meetings-v2/agenda-table-data-v2';
import { selectLanguage, selectWeeklyMeetingsOnlyFeature } from '@ninety/ui/legacy/state/index';
import { MeetingsAgendasV2Actions } from '@ninety/web/pages/meetings/_state/meetings.actions';
import { MeetingsStateSelectors } from '@ninety/web/pages/meetings/_state/meetings.selectors';

@Component({
  selector: 'ninety-agendas-v2',
  templateUrl: './agendas-v2.component.html',
  styleUrls: ['./agendas-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    CardModule,
    MatTableModule,
    AddItemButtonComponent,
    TerraIconModule,
    NinetyCMModule,
    ButtonComponent,
  ],
})
export class AgendasV2Component {
  store = inject(Store);
  router = inject(Router);

  agendas$ = this.store.select(MeetingsStateSelectors.selectAgendas);
  teams$ = this.store.select(MeetingsStateSelectors.selectTeamFilterOptions);
  selectedTeam$ = this.store.select(MeetingsStateSelectors.selectSelectedTeam);

  language$ = this.store.select(selectLanguage);
  weeklyMeetingsOnlyFeature$ = this.store.select(selectWeeklyMeetingsOnlyFeature);

  displayedColumns: string[] = ['frontSpacer', 'title', 'type', 'endSpacer'];
  MeetingType = MeetingType;

  onSelected(agenda: AgendaTableDataV2): void {
    this.router.navigate([`/${MeetingRoutes.home}/${MeetingRoutes.agendas}/${agenda.id}`]);
  }

  onTeamSelected(team: Team | Team[]): void {
    this.store.dispatch(MeetingsAgendasV2Actions.selectTeam({ team: team as Team }));
  }

  createAgenda(): void {
    this.router.navigate([`/${MeetingRoutes.home}/${MeetingRoutes.agendas}/${MeetingRoutes.newCustomAgenda}`]);
  }
}
