import { PricingTier } from '../company/company-pricing-tiers';
import { CustomLanguage } from '../language/custom-language';

import { PlanFeature } from './plan-feature';

export interface SubscriptionDetails {
  description: string;
  everythingText: string;
  features: PlanFeature[];
  featuresExtended?: PlanFeature[];
}

export function getSubscriptionDetails(language: CustomLanguage): Record<PricingTier, SubscriptionDetails> {
  return {
    [PricingTier.Free]: {
      description: `Good for teams just starting their journey, the ${PricingTier.Free} plan gives you the tools to learn,
      strategize, and build the foundation of your organization.`,
      everythingText: 'Everything you need to get started!',
      features: [
        { title: 'Baseline + Org Fit Assessments', description: 'Baseline + Org Fit Assessments' },
        { title: language?.directory?.route, description: language?.directory?.route },
        { title: 'KST: Mastery', description: 'KST: Mastery' },
        { title: 'Maz for Onboarding', description: 'Maz for Onboarding' },
        { title: 'language?.acChart?.route', description: 'language?.acChart?.route' },
        { title: `${language.vto.vision} + Strategy`, description: `${language.vto.vision} + Strategy` },
      ],
    },
    [PricingTier.Essentials]: {
      description: `Ideal for teams focused on tactical execution, the ${PricingTier.Essentials} plan equips you to run
      effective Weekly Team Meetings and stay aligned on your goals.`,
      everythingText: `Everything in ${PricingTier.Free} plus:`,
      features: [
        { title: language?.issue?.items, description: language?.issue?.items },
        { title: language?.my90?.route, description: language?.my90?.route },
        { title: language?.rock?.items, description: language?.rock?.items },
        { title: language?.scorecard?.item, description: language?.scorecard?.item },
        { title: language?.todo?.items, description: language?.todo?.items },
        { title: language?.meeting?.levelTens, description: language?.meeting?.levelTens },
      ],
      featuresExtended: [
        { title: language?.headline?.items, description: language?.headline?.items },
        { title: language?.milestone?.items, description: language?.milestone?.items },
      ],
    },
    [PricingTier.Accelerate]: {
      description: `Designed for teams ready to grow, the ${PricingTier.Accelerate} plan offers tools to plan each quarter,
       think strategically, and scale your operations seamlessly.`,
      everythingText: `Everything in ${PricingTier.Essentials} plus:`,
      features: [
        { title: language?.meeting?.annualSessions, description: language?.meeting?.annualSessions },
        { title: 'Google Integrations', description: 'Google Integrations' },
        { title: 'Microsoft Integrations', description: 'Microsoft Integrations' },
        { title: language?.meeting?.quarterlySessions, description: language?.meeting?.quarterlySessions },
        { title: 'Reporting', description: 'Reporting' },
        { title: `${language.scorecard.item} Integrations`, description: `${language.scorecard.item} Integrations` },
      ],
      featuresExtended: [
        { title: 'Cascading Messages', description: 'Cascading Messages' },
        { title: 'Custom Meetings', description: 'Custom Meetings' },
        { title: 'Implementations Meetings', description: 'Implementations Meetings' },
        { title: 'Legacy Process Tool', description: 'Legacy Process Tool' },
        { title: 'Weekly Discussions', description: 'Weekly Discussions' },
      ],
    },
    [PricingTier.Thrive]: {
      description: `For ambitious organizations, the ${PricingTier.Thrive} plan leverages advanced tools and AI-powered
      capabilities to align teams, accelerate growth, and drive unparalleled performance.`,
      everythingText: `Everything in ${PricingTier.Accelerate} plus:`,
      features: [
        { title: 'Benchmarking', description: 'Benchmarking' },
        { title: 'Board meetings', description: 'Board meetings' },
        { title: `Custom ${language.survey.items}`, description: `Custom ${language.survey.items}` },
        { title: 'General purpose APIs', description: 'General purpose APIs' },
        { title: 'KST: Authoring', description: 'KST: Authoring' },
        { title: 'Project views', description: 'Project views' },
      ],
      featuresExtended: [
        { title: 'Maz for KPIs', description: 'Maz for KPIs' },
        { title: 'Maz for Personal Development', description: 'Maz for Personal Development' },
        { title: 'Portfolio Views', description: 'Portfolio Views' },
        { title: 'Predictive Analytics', description: 'Predictive Analytics' },
        { title: 'Teams / Slack / Zoom integrations', description: 'Teams / Slack / Zoom integrations' },
      ],
    },
  };
}
