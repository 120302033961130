/* eslint-disable @angular-eslint/no-outputs-metadata-property */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Inject,
  Input,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PushPipe } from '@ngrx/component';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { NotifyIntersectDirective } from '@ninety/ui/legacy/components/tree/directives/notify-intersect.directive';
import { FocusedDirective } from '@ninety/ui/legacy/shared/directives/focused/focused.directive';
import { UserIdToAvatarPipe } from '@ninety/ui/legacy/shared/pipes/user/user-id-to-avatar.pipe';
import { UserInitialsPipe } from '@ninety/ui/legacy/shared/pipes/user-initials.pipe';
import { BoldSearchResultsPipe } from '@ninety/web/pages/accountability-chart/pipes/bold-search-results.pipe';

import { TextInputWrapperComponent } from '../../../text-input/components/text-input-wrapper/text-input-wrapper.component';
import { NinetyInputDirective } from '../../../text-input/directives/ninety-input.directive';
import { FakeCheckboxComponent } from '../../components/fake-checkbox/fake-checkbox.component';
import { SelectBoxComponent } from '../../components/select-box/select-box.component';
import { AutoCompleteDirective } from '../../directives/auto-complete.directive';
import { OptionListDirective } from '../../directives/option-list.directive';
import { OptionDirective } from '../../directives/option.directive';
import { SelectChipTemplateDirective } from '../../directives/select-chip-template.directive';
import { SelectImplementationDirective } from '../../directives/select-implementation.directive';
import { FUSE_PROVIDER_INJECTION_TOKEN, FuseSearchProvider } from '../../services/fuse-provider';
import { ManagedOptions } from '../../services/fuse-search.service.model';

@Component({
  selector: 'ninety-chip-select-kitchen-sink',
  templateUrl: './chip-select-kitchen-sink.component.html',
  styleUrls: ['./chip-select-kitchen-sink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SelectBoxComponent,
    OptionListDirective,
    OptionDirective,
    SelectChipTemplateDirective,
    UserInitialsPipe,
    PushPipe,
    FormsModule,
    BoldSearchResultsPipe,
    NinetyInputDirective,
    FakeCheckboxComponent,
    UserIdToAvatarPipe,
    FocusedDirective,
    AutoCompleteDirective,
    TextInputWrapperComponent,
    ButtonComponent,
    TerraIconModule,
    NotifyIntersectDirective,
  ],
})
export class ChipSelectKitchenSinkComponent<T extends { _id: string }> implements AfterViewInit {
  @Input() chipTemplate: SelectChipTemplateDirective;

  @Input() trackByFn: TrackByFunction<ManagedOptions<T>>;

  @ViewChild(NinetyInputDirective) input: NinetyInputDirective;
  @ViewChild(OptionListDirective) optionList: OptionListDirective<T>;
  @ViewChild(SelectBoxComponent) selectBox: SelectBoxComponent;

  @ContentChild(TemplateRef) template!: TemplateRef<unknown>;

  constructor(
    @Inject(FUSE_PROVIDER_INJECTION_TOKEN) protected fuseProvider: FuseSearchProvider<T>,
    protected select: SelectImplementationDirective<T>
  ) {}

  ngAfterViewInit() {
    this.select.optionList = this.optionList;
  }

  search(query: string) {
    this.fuseProvider.fuse.search({ query });
  }
}
