import { TerraIconName } from '@ninety/terra';

export class ConfirmDialogData {
  title = 'Are you sure?';
  message?: string;
  additionalMessage?: string;

  /** Buttons */
  confirmButtonText? = 'OK';
  cancelButtonText? = 'Cancel';
  confirmButtonIcon?: TerraIconName;
  cancelButtonIcon?: TerraIconName;

  /** Options */
  isWarningDialog?: boolean; // If true, the dialog will have a warning icon and a red confirm button

  constructor(data: ConfirmDialogData) {
    Object.assign(this, data);
  }

  /** Returns ConfirmDialogData with default values for a delete dialog
   * isWarningDialog: true,
   * confirmButtonText: 'Delete'
   * */
  static WarningDialog(data: ConfirmDialogData): ConfirmDialogData {
    return new ConfirmDialogData({
      isWarningDialog: true,
      confirmButtonText: 'Delete',
      ...data,
    });
  }

  static UnsavedChangesDialog(data: ConfirmDialogData): ConfirmDialogData {
    return new ConfirmDialogData({
      title: 'Unsaved changes',
      message: `You've made some new changes that haven't been saved. Do you want to save them?`,
      confirmButtonText: 'Save changes',
      cancelButtonText: 'Discard changes',
      ...data,
    });
  }
}
