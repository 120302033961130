import { createSelector } from '@ngrx/store';

import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { selectAnonymousPartnerConfiguration } from '@ninety/ui/legacy/state/app-global/anonymous/anonymous-state.selectors';

import { selectLoginFeature } from '../../_state';

import { initialLoginSharedState, LoginSharedStateKey } from './login-shared-state.model';

export const selectLoginState = createSelector(selectLoginFeature, feature => {
  // We need this additional check.  Login state is consumed very early in the app lifecycle.
  // Typically this would not be necessary.  This will return the default initial state for
  // the shared login state.
  if (feature === undefined) {
    return initialLoginSharedState;
  }
  return feature[LoginSharedStateKey];
});
export const selectIsNinetyBrand = createSelector(
  selectAnonymousPartnerConfiguration,
  configuration => configuration.bos === BusinessOperatingSystem.ninetyOS
);
export const selectIsPartnerBrand = createSelector(
  selectAnonymousPartnerConfiguration,
  configuration => configuration.bos !== BusinessOperatingSystem.ninetyOS
);
export const selectLoginSplash = createSelector(selectAnonymousPartnerConfiguration, bos => bos.loginSplash);
export const selectLoginLogo = createSelector(selectAnonymousPartnerConfiguration, bos => bos.loginLogo);
export const selectIsSpinning = createSelector(selectLoginState, state => state.isSpinning);
export const selectIsFederated = createSelector(selectLoginState, state => state.isFederated);
export const selectParameters = createSelector(selectLoginState, state => state.queryParams);

export const selectImplementer = createSelector(selectParameters, p => p.implementer);

export const selectDecodedToken = createSelector(selectLoginState, state => state.prefillFields);

export const selectPrefillFields = createSelector(selectLoginState, state => ({
  prefillFields: {
    firstName: state.prefillFields.firstName,
    lastName: state.prefillFields.lastName,
    email: state.prefillFields.email,
  },
  decodedTokenId: state.prefillFields.idToken,
  isFederated: state.isFederated,
}));
