import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, filter, take, tap } from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { MeetingMessageAction } from '@ninety/ui/legacy/shared/models/meetings/meeting-message-action';
import { MeetingSection } from '@ninety/ui/legacy/shared/models/meetings/meeting-section';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { UserRating } from '@ninety/ui/legacy/shared/models/meetings/user-rating';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectDoesUserHaveAccessToOrgChart } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';

import { MeetingService } from '../_shared/services/meeting.service';

@Component({
  selector: 'ninety-meeting-sidenav',
  templateUrl: './meeting-sidenav.component.html',
  styleUrls: ['./meeting-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingSidenavComponent implements OnInit, OnDestroy {
  @Input() showNav: boolean;
  @Input() isMobile: boolean;
  @Input() showNotes: boolean;

  @Output() toggleSideNav = new EventEmitter();

  protected readonly canAccessOrgChart$ = this.store.select(selectDoesUserHaveAccessToOrgChart);

  sectionRoutes: { [ordinal: number]: string[] } = {};
  showSidebar = false;

  readonly MeetingType = MeetingType;

  private readonly subscriptions = new Subscription();
  protected suspending = false;

  protected readonly rocksV3$ = this.featureFlags.getFlag(FeatureFlagKeys.webRocksV3);

  constructor(
    public meetingService: MeetingService,
    public stateService: StateService,
    private store: Store,
    private featureFlags: FeatureFlagFacade
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.meetingService.currentMeeting$
        .pipe(
          filter(m => !!m),
          take(1),
          tap(() => (this.showSidebar = true))
        )
        .subscribe({
          next: (currentMeeting: Meeting) => {
            this.meetingService.updateRocksV3Sections(currentMeeting);
            currentMeeting.sections?.forEach(s => {
              this.sectionRoutes[s.ordinal] = this.meetingService.getMeetingRoute(s);
            });
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleNotes() {
    this.meetingService.toggleShowNotes();
    if (this.isMobile) this.toggleSideNav.emit();
  }

  changeMeetingTitle(meetingTitle: string) {
    if (meetingTitle) this.meetingService.setMeetingTitle(meetingTitle);
    if (this.isMobile) this.toggleSideNav.emit();
  }

  onSectionChange(section: MeetingSection, index: number) {
    if (this.meetingService.currentMeeting.presenterUserId === this.stateService.currentCompanyUser._id) {
      this.meetingService
        .broadcastMessage({
          messageType: 'change-stage',
          document: {
            meetingElapsedTime: this.meetingService.currentMeeting.elapsedTime,
            stageElapsedTime: this.meetingService.currentMeeting.currentSection.elapsedTime,
            stage: section.name,
          },
        })
        .subscribe();
    }
    this.meetingService.navigate(this.sectionRoutes[section.ordinal]);
    this.meetingService.changeMeetingSection(section, index);
    if (this.isMobile) this.toggleSideNav.emit();
  }

  onToggleTimers() {
    this.meetingService.toggleTimers();

    this.meetingService
      .broadcastMessage({
        messageType: 'meeting',
        document: {
          action: this.meetingService.currentMeeting.paused
            ? MeetingMessageAction.Pause
            : MeetingMessageAction.Continue,
          emitterId: this.stateService.currentUser._id,
          meetingElapsedTime: this.meetingService.currentMeeting.elapsedTime,
          stageElapsedTime: this.meetingService.currentMeeting.currentSection.elapsedTime,
          stage: this.meetingService.currentMeeting.currentSection.name,
        },
      })
      .subscribe();
  }

  changeSection(next: boolean): void {
    const currentOrdinal = this.meetingService.currentMeeting.currentSection.ordinal;
    const i = next ? currentOrdinal + 1 : currentOrdinal - 1;
    const section = this.meetingService.currentMeeting.sections.find((s: MeetingSection) => s.ordinal === i);
    if (section) {
      this.meetingService.navigate(this.meetingService.getMeetingRoute(section));
      this.meetingService.changeMeetingSection(section, i);
    }
  }

  saveAndExit() {
    if (!this.meetingService.currentMeeting) return;
    const allFilled = this.meetingService.currentMeeting.userRatings.every(
      (r: UserRating, i) => !!r.rating || this.meetingService.currentMeeting.absences[i]
    );
    if (this.meetingService.currentMeeting.type === MeetingType.annualDayOne || allFilled) {
      this.meetingService.finishMeeting();
    } else {
      this.meetingService.checkRatingsAndAbsences().subscribe({
        next: (result: boolean) => {
          if (result) {
            this.meetingService.currentMeeting.userRatings.forEach((r: UserRating, i) => {
              if (!r.rating) this.meetingService.currentMeeting.absences[i] = true;
            });
            this.meetingService.finishMeeting();
          }
        },
      });
    }
  }

  exit() {
    this.meetingService.clearMeeting();
    this.meetingService.navigateToMeetingsHome();
  }

  suspend(): void {
    this.suspending = true;
    this.meetingService.suspendCurrentMeeting();
  }
}
