import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { of, switchMap } from 'rxjs';

import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { PageStateActions } from '@ninety/web/pages/layouts/_state/page/page-state.actions';
import { NinetyLayoutType } from '@ninety/web/pages/layouts/_state/page/page-state.models';

import { MeetingService } from './_shared/services/meeting.service';

@Component({
  selector: 'ninety-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingComponent implements OnInit, OnDestroy {
  canOutletBeActive$ = this.stateService.routeChange$.pipe(
    switchMap(route => (route.isMeetingEnded ? of(true) : this.meetingService.navigationEnded$))
  );

  constructor(
    public filterService: FilterService,
    public stateService: StateService,
    public meetingService: MeetingService,
    public spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    public store: Store
  ) {}

  get meetingId() {
    return this.activatedRoute.snapshot.params.meetingId;
  }

  ngOnInit() {
    this.store.dispatch(PageStateActions.setLayoutType({ layoutType: NinetyLayoutType.meeting }));

    if (!this.meetingService.currentMeeting && this.meetingId) {
      this.meetingService.getAndStartMeeting(this.activatedRoute.snapshot.params.meetingId);
    }

    window.addEventListener('beforeunload', onbeforeunload);
  }

  ngOnDestroy(): void {
    this.store.dispatch(PageStateActions.setLayoutType({ layoutType: NinetyLayoutType.normal }));

    window.removeEventListener('beforeunload', onbeforeunload);
  }

  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload(event: BeforeUnloadEvent) {
    return (event.returnValue = false);
  }
}
