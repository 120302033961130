import { SubscriptionFrequencies } from '../../../_shared/models/billingv2/subscription-frequencies.enum';
import { SubscriptionPendingInvoiceIntervals } from '../../../_shared/models/billingv2/subscription-pending-invoice-intervals.enum';
import { SubscriptionProviders } from '../../../_shared/models/billingv2/subscription-providers.enum';
import { PricingTier } from '../../../_shared/models/company/company-pricing-tiers';

import { SubscriptionPlanId } from './subscription-plan-id.enum';

export interface SubscriptionConfiguration {
  id: SubscriptionPlanId;
  provider: SubscriptionProviders;
  name: PricingTier;
  frequency: SubscriptionFrequencies;
  price: number;
  trialDays: number;
  billingVersion: string;
  active: boolean;
  activeStart: string;
  activeEnd: string;
  pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals;
}

export const FREE_SUBSCRIPTION_CONFIGURATIONS: SubscriptionConfiguration[] = [
  {
    id: SubscriptionPlanId.Free_Monthly,
    provider: SubscriptionProviders.Internal,
    name: PricingTier.Free,
    frequency: SubscriptionFrequencies.Monthly,
    price: 0,
    trialDays: 0,
    billingVersion: 'v3',
    active: true,
    activeStart: '2024-11-01T00:00:00Z',
    activeEnd: '2099-12-31T00:00:00Z',
    pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals.none,
  },
  {
    id: SubscriptionPlanId.Free_Annual,
    provider: SubscriptionProviders.Internal,
    name: PricingTier.Free,
    frequency: SubscriptionFrequencies.Annual,
    price: 0,
    trialDays: 0,
    billingVersion: 'v3',
    active: true,
    activeStart: '2024-11-01T00:00:00Z',
    activeEnd: '2099-12-31T00:00:00Z',
    pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals.none,
  },
];

export const PAID_SUBSCRIPTION_CONFIGURATIONS: SubscriptionConfiguration[] = [
  {
    id: SubscriptionPlanId.Essentials_Monthly,
    provider: SubscriptionProviders.Stripe,
    name: PricingTier.Essentials,
    frequency: SubscriptionFrequencies.Monthly,
    price: 13,
    trialDays: 0,
    billingVersion: 'v3',
    active: true,
    activeStart: '2024-11-01T00:00:00Z',
    activeEnd: '2099-12-31T00:00:00Z',
    pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals.none,
  },
  {
    id: SubscriptionPlanId.Essentials_Annual,
    provider: SubscriptionProviders.Stripe,
    name: PricingTier.Essentials,
    frequency: SubscriptionFrequencies.Annual,
    price: 156,
    trialDays: 0,
    billingVersion: 'v3',
    active: true,
    activeStart: '2024-11-01T00:00:00Z',
    activeEnd: '2099-12-31T00:00:00Z',
    pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals.none,
  },

  {
    id: SubscriptionPlanId.Accelerate_Monthly,
    provider: SubscriptionProviders.Stripe,
    name: PricingTier.Accelerate,
    frequency: SubscriptionFrequencies.Monthly,
    price: 15,
    trialDays: 0,
    billingVersion: 'v3',
    active: true,
    activeStart: '2024-11-01T00:00:00Z',
    activeEnd: '2099-12-31T00:00:00Z',
    pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals.none,
  },
  {
    id: SubscriptionPlanId.Accelerate_Annual,
    provider: SubscriptionProviders.Stripe,
    name: PricingTier.Accelerate,
    frequency: SubscriptionFrequencies.Annual,
    price: 180,
    trialDays: 0,
    billingVersion: 'v3',
    active: true,
    activeStart: '2024-11-01T00:00:00Z',
    activeEnd: '2099-12-31T00:00:00Z',
    pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals.none,
  },

  {
    id: SubscriptionPlanId.Thrive_Monthly,
    provider: SubscriptionProviders.Stripe,
    name: PricingTier.Thrive,
    frequency: SubscriptionFrequencies.Monthly,
    price: 17,
    trialDays: 0,
    billingVersion: 'v3',
    active: true,
    activeStart: '2024-11-01T00:00:00Z',
    activeEnd: '2099-12-31T00:00:00Z',
    pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals.none,
  },
  {
    id: SubscriptionPlanId.Thrive_Annual,
    provider: SubscriptionProviders.Stripe,
    name: PricingTier.Thrive,
    frequency: SubscriptionFrequencies.Annual,
    price: 204,
    trialDays: 0,
    billingVersion: 'v3',
    active: true,
    activeStart: '2024-11-01T00:00:00Z',
    activeEnd: '2099-12-31T00:00:00Z',
    pendingInvoiceInterval: SubscriptionPendingInvoiceIntervals.none,
  },
];

/** when no longer needed, move to test setup */
export const SUBSCRIPTION_CONFIGURATIONS: SubscriptionConfiguration[] = [
  ...FREE_SUBSCRIPTION_CONFIGURATIONS,
  ...PAID_SUBSCRIPTION_CONFIGURATIONS,
];
