import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import * as FeatureFlagSelectors from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import * as CompanySelectors from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';

import { VtoSelectors } from '../_state/vto-state.selectors';

import { VtoNormalizerOpts } from './vto-normalizer.service';

@Injectable({
  providedIn: 'root',
})
export class VtoNormalizerOptsFactoryService {
  constructor(private readonly store: Store) {}

  // TODO future refactors should seek to migrate this entirely to the store using a higher order function
  getCompleteOpts(opts?: Partial<VtoNormalizerOpts>): Observable<VtoNormalizerOpts> {
    const bosSource = opts?.bos ? of(opts.bos) : this.store.select(CompanySelectors.selectBos);

    const usingNinetyFlagSource =
      opts?.usingNinetyFlag !== undefined
        ? of(opts.usingNinetyFlag)
        : this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.coreNinetyDefaultVTO));

    const usingPinnacleFlagSource =
      opts?.usingPinnacleFlag !== undefined
        ? of(opts.usingPinnacleFlag)
        : this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.pinnacleDefaultVTO));

    const defaultsSource = opts?.defaults ? of(opts.defaults) : this.store.select(VtoSelectors.selectDefaults);

    return zip(usingNinetyFlagSource, usingPinnacleFlagSource, defaultsSource, bosSource).pipe(
      take(1),
      map(([usingNinetyFlag, usingPinnacleFlag, defaults, bos]) => ({
        // opts can be a union type (as it is when this method is called via the VtoCascaderService), even tho we explicitly
        // deconstruct all properties of VtoNormalizerOpts here, we still use `...opts` to capture any other properties.
        ...opts,
        usingNinetyFlag,
        usingPinnacleFlag,
        defaults,
        bos,
      }))
    );
  }
}
