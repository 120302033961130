import { NgGridStackOptions } from 'gridstack/dist/angular';

import { DashboardConstants } from './dashboard-constants';

/**
 * For now, just the options you directly provide to the gridstack component. We'll want to hide the GridStack domain behind the
 * Ninety domain.
 */
export type DashboardOptions = NgGridStackOptions;

export namespace DashboardOptions {
  export function defaults(overrides?: Partial<DashboardOptions>): DashboardOptions {
    const options: NgGridStackOptions = {
      cellHeight: DashboardConstants.CELL_HEIGHT,
      column: DashboardConstants.COLUMN_COUNT,
      columnOpts: DashboardConstants.COLUMN_OPTS,
    };

    return { ...options, ...overrides };
  }
}
