<div
  class="container"
  *ngIf="{
    person: stateService.currentPerson$ | async,
    cognitoUser: idpService.cognitoUser$ | async,
  } as data">
  <mat-card class="ninety-card" *ngIf="!!data.person">
    <mat-card-header>
      <mat-card-title>Email</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="field-wrapper">
        <terra-form-field>
          <terra-form-label>Primary Email</terra-form-label>
          <terra-text-input
            attr.data-cy="contact_primary-email-{{ data.person.primaryEmail }}"
            [ngModel]="data.person.primaryEmail"
            disabled="true"
            readonly>
            <terra-input-suffix>
              <terra-icon
                icon="edit"
                *ngIf="!data.cognitoUser?.attributes?.identities"
                class="terra-text-input__edit"
                data-cy="contact_edit_primary-email"
                routerLink="/settings/user/account" />
            </terra-input-suffix>
          </terra-text-input>
          <terra-description
            >This email is used to log in to Ninety and for platform-related communications.</terra-description
          >
        </terra-form-field>
      </div>

      <terra-divider height="tall" margins="wide" />

      <h4>Additional Emails</h4>
      <mat-list class="contact-list">
        <mat-list-item
          *ngFor="let email of userInfo.emailAddresses; let i = index; let first = first; trackBy: trackByIndex"
          [class.first]="first">
          <div class="field-wrapper flex2">
            <terra-form-field>
              <terra-form-label *ngIf="first">Email Addresses</terra-form-label>
              <terra-text-input
                data-cy="contact_email-input-field"
                [(ngModel)]="email.email"
                (ngModelChange)="updateUser$.next(null)"
                placeholder="Email" />
            </terra-form-field>
          </div>
          <div class="field-wrapper">
            <terra-form-field>
              <terra-form-label *ngIf="first">Type</terra-form-label>
              <terra-select
                data-cy="contact_select-type-email"
                [(ngModel)]="email.typeCode"
                (selectionChange)="emitUpdate()"
                [compareWith]="compareTypeCode">
                <terra-option
                  attr.data-cy="contact_email-type-{{ option.label }}"
                  *ngFor="let option of emailTypes"
                  [value]="option.typeCode"
                  >{{ option.label }}</terra-option
                >
              </terra-select>
            </terra-form-field>
          </div>
          <ninety-button
            flat
            negative
            data-cy="contact_delete-additional-email"
            (click)="deleteEmail(i)"
            class="delete-btn">
            <terra-icon icon="delete" />
          </ninety-button>
        </mat-list-item>
      </mat-list>
      <ninety-button flat primary data-cy="contact_add-email-address" mat-button color="accent" (click)="addEmail()">
        <terra-icon icon="add-circle" />
        Add Email Address
      </ninety-button>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card" *ngIf="!!data.person">
    <mat-card-header>
      <mat-card-title>Phone</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="field-wrapper">
        <terra-form-field>
          <terra-form-label>Primary Phone Number</terra-form-label>
          <terra-text-input
            attr.data-cy="contact_primary-phone-{{ data.person.primaryPhoneNumber | prettyPhoneNumber }}"
            [ngModel]="data.person.primaryPhoneNumber | prettyPhoneNumber"
            disabled="true"
            readonly
            placeholder="None">
            <terra-input-suffix>
              <terra-icon
                icon="edit"
                class="terra-text-input__edit"
                data-cy="contact_edit_primary-phone-number"
                routerLink="/settings/user/account" />
            </terra-input-suffix>
          </terra-text-input>
          <terra-description>This phone number is used for MFA authentication.</terra-description>
        </terra-form-field>
      </div>
      <terra-divider height="tall" margins="wide" />

      <h4>Additional Phone Numbers</h4>
      <mat-list class="contact-list">
        <mat-list-item
          *ngFor="let phone of userInfo.phoneNumbers; let i = index; let first = first; trackBy: trackByIndex"
          [class.first]="first">
          <div class="field-wrapper flex2">
            <terra-form-field>
              <terra-form-label *ngIf="first">Phone Number</terra-form-label>
              <terra-text-input
                data-cy="contact_phone-number-input-field"
                [(ngModel)]="phone.number"
                (ngModelChange)="updateUser$.next(null)"
                placeholder="Phone Number" />
            </terra-form-field>
          </div>
          <div class="field-wrapper">
            <terra-form-field>
              <terra-form-label *ngIf="first">Type</terra-form-label>
              <terra-select
                data-cy="contact_select-type-phone"
                [(ngModel)]="phone.typeCode"
                (selectionChange)="emitUpdate()"
                [compareWith]="compareTypeCode">
                <terra-option
                  attr.data-cy="contact_phone-number-type-{{ option.label }}"
                  *ngFor="let option of phoneTypes"
                  [value]="option.typeCode"
                  >{{ option.label }}</terra-option
                >
              </terra-select>
            </terra-form-field>
          </div>

          <ninety-button
            flat
            negative
            data-cy="contact_delete-additional-phone-number"
            (click)="deletePhoneNumber(i)"
            class="delete-btn">
            <terra-icon icon="delete" />
          </ninety-button>
        </mat-list-item>
      </mat-list>

      <ninety-button
        flat
        primary
        data-cy="contact_add-phone-number"
        mat-button
        color="accent"
        (click)="addPhoneNumber()">
        <terra-icon icon="add-circle" />
        Add Phone Number
      </ninety-button>
    </mat-card-content>
  </mat-card>
</div>
