<ng-container
  *ngIf="
    {
      chipMessage: chipMessage$ | async,
      companySettings: companySettings$ | async,
      createDialogIsOpen: createDialogService.isOpen$ | async,
      currentMeeting: currentMeeting$ | async,
      currentPerson: currentPerson$ | async,
      currentUserId: currentUserId$ | async,
      disableNavLinks: disableNavLinks$ | async,
      enableLearningModule: enableLearningModule$ | async,
      enableTerrafyToolbarsFeatureFlag: enableTerrafyToolbarsFeatureFlag$ | async,
      enableSurveys: enableSurveys$ | async,
      isEditing: isEditing$ | async,
      isFetchingData: filterService.isFetchingData$ | async,
      isManageeOrAbove: isManageeOrAbove$ | async,
      isObserver: isObserver$ | async,
      language: language$ | async,
      mainToolbarHasShadow: mainToolbarHasShadow$ | async,
      meetingTeam: meetingService.team$ | async,
      pageTitle: pageTitle$ | async,
      participants: participants$ | async,
      toolBarHidden: mainToolbarHidden$ | async,
      directoryV1: directoryV1$ | async,
      directoryV2: directoryV2$ | async,
      currentConversationMeeting: currentConversationMeeting$ | async,
      rocksV3: rocksV3$ | async,
    } | ngrxPush as data
  ">
  <!-- Terrafy Toolbar Header -->
  <header
    *ngIf="data.enableTerrafyToolbarsFeatureFlag && data.currentPerson && !data.toolBarHidden"
    class="terrafy-top-toolbar__header"
    [class.has-box-shadow]="data.mainToolbarHasShadow !== false">
    <button
      data-cy="top-toolbar_toggle-meeting-side-nav-button"
      *ngIf="meetingStarted"
      mat-icon-button
      (click)="stateService.toggleMeetingSideNav(!stateService.toggleMeetingSideNavB$.value)">
      <terra-icon icon="list" />
    </button>
    <!-- Mobile Navigation Menu Trigger -->
    <button
      data-cy="top-toolbar_hamburger-menu-button"
      *ngIf="!(meetingStarted || data.isEditing)"
      #responsiveMenuTrigger
      class="menu-button-hamburger"
      mat-icon-button
      [matMenuTriggerFor]="responsiveNavMenu">
      <terra-icon icon="list" />
    </button>
    <!-- Mobile Navigation Menu -->
    <mat-menu #responsiveNavMenu="matMenu">
      <ng-template matMenuContent>
        <div class="responsive-nav-menu">
          <ninety-company-menu [menuItem]="true"></ninety-company-menu>
          <a
            mat-menu-item
            [routerLink]="[{ outlets: { detail: null, primary: 'partner-hub' } }]"
            routerLinkActive="active"
            *ngIf="data.currentPerson?.partnerType">
            <terra-icon icon="users" />
            Partner Hub
          </a>
          <a
            mat-menu-item
            [href]="'/dashboard'"
            *ngIf="data.companySettings.dashboard"
            [class.disabled]="data.disableNavLinks">
            <terra-icon icon="dashboard" />
            {{ data.language.dashboard.route }}
          </a>
          <!-- My90 Route -->
          <a
            mat-menu-item
            [routerLink]="[{ outlets: { detail: null, primary: 'home' } }]"
            routerLinkActive="active"
            [class.disabled]="data.disableNavLinks">
            <terra-icon icon="my-90" />
            {{ data.language.my90.route }}
          </a>
          <!-- Insights Route -->
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'insights' } }]"
            routerLinkActive="active">
            <terra-icon icon="lightbulb" />
            <!-- TODO: Custom Language -->
            Insights
          </a>

          <!-- Scorecard Route -->
          <div class="beta-menu-item">
            <a
              iconKey="data"
              data-cy="main-sidebar_data"
              [attr.data-product]="'top-toolbar__scorecard-beta-link'"
              [class.disabled]="data.disableNavLinks"
              [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'data-v2' } }]">
              <terra-icon icon="data" />
              {{ data.language.scorecard.route }}
            </a>
          </div>
          <!-- Rocks -->
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="
              data.disableNavLinks
                ? null
                : [{ outlets: { detail: null, primary: data.rocksV3 ? 'rocks-v3' : 'rocks' } }]
            "
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <terra-icon icon="rocks" />
            {{ data.language.rock.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'todos' } }]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <terra-icon icon="to-dos" />
            {{ data.language.todo.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'issues' } }]"
            routerLinkActive="active">
            <terra-icon icon="issues" />
            {{ data.language.issue.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'meetings' } }]"
            routerLinkActive="active">
            <terra-icon icon="meetings" />
            {{ data.language.meeting.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'headlines' } }]"
            routerLinkActive="active">
            <terra-icon icon="headlines" />
            {{ data.language.headline.items }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'vision' } }]"
            routerLinkActive="active">
            <terra-icon icon="vision" />
            {{ data.language.vto.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'chart' } }]"
            routerLinkActive="active"
            *ngIf="canAccessOrgChart$ | ngrxPush">
            <terra-icon icon="responsibilities" />
            {{ data.language.acChart.route }}
          </a>
          <a
            *ngIf="data.isManageeOrAbove"
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: '1-on-1' } }]"
            routerLinkActive="active">
            <terra-icon matTooltip="Feedback" icon="one-on-one" />
            {{ data.language.feedback.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'process' } }]"
            routerLinkActive="active">
            <terra-icon icon="process" matTooltip="Process" />
            {{ data.language.process.route }}
          </a>
          <a
            *ngIf="data.directoryV1"
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory' } }]"
            routerLinkActive="active">
            <terra-icon icon="directory" />
            {{ data.language.directory.route }}
          </a>
          <a
            *ngIf="data.directoryV2"
            mat-menu-item
            data-product="main-sidenav__directory-v2-link"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory-v2' } }]"
            routerLinkActive="active">
            <terra-icon icon="directory" />
            {{ data.language.directory.route + data.directoryV1 ? ' V2' : '' }}
          </a>
          <a
            mat-menu-item
            *ngIf="data.companySettings?.mastery.enabled && !stateService.isLiteUser"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'mastery' } }]"
            routerLinkActive="active">
            <terra-icon icon="mastery" />
            {{ data.language.mastery.route }}
          </a>
          <a
            mat-menu-item
            *ngIf="data.enableLearningModule"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'knowledge' } }]"
            routerLinkActive="active">
            <terra-icon icon="learn" />
            Knowledge
          </a>
          <a
            mat-menu-item
            *ngIf="data.enableSurveys"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'assessments' } }]"
            routerLinkActive="active">
            <terra-icon icon="surveys" />
            {{ data.language.survey.route }}
          </a>
          <terra-divider />
          <ninety-settings-menu
            class="settings-menu-extended"
            (settingLinkClicked)="handleSettingLinkClicked()"></ninety-settings-menu>
          <a mat-menu-item (click)="logout()">
            <terra-icon icon="sign-out" />
            Log Out
          </a>
        </div>
      </ng-template>
    </mat-menu>

    <div>
      <h1
        fxHide.lt-sm
        attr.data-cy-dynamic="top-toolbar_page-title__{{ data.pageTitle }}"
        data-cy="top-toolbar_page-title"
        class="page-title terrafy-top-toolbar__title"
        [class.no-border-left]="meetingStarted">
        <span [innerHTML]="data.pageTitle | unsafeHtml"></span>
        <span *ngIf="data.meetingTeam as team">- {{ team?.name }}</span>
      </h1>
      <p fxHide.lt-sm class="terrafy-top-toolbar__description">{{ _getPageDescription(data.pageTitle) }}</p>
    </div>
    <mat-chip-list
      data-cy="top-toolbar_chip-message"
      fxHide.lt-sm="true"
      *ngIf="data.chipMessage as message"
      matTooltip="Shared Configurations and companies that have prevented their coach control are Read Only."
      matTooltipClass="ninety-tooltip">
      <mat-chip selected color="accent" class="ninety-chip">{{ message }}</mat-chip>
    </mat-chip-list>
    <div class="fill-remaining-space"></div>

    <ng-container *ngIf="data.currentMeeting && data.participants?.userIds?.length > 0">
      <terra-avatar
        *ngIf="
          data.currentMeeting.presenterUserId && store.select(selectUserAvatarInfo(data.currentMeeting.presenterUserId))
            | ngrxPush as avatar
        "
        class="meeting-user"
        [initials]="avatar.userInitials"
        [src]="avatar.pictureURL" />

      <div *ngIf="data.participants?.usernames?.length > 0" class="presence-container">
        <ng-container *ngFor="let userId of data.participants.userIds; let i = index">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(userId)) | ngrxPush as avatar"
            class="meeting-user"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL" />
        </ng-container>
        <div
          [matTooltip]="data.participants.usernames.join(' \n ')"
          *ngIf="data.participants.usernames.length > 0"
          matTooltipClass="ninety-tooltip multiline-tooltip"
          matTooltipPosition="above"
          ninetyStopPropagation
          class="d-print-none more-participants">
          <span class="user-initials"> + {{ data.participants.usernames.length }} </span>
        </div>
        <ninety-button
          class="claim-presenter-button"
          stroked
          *ngIf="data.currentUserId !== data.currentMeeting.presenterUserId && data.isManageeOrAbove"
          (click)="openChangePresenterDialog()">
          <terra-icon icon="microphone" aria-hidden="true" /> Claim Presenter
        </ninety-button>
      </div>
    </ng-container>

    <ng-container *ngIf="data.currentConversationMeeting">
      <div *ngIf="data.currentConversationMeeting.occupants?.length > 0" class="presence-container">
        <ng-container *ngFor="let userId of data.currentConversationMeeting.occupants">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(userId)) | ngrxPush as avatar"
            class="meeting-user"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL" />
        </ng-container>
      </div>
    </ng-container>

    <ninety-universal-create-btn
      *ngIf="data.isManageeOrAbove"
      [disabled]="
        data.disableNavLinks ||
        data.isFetchingData ||
        data.isObserver ||
        !(userTeams$ | async)?.length ||
        !!data.createDialogIsOpen
      "
      [locked]="!(universalCreateFeature$ | ngrxPush)"
      (openUniversalCreateModal)="openUniversalCreateModal()" />
  </header>

  <!-- Legacy Mat Toolbar -->
  <mat-toolbar
    *ngIf="!data.enableTerrafyToolbarsFeatureFlag && data.currentPerson && !data.toolBarHidden"
    class="top-toolbar"
    [class.has-box-shadow]="data.mainToolbarHasShadow !== false">
    <button
      data-cy="top-toolbar_toggle-meeting-side-nav-button"
      *ngIf="meetingStarted"
      mat-icon-button
      (click)="stateService.toggleMeetingSideNav(!stateService.toggleMeetingSideNavB$.value)">
      <terra-icon icon="list" />
    </button>
    <!-- Mobile Navigation Menu Trigger -->
    <button
      data-cy="top-toolbar_hamburger-menu-button"
      *ngIf="!(meetingStarted || data.isEditing)"
      #responsiveMenuTrigger
      class="menu-button-hamburger"
      mat-icon-button
      [matMenuTriggerFor]="responsiveNavMenu">
      <terra-icon icon="list" />
    </button>
    <!-- Mobile Navigation Menu -->
    <mat-menu #responsiveNavMenu="matMenu">
      <ng-template matMenuContent>
        <div class="responsive-nav-menu">
          <ninety-company-menu [menuItem]="true"></ninety-company-menu>
          <a
            mat-menu-item
            [routerLink]="[{ outlets: { detail: null, primary: 'partner-hub' } }]"
            routerLinkActive="active"
            *ngIf="data.currentPerson?.partnerType">
            <terra-icon icon="users" />
            Partner Hub
          </a>
          <a
            mat-menu-item
            [href]="'/dashboard'"
            *ngIf="data.companySettings.dashboard"
            [class.disabled]="data.disableNavLinks">
            <terra-icon icon="dashboard" />
            {{ data.language.dashboard.route }}
          </a>
          <a
            mat-menu-item
            [routerLink]="[{ outlets: { detail: null, primary: 'home' } }]"
            routerLinkActive="active"
            [class.disabled]="data.disableNavLinks">
            <terra-icon icon="my-90" />
            {{ data.language.my90.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'insights' } }]"
            routerLinkActive="active">
            <terra-icon icon="lightbulb" />
            <!-- TODO: Custom Language -->
            Insights
          </a>
          <!-- Scorecard Route -->
          <div class="beta-menu-item">
            <a
              [attr.data-product]="'top-toolbar__scorecard-beta-link'"
              [class.disabled]="data.disableNavLinks"
              [title]="data.language.scorecard.route"
              data-cy="top-toolbar_data"
              iconKey="data"
              mat-menu-item
              [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'data-v2' } }]"
              routerLinkActive="active">
              <terra-icon icon="data" />
              {{ data.language.scorecard.route }}
            </a>
          </div>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="
              data.disableNavLinks
                ? null
                : [{ outlets: { detail: null, primary: data.rocksV3 ? 'rocks-v3' : 'rocks' } }]
            "
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <terra-icon icon="rocks" />
            {{ data.language.rock.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'todos' } }]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <terra-icon icon="to-dos" />
            {{ data.language.todo.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'issues' } }]"
            routerLinkActive="active">
            <terra-icon icon="issues" />
            {{ data.language.issue.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'meetings' } }]"
            routerLinkActive="active">
            <terra-icon icon="meetings" />
            {{ data.language.meeting.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'headlines' } }]"
            routerLinkActive="active">
            <terra-icon icon="headlines" />
            {{ data.language.headline.items }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'vision' } }]"
            routerLinkActive="active">
            <terra-icon icon="vision" />
            {{ data.language.vto.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'chart' } }]"
            routerLinkActive="active"
            *ngIf="canAccessOrgChart$ | ngrxPush">
            <terra-icon icon="responsibilities" />
            {{ data.language.acChart.route }}
          </a>
          <a
            *ngIf="data.isManageeOrAbove"
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: '1-on-1' } }]"
            routerLinkActive="active">
            <terra-icon matTooltip="Feedback" icon="one-on-one" />
            {{ data.language.feedback.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'process' } }]"
            routerLinkActive="active">
            <terra-icon icon="process" matTooltip="Process" />
            {{ data.language.process.route }}
          </a>
          <a
            *ngIf="data.directoryV1"
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory' } }]"
            routerLinkActive="active">
            <terra-icon icon="directory" />
            {{ data.language.directory.route }}
          </a>
          <a
            *ngIf="data.directoryV2"
            mat-menu-item
            data-product="main-sidenav__directory-v2-link"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory-v2' } }]"
            routerLinkActive="active">
            <terra-icon icon="directory" />
            {{ data.language.directory.route + data.directoryV1 ? ' V2' : '' }}
          </a>
          <a
            mat-menu-item
            *ngIf="data.companySettings?.mastery.enabled && !stateService.isLiteUser"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'mastery' } }]"
            routerLinkActive="active">
            <terra-icon icon="mastery" />
            {{ data.language.mastery.route }}
          </a>
          <a
            mat-menu-item
            *ngIf="data.enableLearningModule"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'knowledge' } }]"
            routerLinkActive="active">
            <terra-icon icon="learn" />
            Knowledge
          </a>
          <a
            mat-menu-item
            *ngIf="data.enableSurveys"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'assessments' } }]"
            routerLinkActive="active">
            <terra-icon icon="surveys" />
            {{ data.language.survey.route }}
          </a>
          <terra-divider />
          <ninety-settings-menu
            class="settings-menu-extended"
            (settingLinkClicked)="handleSettingLinkClicked()"></ninety-settings-menu>
          <a mat-menu-item (click)="logout()">
            <terra-icon icon="sign-out" />
            Log Out
          </a>
        </div>
      </ng-template>
    </mat-menu>

    <h1
      fxHide.lt-sm
      attr.data-cy-dynamic="top-toolbar_page-title__{{ data.pageTitle }}"
      data-cy="top-toolbar_page-title"
      class="page-title"
      [class.no-border-left]="meetingStarted">
      <span [innerHTML]="data.pageTitle | unsafeHtml"></span>
      <span *ngIf="data.meetingTeam as team">- {{ team?.name }}</span>
    </h1>
    <mat-chip-list
      data-cy="top-toolbar_chip-message"
      fxHide.lt-sm="true"
      *ngIf="data.chipMessage as message"
      matTooltip="Shared Configurations and companies that have prevented their coach control are Read Only."
      matTooltipClass="ninety-tooltip">
      <mat-chip selected color="accent" class="ninety-chip">{{ message }}</mat-chip>
    </mat-chip-list>
    <div class="fill-remaining-space"></div>

    <div *ngIf="data.currentMeeting && data.participants?.userIds?.length > 0" class="presence-container">
      <ng-container *ngFor="let userId of data.participants.userIds">
        <div class="avatar-container" [class.presenter]="userId === data.currentMeeting.presenterUserId">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(userId)) | ngrxPush as avatar"
            class="meeting-user"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL"
            [ninetyContactCardHover]="userId"
            orientation="below" />
          <terra-icon
            *ngIf="userId === data.currentMeeting.presenterUserId"
            icon="microphone"
            variant="fill"
            size="16"
            class="presenter-icon" />
        </div>
      </ng-container>
      <div
        *ngIf="data.participants?.usernames?.length > 0"
        [matTooltip]="data.participants.usernames.join(' \n ')"
        matTooltipClass="ninety-tooltip multiline-tooltip"
        matTooltipPosition="above"
        ninetyStopPropagation
        class="d-print-none more-participants">
        <span class="user-initials"> + {{ data.participants.usernames.length }} </span>
      </div>
      <ninety-button
        class="claim-presenter-button"
        stroked
        *ngIf="data.currentUserId !== data.currentMeeting.presenterUserId && data.isManageeOrAbove"
        (click)="openChangePresenterDialog()">
        <terra-icon icon="microphone" aria-hidden="true" /> Claim Presenter
      </ninety-button>
    </div>

    <ng-container *ngIf="data.currentConversationMeeting">
      <div *ngIf="data.currentConversationMeeting.occupants?.length > 0" class="presence-container">
        <ng-container *ngFor="let userId of data.currentConversationMeeting.occupants">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(userId)) | ngrxPush as avatar"
            class="meeting-user"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL" />
        </ng-container>
      </div>
    </ng-container>

    <ninety-universal-create-btn
      *ngIf="data.isManageeOrAbove"
      [disabled]="
        data.disableNavLinks ||
        data.isFetchingData ||
        data.isObserver ||
        !(userTeams$ | async)?.length ||
        !!data.createDialogIsOpen
      "
      [locked]="!(universalCreateFeature$ | ngrxPush)"
      (openUniversalCreateModal)="openUniversalCreateModal()" />
  </mat-toolbar>
</ng-container>
