import { Cadence } from './cadence.enum';

export interface AddMeetingScheduleModel {
  teamId: string;
  meetingType: string;
  agendaId: string;
  presenterId: string;
  scribeId: string;
  cadence: Cadence;
  cadenceStartDate: Date;
  lastWeekOfMonth: boolean;
  parentId: string;
}
