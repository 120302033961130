import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraCounterModule } from '@ninety/terra';

import { IsNumberPipe } from '../../_shared/pipes/is-number';

import { CardFooterComponent } from './card-footer.component';
import { CardHeaderComponent } from './card-header.component';
import { CardTitleWithCountComponent } from './card-title-with-count.component';
import { CardComponent } from './card.component';

const public_api = [CardComponent, CardHeaderComponent, CardFooterComponent, CardTitleWithCountComponent];

@NgModule({
  imports: [CommonModule, CdkScrollableModule, TerraCounterModule, IsNumberPipe],
  declarations: [...public_api],
  exports: [...public_api],
})
export class CardModule {}
