import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';

import { MeetingsFacade } from '../../meetings.facade';
import { PastMeetingsTableComponent } from '../past-meetings-table/past-meetings.component';

@Component({
  selector: 'ninety-past-meetings-page',
  standalone: true,
  imports: [CommonModule, PastMeetingsTableComponent],
  template: ` <ninety-past-meetings></ninety-past-meetings> `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PastMeetingsPageComponent implements OnInit {
  facade = inject(MeetingsFacade);

  ngOnInit(): void {
    this.facade.pastMeetingsMeetingTypeSelected({ id: null, displayText: 'all', selected: true });
  }
}
