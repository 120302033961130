/* eslint-disable @angular-eslint/no-host-metadata-property */
import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { StopPropagationDirective } from '@ninety/ui/legacy/shared/directives/stop-propagation.directive';

import { OptionListDirective } from '../../directives/option-list.directive';

@Component({
  selector: 'ninety-select-clear-button',
  standalone: true,
  imports: [ButtonComponent, NgIf, TerraIconModule, StopPropagationDirective],
  template: `
    <ninety-button flat ninetyStopPropagation icon *ngIf="optionList?.canBeCleared" (click)="optionList?.clear()">
      <terra-icon [icon]="optionList?.multiple ? 'delete' : 'x'" size="20" />
    </ninety-button>
  `,
  styles: [
    `
      @import '../../styles';

      :host {
        @include style-select-button;
      }
    `,
  ],
})
export class SelectClearButtonComponent<T = unknown> implements OnChanges, OnDestroy {
  @Input() optionList: OptionListDirective<T>;

  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    // Ensures that changes to multi/single after initialization are detected
    if (changes.optionList) {
      if (this.subscription) this.subscription.unsubscribe();

      this.subscription = this.optionList.changeStream.subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
