import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { filterForChanges } from '@ninety/ui/legacy/shared/functions/filter-for-changes';
import { PersonMetadata, PersonMetadataUpdate } from '@ninety/ui/legacy/shared/models/_shared/person-metadata';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { Page } from '@ninety/ui/legacy/shared/models/user/page-views';
import { GuideActions } from '@ninety/web/pages/getting-started/guide/_state/guide.actions';

@Component({
  selector: 'ninety-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit, OnDestroy {
  hasChanges = false;
  userInfo: Partial<User>;
  userInfoCopy: Partial<User>;
  metadata: PersonMetadata;
  metadataCopy: PersonMetadata;
  userChanged = false;
  metadataChanged = false;
  isValid = false;
  selectedTabName = 'personal';
  subscriptions = new Subscription();

  tabMap: { [key: string]: number } = {
    personal: 0,
    metrics: 1,
    contact: 2,
    social: 3,
  };

  constructor(
    public userService: UserService,
    public stateService: StateService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.stateService.currentUser$.pipe(filter(currentUser => !!currentUser)).subscribe((currentUser: User) => {
        const { _id, bio, title, phoneNumbers, emailAddresses } = currentUser;

        this.userInfo = { _id, bio, title, phoneNumbers, emailAddresses };
        this.userInfoCopy = _cloneDeep(this.userInfo);

        this.metadata = currentUser.metadata;
        this.metadataCopy = _cloneDeep(currentUser.metadata);
      })
    );

    this.retainTabOnRefresh();

    this.store.dispatch(GuideActions.pageViewed({ page: Page.userSettings }));
  }

  retainTabOnRefresh() {
    this.activatedRoute.queryParams.subscribe(params => {
      const tabName = params['tab'];
      if (tabName !== undefined) {
        this.selectedTabName = tabName;
      } else {
        //make sure by default tab name is in url
        this.tabChanged(this.selectedTabName);
      }

      //This is required so the tab loads promptly on change
      this.cdr.markForCheck();
    });
  }

  tabChanged(tabName: string): void {
    this.selectedTabName = tabName;
    this.router.navigate([], {
      queryParams: { tab: tabName },
      queryParamsHandling: 'merge',
    });
  }

  getTabNameByIndex(index: number): string {
    for (const tabName in this.tabMap) {
      if (this.tabMap[tabName] === index) {
        return tabName;
      }
    }
    return 'personal'; // Default to 'personal' if index is not found
  }

  getTabIndexByName(tabName: string): number {
    return this.tabMap[tabName] || 0; // Default to 0 if tabName is not found
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateMetadata(update: Partial<PersonMetadata>): void {
    _merge(this.metadata, _cloneDeep(update));
    this.checkForChanges();
  }

  updateUser(update: Partial<User>): void {
    _merge(this.userInfo, _cloneDeep(update));
    this.checkForChanges();
  }

  checkIfValid(): void {
    this.isValid = !this.userInfo.emailAddresses.some(e => !HelperService.Regex.email.test(e.email));
  }

  checkForChanges(): void {
    this.checkIfValid();
    this.metadataChanged = !_isEqual(this.metadata, this.metadataCopy);
    this.userChanged = !_isEqual(this.userInfo, this.userInfoCopy);

    this.hasChanges = (this.metadataChanged || this.userChanged) && this.isValid;
  }

  save(): void {
    const updates: PersonMetadataUpdate = {};
    if (this.metadataChanged) updates.metadataUpdate = filterForChanges(_cloneDeep(this.metadata), this.metadataCopy);
    if (this.userChanged) updates.userUpdate = filterForChanges(_cloneDeep(this.userInfo), this.userInfoCopy);
    this.userService.updateUserAndPersonMetadata(updates).subscribe({
      next: () => {
        this.checkForChanges();
        this.hasChanges = false;
        this.cdr.markForCheck();
      },
    });
  }
}
