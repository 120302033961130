import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { TerraFormFieldModule, TerraSelectModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';

import { SeatToMoveToProjection } from './seat-move.component.model';

@Component({
  selector: 'ninety-seat-move',
  templateUrl: './seat-move.component.html',
  styleUrls: ['./seat-move.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    MatCardModule,
    MatDialogModule,
    MatInputModule,
    TerraFormFieldModule,
    TerraSelectModule,
    FormsModule,
    ButtonComponent,
  ],
  standalone: true,
})
export class SeatMoveComponent {
  /** Name of the seat to move */
  @Input() nameOfSeatToMove: string;
  /** Array of seats that this seat can be moved to. Projected model created by */
  @Input() seats: SeatToMoveToProjection[];

  @Input() language: CustomLanguage;

  /** Event emitted when the cancel button is clicked */
  @Output() cancel = new EventEmitter<void>();
  /** Event emitted when the move (save) button is clicked. Only possible when form is valid */
  @Output() move = new EventEmitter<string>();

  /** [(ngModel)] bound seat id representing the result of seat selection */
  selectedSeatId: string;
}
