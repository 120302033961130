import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Phase } from '../../models/phase';

@Component({
  selector: 'ninety-phase',
  templateUrl: './phase.component.html',
  styleUrls: ['./phase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhaseComponent {
  @Input() phase: Phase;
  @Input() previousPhaseTitle?: string;
}
