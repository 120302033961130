/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Directive } from '@angular/core';

import { HasErrorDirective } from '../../../directives/has-error.directive';

@Directive({
  selector: '[ninetyDescribeSub]',
  standalone: true,
})
export class DescribeSubContentDirective {}

@Directive({
  selector: '[ninetyDescribeSuper]',
  standalone: true,
})
export class DescribeSuperContentDirective {}

/**
 * A wrapper that can be added to any element to describe it. Intended to be used with the various ninetyInput
 * components, such as the select, text-field, and text-area.
 *
 * See storybook for examples.
 */
@Component({
  selector: 'ninety-describable-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  host: {
    '[class.has-internal-error]': 'error?.ninetyHasError',
    '[class.has-sub]': 'subMarker !== undefined',
    '[class.has-super]': 'superMarker !== undefined',
  },
  template: `
    <div class="super">
      <ng-content select="[ninetyDescribeSuper]"></ng-content>
    </div>
    <ng-content></ng-content>
    <div class="sub">
      <ng-content select="[ninetyDescribeSub]"></ng-content>
    </div>
  `,
  styles: [
    `
      @use 'terra';

      :host {
        &.has-internal-error .sub {
          color: terra.color(red);
        }

        &.has-sub .sub {
          margin-top: 4px;
        }

        &.has-super .super {
          margin-bottom: 4px;
        }
      }

      .super,
      .sub {
        // Font size is explicitly not set - by default, we expect body here. Even with other sizes, the 4px of padding
        // is expected to be sufficient.

        // Color is set by default. Override with your own styles when desired.
        color: terra.color(text, light);
      }
    `,
  ],
})
export class DescribableInputComponent {
  @ContentChild(HasErrorDirective) protected error?: HasErrorDirective;
  @ContentChild(DescribeSuperContentDirective) protected superMarker?: DescribeSuperContentDirective;
  @ContentChild(DescribeSubContentDirective) protected subMarker?: DescribeSubContentDirective;
}
