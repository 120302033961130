import { createSelector } from '@ngrx/store';

import { CurrentUserSelectors } from '@ninety/ui/legacy/state/app-entities/user-list/user-list-state.selectors';

import { DEFAULT_DIALOG_MODE } from '../../services/create-dialog.service';

export const selectUserDialogModePreference = createSelector(
  CurrentUserSelectors.selectUser,
  user => user.settings?.dialogModePreference || DEFAULT_DIALOG_MODE
);
