import { Update } from '@ngrx/entity';
import { UpdateStr } from '@ngrx/entity/src/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ChangeParentParams } from '@ninety/ui/legacy/components/tree/logic/move-node.logic';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';

import { SeatFormPatch } from '../../../logic/seat-form-patch.logic';
import { ToggleVisionaryResponse } from '../../../models/toggle-visionary-response';

export type ErrorProps = { message: string; error: unknown };

export const SeatActions = createActionGroup({
  source: 'Responsibilities - Seat',
  events: {
    /** When clicking on the move icon of a seat in the chart */
    'Open Change Parent Dialog': props<{ seat: SeatModel; childIds: string[] }>(),
    'Cancel Change Parent Dialog': emptyProps(),
    'Change Parent': props<ChangeParentParams>(),
    'Change Parent Success': props<ChangeParentParams>(),
    'Change Parent Failure': props<ErrorProps>(),

    /** Updates internal state */
    'Update One': props<{ update: Update<SeatModel> }>(),
    'Update Many': props<{ updates: Update<SeatModel>[] }>(),
    'Insert One': props<{ seat: SeatModel }>(),
    'Remove Many': props<{ ids: string[] }>(),

    /** Persists updates to the API */
    'Patch Updates': props<{ updates: Update<Omit<SeatModel, 'ordinal'>> }>(), // TODO better type
    'Patch Updates Success': emptyProps(),
    'Patch Updates Failure': props<ErrorProps>(),

    /** Persists updates to the API */
    'Patch Ordinal Updates': props<{ updates: Update<Pick<SeatModel, 'ordinal'>>[] }>(),
    'Patch Ordinal Updates Success': emptyProps(),
    'Patch Ordinal Updates Failure': props<ErrorProps>(),

    /**
     * When clicking on create child from a seat
     * @deprecated - v2 seat form logic only, replaced by {@link SeatDetailComponentStore}
     */
    'Open Create Dialog': props<{ parentSeatId: string }>(),
    /** @deprecated - v2 seat form logic only, replaced by {@link SeatDetailComponentStore} */
    'Cancel Create Dialog': emptyProps(),
    /** @deprecated - v2 seat form logic only, replaced by {@link SeatDetailComponentStore} */
    Create: props<{ seat: SeatModel }>(),

    /** Dispatched by the {@link SeatDetailComponentStore} after it has successfully completed its API requests. */
    'Create Success': props<{ seat: SeatModel }>(),

    /** When clicking on the clone icon of a seat in its context menu */
    Clone: props<{ id: string }>(),
    'Clone Success': props<{ seat: SeatModel }>(),
    'Clone Failure': props<ErrorProps>(),

    /**
     * When clicking on the edit/view icon of a seat in the chart. Used for readonly details and editing if capable
     * @deprecated - v2 seat form logic only, replaced by {@link SeatDetailComponentStore}
     */
    'Open Details Dialog': props<{ id: string }>(),
    /** @deprecated - v2 seat form logic only, replaced by {@link SeatDetailComponentStore} */
    'Detail Dialog Closed': emptyProps(),
    /** @deprecated - v2 seat form logic only, replaced by {@link SeatDetailComponentStore} */
    'Detail Dialog Closed With Updates': props<{ seat: SeatModel }>(),

    // V3 flows

    /** Open the v3 seat details dialog  */
    'Details Launch From Node': props<{ id: string }>(),

    /**
     * During normal flows, the seat node dispatches detailsLaunchFromNode. However, navigation events must also be
     * supported. This action is dispatched by the {@link SeatDetailComponent} when it is initialized with its routed
     * seatId. When viewing/editing a seat, this is the seatId and during create, this is always null.
     */
    'Details Register Selected Seat': props<{ id: string | null }>(),

    /**
     * Sets the id of the selected seat as well as all its descendants. Dispatched in response to changes in
     * detailSeatId
     */
    'Details Set Descendants': props<{ childrenIds: string[] }>(),

    /** Dispatched by the {@link SeatDetailComponentStore} when its routed id does not exist in global state */
    'Details Failed To Fetch Seat': props<{ error: unknown }>(),

    /** Dispatched whenever the details component is closed, via any mechanism */
    'Details Destroy': emptyProps(),

    /** Close the dialog. Can result in a state update if attachments were added or removed */
    'Details Closed': props<{ update?: UpdateStr<SeatModel> }>(),

    /**
     * Dispatched by {@link SeatDetailComponentStore} after it has successfully completed its API requests. Persists
     * the forms data in global state.
     */
    'Details Updated Successfully': props<{ update: UpdateStr<SeatModel>; patch: SeatFormPatch }>(),

    /** Delete a seat and all its children */
    'Open Delete Dialog': props<{ seatId: string }>(),
    'Cancel Delete Dialog': emptyProps(),
    Delete: props<{ seatId: string }>(),
    'Delete Success': props<{ seatId: string }>(),
    'Delete Failure': props<ErrorProps>(),

    'Transform Seat': props<{ seatId: string; itemType: ItemType.todo | ItemType.issue }>(),

    /** Add the visionary back to the chart after its been removed */
    'Show Visionary': emptyProps(),
    'Show Visionary Success': props<{ apiResponse: ToggleVisionaryResponse; integratorSeatId: string }>(),
    'Show Visionary Failure': props<ErrorProps>(),

    /** Hide the visionary from the chart */
    'Hide Visionary': emptyProps(),
    'Hide Visionary Success': props<{
      apiResponse: ToggleVisionaryResponse;
      visionarySeatId: string;
      integratorSeatId: string;
    }>(),
    'Hide Visionary Failure': props<ErrorProps>(),
  },
});
