/* eslint-disable @angular-eslint/no-output-rename, @angular-eslint/no-outputs-metadata-property */
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, TrackByFunction, ViewChild } from '@angular/core';
import { PushPipe } from '@ngrx/component';

import { TerraAvatarModule } from '@ninety/terra';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { UserIdToAvatarPipe } from '@ninety/ui/legacy/shared/pipes/user/user-id-to-avatar.pipe';
import { BoldSearchResultsPipe } from '@ninety/web/pages/accountability-chart/pipes/bold-search-results.pipe';

import { NinetyInputDirective } from '../../../text-input/directives/ninety-input.directive';
import { SelectBoxComponent } from '../../components/select-box/select-box.component';
import { SelectChipTemplateDirective } from '../../directives/select-chip-template.directive';
import { SelectImplementationDirective } from '../../directives/select-implementation.directive';
import { ManagedOptions } from '../../services/fuse-search.service.model';
import { FuseUserDirective } from '../../services/fuse-user.directive';
import { ChipSelectKitchenSinkComponent } from '../chip-select-kitchen-sink/chip-select-kitchen-sink.component';

@Component({
  selector: 'ninety-chip-user-select-box',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ChipSelectKitchenSinkComponent,
    BoldSearchResultsPipe,
    NgIf,
    SelectChipTemplateDirective,
    UserIdToAvatarPipe,
    PushPipe,
    TerraAvatarModule,
  ],
  hostDirectives: [
    {
      directive: SelectImplementationDirective,
      inputs: ['multiple', 'readonly', 'disabled', 'id', 'placeholder', 'noResultsText', 'selected: selectedUserIds'],
      outputs: ['selectedChange: selectedUserIdsChange'],
    },
    {
      directive: FuseUserDirective,
      inputs: ['ninetyFuseUser: users$', 'ninetyFuseSeatOptions: fuseOptions'],
    },
  ],
  template: `
    <ninety-chip-select-kitchen-sink [chipTemplate]="chipTemplate" [trackByFn]="trackBy">
      <ng-template let-option>
        <span
          *ngIf="option.match?.matches; else inline"
          [innerHTML]="option.value.fullName | boldSearchResults : option.match.matches">
        </span>
        <ng-template #inline>{{ option.value.fullName }}</ng-template>
      </ng-template>
    </ninety-chip-select-kitchen-sink>

    <ng-template #chipTemplate="ninetySelectChipTemplate" ninetySelectChipTemplate let-userId="value">
      <ng-container *ngIf="$any(userId) | userIdToAvatar | ngrxPush as avatar">
        <terra-avatar [src]="avatar.pictureURL" [initials]="avatar.userInitials" size="tiny" />
        {{ avatar.fullUserName }}
      </ng-container>
    </ng-template>
  `,
})
export class ChipUserSelectBoxComponent {
  @ViewChild(NinetyInputDirective) input: NinetyInputDirective;
  @ViewChild(SelectBoxComponent) selectBox: SelectBoxComponent;

  constructor(protected select: SelectImplementationDirective<string>) {}

  protected trackBy: TrackByFunction<ManagedOptions<User>> = (_, item) => item.value._id;
}
