import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { distinctUntilChanged, filter, skip } from 'rxjs';

import { TerraCheckboxModule, TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { NotifyIntersectDirective } from '@ninety/ui/legacy/components/tree/directives/notify-intersect.directive';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { BoldSearchResultsPipe } from '@ninety/web/pages/accountability-chart/pipes/bold-search-results.pipe';
import { AutoCompleteDirective } from '@ninety/web/pages/future-libs';
import { OptionListDirective } from '@ninety/web/pages/future-libs';
import { OptionDirective } from '@ninety/web/pages/future-libs';
import { SelectImplementationDirective } from '@ninety/web/pages/future-libs';
import { SelectLauncherDirective } from '@ninety/web/pages/future-libs';
import { ManagedOptions } from '@ninety/web/pages/future-libs';
import { FuseTeamDirective } from '@ninety/web/pages/future-libs';
import { TextInputWrapperComponent } from '@ninety/web/pages/future-libs';

/* eslint-disable @angular-eslint/no-outputs-metadata-property,@angular-eslint/no-output-rename */

@Component({
  selector: 'ninety-inline-teams-select',
  standalone: true,
  imports: [
    CommonModule,
    PushPipe,
    SelectLauncherDirective,
    BoldSearchResultsPipe,
    ButtonComponent,
    OptionDirective,
    OptionListDirective,
    TerraIconModule,
    AutoCompleteDirective,
    ButtonComponent,
    TextInputWrapperComponent,
    NotifyIntersectDirective,
    BoldSearchResultsPipe,
    FormsModule,
    TerraCheckboxModule,
    NinetyTooltipDirective,
    LetDirective,
  ],
  templateUrl: './inline-teams-select.component.html',
  styleUrls: ['./inline-teams-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: SelectImplementationDirective,
      inputs: ['multiple', 'readonly', 'disabled', 'id', 'placeholder', 'noResultsText', 'selected: selectedTeams'],
      outputs: ['selectedChange: selectedTeamIdsChanged'],
    },
    {
      directive: FuseTeamDirective,
      inputs: ['ninetyFuseTeam: teams$', 'ninetyFuseTeamOptions: fuseOptions'],
    },
  ],
})
export class InlineTeamsSelectComponent implements OnInit, AfterViewInit {
  @Output() saveTeams = new EventEmitter<Team[]>();

  @ViewChild(SelectLauncherDirective) protected selectLauncher: SelectLauncherDirective;
  @ViewChild(OptionListDirective, { read: ElementRef<HTMLElement> })
  protected optionListElement: ElementRef<HTMLElement>;

  /** for tooltip, easier to store and maintain here */
  protected teamNames: string;
  protected originalTeamNames: string;
  protected originalSelected: Team[];

  constructor(
    protected readonly select: SelectImplementationDirective<Team>,
    protected readonly fuseProvider: FuseTeamDirective,
    private renderer: Renderer2,
    private host: ElementRef,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.renderer.setAttribute(this.host.nativeElement, 'id', this.select.id);
    this.teamNames = this.select.selected.map(t => t.name).join(', ');
    this.originalTeamNames = this.teamNames;
    this.originalSelected = [...this.select.selected];
  }

  ngAfterViewInit() {
    this.fuseProvider.fuse.managedOptions$
      .pipe(
        distinctUntilChanged(),
        skip(1),
        // The option list is removed from the DOM when there is no data to display. Also, due to the observable nature of the data source,
        // the list might emit while the select is closed.
        filter(() => Boolean(this.optionListElement)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.optionListElement.nativeElement.scrollTo({ top: 0, behavior: 'smooth' }));
  }

  close() {
    this.selectLauncher.isOpen = false;
  }

  protected search(query: string) {
    this.fuseProvider.fuse.search({ query });
  }

  protected onSelectValue(teams: Team[]) {
    this.select.updateObserversOnValueChange(teams);
  }

  /** whether closed by clicking outside, or the ok button, it will emit selected teams */
  protected onOpenChange(isOpen: boolean) {
    if (!isOpen) {
      this.fuseProvider.fuse.search({ query: null });
      this.teamNames = this.select.selected.map(t => t.name).join(', ');
      if (this.select.selected.length > 0) {
        this.teamNames = this.select.selected.map(t => t.name).join(', ');
        this.saveTeams.emit(this.select.selected);
      } else {
        this.teamNames = this.originalTeamNames;
        this.select.selected = this.originalSelected;
      }
    }
  }

  protected readonly trackBy: TrackByFunction<ManagedOptions<Team>> = (_index, item) => item.value._id;
}
