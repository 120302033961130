import { MeetingType, User } from '@ninety/ui/legacy/shared/index';

import { Cadence } from './cadence.enum';

export class MeetingSchedule {
  _id: string;

  companyId: string;
  teamId: string;

  meetingType: MeetingType;
  presenterId: string;
  scribeId: string;

  agendaId: string;

  cadence: Cadence;
  cadenceStartDate: Date; //date and time of the meeting, will repeat based on cadence
  lastWeekOfMonth?: boolean; //if true, the meeting will be scheduled on the last matching day of the month, eg. last Friday, Thursday, etc.

  createdAt: Date;
  createdByUserId: string;

  parentId: string;

  presenterUser: Partial<User>;
  scribeUser: Partial<User>;

  agendaName: string;
  scheduledDate: Date;

  deleted: boolean;
}

export class MeetingScheduleResponse {
  _id: string;

  companyId: string;
  teamId: string;

  meetingType: MeetingType;
  presenterId: string;
  scribeId: string;

  agendaId: string;

  cadence: Cadence;
  cadenceStartDate: string; //date and time of the meeting, will repeat based on cadence
  lastWeekOfMonth?: boolean; //if true, the meeting will be scheduled on the last matching day of the month, eg. last Friday, Thursday, etc.

  createdAt: string;
  createdByUserId: string;

  parentId: string;

  presenterUser: Partial<User>;
  scribeUser: Partial<User>;

  agendaName: string;
  scheduledDate: string;

  deleted: boolean;
}
