<h1 mat-dialog-title>Re-enter your password</h1>
<div mat-dialog-content>
  <p>To edit some features in your account, please enter your password</p>
  <mat-form-field class="form-fields _terra-migration-approved-override--mat-form-field" appearance="outline">
    <input
      matInput
      autocomplete="password"
      placeholder="Password"
      type="password"
      data-cy="reenter_password-input"
      [formControl]="passwordControl"
      required />
    <mat-error *ngIf="passwordControl.errors?.required">Password required</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions class="submit-button-box" fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
  <button
    mat-raised-button
    color="accent"
    cdkFocusInitial
    data-cy="reenter_password-submit"
    [disabled]="passwordControl.invalid || (isLoading$ | async)"
    (click)="submitPassword()">
    Enter
  </button>
</div>
