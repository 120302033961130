import { Route } from '@angular/router';

import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';

import { DefaultTieredPageComponent } from './components/_default-tiered-page/default-tiered-page.component';
import { HeadlinesTieredPageComponent } from './components/headlines-tiered-page/headlines-tiered-page';
import { InsightsTieredPageComponent } from './components/insights-tiered-page/insights-tiered-page.component';
import { IssuesTieredPageComponent } from './components/issues-tiered-page/issues-tiered-page.component';
import { MeetingsTieredPageComponent } from './components/meetings-tiered-page/meetings-tiered-page.component';
import { RocksTieredPageComponent } from './components/rocks-tiered-page/rocks-tiered-page.component';
import { ScorecardTieredPageComponent } from './components/scorecard-tiered-page/scorecard-tiered-page.component';
import { TodosTieredPageComponent } from './components/todos-tiered-page/todos-tiered-page.component';

export const COMMERCIAL_MODEL_ROUTES: Route[] = [
  /** default tiered pages (no access on current tier) */
  {
    path: 'no-access',
    children: [
      {
        path: NinetyFeatures.issues,
        component: IssuesTieredPageComponent,
        data: { route: 'issue' },
      },
      {
        path: NinetyFeatures.rocks,
        component: RocksTieredPageComponent,
        data: { route: 'rock' },
      },
      {
        path: NinetyFeatures.todos,
        component: TodosTieredPageComponent,
        data: { route: 'todo' },
      },
      {
        path: NinetyFeatures.insights,
        component: InsightsTieredPageComponent,
        data: { title: 'Insights' },
      },
      { path: NinetyFeatures.weeklyMeetings, component: MeetingsTieredPageComponent, data: { title: 'Meetings' } },
      {
        path: NinetyFeatures.headlines,
        component: HeadlinesTieredPageComponent,
        data: { title: 'Headlines' },
      },
      {
        path: NinetyFeatures.scorecard,
        component: ScorecardTieredPageComponent,
        data: { route: 'scorecard' },
      },
      {
        path: ':feature',
        component: DefaultTieredPageComponent,
        data: { title: 'No Access' },
      },
      { path: '', component: DefaultTieredPageComponent, data: { title: 'No Access' } },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];
