import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs';

import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { DetailViewActions } from '@ninety/web/pages/detail-view/_state/detail-view.actions';

import { PersonalTodoActions } from '../personal/personal-todo.actions';
import { TeamTodoActions } from '../team/team-todo.actions';

import { TodoDetailActions } from './todo-detail.actions';

@Injectable()
export class TodoDetailEffects {
  constructor(private actions$: Actions) {}

  /**
   * Opens the todos card component detail when a _todo is selected
   */
  todoSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodoDetailActions.openInDetail),
      map(({ todo }) =>
        DetailViewActions.opened({
          config: { pathParts: [DetailType.todo, todo._id] },
          source: 'Todo Selected',
        })
      )
    )
  );

  closeDetailOnDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TeamTodoActions.deleteSuccess,
        TeamTodoActions.removeAcceptedTodoFromState,
        PersonalTodoActions.deleteSuccess
      ),
      map(() => DetailViewActions.close())
    )
  );

  clearSelectedOnClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.closed),
      filter(({ itemType }) => this.isTodoAction(itemType)),
      map(() => TodoDetailActions.clearSelectedTodo())
    )
  );

  private isTodoAction(itemType: DetailType) {
    return itemType === DetailType.todo;
  }
}
