import { ActionReducerMap, createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { TodoDetailChildStateKey, TodoDetailState } from './detail/todo-detail.model';
import { todoDetailReducers } from './detail/todo-detail.reducers';
import { PersonalTodoState } from './personal/personal-todo.model';
import { personalTodoReducer } from './personal/personal-todo.reducers';
import { TeamTodoState } from './team/team-todo.model';
import { teamTodoReducer } from './team/team-todo.reducers';

/**
 * Top level state key for Todos module.
 */
export const TodoRootStateKey = 'todo';

/**
 * Child state key of {@link TodoRootStateKey} for {@link PersonalTodoState}
 */
export const PersonalTodosChildStateKey = 'personal';

/**
 * Child state key of {@link TodoRootStateKey} for {@link TeamTodoState}
 */
export const TeamTodosChildStateKey = 'team';

/**
 * Union of possible child keys of {@link TodoRootStateKey}
 */
export type TodoChildStateKeyUnion = typeof TeamTodosChildStateKey | typeof PersonalTodosChildStateKey;

/**
 * Top level model of Todos state.
 */
export interface TodoRootFeatureState {
  [PersonalTodosChildStateKey]: PersonalTodoState;
  [TeamTodosChildStateKey]: TeamTodoState;
  [TodoDetailChildStateKey]: TodoDetailState;
}

/**
 * Feature selector for root level Todos state
 */
export const selectTodoRootState = createFeatureSelector<TodoRootFeatureState>(TodoRootStateKey);

/**
 * Selects child of root state, {@link TeamTodoState}
 */
export const selectTeamTodoState: MemoizedSelector<TodoRootFeatureState, TeamTodoState> = createSelector(
  selectTodoRootState,
  state => state[TeamTodosChildStateKey]
);

/**
 * Selects child of root state, {@link PersonalTodoState}
 */
export const selectPersonalTodoState: MemoizedSelector<TodoRootFeatureState, PersonalTodoState> = createSelector(
  selectTodoRootState,
  state => state[PersonalTodosChildStateKey]
);

/**
 * Selects child of root state, {@link TodoDetailState}
 */
export const selectTodoDetailState: MemoizedSelector<TodoRootFeatureState, TodoDetailState> = createSelector(
  selectTodoRootState,
  state => state[TodoDetailChildStateKey]
);

/**
 * Reducer map combining all the children of {@link TodoRootStateKey}
 */
export const todoReducerMap: ActionReducerMap<TodoRootFeatureState> = {
  [PersonalTodosChildStateKey]: personalTodoReducer,
  [TeamTodosChildStateKey]: teamTodoReducer,
  [TodoDetailChildStateKey]: todoDetailReducers,
};

/**
 * Map of child state key to "child of root state" selector
 */
export const selectTodoStateByChildKey = {
  [PersonalTodosChildStateKey]: selectPersonalTodoState,
  [TeamTodosChildStateKey]: selectTeamTodoState,
  [TodoDetailChildStateKey]: selectTodoDetailState,
};
