import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { LinkedItemTypeEnum, linkedItemTypeToDetailTypesMap } from '@ninety/ui/legacy/shared/index';
import { LinkedItemMessageType } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-message-type';
import {
  LinkedItemFromRealtime,
  UnlinkedItemFromRealtime,
} from '@ninety/ui/legacy/shared/models/linked-items/linked-items.model';
import * as UserSelectors from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { RealTimeActions } from '@ninety/ui/legacy/state/app-global/real-time/real-time.actions';
import { selectIsMyNinetyUrl, selectRouteIsAnyOfDetailType } from '@ninety/ui/legacy/state/route.selectors';

import { LinkedItemsService } from '../linked-items.service';

import { LinkedItemsActions } from './linked-items.actions';
import { LinkedItemsSelectors } from './linked-items.selectors';

@Injectable()
export class LinkedItemsEffects {
  constructor(
    private actions$: Actions,
    private linkedItemsService: LinkedItemsService,
    private store: Store,
    private channelService: ChannelService
  ) {}

  getLinkedItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedItemsActions.getLinkedItem),
      concatLatestFrom(() => [this.store.select(UserSelectors.selectUserEntities)]),
      switchMap(([{ id, linkedItemType }, users]) =>
        this.linkedItemsService.getLinkedItems(linkedItemType, id).pipe(
          map(linkedItems => {
            linkedItems.todos = this.mapItemsWithUser(users, linkedItems.todos, 'userId');
            linkedItems.issues = this.mapItemsWithUser(users, linkedItems.issues, 'userId');
            linkedItems.rocks = this.mapItemsWithUser(users, linkedItems.rocks, 'userId');
            linkedItems.headlines = this.mapItemsWithUser(users, linkedItems.headlines, 'ownedByUserId');
            linkedItems.cascadingMessages = this.mapItemsWithUser(
              users,
              linkedItems.cascadingMessages,
              'ownedByUserId'
            );
            linkedItems.learningTasks = this.mapItemsWithUser(users, linkedItems.learningTasks || [], 'ownedByUserId');
            linkedItems.learningTopics = this.mapItemsWithUser(
              users,
              linkedItems.learningTopics || [],
              'ownedByUserId'
            );
            linkedItems.milestones = this.mapItemsWithUser(users, linkedItems.milestones, 'ownedByUserId');
            return LinkedItemsActions.getLinkedItemSuccess({ response: linkedItems });
          }),
          catchError((error: unknown) => of(LinkedItemsActions.getLinkedItemFailure({ error })))
        )
      )
    )
  );

  unlinkItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedItemsActions.unlinkItem),
      switchMap(({ id, linkedItemType }) =>
        this.linkedItemsService.deleteLinkedItem(id).pipe(
          map(_ => LinkedItemsActions.unlinkItemSuccess({ id, linkedItemType })),
          catchError((error: unknown) => of(LinkedItemsActions.unlinkItemFailure({ error })))
        )
      )
    )
  );

  broadcastUnlinkedItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedItemsActions.unlinkItemSuccess),
      concatLatestFrom(() => [this.store.select(LinkedItemsSelectors.selectId)]),
      map(([{ id, linkedItemType }, _id]) =>
        LinkedItemsActions.broadcastMessage({
          message: {
            messageType: LinkedItemMessageType.unlinkItem,
            document: {
              id: _id,
              linkedItemType,
              linkedItemId: id,
            },
          },
        })
      )
    )
  );

  broadcastLinkedItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedItemsActions.createdLinkedItem),
      map(({ linkedItem: { id, linkedItemType, linkedItemId, linkedItem } }) =>
        LinkedItemsActions.broadcastMessage({
          message: {
            messageType: LinkedItemMessageType.linkedItem,
            document: {
              id,
              linkedItemType,
              linkedItemId,
              linkedItem,
            },
          },
        })
      )
    )
  );

  broadcastMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedItemsActions.broadcastMessage),
      concatLatestFrom(() => [
        this.store.select(LinkedItemsSelectors.selectShouldBroadcast),
        this.store.select(LinkedItemsSelectors.selectChannelId),
      ]),
      filter(([, shouldBroadcast]) => shouldBroadcast),
      switchMap(([{ message }, , channelId]) =>
        this.channelService.sendMessage(channelId, cloneDeep(message)).pipe(
          map(_ => LinkedItemsActions.broadcastMessageSuccess()),
          catchError((error: unknown) => of(LinkedItemsActions.broadcastMessageFailure({ error })))
        )
      )
    )
  );

  createdLinkedItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedItemsActions.createdLinkedItem),
      concatLatestFrom(({ createdFrom }) => [
        this.store.select(LinkedItemsSelectors.selectId),
        this.store.select(selectRouteIsAnyOfDetailType(linkedItemTypeToDetailTypesMap[createdFrom])),
        this.store.select(selectIsMyNinetyUrl),
      ]),
      filter(
        ([{ linkedItem }, _id, isType, isMy90]) =>
          linkedItem.id === _id && linkedItem.linkedItemType !== LinkedItemTypeEnum.todo && (isType || isMy90)
      ),
      map(([{ linkedItem }]) => LinkedItemsActions.displayCreatedLinkedItem({ linkedItem }))
    )
  );

  realtimeReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RealTimeActions.messageReceived),
      concatLatestFrom(() => [this.store.select(LinkedItemsSelectors.selectChannelId)]),
      filter(
        ([{ channelId, message }, currentChannelId]) =>
          channelId === currentChannelId &&
          (message.messageType === LinkedItemMessageType.linkedItem ||
            message.messageType === LinkedItemMessageType.unlinkItem)
      ),
      concatLatestFrom(() => [this.store.select(LinkedItemsSelectors.selectId)]),
      filter(([[{ message }], _id]) => (message.document as UnlinkedItemFromRealtime).id === _id),
      map(([[{ message }], _id]) => {
        if (message.messageType === LinkedItemMessageType.unlinkItem) {
          const mess = message.document;
          return LinkedItemsActions.unlinkItemFromRealtime({
            id: mess.linkedItemId,
            linkedItemType: mess.linkedItemType,
          });
        } else {
          return LinkedItemsActions.linkItemFromRealtime({
            linkedItem: message.document as LinkedItemFromRealtime,
          });
        }
      })
    )
  );

  private mapItemsWithUser = (users, items, userIdKey) =>
    items.map(item => {
      const user = users[item[userIdKey]];
      return { ...item, user };
    });
}
