import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs';

import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { MeetingMessageAction } from '@ninety/ui/legacy/shared/index';
import { selectCompanyId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { RealTimeActions } from '@ninety/ui/legacy/state/app-global/real-time/real-time.actions';

import {
  MeetingDialogActions,
  MeetingRealTimeActions,
  MeetingsPageActions,
  MeetingStateActions,
} from '../meetings.actions';
import { MeetingsStateSelectors } from '../meetings.selectors';

/**
 * Messages broadcast from the current users client to others
 */

@Injectable()
export class MeetingsRealTimeBroadcastEffects {
  private readonly actions$ = inject(Actions);
  private readonly channelService = inject(ChannelService);
  private readonly store = inject(Store);

  /** Subscribe to messages for the selected meeting type when joining a meeting */
  subscribeToMeetingChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsPageActions.getMeetingSuccess, MeetingsPageActions.resumeMeetingSuccess),
        concatLatestFrom(() => [this.store.select(MeetingsStateSelectors.selectCurrentMeeting)]),
        filter(([, meeting]) => !!meeting),
        switchMap(([, meeting]) => this.channelService.subscribeToChannel(`meeting-${meeting._id}`, true))
      ),
    { dispatch: false }
  );

  /**
   * Broadcast meeting start/stop/pause changes. These same changes are being broadcast
   * from the meeting service for now so we need to handle duplicate message -
   * but the logic there is too convoluted to unwind until everything is state.
   */

  /** Broadcast changes when starting/stopping/etc a meeting */
  meetingStateChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeetingDialogActions.createMeetingSuccess,
        MeetingsPageActions.getMeetingSuccess,
        MeetingsPageActions.resumeMeetingSuccess,
        MeetingRealTimeActions.meetingStateChange
      ),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectCurrentMeeting),
        this.store.select(selectCompanyId),
        this.store.select(MeetingsStateSelectors.selectTeamId),
      ]),
      map(([, meeting, companyId, teamId]) => {
        return RealTimeActions.sendMessage({
          channelId: companyId,
          message: {
            messageType: 'meeting-state-change',
            document: {
              meeting: {
                _id: meeting._id,
                type: meeting.type,
                inProgress: meeting.inProgress,
                paused: meeting.paused,
              },
              teamId,
            },
          },
        });
      })
    )
  );

  /** Broadcasts to users on the meetings page that a meeting has ended */
  deleteMeetingStateChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.deleteActiveMeetingSuccess),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectMeetingStatus),
        this.store.select(selectCompanyId),
        this.store.select(MeetingsStateSelectors.selectTeamId),
      ]),
      map(([, meeting, companyId, teamId]) => {
        return RealTimeActions.sendMessage({
          channelId: companyId,
          message: {
            messageType: 'meeting-state-change',
            document: {
              meeting: {
                _id: meeting._id,
                type: meeting.type,
                inProgress: false,
                paused: false,
              },
              teamId,
            },
          },
        });
      })
    )
  );

  /** Broadcasts to meeting attendees that the meeting has been deleted or suspended */
  broadcastMeetingDeletedOrSuspended$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.deleteActiveMeetingSuccess, MeetingStateActions.suspendMeeting),
      concatLatestFrom(() => [this.store.select(selectCompanyId)]),
      map(([{ type, meetingId }, companyId]) => {
        return RealTimeActions.sendMessage({
          channelId: companyId,
          message: {
            messageType:
              type === MeetingsPageActions.deleteActiveMeetingSuccess.type
                ? MeetingMessageAction.Delete
                : MeetingMessageAction.Suspended,
            document: meetingId,
          },
        });
      })
    )
  );
}
