import { createReducer, on } from '@ngrx/store';

import { BillingStateActions } from './billing-state.actions';
import { BillingState, InitialBillingState } from './billing-state.model';

export const BillingStateReducer = createReducer<BillingState>(
  InitialBillingState,
  on(
    BillingStateActions.getActiveSubscriptionConfigurationsSuccess,
    (state, { subscriptionConfigurations }): BillingState => ({
      ...state,
      subscriptionConfigurations,
    })
  ),
  on(
    BillingStateActions.getCompanyBillingCountsSuccess,
    (state, { companyBillingCounts }): BillingState => ({
      ...state,
      companyBillingCounts,
    })
  ),
  on(
    BillingStateActions.getUpcomingInvoiceSuccess,
    (state, { invoice }): BillingState => ({
      ...state,
      upcomingInvoice: invoice,
    })
  ),
  on(BillingStateActions.getCommercialModelSubscriptionsSuccess, (state, { pricingPlans }) => ({
    ...state,
    pricingPlans,
  }))
);
