import { TooltipPosition } from '@angular/material/tooltip';
import { createSelector } from '@ngrx/store';

import { RoleService } from '../../../_core/services/role.service';
import { DirectoryLanguage } from '../../../_shared';
import { RoleCode } from '../../../_shared/models/_shared/role-code';
import { getRolesMetadata } from '../../../_shared/models/_shared/role-metadata';
import { RoleName } from '../../../_shared/models/_shared/role-name';
import { RoleNameAsCode } from '../../../_shared/models/_shared/role-name-as-code';
import { RoleSelectOption } from '../../../_shared/models/_shared/role-select-option';
import { User } from '../../../_shared/models/_shared/user';
import { selectIsTrialingLegacy } from '../../app-global/company/company-state.selectors';
import { selectLanguage, selectLanguageArea } from '../../app-global/language/language.selectors';
import { selectCurrentUserId, selectNumberOfOwners, selectRawCurrentUser } from '../users/users-state.selectors';

export const selectRolesMetadata = createSelector(selectLanguage, language => getRolesMetadata(language));

export const selectRoleSelectData = createSelector(
  selectRolesMetadata,
  selectRawCurrentUser,
  selectIsTrialingLegacy,
  (rolesMetadata, currentUser, isTrialingLegacy): RoleSelectOption[] =>
    rolesMetadata.map(role => {
      const canAssign =
        currentUser.isImplementer ||
        RoleService.canUserAssignRoleCode({
          userRoleCode: currentUser.roleCode,
          otherRoleCode: role.roleCode,
        });
      const isItemDisabled =
        (!isTrialingLegacy &&
          currentUser.roleCode === RoleCode.observer &&
          RoleService.minRole.managee(role.roleCode) &&
          !role.isImplementer) ||
        !canAssign;
      return {
        ...role,
        cannotAssign: !canAssign,
        isItemDisabled,
        itemCssClass: !(role.roleCode == RoleCode.observer || role.isImplementer) ? 'paid-role' : '',
        text: role.title,
        tooltip: isItemDisabled ? `Add more licenses to update your teammate's Role` : '',
        tooltipPosition: 'left' as TooltipPosition,
        value: { title: role.title, roleCode: role.roleCode },
      } as RoleSelectOption;
    })
);

export const selectUserRole = (roleCode: RoleCode, isImplementer: boolean) =>
  createSelector(selectRoleSelectData, roles => {
    return roles.find(role => role.roleCode === roleCode && role.isImplementer === !!isImplementer);
  });

export const selectUserRoleByName = (role: string) =>
  createSelector(selectRoleSelectData, roles => {
    const roleCode = RoleNameAsCode[role.toLowerCase()] || RoleCode.managee;
    const isImplementer = role.toLowerCase() === RoleName.coach;
    return roles.find(r => r.isImplementer === isImplementer && r.roleCode === roleCode);
  });

export const selectCannotEditOwnerToolTip = (user: User) =>
  createSelector(
    selectNumberOfOwners,
    selectCurrentUserId,
    selectLanguageArea('directory'),
    (numberOfOwners, currentUserId, { owner }: DirectoryLanguage): string => {
      if (user.roleCode !== RoleCode.owner || numberOfOwners > 1) return '';
      if (user._id === currentUserId) {
        return `You cannot change your role because you are the only ${owner}.
      You must first make someone else an ${owner}`;
      }
      return `You cannot change this role because they are the only ${owner}`;
    }
  );

/** can change user role, not if can edit user */
/*
export const selectCanChangeUserRole = (userToEdit: User) =>
  createSelector(selectCurrentUser, selectNumberOfOwners, (currentUser, numOwners) =>
    RoleService.canChangeUserRole(currentUser, userToEdit, numOwners)
  );

export const selectCanEditUser = (userToEdit: User) =>
  createSelector(selectCurrentUser, currentUser => RoleService.canEditUser(currentUser, userToEdit));
*/
