<div class="container">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Personal Information</mat-card-title>
    </mat-card-header>

    <mat-card-content data-cy="personal_profile-container" class="personal-card">
      <ninety-avatar-uploader [metadata]="metadata" [userInfo]="userInfo"></ninety-avatar-uploader>

      <div class="personal-info">
        <div class="fields-dev">
          <div class="field-wrapper">
            <terra-form-field>
              <terra-form-label>First Name</terra-form-label>
              <terra-text-input
                data-cy="personal_first-name"
                [(ngModel)]="metadata.name.first"
                (ngModelChange)="debounceUpdate()"
                placeholder="First Name" />
            </terra-form-field>
          </div>
          <div class="field-wrapper">
            <terra-form-label>Last Name</terra-form-label>
            <terra-form-field>
              <terra-text-input
                data-cy="personal_last-name"
                [(ngModel)]="metadata.name.last"
                (ngModelChange)="debounceUpdate()"
                placeholder="Last Name" />
            </terra-form-field>
          </div>
        </div>
        <div class="fields-dev">
          <div class="field-wrapper">
            <terra-form-field optional="true">
              <terra-form-label>Pronouns</terra-form-label>
              <terra-text-input
                data-cy="personal_pronouns_input-field"
                [(ngModel)]="metadata.preferredPronouns"
                (ngModelChange)="debounceUpdate()"
                placeholder="Enter your pronouns"
                aria-label="Pronouns (Optional)" />
            </terra-form-field>
          </div>
          <div class="field-wrapper _terra-migration-approved-override--terra-mat-form-field">
            <terra-form-label
              >Date of Birth
              <span class="_terra-migration-approved-override--terra-form-optional">(optional)</span></terra-form-label
            >
            <mat-form-field class="_terra-migration-approved-override--mat-form-field" appearance="outline">
              <input
                data-cy="personal_date-of-birth"
                matInput
                [(ngModel)]="metadata.dateOfBirth"
                (click)="dateOfBirth.open()"
                (ngModelChange)="updateMetadata.emit(this.metadata)"
                [matDatepicker]="dateOfBirth"
                placeholder="Select your date of birth"
                aria-label="Date of Birth (Optional)" />
              <mat-datepicker-toggle matSuffix [for]="dateOfBirth">
                <terra-icon matDatepickerToggleIcon icon="calendar" size="16" />
              </mat-datepicker-toggle>
              <mat-datepicker #dateOfBirth></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="fields-dev">
          <div class="field-wrapper">
            <terra-form-field>
              <terra-form-label>Title</terra-form-label>
              <terra-text-input
                maxlength="100"
                [(ngModel)]="userInfo.title"
                (ngModelChange)="updateUser.emit({ title: $event })"
                placeholder="Enter a title..."
                aria-label="Title" />
              <terra-description>Your title will only be visible to {{ companyName$ | async }}.</terra-description>
            </terra-form-field>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>
        Biography
        <small class="muted">(For this company only)</small>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ninety-text-editor
        placeholder="Bio..."
        data-cy="personal_biography_container"
        [(text)]="userInfo.bio"
        (textChange)="updateUser.emit({ bio: $event })">
      </ninety-text-editor>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Address</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="field-wrapper">
        <terra-form-field>
          <terra-form-label>Street</terra-form-label>
          <terra-text-input
            data-cy="personal_address-street_input-field"
            [(ngModel)]="metadata.streetAddress"
            (ngModelChange)="debounceUpdate()"
            placeholder="Street" />
        </terra-form-field>
      </div>
      <div class="fields-dev">
        <div class="field-wrapper">
          <terra-form-field>
            <terra-form-label>City</terra-form-label>
            <terra-text-input
              data-cy="personal_address-city_input-field"
              [(ngModel)]="metadata.city"
              (ngModelChange)="debounceUpdate()"
              placeholder="City" />
          </terra-form-field>
        </div>
        <div class="field-wrapper">
          <terra-form-field>
            <terra-form-label>{{ userCountry?.province_label || 'Region' }}</terra-form-label>
            <terra-select
              data-cy="personal_address-region-dropdown-button"
              [(ngModel)]="metadata.province"
              [compareWith]="compareRegions"
              (selectionChange)="updateMetadata.emit(this.metadata)">
              <terra-option
                attr.data-cy="personal_address-region-{{ region.key }}"
                *ngFor="let region of userCountry?.province_codes | keyvalue"
                [value]="{ name: region.key, code: region.value }">
                {{ region.key }}
              </terra-option>
            </terra-select>
          </terra-form-field>
        </div>
      </div>
      <div class="fields-dev">
        <div class="field-wrapper">
          <terra-form-field>
            <terra-form-label>{{ userCountry?.zip_label || 'Postal Code' }}</terra-form-label>
            <terra-text-input
              data-cy="personal_address-postal-code_input-field"
              [(ngModel)]="metadata.postalCode"
              (ngModelChange)="debounceUpdate()"
              placeholder="Postal Code" />
          </terra-form-field>
        </div>
        <div class="field-wrapper">
          <terra-form-field>
            <terra-form-label>Country</terra-form-label>
            <terra-select
              data-cy="personal_address-country-dropdown-button"
              [ngModel]="metadata.country"
              [compareWith]="compareCountries"
              [maxHeight]="300"
              (selectionChange)="selectCountry($event)">
              <terra-option
                attr.data-cy="personal_address-country-{{ country.name }}"
                *ngFor="let country of Countries"
                [value]="country"
                >{{ country.name }}</terra-option
              >
            </terra-select>
          </terra-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Emergency Contact(s)</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-list class="contact-list">
        <mat-list-item
          *ngFor="let contact of metadata.emergencyContacts; let i = index; let first = first; trackBy: trackByIndex"
          [class.first]="first">
          <div class="field-wrapper flex2">
            <terra-form-label *ngIf="first">Name</terra-form-label>
            <terra-form-field>
              <terra-text-input
                data-cy="personal_emergency-contacts-name_input-field"
                [(ngModel)]="contact.name"
                (ngModelChange)="debounceUpdate()"
                placeholder="Name" />
            </terra-form-field>
          </div>
          <div class="field-wrapper">
            <terra-form-label *ngIf="first">Relationship</terra-form-label>
            <terra-form-field>
              <terra-text-input
                data-cy="personal_emergency-contacts-relationship_input-field"
                matInput
                [(ngModel)]="contact.relationship"
                (ngModelChange)="debounceUpdate()"
                placeholder="Relationship" />
            </terra-form-field>
          </div>
          <div class="field-wrapper">
            <terra-form-field>
              <terra-form-label *ngIf="first">Phone Number</terra-form-label>
              <terra-text-input
                data-cy="personal_emergency-contacts-phone-number_input-field"
                [(ngModel)]="contact.phoneNumber.number"
                (ngModelChange)="debounceUpdate()"
                placeholder="Phone Number" />
            </terra-form-field>
          </div>
          <ninety-button
            flat
            negative
            data-cy="personal_emergency-contacts_delete-contact-button"
            (click)="deleteEmergencyContact(i)"
            class="delete-btn">
            <terra-icon icon="delete" />
          </ninety-button>
        </mat-list-item>
      </mat-list>
      <ninety-button
        flat
        primary
        data-cy="personal_emergency-contacts_add-contact-button"
        mat-button
        color="accent"
        (click)="addEmergencyContact()">
        <terra-icon icon="add-circle" />
        Add Contact
      </ninety-button>
    </mat-card-content>
  </mat-card>
</div>
