import { PricingPlan } from '../../../_shared/models/billing-v3/pricing-plan';
import { InvoiceV2 } from '../../../_shared/models/billingv2/InvoiceV2-response';
import { BillingCounts } from '../../../_shared/models/billingv2/billing-counts-response';
import { SubscriptionProviderConfiguration } from '../../../_shared/models/billingv2/subscription-provider-configuration.model';

export const BillingStateKey = 'billing';
export interface BillingState {
  subscriptionConfigurations: SubscriptionProviderConfiguration[];
  companyBillingCounts: BillingCounts;
  upcomingInvoice?: InvoiceV2;

  // commercial model
  pricingPlans: PricingPlan[];
}

export const InitialBillingState: BillingState = {
  subscriptionConfigurations: [],
  companyBillingCounts: {} as BillingCounts,
  upcomingInvoice: {} as InvoiceV2,

  // commercial model
  pricingPlans: null,
};
