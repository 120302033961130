<div class="container">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Myers-Briggs Type Indicator®</mat-card-title>
      <mat-card-subtitle>
        The Myers-Briggs Type Indicator assessment uses a series of self-reported questions to identify a person's
        personality type, strengths, and preferences.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="field-wrapper">
        <terra-form-field>
          <terra-form-label>Myers-Briggs Type Indicator</terra-form-label>
          <terra-select
            [(ngModel)]="metadata.myersBriggs"
            [compareWith]="compareMyersBriggs"
            [maxHeight]="300"
            (selectionChange)="updateMyersBriggs($event)"
            data-cy="metrics_myers-briggs-select">
            <terra-option
              *ngFor="let option of myersBriggsOptions"
              [value]="option"
              [attr.data-cy]="'metrics-my-option-' + option">
              {{ option }}
            </terra-option>
          </terra-select>
        </terra-form-field>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Kolbe System™</mat-card-title>
      <mat-card-subtitle>
        The Kolbe System measures a person's conation – how they take action. The Kolbe assessment provides actionable
        advice on personal productivity, team performance, job alignment, and hiring.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="fields-dev">
        <div class="field-wrapper">
          <terra-form-field>
            <terra-form-label>Fact Finder</terra-form-label>
            <terra-select
              [(ngModel)]="metadata.kolbe.factFinder"
              (selectionChange)="emitUpdate()"
              data-cy="metrics_kolbe-fact-finder-select">
              <terra-option
                *ngFor="let option of kolbeNumbers"
                [value]="option"
                [attr.data-cy]="'metrics-fact-finder-' + option"
                >{{ option }}</terra-option
              >
            </terra-select>
          </terra-form-field>
        </div>
        <div class="field-wrapper">
          <terra-form-field>
            <terra-form-label>Follow Thru</terra-form-label>
            <terra-select
              [(ngModel)]="metadata.kolbe.followThru"
              (selectionChange)="emitUpdate()"
              data-cy="metrics_kolbe-follow-thru-select">
              <terra-option
                *ngFor="let option of kolbeNumbers"
                [value]="option"
                [attr.data-cy]="'metrics-follow-thru-' + option"
                >{{ option }}</terra-option
              >
            </terra-select>
          </terra-form-field>
        </div>
        <div class="field-wrapper">
          <terra-form-field>
            <terra-form-label>Quick Start</terra-form-label>
            <terra-select
              [(ngModel)]="metadata.kolbe.quickStart"
              (selectionChange)="emitUpdate()"
              data-cy="metrics_kolbe-quick-start-select">
              <terra-option
                *ngFor="let option of kolbeNumbers"
                [value]="option"
                [attr.data-cy]="'metrics-quick-start-' + option"
                >{{ option }}</terra-option
              >
            </terra-select>
          </terra-form-field>
        </div>
        <div class="field-wrapper">
          <terra-form-field data-cy="metrics_kolbe-implementer-select">
            <terra-form-label>Implementer</terra-form-label>
            <terra-select [(ngModel)]="metadata.kolbe.implementor" (selectionChange)="emitUpdate()">
              <terra-option
                *ngFor="let option of kolbeNumbers"
                [value]="option"
                [attr.data-cy]="'metrics-implementer-' + option"
                >{{ option }}</terra-option
              >
            </terra-select>
          </terra-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>CliftonStrengths™</mat-card-title>
      <mat-card-subtitle>
        The CliftonStrengths assessment analyzes your talent DNA; your natural patterns of thinking, feeling, and
        behaving, and categorizes them in rank order.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="strengths-wrapper">
        <div class="top-five">
          <h4>Top Five</h4>
          <mat-list class="strength-list">
            <mat-list-item
              *ngFor="let strength of metadata.strengthFinder?.topFive; let i = index; trackBy: trackByIndex">
              <div class="muted right-space-5">{{ i + 1 }}.</div>
              <div class="field-wrapper flex2">
                <terra-form-field>
                  <terra-text-input
                    data-cy="metrics_input-field-top-strength"
                    [(ngModel)]="metadata.strengthFinder.topFive[i]"
                    (ngModelChange)="debounceUpdate()"
                    placeholder="Top Five Strength" />
                </terra-form-field>
              </div>
              <ninety-button
                flat
                negative
                data-cy="metrics_delete-top-strength"
                (click)="deleteStrength(i, 'topFive')"
                class="delete-btn">
                <terra-icon icon="delete" />
              </ninety-button>
            </mat-list-item>
            <mat-list-item>
              <ninety-button
                flat
                primary
                data-cy="metrics_add-top-strength"
                mat-button
                color="accent"
                (click)="addStrength('topFive')">
                <terra-icon icon="add-circle" />
                Add Top Strength
              </ninety-button>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="bottom-five">
          <h4>Bottom Five</h4>
          <mat-list class="strength-list no-hover">
            <mat-list-item
              *ngFor="let strength of metadata.strengthFinder?.bottomFive; let i = index; trackBy: trackByIndex">
              <div class="muted right-space-5">{{ i + 1 }}.</div>
              <div class="field-wrapper flex2">
                <terra-form-field>
                  <terra-text-input
                    data-cy="metrics_input-field-bottom-strength"
                    [(ngModel)]="metadata.strengthFinder.bottomFive[i]"
                    (ngModelChange)="debounceUpdate()"
                    placeholder="Bottom Five Strength" />
                </terra-form-field>
              </div>
              <ninety-button
                flat
                negative
                data-cy="metrics_delete-bottom-strength"
                (click)="deleteStrength(i, 'bottomFive')"
                class="delete-btn">
                <terra-icon icon="delete" />
              </ninety-button>
            </mat-list-item>
            <mat-list-item class="list-item no-hover">
              <ninety-button
                flat
                primary
                data-cy="metrics_add-bottom-strength"
                mat-button
                color="accent"
                (click)="addStrength('bottomFive')">
                <terra-icon icon="add-circle" />
                Add Bottom Strength
              </ninety-button>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
