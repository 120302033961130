import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * This pipe is used to bypass Angular's security and declare trust for the HTML content; no sanitization occurs here.
 * Use with caution and ensure content is sanitized upstream.
 */
@Pipe({
  name: 'unsafeHtml',
  standalone: true,
})
export class UnsafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
