import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

import { SeatMoveComponent } from './seat-move.component';
import { SeatMoveDialogData } from './seat-move.component.model';

@Component({
  selector: 'ninety-seat-move-dialog',
  standalone: true,
  imports: [CommonModule, SeatMoveComponent],
  template: `
    <ninety-seat-move
      [language]="language$ | async"
      [nameOfSeatToMove]="data.nameOfSeatToMove"
      [seats]="data.seats"
      (cancel)="dialogRef.close()"
      (move)="dialogRef.close($event)"></ninety-seat-move>
  `,
})
export class SeatMoveDialogComponent {
  protected readonly dialogRef = inject(MatDialogRef<SeatMoveDialogComponent>);
  protected readonly data = inject<SeatMoveDialogData>(MAT_DIALOG_DATA);
  protected readonly store = inject(Store);
  protected readonly language$ = this.store.select(selectLanguage);
}
