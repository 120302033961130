import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { DirectoryUserStatusV2 } from '@ninety/ui/legacy/shared/models/directory/directory-user-status-v2';

import { DataImportActions } from '../../_state/data-import.actions';
import { ImportUserFormArray } from '../../models/import-user-form';
import { UserImportFormService } from '../../services/user-import-form.service';

@Component({
  selector: 'ninety-user-import-card',
  templateUrl: './user-import-card.component.html',
  styleUrls: ['./user-import-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserImportCardComponent implements OnInit {
  protected readonly DirectoryUserStatusV2 = DirectoryUserStatusV2;
  protected readonly RoleCode = RoleCode;
  form: ImportUserFormArray;

  constructor(
    private store: Store,
    public userImportFormService: UserImportFormService,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.userImportFormService.form;
  }

  ngOnInit() {
    this.userImportFormService.formChanged$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.cdr.detectChanges())
      )
      .subscribe();
  }

  cancel() {
    this.store.dispatch(DataImportActions.openCancelConfirmDialog());
  }

  importUsers() {
    this.store.dispatch(DataImportActions.openImportOverviewDialog());
  }
}
