import { CommonModule, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MatDialogRef, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TerraIconModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { google, outlook, office365, ics, msTeams } from '@ninety/ui/legacy/shared/calendar-url/calendar';
import { CalendarEvent, CalendarCadence } from '@ninety/ui/legacy/shared/calendar-url/interfaces';

import { Cadence } from '../../../_models/cadence.enum';
import { CustomAmPmFormatTimePipe } from '../pipes/custom-time-date.pipe';

import { MeetingConfirmationModel } from './meeting-confirmation.model';

@Component({
  selector: 'ninety-meeting-info-confirmation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TerraIconModule,
    MatDialogModule,
    MaterialMdcModule,
    TerraIconModule,
    CustomAmPmFormatTimePipe,
    TitleCasePipe,
  ],
  templateUrl: './meeting-info-confirmation-dialog.component.html',
  styleUrls: ['./meeting-info-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingInfoConfirmationDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<MeetingInfoConfirmationDialogComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: MeetingConfirmationModel) {}

  addToCalendar(calendar: string) {
    const calendarEvent: CalendarEvent = {
      title: `${this.data.meetingTitle} - ${this.data.teamName}`,
      startDate: this.data.meetingStartDate,
      endDate: this.data.meetingEndDate,
      cadence: this.mapToCalendarCadence(this.data.cadence),
      guests: this.data.invitees,
      allDay: false, // We don't have all day events for meetings
    };

    let url;
    switch (calendar) {
      case 'google':
        url = google(calendarEvent);
        break;
      case 'outlook':
        url = outlook(calendarEvent);
        break;
      case 'office365':
        url = office365(calendarEvent);
        break;
      case 'ics':
        url = ics(calendarEvent);
        break;
      case 'msTeams':
        url = msTeams(calendarEvent);
      default:
        break;
    }

    if (url) window.open(url, '_blank').focus();
    this.dialogRef.close();
  }

  mapToCalendarCadence(cadence: Cadence): CalendarCadence {
    switch (cadence) {
      case 'biWeekly':
        return { frequency: 'weekly', interval: 2 };
      case 'weekly':
        return { frequency: 'weekly', interval: 1 };
      case 'monthly':
        return { frequency: 'monthly', interval: 1 };
      default:
        return null;
    }
  }
}
