import { Directive, ElementRef, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN,
  DashboardExpandCandidateStateFacade,
} from './dashboard-expand-candidate-state-facade';
import { DashboardExpandTargetDirective } from './dashboard-expand-target.directive';
import { DashboardExpandActions } from './dashboard-expand.actions';
import { DashboardExpandSelectors } from './dashboard-expand.selectors';

/** Marks its host as capable of being rendered expanded within a {@link DashboardExpandTargetDirective} host. */
@Directive({
  selector: '[ninetyDashboardExpandCandidate]',
  standalone: true,
  providers: [
    // Register self as a provider for the state facade
    {
      provide: DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN,
      useExisting: DashboardExpandCandidateDirective,
    },
  ],
})
export class DashboardExpandCandidateDirective implements OnInit, OnDestroy, DashboardExpandCandidateStateFacade {
  @Input({ alias: 'ninetyDashboardExpandCandidate' }) id: string;

  isExpanded$: Observable<boolean>;

  constructor(
    public readonly host: ElementRef,
    private readonly store: Store,
    @Optional() private readonly dashboardExpandTarget: DashboardExpandTargetDirective
  ) {}

  ngOnInit(): void {
    if (!this.id) throw new Error('DashboardExpandCandidateDirective: No ID provided.');

    this.isExpanded$ = this.store.select(DashboardExpandSelectors.isExpanded(this.id));
    this.dashboardExpandTarget?.register(this);
  }

  ngOnDestroy(): void {
    this.dashboardExpandTarget?.deregister(this);
  }

  /** Expand this widget into the {@link DashboardExpandTargetDirective} host. */
  requestExpand(): void {
    this.store.dispatch(DashboardExpandActions.expand({ widgetId: this.id }));
  }

  /** Collapse this widget from the {@link DashboardExpandTargetDirective} host. */
  requestCollapse(): void {
    this.store.dispatch(DashboardExpandActions.collapse({ widgetId: this.id }));
  }
}
