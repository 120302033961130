import { componentWrapperDecorator, StoryObj } from '@storybook/angular';
import { merge } from 'lodash';

/**
 * Story level decorator to wrap the story in a container of a given width. Useful for stories showing state at
 * different widths.
 *
 * Note, it's likely that other use-cases want the viewport functionality from Chromatic to capture UI tests at
 * different viewports. See https://www.chromatic.com/docs/viewports
 */
export function wrapWithContainerOfWidth(width: number) {
  return componentWrapperDecorator(
    story => `
    <div style="width: ${width}px; border: 1px dashed black; box-sizing: content-box;">${story}</div>
    <div>Width: ${width}px</div>`
  );
}

export function wrapWithContainer(opts?: { width?: string; height?: string; skipBorder?: boolean; extraCss?: string }) {
  const width = opts?.width ?? '100%';
  const height = opts?.height ?? '100%';
  const borderStyle = opts?.skipBorder ? '' : 'border: 1px dashed black';
  const extraCss = opts?.extraCss ?? '';

  return componentWrapperDecorator(
    story =>
      ` <div style="width: ${width}; height: ${height}; ${borderStyle}; box-sizing: content-box; ${extraCss}">${story}</div>`
  );
}

export const MIN_CHROMATIC_VIEWPORT = 200;
export const MAX_CHROMATIC_VIEWPORT = 2560;

/** Ensures that `width` is within the range of Chromatic's viewport support. */
export function getEffectiveChromaticViewport(width: number): number {
  if (width < MIN_CHROMATIC_VIEWPORT) return MIN_CHROMATIC_VIEWPORT;
  if (width > MAX_CHROMATIC_VIEWPORT) return MAX_CHROMATIC_VIEWPORT;
  return width;
}

/**
 * Story level decorator to wrap the story in a container of a given width and configure the viewport for Chromatic.
 */
export function wrapWithContainerOfWidthAndConfigureViewport<T>(width: number, story: StoryObj<T>): StoryObj<T> {
  const chromaticViewport = getEffectiveChromaticViewport(width);

  const wrappedConfig: Partial<StoryObj<T>> = {
    decorators: [wrapWithContainerOfWidth(width)],
    parameters: {
      chromatic: {
        viewports: [chromaticViewport],
      },
    },
  };

  return merge(story, wrappedConfig);
}
