import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CatchErrorAndNotify } from '../../_core/decorators/catch-error-and-notify';
import { ErrorService } from '../../_core/services/error.service';
import {
  FREE_SUBSCRIPTION_CONFIGURATIONS,
  PAID_SUBSCRIPTION_CONFIGURATIONS,
  SubscriptionConfiguration,
} from '../../_shared/models/billing-v3/subscription-configuration';

@Injectable({
  providedIn: 'root',
})
export class BillingV3Service {
  private readonly billingV3Api = '/billing/v3';
  private readonly subscriptionConfigsApi = `${this.billingV3Api}/active-subscription-configurations/Stripe`;

  constructor(private readonly http: HttpClient, private readonly _errorService: ErrorService) {}

  @CatchErrorAndNotify
  public getCommercialModelSubscriptions(): Observable<SubscriptionConfiguration[]> {
    // return this.http.get<SubscriptionConfiguration[]>(this.subscriptionConfigsApi);
    return of([...FREE_SUBSCRIPTION_CONFIGURATIONS, ...PAID_SUBSCRIPTION_CONFIGURATIONS]);
  }
}
