import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { endOfDay } from 'date-fns';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { getLocalPageSize } from '@ninety/ui/legacy/components/pagination/pagination.component';
import { PrintOptions } from '@ninety/ui/legacy/core/services/print.service';
import { LocalStorageService } from '@ninety/ui/legacy/core/services/storage.service';
import { ItemType } from '@ninety/ui/legacy/shared/index';
import { AttachmentEvent } from '@ninety/ui/legacy/shared/models/_shared/attachment-event';
import { AttachmentMessageType } from '@ninety/ui/legacy/shared/models/_shared/attachment-message-type';
import { ContactCardStatusModel } from '@ninety/ui/legacy/shared/models/_shared/contact-card-status-model';
import { CommentDeleteEvent } from '@ninety/ui/legacy/shared/models/_shared/detail-view-input';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoSort } from '@ninety/ui/legacy/shared/models/todos/todo-sort';
import { TodoSortField } from '@ninety/ui/legacy/shared/models/todos/todo-sort-field';
import {
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentUserIsManagerOrAboveOrImplementer,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage, TeamSelectors } from '@ninety/ui/legacy/state/index';
import { selectIsInMeeting, selectIsMyNinetyUrl, selectUrl } from '@ninety/ui/legacy/state/route.selectors';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';
import { UniversalCreateService } from '@ninety/web/pages/layouts/services/universal-create.service';

import { TeamTodosChildStateKey, TodoRootStateKey } from '..';
import { TodoDetailSelectors } from '../detail/todo-detail.selectors';

import { TeamTodoActions, TeamTodoInlineActions, TeamTodoPubnubActions } from './team-todo.actions';
import { TeamTodoState } from './team-todo.model';
import { TeamTodoSelectors } from './team-todo.selectors';

@Injectable()
export class TeamTodoFacade {
  /** Team selectors */

  todoCount$ = this.store.select(TeamTodoSelectors.selectTeamTodoCount);
  todos$ = this.store.select(TeamTodoSelectors.selectTeamTodos);
  pageSize$ = this.store.select(TeamTodoSelectors.selectTeamPageSize);
  pageIndex$ = this.store.select(TeamTodoSelectors.selectTeamPageIndex);
  todosWithCount$ = this.store.select(TeamTodoSelectors.selectTodoResponse);
  showEmptyState$ = this.store.select(TeamTodoSelectors.selectShowEmptyState);
  showUserPendingAgreementsTodosEmptyState$ = this.store.select(
    TeamTodoSelectors.selectShowUserPendingAgreementsTodosEmptyState
  );
  showCard$ = this.store.select(TeamTodoSelectors.selectShowCard);
  loading$ = this.store.select(TeamTodoSelectors.selectTeamTodosLoading);
  showArchived$ = this.store.select(TeamTodoSelectors.selectShowTeamArchived);
  selectedTeamId$ = this.store.select(TeamTodoSelectors.selectTeamId);
  listControlsDisabled$ = this.store.select(TeamTodoSelectors.selectListControlsDisabled);
  focusOnInlineAddTodo$ = this.store.select(TeamTodoSelectors.selectFocusOnInlineAddTodo);
  showIntegrations$ = this.store.select(TeamTodoSelectors.selectShowIntegration);
  selectedTodo$ = this.store.select(TodoDetailSelectors.selectedTodo);
  selectSelectedTodoId$ = this.store.select(TeamTodoSelectors.selectSelectedTodoId);
  sortField$ = this.store.select(TeamTodoSelectors.selectTeamSortField);
  sortDirection$ = this.store.select(TeamTodoSelectors.selectTeamSortDirection);
  currentUrl$: Observable<string> = this.store.select(selectUrl);
  isMyNinetyUrl$ = this.store.select(selectIsMyNinetyUrl);
  isAgreementsBasedTodosCompany$ = this.store.select(TeamTodoSelectors.selectIsAgreementsBasedTodosCompany);
  currentUserId$ = this.store.select(selectCurrentUserId);
  userPendingAgreementsFilter$ = this.store.select(TeamTodoSelectors.selectUserPendingAgreementsFilter);
  currentUserIsManagerOrAboveOrImplementer$ = this.store.select(selectCurrentUserIsManagerOrAboveOrImplementer);
  completionTooltip$ = this.store.select(TeamTodoSelectors.selectCompletionTooltip);

  showUserPendingAgreementsBtn$ = this.store.select(selectIsInMeeting).pipe(
    concatLatestFrom(() => [this.isAgreementsBasedTodosCompany$, this.showArchived$]),
    map(([currentMeeting, isABCompany, showArchived]) => isABCompany && !currentMeeting && !showArchived)
  );

  private solveRateDataProductB$ = new BehaviorSubject<string>('');
  solveRateDataProduct$ = this.solveRateDataProductB$.asObservable();

  constructor(
    private store: Store,
    private localStorageService: LocalStorageService,
    private universalCreateService: UniversalCreateService
  ) {}

  /** Shared actions */

  addOne(todo: Todo) {
    this.store.dispatch(TeamTodoActions.addOne({ todo }));
  }

  remove(id: string) {
    this.store.dispatch(TeamTodoActions.remove({ id }));
  }

  removeSeries(seriesId: string) {
    this.store.dispatch(TeamTodoActions.removeSeries({ seriesId }));
  }

  set(todos: Todo[]) {
    this.store.dispatch(TeamTodoActions.set({ todos }));
  }

  resetState(overrides?: Partial<TeamTodoState>) {
    this.store.dispatch(TeamTodoActions.resetState({ overrides }));
  }

  updateLocal(id: string, todo: Partial<Todo>) {
    this.store.dispatch(TeamTodoActions.updateLocal({ id, todo }));
  }

  update(todo: Partial<Todo>, id?: string) {
    this.store.dispatch(TeamTodoActions.update({ id, todo }));
  }

  updateInline(todo: Partial<Todo>, id?: string) {
    this.store.dispatch(TeamTodoActions.updateInline({ id, todo }));
  }

  setCompleted(todo: Partial<Todo>, id?: string) {
    this.store.dispatch(TeamTodoActions.setCompleted({ id, todo, eventSource: 'to-do page' }));
  }
  deleteConfirm(todo: Todo) {
    this.store.dispatch(TeamTodoActions.deleteConfirm({ todo }));
  }

  delete(todo: Todo) {
    this.store.dispatch(TeamTodoActions.delete({ todo }));
  }

  deleteSeries(todo: Todo) {
    this.store.dispatch(TeamTodoActions.deleteSeries({ todo }));
  }

  paginationChange(index: number, size: number) {
    this.store.dispatch(TeamTodoActions.paginationChange({ index, size }));
  }

  sortBy(todoSortField: TodoSortField, sortDirection: SortDirection, broadcast?: boolean) {
    this.store.dispatch(
      TeamTodoActions.sortBy({ sort: { field: todoSortField, direction: sortDirection }, broadcast })
    );
  }

  updateOrdinals(previousIndex: number, currentIndex: number) {
    this.store.dispatch(TeamTodoActions.updateOrdinals({ previousIndex, currentIndex }));
  }

  updateLocalOrdinals(previousIndex: number, currentIndex: number) {
    this.store.dispatch(TeamTodoActions.updateLocalOrdinals({ previousIndex, currentIndex }));
  }

  setShouldBroadcast(broadcast?: boolean) {
    this.store.dispatch(TeamTodoActions.setShouldBroadcast({ broadcast }));
  }

  loading(loading: boolean) {
    this.store.dispatch(TeamTodoActions.loading({ loading }));
  }

  /** Team actions */

  getTeamTodos() {
    this.store.dispatch(TeamTodoActions.getTeam());
  }

  teamSelected(teamId: string) {
    this.store.dispatch(TeamTodoActions.teamSelected({ teamId }));
  }

  showArchived(showArchived: boolean) {
    this.store.dispatch(TeamTodoActions.showArchived({ showArchived }));
  }

  toggleArchived(todo?: Todo) {
    this.store.dispatch(TeamTodoActions.toggleArchived({ todo }));
  }

  archiveAllCompleted() {
    this.store.dispatch(TeamTodoActions.archiveAllCompleted());
  }

  openCreateDialog() {
    this.store.dispatch(TeamTodoActions.openCreateDialog());
  }

  addOneInLine(): void {
    const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);
    const currentUser = extractValueFromStore(this.store, selectCurrentUser);
    const todo: Item = {
      userId: currentUser._id,
      title: '',
      description: '',
      dueDate: this.universalCreateService.getDueDate(ItemType.todo),
      teamId: selectedTeamId,
      itemType: ItemType.todo,
      companyId: currentUser.company.companyId,
      ordinal: -1,
      userOrdinal: -1,
      who: '',
      intervalCode: null,
    };
    this.store.dispatch(TeamTodoInlineActions.addOne({ todo }));
  }

  cancelAddOneInline() {
    this.store.dispatch(TeamTodoInlineActions.cancelAddOne());
  }

  createOneInline(todo: Item) {
    this.store.dispatch(TeamTodoInlineActions.createOne({ todo }));
  }

  showIntegrations(showIntegrations: boolean) {
    this.store.dispatch(TeamTodoActions.showIntegrations({ showIntegrations }));
  }

  createTask(id: string) {
    this.store.dispatch(TeamTodoActions.createTask({ id }));
  }

  initTodosForMeetingConclude(teamId: string, pageSize: number) {
    this.store.dispatch(TeamTodoActions.initMeetingConclude({ teamId, pageSize }));
  }

  hydratePageSize() {
    this.store.dispatch(
      TeamTodoActions.hydratePageSizeFromLocalStore({
        pageSize: getLocalPageSize(`${TodoRootStateKey}.${TeamTodosChildStateKey}`),
      })
    );
  }

  savePageSizeOnLocalStore(pageSize: number) {
    this.store.dispatch(TeamTodoActions.savePageSizeOnLocalStore({ pageSize }));
  }

  hydratePageSortSettings() {
    const sort = this.localStorageService.getAndParse<TodoSort>(`${TodoRootStateKey}.${TeamTodosChildStateKey}.sort`);

    this.store.dispatch(
      TeamTodoActions.hydratePageSortingFromLocalStore({
        sortField: sort?.field,
        sortDirection: sort?.direction,
      })
    );
  }

  syncTasks() {
    this.store.dispatch(TeamTodoActions.syncTasks());
  }

  printTodos(printOptions: PrintOptions) {
    this.store.dispatch(TeamTodoActions.print({ printOptions }));
  }

  deleteComment(event: CommentDeleteEvent) {
    this.store.dispatch(TeamTodoActions.deleteComment({ event }));
  }

  /** Local Attachment Actions */
  attachmentEvent(event: AttachmentEvent & { meetingMessageType: AttachmentMessageType }) {
    if (event.type === 'upload') {
      this.store.dispatch(TeamTodoActions.attachmentUploaded({ event }));
    } else {
      this.store.dispatch(TeamTodoActions.attachmentRemoved({ event }));
    }
  }

  attachmentDragAndDropEvent(event: Todo & { meetingMessageType: AttachmentMessageType }) {
    this.store.dispatch(TeamTodoActions.attachmentReordered({ event }));
  }

  /** Pubnub Broadcasted Attachment Actions */
  attachmentEventReceived(id: string) {
    this.store.dispatch(TeamTodoPubnubActions.attachmentEventReceived({ id }));
  }

  updateDataProduct(setDataProduct: boolean) {
    this.solveRateDataProductB$.next(setDataProduct ? 'meeting-todo-metrics__solve-rate-hover' : '');
  }

  select(todo: Todo) {
    if (todo._id) {
      this.store.dispatch(TeamTodoActions.select({ todo }));
    }
  }

  getStatusModel(todo: Todo): ContactCardStatusModel {
    const language = extractValueFromStore(this.store, selectLanguage);

    const todoLabel = language.todo.item;
    if (todo.status === 'accepted') {
      return {
        statusText: `${todoLabel} accepted`,
        statusIcon: 'check-circle',
        statusIconVariant: 'fill',
      };
    }
    if (todo.status === 'pending') {
      return {
        statusText: `${todoLabel} not accepted`,
        statusIcon: 'clock',
        statusIconVariant: 'fill',
      };
    }
    return null;
  }

  toggleUserPendingAgreements() {
    this.store.dispatch(TeamTodoActions.toggleUserPendingAgreements());
  }

  onDateChange(todo: Todo, dueDate: string | Date) {
    this.store.dispatch(
      TeamTodoActions.updateDueDate({
        id: todo._id,
        todo: { dueDate: endOfDay(new Date(dueDate)), dueDateChanged: true },
      })
    );
  }

  onInlineTitleChange(todo: Todo, title: string) {
    if (!todo._id) {
      this.createOneInline({ ...todo, title } as Item);
    } else {
      this.updateInline({ title }, todo._id);
    }
  }
}
