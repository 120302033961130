import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { User } from '@ninety/ui/legacy/shared/index';
import { DirectoryUserStatusV2 } from '@ninety/ui/legacy/shared/models/directory/directory-user-status-v2';

import { DirectoryActions, UserDetailActions } from '../../_state/directory.actions';

@Component({
  selector: 'ninety-directory-table-action',
  templateUrl: './directory-table-action.component.html',
  styleUrls: ['./directory-table-action.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, TerraIconModule, NinetyTooltipDirective, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryTableActionComponent {
  @Input() user: User;
  @Input() disabled: boolean;

  constructor(private readonly store: Store) {}

  openDeactivateUserDialog(): void {
    this.store.dispatch(DirectoryActions.openDeactivateUserDialog({ user: this.user }));
  }

  copyInviteLink() {
    this.store.dispatch(UserDetailActions.copyInviteLink({ user: this.user }));
  }

  openRemovePersonDialog(): void {
    this.store.dispatch(DirectoryActions.openDeleteUserDialog({ user: this.user }));
  }

  protected readonly DirectoryUserStatusV2 = DirectoryUserStatusV2;
}
