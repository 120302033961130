/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createSelector } from '@ngrx/store';

import { selectQueryParam } from '@ninety/ui/legacy/state/route.selectors';

export const EXPANDED_WIDGET_QUERY_PARAM_KEY = 'expanded';

export namespace DashboardExpandSelectors {
  export const expandedWidgetFromQueryParams = selectQueryParam(EXPANDED_WIDGET_QUERY_PARAM_KEY);

  export const anyExpandedWidget = createSelector(expandedWidgetFromQueryParams, Boolean);

  export const isExpanded = (id: string) =>
    createSelector(expandedWidgetFromQueryParams, expandedId => expandedId === id);
}
