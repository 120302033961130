export enum NinetyFeatures {
  vision = 'vision',
  printOrDownload = 'print-or-download',
  swot = 'swot',
  strategy = 'strategy',
  orgChart = 'org-chart',
  directory = 'directory',
  assessments = 'assessments',
  kst = 'kst',
  my90 = 'my90',
  weeklyMeetings = 'weekly-meetings',
  allMeetings = 'all-meetings',
  rocks = 'rocks',
  milestones = 'milestones',
  todos = 'to-dos',
  issues = 'issues',
  mazOnboarding = 'maz-onboarding',
  scorecard = 'scorecard',
  headlines = 'headlines',
  cascadingMessages = 'cascading-messages',
  insights = 'insights',
  weeklyDiscussions = 'weekly-discussions',
  quarterlyDiscussions = 'quarterly-discussions',
  integrations = 'integrations',
  dataImport = 'data-import',
  oneOnOne = 'one-on-one',
  universalCreate = 'universal-create',

  /**.Features will be deprecated after commercial model goes live
   * Access is handled in the company selectors for easier cleanup */
  mastery = 'mastery',
  process = 'process',
}

/*
 * TODO: Remove once feature access is provided by the BE
 */
export enum FeatureAccessLevel {
  read = 'read',
  write = 'write',
}

export interface FeatureAccess {
  featureId: NinetyFeatures;
  access?: FeatureAccessLevel;
}

export enum PricingTier {
  Free = 'Free',
  Essentials = 'Essentials',
  Accelerate = 'Accelerate',
  Thrive = 'Thrive',
}

const pricingTierFree: FeatureAccess[] = [
  { featureId: NinetyFeatures.vision, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.strategy, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.orgChart, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.directory, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.assessments, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.mazOnboarding, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.kst, access: FeatureAccessLevel.read },
];
const pricingTierEssentials: FeatureAccess[] = [
  { featureId: NinetyFeatures.printOrDownload },
  { featureId: NinetyFeatures.universalCreate, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.swot, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.my90, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.weeklyMeetings, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.scorecard, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.headlines, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.issues, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.rocks, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.milestones, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.todos, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.dataImport, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.kst, access: FeatureAccessLevel.read },
];
const pricingTierAccelerate: FeatureAccess[] = [
  { featureId: NinetyFeatures.insights, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.cascadingMessages, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.allMeetings, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.oneOnOne, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.integrations, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.kst, access: FeatureAccessLevel.read },
];

//add thrive features below
const pricingTierThrive: FeatureAccess[] = [{ featureId: NinetyFeatures.kst, access: FeatureAccessLevel.write }];

const mergeFeatureAccess = (...tiers: FeatureAccess[][]): FeatureAccess[] => {
  const merged = tiers.flat().reduce((acc, feature) => {
    if (!acc[feature.featureId] || feature.access === FeatureAccessLevel.write) {
      acc[feature.featureId] = feature;
    }
    return acc;
  }, {} as Record<string, FeatureAccess>);

  return Object.values(merged);
};

export const PricingTiers: Record<keyof typeof PricingTier, FeatureAccess[]> = {
  [PricingTier.Free]: pricingTierFree,
  [PricingTier.Essentials]: mergeFeatureAccess(pricingTierFree, pricingTierEssentials),
  [PricingTier.Accelerate]: mergeFeatureAccess(pricingTierFree, pricingTierEssentials, pricingTierAccelerate),
  [PricingTier.Thrive]: mergeFeatureAccess(
    pricingTierFree,
    pricingTierEssentials,
    pricingTierAccelerate,
    pricingTierThrive
  ),
};
