/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, forwardRef, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { IFuseOptions } from 'fuse.js';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { ResponsibilityChartSelectors } from '@ninety/web/pages/accountability-chart/_state/chart/responsibility-chart.selectors';

import { AUTO_COMPLETE_CALLBACK_HANDLER } from '../directives/auto-complete.directive';

import { FUSE_PROVIDER_INJECTION_TOKEN, FuseSearchProvider } from './fuse-provider';
import { FuseSearchService } from './fuse-search.service';

@Directive({
  selector: '[ninetyFuseSeat]',
  standalone: true,
  providers: [
    FuseSearchService,
    {
      provide: AUTO_COMPLETE_CALLBACK_HANDLER,
      useExisting: FuseSearchService,
    },
    {
      provide: FUSE_PROVIDER_INJECTION_TOKEN,
      useExisting: forwardRef(() => FuseSeatDirective),
    },
  ],
})
export class FuseSeatDirective implements OnInit, FuseSearchProvider<SeatModel>, OnDestroy {
  /** {@link IFuseOptions} for the seat index */
  @Input({ alias: 'ninetyFuseSeatOptions' }) fuseOptions: IFuseOptions<SeatModel> = {
    keys: ['name'],
    includeMatches: true,
  };

  /** Handle of the current subscription, assuming that the datasource is not an array */
  private inputSubscription?: Subscription;

  /**
   * this.data$ source. Ensures that a single observable gets passed to {@link FuseSearchService.init} even after an
   * arbitrary sequences of datasource changes.
   */
  private readonly internalSource = new ReplaySubject<SeatModel[]>(1);

  /** Definition of all seats indexed by the {@link FuseSearchService} */
  public readonly data$: Observable<SeatModel[]> = this.internalSource.asObservable();

  /** Internal flag to track if the datasource has been configured. See {@link ngOnInit} */
  private isDatasourceConfigured = false;

  /**
   * Polymorphic datasource of all seats indexed by the {@link FuseSearchService}. Capable of responding to both
   * {@link Observable} and {@link SeatModel[]} inputs, as well as any combination of the two. If not set, default value
   * until set is the store selector {@link ResponsibilityChartSelectors.allSeats}.
   */
  @Input() set datasource(value: Observable<SeatModel[]> | SeatModel[]) {
    if (!value) return;

    if (Array.isArray(value)) {
      this.internalSource.next(value);
    } else {
      if (this.inputSubscription) this.inputSubscription.unsubscribe();
      this.inputSubscription = value.subscribe(this.internalSource);
    }

    this.isDatasourceConfigured = true;
  }

  constructor(public readonly fuse: FuseSearchService<SeatModel>, @Optional() private store: Store) {}

  ngOnInit() {
    if (!this.isDatasourceConfigured) {
      // Explicitly throws Null Pointer if no input and no injected store
      // eslint-disable-next-line @ngrx/no-store-subscription -- WHY? polymorphic input pattern & directive use
      this.inputSubscription = this.store.select(ResponsibilityChartSelectors.allSeats).subscribe(this.internalSource);
    }

    this.fuse.init({
      allOptionsDataSource$: this.data$,
      fuseOptions: this.fuseOptions,
    });
  }

  ngOnDestroy() {
    if (this.inputSubscription) this.inputSubscription.unsubscribe();
  }
}
