import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { AlertDialogComponent } from '@ninety/ui/legacy/shared/components/alert-dialog/alert-dialog.component';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { ConversationToolType } from '@ninety/ui/legacy/shared/models/enums/conversation-tool-type';
import { ConversationLanguage } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { ConversationSideNav } from '@ninety/ui/legacy/shared/models/feedback/conversation-side-nav';
import { ConversationType } from '@ninety/ui/legacy/shared/models/feedback/conversation-type.enum';
import { FormalConversationQuestion } from '@ninety/ui/legacy/shared/models/feedback/formal-conversation-question';
import { Section } from '@ninety/ui/legacy/shared/models/feedback/section';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { MainLayoutService } from '@ninety/web/pages/layouts/main-layout/main-layout.service';

import { FeedbackService } from '../_shared/feedback.service';

@Component({
  selector: 'ninety-conversation-sidenav',
  templateUrl: './conversation-sidenav.component.html',
  styleUrls: ['./conversation-sidenav.component.scss'],
})
export class ConversationSidenavComponent implements OnInit, OnDestroy {
  @Input() showNav: boolean;
  @Input() isMobile: boolean;
  @Input() showQuarterlyQuestions = false;

  @HostBinding('class.mobile-conversation-sidenav')
  get isMobileConversationSidenav() {
    return this.isMobile;
  }

  @Output() toggleSideNav = new EventEmitter<void>();

  formalQuestionSections: Section[];
  isMeeting: boolean;
  holdingMeeting: boolean;
  isConversationManager: boolean;
  canEvaluateManager: boolean;
  canEvaluateSelf: boolean;
  managee: User;
  manager: User;
  currentSection: Section;
  manageeSections: Section[];
  managerSections: Section[];
  conversationId: string;
  conversationType: ConversationType;
  conversationBOS: BusinessOperatingSystem;
  conversationLanguage: ConversationLanguage;
  conversationToolType = ConversationToolType;
  viewingTool = false;
  generalNotesSection = { elementId: 'general-notes-managee', title: 'General Notes', manageeSection: true };
  quarterlyManageeSections: Section[] = [
    { elementId: 'core-values', title: 'Core Values ', manageeSection: true },
    { elementId: 'gwc', title: 'GWC', manageeSection: true },
    { elementId: 'rocks', title: this.stateService.language.rock.items, manageeSection: true },
  ];
  quarterlyManageeSectionsV2: Section[] = [
    { elementId: 'core-values', title: 'Core Values ', manageeSection: true },
    { elementId: 'ccc', title: 'CCC', manageeSection: true },
    { elementId: 'rocks', title: this.stateService.language.rock.items, manageeSection: true },
  ];
  quarterlyManageeSectionsFormal: Section[] = [
    { elementId: 'core-values', title: 'Core Values ', manageeSection: true },
    { elementId: 'gwc', title: 'GWC', manageeSection: true },
    { elementId: 'performance-data', title: 'Performance Data', manageeSection: true },
  ];
  quarterlyManageeSectionsFormalV2: Section[] = [
    { elementId: 'core-values', title: 'Core Values ', manageeSection: true },
    { elementId: 'ccc', title: 'CCC', manageeSection: true },
    { elementId: 'performance-data', title: 'Performance Data', manageeSection: true },
  ];
  formalManageeSections: Section[] = [
    { elementId: 'core-values', title: 'Core Values', manageeSection: true },
    { elementId: 'gwc', title: 'GWC', manageeSection: true },
    { elementId: 'performance-data', title: 'Performance Data', manageeSection: true },
  ];
  formalManageeSectionsV2: Section[] = [
    { elementId: 'core-values', title: 'Core Values', manageeSection: true },
    { elementId: 'ccc', title: 'CCC', manageeSection: true },
    { elementId: 'performance-data', title: 'Performance Data', manageeSection: true },
  ];
  nonMeetingManagerSections: Section[] = [
    { elementId: 'leadership-commitment', title: 'Leadership Assessment', manageeSection: false },
    { elementId: 'coaching-commitment', title: 'Management Assessment', manageeSection: false },
    { elementId: 'general-notes-manager', title: 'General Notes', manageeSection: false },
  ];
  nonMeetingManagerSectionsV2: Section[] = [
    {
      elementId: 'leadership-commitment',
      title: this.stateService.language.feedback.leadershipCommitments,
      manageeSection: false,
    },
    {
      elementId: 'coaching-commitment',
      title: this.stateService.language.feedback.coachingCommitments,
      manageeSection: false,
    },
    { elementId: 'general-notes-manager', title: 'General Notes', manageeSection: false },
  ];

  signatureSections: Section[];

  private subscriptions = new Subscription();

  showSaveButtons$: Observable<boolean>;

  constructor(
    public feedbackService: FeedbackService,
    public stateService: StateService,
    private dialog: MatDialog,
    private router: Router,
    private spinnerService: SpinnerService,
    private mainLayoutService: MainLayoutService,
    private featureFlags: FeatureFlagFacade
  ) {}

  ngOnInit() {
    this.mainLayoutService.setClass('conversation-page-content');

    this.subscriptions.add(
      this.feedbackService.initializeSideNav$.subscribe({
        next: (sideNavProps: ConversationSideNav) => {
          this.manager = sideNavProps?.manager;
          this.managee = sideNavProps?.managee;
          this.isConversationManager = sideNavProps?.isConversationManager;
          this.conversationId = sideNavProps?.conversationId;
          this.conversationType = sideNavProps?.conversationType;
          this.canEvaluateManager = sideNavProps?.canEvaluateManager;
          this.canEvaluateSelf = sideNavProps?.canEvaluateSelf;
          this.isMeeting = sideNavProps?.isMeeting;
          this.conversationBOS = sideNavProps?.conversationBOS;
          this.conversationLanguage = sideNavProps?.conversationLanguage;
          this.holdingMeeting = sideNavProps?.holdingMeeting;
          let sideNavFormalQuestions = [];
          if (sideNavProps?.meetingQuestions?.length) {
            sideNavFormalQuestions = sideNavProps.meetingQuestions;
          } else if (sideNavProps?.quarterlyQuestions?.length && this.showQuarterlyQuestions) {
            sideNavFormalQuestions = sideNavProps.quarterlyQuestions;
          }
          this.setFormalConversationQuestionSections(sideNavFormalQuestions);

          this.setupSections();
          this.showSaveButtons$ = this.featureFlags.getFlag(FeatureFlagKeys.webDiscussionsPubnub).pipe(
            map(enablePubnub => {
              if (!this.isMeeting) return true;
              if (enablePubnub) return this.isConversationManager;
              return true;
            })
          );
        },
      })
    );
    this.subscriptions.add(
      this.router.events.pipe(filter((e: any) => e?.urlAfterRedirects && e instanceof NavigationEnd)).subscribe({
        next: (e: NavigationEnd) => {
          // Need to see if we're viewing a tool or not to show/hide sections in side nav.
          const tree: UrlTree = this.router.parseUrl(e?.urlAfterRedirects);
          const g: UrlSegmentGroup = tree?.root?.children['primary'];
          const s: UrlSegment[] = g?.segments;
          this.viewingTool = !s[s?.length - 1]?.path.includes('meeting');
        },
      })
    );
  }

  ngOnDestroy() {
    this.mainLayoutService.removeClass('conversation-page-content');
    this.subscriptions.unsubscribe();
    this.feedbackService.initializeSidenav(null);
  }

  toggleNav(show: boolean) {
    this.stateService.toggleMeetingSideNav(show);
  }

  setupSections() {
    if (this.conversationLanguage) {
      this.quarterlyManageeSections[1].title = this.conversationLanguage.CCC;
      this.quarterlyManageeSectionsV2[1].title = this.conversationLanguage.CCC;
      this.quarterlyManageeSectionsV2[2].title = this.conversationLanguage.rockItems;
      this.quarterlyManageeSectionsFormal[1].title = this.conversationLanguage.CCC;
      this.quarterlyManageeSectionsFormalV2[1].title = this.conversationLanguage.CCC;
      this.formalManageeSections[1].title = this.conversationLanguage.CCC;
      this.formalManageeSectionsV2[1].title = this.conversationLanguage.CCC;
      this.nonMeetingManagerSections[0].title = this.conversationLanguage.leadershipCommitments;
      this.nonMeetingManagerSections[1].title = this.conversationLanguage.coachingCommitments;
      this.nonMeetingManagerSectionsV2[0].title = this.conversationLanguage.leadershipCommitments;
      this.nonMeetingManagerSectionsV2[1].title = this.conversationLanguage.coachingCommitments;
    }

    if (this.conversationType === ConversationType.annual) {
      if (this.conversationBOS !== BusinessOperatingSystem.eos) {
        this.manageeSections = [
          ...this.formalManageeSectionsV2,
          ...this.formalQuestionSections,
          this.generalNotesSection,
        ];
      } else {
        this.manageeSections = [
          ...this.formalManageeSections,
          ...this.formalQuestionSections,
          this.generalNotesSection,
        ];
      }
    } else {
      if (this.conversationBOS !== BusinessOperatingSystem.eos)
        this.manageeSections = this.holdingMeeting
          ? [...this.quarterlyManageeSectionsFormalV2, ...this.formalQuestionSections, this.generalNotesSection]
          : [...this.quarterlyManageeSectionsV2, ...this.formalQuestionSections, this.generalNotesSection];
      else
        this.manageeSections = this.holdingMeeting
          ? [...this.quarterlyManageeSectionsFormal, ...this.formalQuestionSections, this.generalNotesSection]
          : [...this.quarterlyManageeSections, ...this.formalQuestionSections, this.generalNotesSection];
    }
    if (this.isMeeting) {
      if (this.conversationBOS !== BusinessOperatingSystem.eos)
        this.managerSections = [...this.nonMeetingManagerSectionsV2];
      else this.managerSections = [...this.nonMeetingManagerSections];
      this.signatureSections = [
        { elementId: 'managee-signature', title: 'Managee Signature', manageeSection: false },
        { elementId: 'manager-signature', title: 'Manager Signature', manageeSection: false },
      ];
    } else {
      if (this.conversationBOS !== BusinessOperatingSystem.eos)
        this.managerSections = [...this.nonMeetingManagerSectionsV2];
      else this.managerSections = [...this.nonMeetingManagerSections];
    }
  }

  setFormalConversationQuestionSections(meetingQuestions: FormalConversationQuestion[]) {
    this.formalQuestionSections = meetingQuestions.map(
      (q: FormalConversationQuestion) => new Section(q.elementId, q.title)
    );
  }

  saveConversation(submit = false) {
    const conversationMeeting = this.feedbackService.conversationMeeting;
    if (this.isMeeting) {
      this.spinnerService.start();
      if (!this.feedbackService.conversations) this.feedbackService.updateActiveConversationList([conversationMeeting]);
      this.feedbackService.conversationMeeting = { ...conversationMeeting, hasManagerStarted: true };
      this.feedbackService.conversationMeeting = { ...conversationMeeting, hasManageeStarted: true };
      this.feedbackService
        .updateConversation(this.feedbackService.conversationMeeting._id, conversationMeeting)
        .subscribe({
          next: () => {
            this.feedbackService.conversationMeeting = null;
            this.spinnerService.stop();

            if (this.conversationType === ConversationType.annual) {
              this.router.navigateByUrl('/1-on-1/discussions/annual');
            } else {
              this.router.navigateByUrl('/1-on-1/discussions/quarterly');
            }
          },
          error: () => this.spinnerService.stop(),
        });
    } else {
      this.feedbackService.saveConversation$.next(submit);
    }
  }

  finishMeeting(): void {
    if (this.isMeeting) {
      if (this.bothPartiesHaveSigned()) {
        this.feedbackService.conversationMeeting.isMeetingCompleted = true;
        this.feedbackService.conversationMeeting.isDone = true;
        this.feedbackService.conversationMeeting.isManageeDone = true;
        this.feedbackService.conversationMeeting.isManagerDone = true;
        this.saveConversation();
      } else {
        this.dialog.open(AlertDialogComponent, {
          data: {
            title: 'Missing Required Information',
            message: `Both parties must sign and date before you can finish your ${this.stateService.language.feedback.meeting}`,
          },
        });
      }
    } else {
      this.saveConversation(true);
    }
  }

  bothPartiesHaveSigned(): boolean {
    return (
      !!this.feedbackService.conversationMeeting.manageeSignature?.signature?.length &&
      !!this.feedbackService.conversationMeeting.manageeSignature.date &&
      !!this.feedbackService.conversationMeeting.managerSignature?.signature?.length &&
      !!this.feedbackService.conversationMeeting.managerSignature.date
    );
  }

  changeMeetingTitle(title?: string) {
    this.feedbackService.setConversationTitle(title);
    if (this.isMobile) this.toggleSideNav.emit();
  }

  setSection(section: Section) {
    const scrollContainer = document.querySelector('.page-wrapper');
    const el: HTMLElement = document.getElementById(section.elementId);
    scrollContainer.scroll({
      // subtract around 5rem prevent fixed header covering section title
      top: el.offsetTop - 16 * 5,
      left: 0,
      behavior: 'smooth',
    });

    // Caused weird bug where filter-toolbar slides out of view
    // el.scrollIntoView({
    //   block: isFirst ? 'start' : 'center',
    //   inline: isFirst ? 'start' : 'nearest',
    //   behavior: 'smooth',
    // });

    setTimeout(() => {
      if (this.isMobile) this.toggleSideNav.emit();
      this.feedbackService.conversationSectionUpdate$.next(section);
    }, 500);
  }
}
