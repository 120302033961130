import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import Fuse, { FuseResult, IFuseOptions } from 'fuse.js';
import { debounceTime, Observable, switchMap, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AutoCompleteCallback, AutoCompleteCallbackHandler } from '../directives/auto-complete.directive';

import {
  createInitialManagedOptions,
  FuseSearchDoQueryParams,
  FuseSearchInitParams,
  FuseSearchState,
  ManagedOptions,
  SearchResultsType,
} from './fuse-search.service.model';

interface DoSearchInputs<T> {
  searchParams: FuseSearchDoQueryParams;
  index: Fuse<T>;
  fuseOptions: IFuseOptions<T>;
  data: T[];
}

/**
 * A service for managing a search index and a list of items to search over.
 *
 * While this service is a component store, it is not meant to follow the conventional pattern of a component store. It
 * is a shared injectable service that is meant to be some middle ground between a typical component store (local state)
 * and the global store (global state). Its state is shared, it is injected by >= 2 components, but inside a components
 * scope (ie: itself and its children).
 *
 * A component which wishes to use service must first provide it in a component or modules `providers` array. To use
 * as the callback handler for an {@link AutoCompleteDirective}, provide the service as the value for the
 * {@link AUTO_COMPLETE_CALLBACK_HANDLER}.
 *
 * @example
 * ```typescript
 * @Component({
 *  providers: [
 *    // 👇 Provide the service, registering it in the DI injection scope of the component
 *    FuzeSearchService,
 *    // 👇 Mark the service as the callback handler for the autocomplete directive
 *    { provide: AUTO_COMPLETE_CALLBACK_HANDLER, useExisting: FuzeSearchService }
 *  ]
 *  ...
 * })
 * export class MyComponent implements OnInit {
 *    constructor(private fuzeSearch: FuzeSearchService) {}
 *
 *    ngOnInit() {
 *      // 👇 Initialize the service with the data source and fuse options
 *      this.fuzeSearch.init({
 *        allOptionsDataSource$: this.myDataSource$,
 *        fuseOptions: {
 *          keys: ['aKeyOfYourDataObject'],
 *        }
 *      });
 *    }
 *    ...
 * }
 * ```
 */
@Injectable()
export class FuseSearchService<T> extends ComponentStore<FuseSearchState<T>> implements AutoCompleteCallbackHandler {
  constructor() {
    super(); // initial state is lazily initialized by init method (...initially)
  }

  private readonly fuseOptions$ = this.select(state => state.fuseOptions);
  private readonly data$ = this.select(state => state.data);
  private readonly index$ = this.select(state => state.index);
  private readonly initialOptions$ = this.select(state => state.initialOptions);
  private readonly managedOptionsSource = this.select(state => state.managedOptions);

  /** The options this service manages. Core observable of the service. */
  public readonly managedOptions$: Observable<ManagedOptions<T>[]> = this.select(
    this.initialOptions$,
    this.managedOptionsSource,
    (initialOptions, managedOptions) => managedOptions ?? initialOptions
  ).pipe(
    filter(options => !!options) // The only time options is null is after init, before the datasource emits.
  );

  public readonly type$ = this.select(state => state.searchResultsType);
  public readonly hasNoResults$ = this.select(this.type$, (type: SearchResultsType): boolean => type === 'no-results');
  public readonly hasSearchTerm$ = this.select(this.type$, (type: SearchResultsType): boolean => type !== 'no-query');

  /* Updaters */

  /** Set the data to be searched. Effectively clears any input search results */
  private readonly setData = this.updater<T[]>(
    (state, data: T[]): FuseSearchState<T> => ({
      ...state,
      data,
      // mark index as dead, build on next search
      index: null,
      // Whenever the data changes, reset the managed options (clear match and restore order)
      managedOptions: null,
      initialOptions: createInitialManagedOptions(data),
      searchResultsType: 'no-query',
    })
  );

  /* Effects */

  /** Initialize the service with an option source and fuse options used to create the index */
  public readonly init = this.effect((intiParams$: Observable<FuseSearchInitParams<T>>) =>
    intiParams$.pipe(
      tap(({ fuseOptions }) =>
        this.setState({
          fuseOptions,
          index: null,
          data: [],
          managedOptions: null,
          initialOptions: null,
          searchResultsType: 'no-query',
        })
      ),
      switchMap(({ allOptionsDataSource$ }) => allOptionsDataSource$),
      tap((data: T[]) => this.setData(data))
    )
  );

  /** Implementation of the {@link AutoCompleteCallbackHandler} interface */
  onInput: AutoCompleteCallback = (query: string) => this.search({ query });

  /** Perform a search with the given query */
  public readonly search = this.effect((searchTermParams$: Observable<FuseSearchDoQueryParams>) =>
    searchTermParams$.pipe(
      concatLatestFrom(() => [this.index$, this.fuseOptions$, this.data$]),
      switchMap(([searchTerm, index, fuseOptions, data]: [FuseSearchDoQueryParams, Fuse<T>, IFuseOptions<T>, T[]]) =>
        this.doSearch({ searchParams: searchTerm, index, fuseOptions: fuseOptions, data })
      ),
      debounceTime(200),
      tap(statePatch => this.patchState(statePatch))
    )
  );

  private doSearch({
    searchParams,
    index,
    fuseOptions,
    data,
  }: DoSearchInputs<T>): Observable<Partial<FuseSearchState<T>>> {
    return new Observable<Partial<FuseSearchState<T>>>(subject => {
      if (this.isEmptySearch(searchParams.query)) {
        subject.next({ managedOptions: null, searchResultsType: 'no-query' });
        subject.complete();
        return;
      }

      // Defer index creation until first search (and the next search after each underlying data change)
      const statePatch: Partial<FuseSearchState<T>> = {};
      if (!index) {
        if (!data) {
          console.warn('Attempted to search without data or index');
          statePatch.data = [];
        }

        index = new Fuse<T>(data, fuseOptions);
        statePatch.index = index;
      }

      // Do search
      const searchResults: FuseResult<T>[] = index.search(searchParams.query, { limit: searchParams.limit ?? 10 });
      statePatch.searchResultsType = searchResults.length === 0 ? 'no-results' : 'results';

      // Construct managed options (~= [...matching, ...nonMatching])
      const matchingOptions: ManagedOptions<T>[] = searchResults.map(result => ({
        value: result.item,
        match: result,
        visible: true,
      }));
      const matchingObjects: T[] = matchingOptions.map((match: ManagedOptions<T>) => match.value);
      const booleanIndex: boolean[] = data.map((option: T) => matchingObjects.indexOf(option) === -1);
      const nonMatchingOptions = data
        .filter((_, index) => booleanIndex[index])
        .map(option => ({ value: option, match: null, visible: false }));

      statePatch.managedOptions = matchingOptions.concat(nonMatchingOptions);

      subject.next(statePatch);
      subject.complete();
    });
  }

  /* Internals */

  private isEmptySearch(query: string | null): boolean {
    if (!query) return true;
    return query.trim().length === 0;
  }
}
