import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { GridWidgetById } from '../models/grid-widget-by-id';
import { GridWidget } from '../models/grid-widget.model';
import { GridOptions } from '../models/grid.options';

import { GridState } from './grid-layout-state.model';

/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
export const GridLayoutActions = createActionGroup({
  source: 'Grid Layout',
  events: {
    Initialize: props<{ opts: GridOptions }>(),
    Initialized: props<{ gridState: GridState }>(),

    // TODO consider a general purpose PatchOptions action
    'Change Column Count': props<{ columnCount: number }>(),
    'Change Cell Height': props<{ cellHeight: number }>(),
    'Change Float': props<{ float: boolean }>(),
    Compact: emptyProps(),

    'First Paint Complete': emptyProps(),

    /**
     * Destroy the grid. Optionally clear the state. State can be persisted across grid instances, which is desirable
     * when a page (such as VTO) contains multiple grids across tabs.
     */
    Destroy: props<{ clearState: boolean }>(),
    Destroyed: props<{ clearState: boolean }>(),

    'Modify Edit State': props<{ editLayoutEnabled: boolean }>(),
    'Modified Edit State': props<{ editLayoutEnabled: boolean }>(),

    'Load Layout': props<{ models: GridWidget<unknown>[] }>(),
    'Loaded Layout': props<{ viewModel: GridWidgetById }>(),

    /**
     * Dispatched whenever a user moves or re-sizes a widget. Updates GridState.
     *
     * Feature modules should ignore this action and prefer responding to persist layout.
     */
    'User Updated Layout': props<{ models: GridWidget<unknown>[] }>(),

    /**
     * Dispatched by GridLayoutStateEffects with updated models.
     *
     * Feature modules should handle this action, not GridFilterBarActions.requestPersistChanges. They should dispatch
     * one of the following actions bellow in response. Widgets missing from this action, but in feature state,
     * represent invisible widgets.
     */
    'Persist Layout': props<{ widgets: GridWidget<unknown>[] }>(),
    /** Dispatched by feature modules in response to 'Persist Layout' */
    'Persist Layout Success': emptyProps(),
    /** Dispatched by feature modules in response to 'Persist Layout' */
    'Persist Layout Failure': props<{ error: unknown; message: string }>(),

    /**
     * Drop the widget represented by this id from the layout.
     *
     * Feature modules should NOT respond to this action and update their state. They should respond to 'Persist Layout'
     * above and compare the widgets in action to their own definition of the grid.
     */
    'Remove Widget': props<{ id: string }>(),

    /**
     * Flip the visibility of the widget represented by this id to true.
     *
     * Feature modules should NOT respond to this action and update their state. They should respond to 'Persist Layout'
     * above and compare the widgets in action to their own definition of the grid.
     */
    'Show Widget': props<{ id: string }>(),

    'Patch Widget': props<{ id: string; patch: Partial<GridWidget<unknown>> }>(),

    /** Request - Hide the grid and render the template corresponding to `id` as a singleton */
    'Show Singleton': props<{ id: string }>(),

    /** Based on current state (viewport), the singleton was set */
    'Show Singleton Success': props<{ id: string }>(),

    /** The setting of the singleton was ignored due to the current viewport */
    'Show Singleton Ignored': emptyProps(),

    /** Show the grid and remove any singleton view. */
    'Clear Singleton': emptyProps(),

    /** Fired when the singleton was active and was cleared. */
    'Clear Singleton Success': emptyProps(),

    /** Fired when a request to clear the singleton was fired, but it was not active, so nothing was cleared. */
    'Clear Singleton Ignored': emptyProps(),

    'Less Than Breakpoint': emptyProps(),
    'Greater Than Breakpoint': emptyProps(),

    'Enter One Column Mode': emptyProps(),
    'Successfully Entered One Column Mode': emptyProps(),
    'Failed to Enter One Column Mode': props<{ error: unknown }>(),

    'Exit One Column Mode': emptyProps(),
    'Successfully Exited One Column Mode': emptyProps(),
    'Failed to Exit One Column Mode': props<{ error: unknown }>(),
  },
});
