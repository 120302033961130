<div class="container">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Social</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="field-wrapper">
        <terra-form-label>LinkedIn</terra-form-label>
        <terra-form-field>
          <terra-text-input
            data-cy="social_input-field"
            [(ngModel)]="metadata.linkedInUrl"
            (ngModelChange)="debounceUpdate()">
            <terra-input-suffix>
              <terra-icon
                icon="arrow-square-out"
                data-cy="social_open-link"
                aria-label="Copy Link"
                class="terra-text-input__open-link"
                (click)="openLink(metadata.linkedInUrl)" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>
