import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  CountryCode as CC,
  E164Number,
  NationalNumber,
  parsePhoneNumberFromString,
  PhoneNumber,
} from 'libphonenumber-js';
import { Subscription } from 'rxjs';

import { CountryPhoneCodeComponent } from './country-phone-code.component';
import { CountryISO } from './models/country-iso.enum';
import { Country } from './models/country.model';
import { PhoneCountryService } from './services/phone-country.service';
import { PhoneNumberValidators } from './validators/phone-number.validator';

@Component({
  selector: 'ninety-intl-tel-input',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    CountryPhoneCodeComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => IntlTelInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IntlTelInputComponent,
      multi: true,
    },
    PhoneCountryService,
  ],
  template: `
    <mat-form-field appearance="outline" class="_terra-migration-approved-override--mat-form-field">
      <div matPrefix class="country-code-dropdown-container">
        <ninety-country-phone-code-dropdown
          data-cy="int-tel-input_country-code-dropdown"
          class="country-code-dropdown"
          [selectedCountry]="selectedCountry"
          [disabled]="phoneNumberControl.disabled"
          (countryChanged)="onCountryChanged($event)"></ninety-country-phone-code-dropdown>
      </div>
      <input
        matInput
        autocomplete="off"
        data-cy="int-tel-input_phone-number-input"
        class="phone-number-input"
        id="phone-number-input"
        [formControl]="phoneNumberControl"
        (blur)="onBlur()"
        (keydown)="onInputKeyPress($event)"
        (paste)="onInputPaste($event)"
        maxlength="15"
        #focusable />
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: flex;
      }
      mat-form-field {
        width: 100%;
        flex-grow: 1;
        display: flex;
      }
      .country-code-dropdown-container {
        position: relative;
        top: -5px;
      }
    `,
  ],
})
export class IntlTelInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() preferredCountries: Array<CountryISO>;

  selectedCountry: Country | undefined;
  phoneNumberControl = new FormControl<E164Number | string | undefined>('');
  phoneNumber: NationalNumber | undefined;
  numberInstance: PhoneNumber | undefined;
  public value: E164Number | string | undefined;

  private subscriptions = new Subscription();

  constructor(private countrySvc: PhoneCountryService) {}

  ngOnInit(): void {
    const sub = this.phoneNumberControl.valueChanges.subscribe(value => {
      this.onPhoneNumberChange(value);
    });
    this.subscriptions.add(sub);
    this.selectedCountry = this.countrySvc.getCountry('US');
    this.phoneNumberControl.setValidators(PhoneNumberValidators.Valid(this.selectedCountry?.iso2.toUpperCase() as CC));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Write a value to the UI, something changed on the form, lets the UI know about it.
  public writeValue(value: string): void {
    this.parseAndFormatNumber(value);
  }

  public onBlur(): void {
    this.onTouched();
    this.parseAndFormatNumber(this.value);
  }

  // Update the local value and notify the parent
  public onValueChange(value: string) {
    this.onTouched();
    this.onChange(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  public onChange = (_: string) => {};
  public registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched = () => {};
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public onInputKeyPress(event: KeyboardEvent): void {
    const allowedChars = /[0-9\+\-\(\)\ ]/;
    const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
    const allowedOtherKeys = [
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'End',
      'Insert',
      'Delete',
      'Backspace',
      'Tab',
    ];

    if (
      !allowedChars.test(event.key) &&
      !(event.ctrlKey && allowedCtrlChars.test(event.key)) &&
      !(event.metaKey && allowedCtrlChars.test(event.key)) &&
      !allowedOtherKeys.includes(event.key)
    ) {
      event.preventDefault();
    }
  }

  public onInputPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    const cleanedValue = paste.replace(/\D/g, '');
    this.phoneNumberControl.setValue(cleanedValue);
  }

  public validate() {
    const isNotValid = PhoneNumberValidators.Valid(this.selectedCountry?.iso2.toUpperCase() as CC)(
      this.phoneNumberControl
    );
    return isNotValid && { invalid: true };
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneNumberControl.disable();
    } else {
      this.phoneNumberControl.enable();
    }
  }

  public onCountryChanged(countryCode: Country): void {
    this.selectedCountry = countryCode;
    this.phoneNumberControl.setValidators(PhoneNumberValidators.Valid(this.selectedCountry?.iso2.toUpperCase() as CC));
    this.onPhoneNumberChange(this.phoneNumber);
  }

  public onPhoneNumberChange(value: any): void {
    try {
      this.phoneNumber = value;
      this.numberInstance = parsePhoneNumberFromString(
        this.phoneNumber?.toString() || '',
        this.selectedCountry?.iso2.toUpperCase() as CC
      );
      // this.formatAsYouTypeIfEnabled();
      this.value = this.numberInstance?.number;
      if (this.numberInstance && this.numberInstance.isValid()) {
        if (this.phoneNumber !== this.formattedPhoneNumber) {
          this.phoneNumber = this.formattedPhoneNumber;
        }
      }
    } catch (e) {
      this.value = this.phoneNumber?.toString();
    }
    this.onValueChange(this.value);
  }

  private get formattedPhoneNumber(): string {
    if (!this.numberInstance) {
      return this.phoneNumber?.toString() || '';
    }
    return this.numberInstance.formatNational();
  }

  private parseAndFormatNumber(value: string): void {
    if (value) {
      this.numberInstance = parsePhoneNumberFromString(value);
      if (this.numberInstance) {
        const countryCode = this.numberInstance.country;
        this.phoneNumber = this.formattedPhoneNumber;
        this.phoneNumberControl.setValue(this.phoneNumber);
        if (!countryCode) {
          return;
        }
        this.selectedCountry = this.countrySvc.getCountry(countryCode);
        this.phoneNumberControl.setValidators(PhoneNumberValidators.Valid(countryCode));
      } else {
        this.phoneNumber = value;
        this.phoneNumberControl.setValue(value);
      }
    }
    this.phoneNumberControl.updateValueAndValidity();
  }
}
