/* eslint-disable @angular-eslint/no-output-rename, @angular-eslint/no-outputs-metadata-property */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, TrackByFunction } from '@angular/core';
import { PushPipe } from '@ngrx/component';

import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { BoldSearchResultsPipe } from '@ninety/web/pages/accountability-chart/pipes/bold-search-results.pipe';
import { SelectSeatByIdPipe } from '@ninety/web/pages/accountability-chart/pipes/select-seat-by-id.pipe';

import { SelectChipTemplateDirective } from '../../directives/select-chip-template.directive';
import { SelectImplementationDirective } from '../../directives/select-implementation.directive';
import { ManagedOptions } from '../../services/fuse-search.service.model';
import { FuseSeatDirective } from '../../services/fuse-seat.directive';
import { ChipSelectKitchenSinkComponent } from '../chip-select-kitchen-sink/chip-select-kitchen-sink.component';

@Component({
  selector: 'ninety-chip-seat-select-box',
  standalone: true,
  imports: [
    CommonModule,
    ChipSelectKitchenSinkComponent,
    BoldSearchResultsPipe,
    SelectSeatByIdPipe,
    PushPipe,
    SelectChipTemplateDirective,
  ],
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: SelectImplementationDirective,
      inputs: ['multiple', 'readonly', 'disabled', 'id', 'placeholder', 'noResultsText', 'selected: selectedSeatIds'],
      outputs: ['selectedChange: selectedSeatIdsChange'],
    },
    {
      directive: FuseSeatDirective,
      inputs: ['datasource', 'ninetyFuseSeatOptions: fuseOptions'],
    },
  ],
  template: `
    <ninety-chip-select-kitchen-sink [chipTemplate]="chipTemplate" [trackByFn]="trackBy">
      <ng-template let-option>
        <span
          *ngIf="option.match?.matches; else inline"
          [innerHTML]="option.value.name | boldSearchResults : option.match.matches">
        </span>
        <ng-template #inline>{{ option.value.name }}</ng-template>
      </ng-template>
    </ninety-chip-select-kitchen-sink>

    <ng-template #chipTemplate="ninetySelectChipTemplate" ninetySelectChipTemplate let-seatId="value">
      {{ ($any(seatId) | selectSeatById | ngrxPush)?.name }}
    </ng-template>
  `,
})
export class ChipSeatSelectBoxComponent {
  protected trackBy: TrackByFunction<ManagedOptions<SeatModel>> = (_, item) => item.value._id;
}
