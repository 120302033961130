/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { selectCompanyId } from '../company/company-state.selectors';

import { BannerNotificationActions } from './banner-notification.actions';
import { BannerNotificationEventName } from './banner-notification.event-name.type';

@Injectable()
export class BannerNotificationStateEffects {
  constructor(private readonly actions$: Actions, private readonly store: Store, private readonly router: Router) {}

  dismissBanner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BannerNotificationActions.dismissBanner),
        concatLatestFrom(() => this.store.select(selectCompanyId)),
        map(([_, companyId]) => {
          switch (_.banner?.bannerNotificationEventName) {
            case BannerNotificationEventName.trialBanner:
              const now = new Date();
              localStorage.setItem(
                `${_.banner.bannerNotificationEventName}-BannerLastDismissedDate-${companyId}`,
                `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
              );
              break;
          }
          return BannerNotificationActions.dismissBannerSuccess();
        })
      ),
    { dispatch: true }
  );

  bannerActionClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannerNotificationActions.bannerActionClicked),
      map(({ banner }) => {
        if (banner?.action) return banner.action;
        this.router.navigateByUrl(banner.actionRoute);
      })
    )
  );
}
