/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { IFuseOptions } from 'fuse.js';
import { Observable } from 'rxjs';

import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';

import { AUTO_COMPLETE_CALLBACK_HANDLER } from '../directives/auto-complete.directive';

import { FUSE_PROVIDER_INJECTION_TOKEN, FuseSearchProvider } from './fuse-provider';
import { FuseSearchService } from './fuse-search.service';

@Directive({
  selector: '[ninetyFuseTeam]',
  standalone: true,
  providers: [
    FuseSearchService,
    {
      provide: AUTO_COMPLETE_CALLBACK_HANDLER,
      useExisting: FuseSearchService,
    },
    {
      provide: FUSE_PROVIDER_INJECTION_TOKEN,
      useExisting: forwardRef(() => FuseTeamDirective),
    },
  ],
})
export class FuseTeamDirective implements OnInit, FuseSearchProvider<Team> {
  @Input({ alias: 'ninetyFuseTeam' }) data$: Observable<Team[]>;

  @Input({ alias: 'ninetyFuseTeamOptions' }) fuseOptions: IFuseOptions<Team> = {
    keys: ['name'],
    includeMatches: true,
  };

  constructor(public fuse: FuseSearchService<Team>, @Optional() private store: Store) {}

  ngOnInit() {
    if (!this.data$) {
      // Explicitly throws Null Pointer if no input and no injected store
      this.data$ = this.store.select(TeamSelectors.selectAll);
    }

    this.fuse.init({
      allOptionsDataSource$: this.data$,
      fuseOptions: this.fuseOptions,
    });
  }
}
