import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Spectator } from '@ngneat/spectator';

import { SelectBoxComponentHarness } from '../../components/select-box/select-box.component.harness';
import { SelectImplementationDirective } from '../../directives/select-implementation.directive';

import { ChipUserSelectBoxComponent } from './chip-user-select-box.component';

export class ChipUserSelectBoxComponentHarness<T = ChipUserSelectBoxComponent> extends SelectBoxComponentHarness<T> {
  constructor(spectator: Spectator<T>, private id: string) {
    super(spectator);
  }

  /* Selectors */

  public static readonly hostSelector = 'ninety-chip-user-select-box';

  public static createOptionSelector(rootId: string, userId: string) {
    return `#${rootId}-option-${userId}`;
  }

  /* DOM queries */

  public getComponent(): ChipUserSelectBoxComponent | null {
    const queried = this.spectator.query(ChipUserSelectBoxComponentHarness.hostSelector, {
      read: ChipUserSelectBoxComponent,
    });
    if (queried) return queried;

    // This is a weird work-around. I want this harness to be able to be used by the component in its spec and by
    // other components who declare the component as a child. In the former case, the selector fails - you can't use the
    // host to query the element (since you are querying from that element). In the latter case, you expect to find the
    // element via a selector (since it's a child).
    return this.spectator.component as ChipUserSelectBoxComponent;
  }

  public getSelectDirective(): SelectImplementationDirective<string> {
    const component = this.getComponent();
    return component['select'];
  }

  public getSelectedUserIds(): string[] {
    const select = this.getSelectDirective();
    return select.selected;
  }

  /* Questions */

  public isUserIdSelected(userId: string): boolean {
    const selectedUserIds = this.getSelectedUserIds();
    return selectedUserIds.includes(userId);
  }

  isReadonly(): boolean {
    const dir = this.getSelectDirective();
    return coerceBooleanProperty(dir?.['readonly']) ?? false;
  }

  /* Actions */

  public toggleUserByName(userId: string) {
    this.launchSelect();

    const selector = ChipUserSelectBoxComponentHarness.createOptionSelector(this.id, userId);
    const option = this.spectator.query<HTMLElement>(selector);
    option.click(); // Explicitly throw a NullPointer if not found
  }
}
