import { DataImportType } from '../models/data-import-types';

export const DataImportStateKey = 'data-import';

export interface DataImportModel {
  userJwt: string;
  selectedImportType: DataImportType;
  selectedTeamId: string;
  showOneSchemaIframe: boolean;
  showPreviewTable: boolean;
  showedConfirmDialog: boolean;
}

export const initialDataImportState: DataImportModel = {
  userJwt: '',
  selectedImportType: null,
  selectedTeamId: null,
  showOneSchemaIframe: false,
  showPreviewTable: false,
  showedConfirmDialog: false,
};
