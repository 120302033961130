import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import _isEqual from 'lodash/isEqual';
import { take, tap } from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { TimezoneSelectEvent } from '@ninety/ui/legacy/shared/models/_shared/timezone-select-event';
import { Locale } from '@ninety/ui/legacy/shared/models/_shared/user-settings';
import { Theme } from '@ninety/ui/legacy/shared/models/enums/theme';
import { UserPreferencesActions } from '@ninety/ui/legacy/state/app-entities/users/users-state.actions';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { CurrentUserSelectors } from '@ninety/ui/legacy/state/index';
import { MazActions } from '@ninety/web/pages/getting-started/maz/_state/maz.actions';
import { MazSelectors } from '@ninety/web/pages/getting-started/maz/_state/maz.selectors';

type UserPreferences = {
  defaultTeamId: string;
  preferredLocale: Locale;
  theme: Theme;
  setTimezoneAutomatically: boolean;
  timezone: string;
  timezoneOffsetInMinutes: number;
};

@Component({
  selector: 'ninety-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreferencesComponent implements OnDestroy {
  Locale = Locale;
  today = new Date();
  hasChanges = false;
  settings: UserPreferences;
  settingsCopy: UserPreferences;
  userTeams$ = this.store.select(CurrentUserSelectors.selectTeams);

  protected readonly showMazChatToggle$ = this.store.select(MazSelectors.selectShowMazChatToggle);
  protected readonly userMazStatus$ = this.store.select(MazSelectors.selectUserMazStatusEnabled);

  constructor(public stateService: StateService, private userService: UserService, private store: Store) {
    this.store
      .select(selectCurrentUser)
      .pipe(
        take(1),
        tap(user => {
          const { defaultTeamId, preferredLocale, setTimezoneAutomatically, timezoneOffsetInMinutes } = user.settings;
          const theme = user.settings?.theme || Theme.LIGHT;
          const timezone = user.settings?.timezone || 'GMT';

          this.settings = {
            defaultTeamId,
            preferredLocale,
            theme,
            setTimezoneAutomatically,
            timezone,
            timezoneOffsetInMinutes,
          };
          this.settingsCopy = { ...this.settings };
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.hasChanges && this.settings.theme !== this.settingsCopy.theme) {
      // Revert theme state back to last saved change
      this.updateTheme(this.settingsCopy.theme);
    }
  }

  checkForChanges(): void {
    this.hasChanges = !_isEqual(this.settings, this.settingsCopy);
  }

  themeChanged(): void {
    this.updateTheme(this.settings.theme);
  }

  save(): void {
    // If user has changed theme and saved, we want to capture in Gainsight
    if (this.settings.theme !== this.settingsCopy.theme) {
      this.store.dispatch(UserPreferencesActions.themeSaved({ theme: Theme[this.settings.theme.toUpperCase()] }));
    }

    this.userService.updateUserSettings(this.settings, true).subscribe();

    this.settingsCopy = { ...this.settings };

    this.checkForChanges();
  }

  updateTheme(theme: Theme) {
    this.store.dispatch(UserPreferencesActions.updateTheme({ theme: Theme[theme.toUpperCase()] }));
  }

  setTimeZoneToBrowserTimeZone(checked: boolean) {
    this.settings.setTimezoneAutomatically = checked;

    this.checkForChanges();
  }

  changeTimezone(timezoneSelectEvent?: TimezoneSelectEvent) {
    if (timezoneSelectEvent == null) return;

    this.settings.timezone = timezoneSelectEvent.timezoneEntry.name;
    this.settings.timezoneOffsetInMinutes = timezoneSelectEvent.timezoneEntry.offsetInMinutes;

    this.checkForChanges();
  }

  triggerSaveForFirstChange(isFirstChange: boolean) {
    this.checkForChanges();

    if (isFirstChange && this.hasChanges) {
      this.save();
    }
  }

  toggleUserMazStatus(enabled: boolean) {
    this.store.dispatch(MazActions.toggleUserMazStatus({ enabled }));
  }
}
