import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { PINNACLE_SWOT_TYPES, STANDARD_SWOT_TYPES } from '@ninety/ui/legacy/shared/models/vto/swot-type';
import { ReadonlyVtoBosDependentDefaults } from '@ninety/ui/legacy/shared/models/vto/vto';
import { VtoLabels } from '@ninety/ui/legacy/shared/models/vto/vto-labels';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import * as FeatureFlagSelectors from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import * as CompanySelectors from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';

import { SectionFilteringLogic } from '../logic/section-filtering.logic';

import { VtoStateKey, VtoStateModel } from './vto-state.model';

export namespace VtoSelectors {
  export const selectVtoState = createFeatureSelector<VtoStateModel>(VtoStateKey);

  export const selectVtoBosType = createSelector(
    CompanySelectors.selectBos,
    FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.coreNinetyDefaultVTO),
    FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.pinnacleDefaultVTO),
    (bos, ninetyFlag, pinnacleFlag) => {
      if (bos === BusinessOperatingSystem.ninetyOS) {
        return ninetyFlag ? bos : BusinessOperatingSystem.eos;
      } else if (bos === BusinessOperatingSystem.pinnacle) {
        return pinnacleFlag ? bos : BusinessOperatingSystem.eos;
      } else {
        return bos;
      }

      // Future State
      // return bos
    }
  );

  export const selectDefaults = createSelector(selectVtoBosType, bos => ReadonlyVtoBosDependentDefaults.forBos(bos));

  export const selectDefaultSectionSettings = createSelector(selectDefaults, defaults => defaults.sectionSettings);

  export const selectDefaultCustomSectionSettings = createSelector(
    selectDefaults,
    defaults => defaults.customSectionSettings
  );

  export const selectDefaultLabels = createSelector(selectDefaults, defaults => defaults.labels);

  export const selectDefaultLabelFor = (key: keyof VtoLabels) =>
    createSelector(selectDefaultLabels, labels => labels[key]);

  export const selectSectionFilter = createSelector(selectVtoBosType, bos => SectionFilteringLogic.forBos(bos));

  export const selectSwotTypes = createSelector(CompanySelectors.selectBos, bos =>
    bos === BusinessOperatingSystem.pinnacle ? PINNACLE_SWOT_TYPES : STANDARD_SWOT_TYPES
  );
}
