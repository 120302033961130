import { Injectable } from '@angular/core';
import { UTCDate } from '@date-fns/utc';
import { Store } from '@ngrx/store';
import { addDays, endOfDay } from 'date-fns';
import { Observable, of } from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { Issue } from '@ninety/ui/legacy/shared/models/issues/issue';
import { FromLinkedItem } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { Headline } from '@ninety/ui/legacy/shared/models/meetings/headline';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { HeadlineService } from '@ninety/web/pages/headlines/_shared/services/headline.service';
import { IssueService } from '@ninety/web/pages/issues/_shared/issue.service';
import { RockService } from '@ninety/web/pages/rocks/_shared/rock.service';
import { PersonalTodoActions } from '@ninety/web/pages/todos/_state/personal/personal-todo.actions';
import { TeamTodoActions } from '@ninety/web/pages/todos/_state/team/team-todo.actions';

export function getDueDateFromRockOrTodo(itemType: ItemType.rock | ItemType.todo) {
  const daysToDueDate = itemType === ItemType.rock ? 90 : 7;
  const today = new Date();
  const daysInMilliseconds = daysToDueDate * 24 * 60 * 60 * 1000;
  return new Date(today.setTime(today.getTime() + daysInMilliseconds));
}

@Injectable({
  providedIn: 'root',
})
export class UniversalCreateService {
  constructor(
    private rockService: RockService,
    private issueService: IssueService,
    private headlineService: HeadlineService,
    private stateService: StateService,
    private store: Store
  ) {}

  create(
    itemType: ItemType,
    item: Item,
    userIds?: string[],
    createdFrom?: FromLinkedItem,
    addCreatorToFollowersList = true
  ): Observable<Rock | Todo[] | Issue | string[] | Headline[]> {
    item.companyId = this.stateService.company._id;
    switch (itemType) {
      case ItemType.rock:
        return this.rockService.create(item, userIds, createdFrom, addCreatorToFollowersList);
      case ItemType.todo: {
        const action = item.isPersonal ? PersonalTodoActions.addMany : TeamTodoActions.addMany;
        this.store.dispatch(action({ todo: item, userIds, createdFrom, addCreatorToFollowersList }));
        return of([item] as Todo[]);
      }
      case ItemType.issue:
        return this.issueService.create(item, 'regular', createdFrom, addCreatorToFollowersList);
      case ItemType.headline:
      case ItemType.cascadedMessage:
        return this.headlineService.createHeadlinesFromUniversalCreate(item, itemType, createdFrom);
    }
    throw new Error('Unknown item type for universal create');
  }

  getDueDate(itemType: ItemType.rock | ItemType.todo) {
    const daysToDueDate = itemType === ItemType.rock ? 90 : 7;
    // Convert to UTC to avoid issues with daylight savings time
    const dueDate = addDays(endOfDay(new UTCDate()), daysToDueDate);

    return dueDate;
  }
}
