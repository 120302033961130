export enum PdfLoadingStateEnum {
  print = 'print',
  querying = 'querying',
  opening = 'opening',
  exporting = 'exporting',
  ready = 'ready',
  nodata = 'nodata',
}

export enum DownloadButtonStateEnum {
  print = 'print',
  loading = 'loading',
  download = 'download',
  disabled = 'disabled',
}

export function pdfLoadingStateToDownloadButtonState(pdfState: PdfLoadingStateEnum): DownloadButtonStateEnum {
  switch (pdfState) {
    case PdfLoadingStateEnum.print:
      return DownloadButtonStateEnum.print;
    case PdfLoadingStateEnum.querying:
    case PdfLoadingStateEnum.opening:
    case PdfLoadingStateEnum.exporting:
      return DownloadButtonStateEnum.loading;
    case PdfLoadingStateEnum.ready:
      return DownloadButtonStateEnum.download;
    case PdfLoadingStateEnum.nodata:
      return DownloadButtonStateEnum.disabled;
  }
}
