import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, combineLatest, map } from 'rxjs';

import { TerraSwitchModule } from '@ninety/terra';
import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { CompanySettings } from '@ninety/ui/legacy/shared/models/company/company-settings';
import {
  selectCurrentUserIsImplementer,
  selectCurrentUserIsManagerOrAbove,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectCompanySettings } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectHasAnyHelpfulPermission } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { selectUserIsSLT } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';

import { LegacyVTOGridLayoutActions } from '../services/legacy-vto-grid-layout-actions.service';

@Component({
  selector: 'ninety-vto-config-settings',
  template: `
    <div class="section-options">
      <div class="option" *ngrxLet="{ settings: settings$, language: language$ } as vm">
        <terra-switch
          data-cy="vto-config-settings_toggle-button-vision"
          [disabled]="disabled$ | async"
          [(ngModel)]="vm.settings.customVto"
          (ngModelChange)="updateSettings('customVto', $event)">
          Custom {{ vm.language.vto.vision }}
          <terra-description>
            Allows modification of the layout and content of the {{ vm.language.vto.vision }} page.
          </terra-description>
        </terra-switch>
      </div>
    </div>
  `,
  styles: [],
  standalone: true,
  imports: [FormsModule, AsyncPipe, LetDirective, TerraSwitchModule],
})
export class VtoConfigSettingsComponent implements OnInit {
  disabled$: Observable<boolean>;

  settings$ = this.store.select(selectCompanySettings).pipe(map(settings => cloneDeep(settings)));
  language$ = this.store.select(selectLanguage);
  isSlt$ = this.store.select(selectUserIsSLT);
  isManagerOrAbove$ = this.store.select(selectCurrentUserIsManagerOrAbove);

  constructor(
    public companyService: CompanyService,
    private gridLayoutActions: LegacyVTOGridLayoutActions,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.disabled$ = combineLatest({
      isOnSlt: this.isSlt$,
      isManagerOrAbove: this.isManagerOrAbove$,
      isHelpful: this.store.select(selectHasAnyHelpfulPermission),
      isImplementer: this.store.select(selectCurrentUserIsImplementer),
    }).pipe(
      map(({ isOnSlt, isManagerOrAbove, isHelpful, isImplementer }) => {
        if (isHelpful || isImplementer) {
          return false;
        } else {
          return !(isOnSlt && isManagerOrAbove);
        }
      })
    );
  }

  updateSettings<K extends keyof CompanySettings>(prop: K, value: CompanySettings[K]): void {
    this.companyService.updateSettingsWithKeyValue(prop, value).subscribe();
    this.gridLayoutActions.emitCustomVtoChange(value as any);
  }
}
