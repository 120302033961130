import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';

import { TerraIconModule } from '@ninety/terra';
import { AngularMaterialModule } from '@ninety/ui/legacy/angular-material/angular-material.module';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Theme } from '@ninety/ui/legacy/shared/models/enums/theme';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import { selectTrialDaysCountdownDisplay } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';
import { BillingStateActions, selectCommercialModelActive } from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-trial-countdown',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule,
    CommonModule,
    AngularMaterialModule,
    SharedModule,
    MatTooltipModule,
    TippyDirective,
    TerraIconModule,
    LetDirective,
  ],
  template: `
    <div
      class="ninety-trial-countdown-container"
      [ngClass]="{ 'ninety-trial-countdown-container-dual': this.theme === Theme.DUAL }"
      *ngrxLet="commercialModelActive$ as cmActive">
      <div
        [ngClass]="{
          'trial-countdown': true,
          'trial-countdown-dual': this.theme === Theme.DUAL,
          'trial-countdown-light': this.theme === Theme.LIGHT
        }"
        [style.display]="isSidenavExpanded ? 'flex' : 'none'">
        <div class="trial-countdown-header" data-cy="trial-countdown_text">
          <span class="title">{{ trialHeaderTitle }}</span>
          <span> {{ trialHeaderText }}</span>
        </div>
        <div class="trial-countdown-progress-bar">
          <span
            class="complete"
            [ngClass]="{
              'trial-red': daysRemaining > 0 && daysRemaining < 8,
              'trial-yellow': daysRemaining > 7 && daysRemaining < 15,
              'trial-green': daysRemaining >= 15
            }"
            [style.width]="completedWidthPercent"></span>
        </div>
        <div class="trial-countdown-header-actions">
          <a
            (click)="onBillingInfoClick(cmActive)"
            data-cy="trial-countdown_add-billing-info-button"
            data-product="trial-countdown__add-billing-info-button"
            [style.display]="callToActionActive ? 'block' : 'none'"
            >Subscribe now
          </a>
        </div>
      </div>
      <div
        [tp]="trialTooltipText"
        [tpIsEnabled]="true"
        tpPlacement="right"
        (click)="onBillingInfoClick(cmActive)"
        [ngClass]="{
          'trial-countdown-collapsed': true,
          'trial-countdown-dual': this.theme === Theme.DUAL,
          'trial-countdown-light': this.theme === Theme.LIGHT,
          'trial-collapsed-CTA-active': callToActionActive,
        }"
        data-product="trial-countdown__add-billing-info-button"
        [style.display]="isSidenavExpanded ? 'none' : 'flex'">
        <span [ngClass]="{ disabled: !callToActionActive }" class="trial-countdown-collapsed-icon">
          <terra-icon [icon]="daysRemaining < 1 ? 'lock' : 'hourglass-high'" size="24" variant="fill" />
        </span>
        <div class="trial-countdown-progress-bar">
          <span
            class="complete"
            [ngClass]="{
              'trial-red': daysRemaining > 0 && daysRemaining < 8,
              'trial-yellow': daysRemaining > 7 && daysRemaining < 15,
              'trial-green': daysRemaining >= 15
            }"
            [style.width]="completedWidthPercent"></span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./trial-countdown.component.scss'],
})
export class TrialCountdownComponent implements OnDestroy {
  @Input() daysRemaining: number;
  @Input() trialDays = 30;
  @Input() isSidenavExpanded: boolean;
  @Input() callToActionActive = true;
  @Input() theme: Theme;

  protected readonly commercialModelActive$ = this.store.select(selectCommercialModelActive);

  readonly Theme = Theme;
  private subscriptions: Subscription = new Subscription();
  trialHeaderText: string;
  trialTooltipText: string;
  trialHeaderTitle: string;
  completedWidthPercent: `${number}%`;

  constructor(
    public store: Store,
    public stateService: StateService,
    public router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.add(
      this.store
        .select(selectTrialDaysCountdownDisplay)
        .pipe(
          tap(({ trialHeaderText, trialHeaderTitle, trialDaysRemaining }) => {
            this.trialHeaderText = trialHeaderText;
            this.trialHeaderTitle = trialHeaderTitle;
            this.trialTooltipText = trialHeaderTitle + trialHeaderText;
            this.daysRemaining = trialDaysRemaining;
            this.completedWidthPercent = this.getCompletedWidthPercent(trialDaysRemaining);
            this.cdr.markForCheck();
          })
        )
        .subscribe()
    );
  }

  private getCompletedWidthPercent(trialDaysRemaining: number, totalTrialDays = this.trialDays): `${number}%` {
    let percentCompleted = Math.round((trialDaysRemaining / totalTrialDays) * 100);
    percentCompleted = Math.min(percentCompleted, 100);
    percentCompleted = Math.max(percentCompleted, 0);
    // a bit of a hack to make the progress bar look better when there are only a few days left
    if (!this.isSidenavExpanded && trialDaysRemaining < 6 && trialDaysRemaining > 0) {
      percentCompleted = 16;
    }
    return `${percentCompleted}%`;
  }

  disableCallToAction(): boolean {
    return this.stateService.isAdminOrOwner;
  }

  onBillingInfoClick(commercialModelActive: boolean) {
    if (commercialModelActive) {
      this.store.dispatch(BillingStateActions.openPricingTierDialog());
    } else {
      this.router.navigateByUrl('settings/billing/overview');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
