import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { CreateDialogInput } from '@ninety/ui/legacy/shared/models/_shared/create-dialog-input-params';
import { DialogMode } from '@ninety/ui/legacy/shared/models/_shared/dialog-mode-types';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { selectCurrentUserIsManageeOrAbove } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectFeatureEnabled } from '@ninety/ui/legacy/state/index';

import { UniversalCreateActions } from '../../_state/universal-create/universal-create.actions';
import { MobileNavMenuComponent } from '../mobile-nav-menu/mobile-nav-menu.component';
import { UniversalCreateBtnComponent } from '../universal-create/universal-create-btn/universal-create-btn.component';

/** add sticky attribute to keep the component at the top of the page
 *  <ninety-page-header sticky>...
 * */
@Component({
  selector: 'ninety-page-header, ninety-page-header[sticky]',
  standalone: true,
  imports: [
    CommonModule,
    TerraIconModule,
    PushPipe,
    MobileNavMenuComponent,
    NinetyCMModule,
    UniversalCreateBtnComponent,
  ],
  styleUrls: ['./page-header.component.scss'],
  template: `
    <div class="header-wrapper">
      <header class="ninety-page-header">
        <div>
          <ninety-mobile-nav-menu />
          <h1>{{ header }}</h1>
          <p class="ninety-page-header__description">
            {{ description }}
          </p>
        </div>
        <div>
          <ninety-universal-create-btn
            *ngIf="isManageeOrAbove$ | ngrxPush"
            [locked]="!(universalCreateFeature$ | ngrxPush)"
            (openUniversalCreateModal)="openUniversalCreateModal()" />
        </div>
      </header>
      <div class="action-bar">
        <ng-content select="[action-bar]"></ng-content>
      </div>
      <div class="local-banner">
        <ng-content select="ninety-local-banner"></ng-content>
      </div>
    </div>
  `,
})
export class PageHeaderComponent {
  @Input() header: string;
  @Input() description: string;

  /** Universal Create dialog data */
  @Input() data: CreateDialogInput = {};
  @Input() mode?: DialogMode;
  @Input() shouldHideDescriptionOnMobile = false;
  /** If you want to use a custom action instead of the universal create dialog */
  @Input() stopDefaultUniversalCreateAction = false;
  @Output() onUniversalCreateClick = new EventEmitter<void>();

  private readonly store = inject(Store);

  protected readonly isManageeOrAbove$ = this.store.select(selectCurrentUserIsManageeOrAbove);
  protected readonly universalCreateFeature$ = this.store.select(selectFeatureEnabled(NinetyFeatures.universalCreate));

  openUniversalCreateModal() {
    if (!this.stopDefaultUniversalCreateAction)
      this.store.dispatch(UniversalCreateActions.open({ data: this.data, mode: this.mode }));
    this.onUniversalCreateClick.emit();
  }
}
