import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Dictionary } from '@ngrx/entity';
import { on } from '@ngrx/store';
import { ActionCreator } from '@ngrx/store/src/models';
import { ReducerTypes } from '@ngrx/store/src/reducer_creator';
import { cloneDeep } from 'lodash';

import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoGETManyApiResponse } from '@ninety/ui/legacy/shared/models/todos/todo-response';

import { TodoChildStateKeyUnion } from '../../_state';
import { PersonalTabTodosActions, PersonalTodoActions } from '../personal/personal-todo.actions';
import { TeamTodoActions } from '../team/team-todo.actions';

import { todosStateAdapter, TodoStateBase } from './todo-state.shared.model';

/**
 * Maps {@link TodoChildStateKeyUnion} to an action group.
 */
const TodoActionMap = {
  personal: PersonalTodoActions,
  team: TeamTodoActions,
};

/**
 * Shared reducer functions of the Todos module.
 *
 * Note, functions should only go here if they need to be called by shared and non-shared reducers.
 */
export namespace SharedReducerFunctions {
  export function removeTodoFromStateById<T extends TodoStateBase>(state: T, { id }): T {
    if (state.todos.entities[id]) {
      const todos = todosStateAdapter.removeOne(id, state.todos);
      const completionTooltip = getCompletionTooltip(todos.entities);
      return {
        ...state,
        todos,
        todoCount: todos.ids.length,
        completionTooltip,
      };
    } else {
      return state;
    }
  }

  export function setLoadingToFalse<T extends TodoStateBase>(state: T): T {
    return { ...state, loading: false };
  }

  export function setErrorOnApiFailure<T extends TodoStateBase>(state, { error }): T {
    const updatedState = SharedReducerFunctions.setLoadingToFalse(state);
    updatedState.error = error;
    return updatedState;
  }

  export function updateTodosOnGETManySuccess<T extends TodoStateBase>(
    state: T,
    action: { response: TodoGETManyApiResponse }
  ): T {
    const completionTooltip = getCompletionTooltip(action.response.items);
    return {
      ...state,
      todos: todosStateAdapter.setAll(action.response.items, state.todos),
      todoCount: action.response.totalCount,
      loading: false,
      completionTooltip,
    };
  }

  export function mergeTodoUpdateIntoTodo(t: Todo, todoUpdate: Partial<Todo>) {
    if (t._id == todoUpdate._id) {
      return Object.assign({}, t, todoUpdate);
    }
    return t;
  }

  export function getCompletionTooltip(todos: Todo[] | Dictionary<Todo>): string {
    if (!Array.isArray(todos)) {
      todos = Object.values(todos);
    }

    const todaysDate = Date.now();

    const currentTodos = todos.filter(t => {
      const dueDate = Date.parse(t.dueDate?.toString());
      // Only include future to-dos if they have been completed.
      return dueDate <= todaysDate || (t.completed && dueDate > todaysDate);
    });
    const completed = currentTodos.filter(t => !!t.completed).length;
    const completedPercentage = currentTodos.length !== 0 ? (completed / currentTodos.length) * 100 : 0;
    const formattedCompletedPercentage = completedPercentage.toFixed(2); // Formats to 2 decimal places
    return `${formattedCompletedPercentage || 0}% Completion Rate\n(due by today and completed early)\n
      ${completed}/${todos.length} Total `;
  }
}

/**
 * Constructs reducers common to {@link TeamTodoState} and {@link PersonalTodoState}.
 */
export function constructSharedReducersWithDistinctActions<T extends TodoStateBase>(
  actionKey: TodoChildStateKeyUnion
): ReducerTypes<T, readonly ActionCreator[]>[] {
  const actions = TodoActionMap[actionKey];

  // Note, you must explicitly specify the type here for the ts type magic of NGRX to work
  const arr: ReducerTypes<TodoStateBase, readonly ActionCreator[]>[] = [
    on(actions.addOne, (state, { todo }): TodoStateBase => {
      const todos = todosStateAdapter.addOne(todo, state.todos);
      const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
      return {
        ...state,
        todos,
        todoCount: todos.ids.length,
        completionTooltip,
      };
    }),

    on(actions.update, actions.updateInline, (state): TodoStateBase => ({ ...state })),
    on(actions.updateLocal, (state, { todo: update }): TodoStateBase => {
      const todos = todosStateAdapter.mapOne(
        {
          id: update._id,
          map: todo => {
            if (update.hasOwnProperty('attachments')) {
              const attachmentsInUpdate = update.attachments;

              // An attachment event with only 1 item can mean two things.
              // 1. The one attachment represents all the attachments (happens on add/delete from detail view)
              // 2. The one attachments represents a new attachment from the create dialog and may be one of many
              //    (happens when you upload multiple attachments from the create dialog)
              if (attachmentsInUpdate?.length === 1) {
                const onlyAttachmentInUpdate = attachmentsInUpdate[0];
                const existingAttachment = todo.attachments.find(a => a._id === onlyAttachmentInUpdate._id);
                const isNew = !existingAttachment;
                const attachments = isNew ? [...todo.attachments, onlyAttachmentInUpdate] : attachmentsInUpdate;

                return {
                  ...todo,
                  ...update,
                  attachments,
                };
              }

              // If there is more than one attachment or none at all, then the attachments in the update represent all
              // attachments.
              return { ...todo, ...update, ...{ attachments: attachmentsInUpdate ?? [] } };
            }
            return { ...todo, ...update };
          },
        },
        state.todos
      );
      return {
        ...state,
        todos,
        loading: false,
        completionTooltip: SharedReducerFunctions.getCompletionTooltip(todos.entities),
      };
    }),
    on(
      actions.set,
      (state, { todos }): TodoStateBase => ({ ...state, todos: todosStateAdapter.setAll(todos, state.todos) })
    ),
    on(
      actions.paginationChange,
      PersonalTabTodosActions.paginationChange,
      (state, { index, size }): TodoStateBase => ({
        ...state,
        pageIndex: index,
        pageSize: size,
        loading: true,
      })
    ),
    on(
      actions.sortBy,
      PersonalTabTodosActions.sortBy,
      (state, { sort: { field, direction } }): TodoStateBase => ({
        ...state,
        sortDirection: direction,
        sortField: direction != null ? field : null,
      })
    ),
    on(
      actions.updateOrdinals,
      actions.updateLocalOrdinals,
      PersonalTabTodosActions.updateOrdinals,
      (state: TodoStateBase, { previousIndex, currentIndex }): TodoStateBase => {
        const todosCopy = cloneDeep(Object.values(state.todos.entities));
        moveItemInArray(todosCopy, previousIndex, currentIndex);

        const todos = todosCopy.map((t, i) => ({
          ...t,
          [state.ordinalKey]: i + state.pageIndex * state.pageSize,
        }));

        return {
          ...state,
          todos: todosStateAdapter.setAll(todos, state.todos),
        };
      }
    ),
    on(
      actions.setShouldBroadcast,
      (state, { broadcast: shouldBroadcast }): TodoStateBase => ({
        ...state,
        shouldBroadcast,
      })
    ),
    on(actions.remove, actions.deleteSuccess, SharedReducerFunctions.removeTodoFromStateById),
    on(
      actions.updateFailure,
      actions.deleteFailure,
      actions.deleteSeriesFailure,
      actions.updateOrdinalsFailure,
      SharedReducerFunctions.setLoadingToFalse
    ),
    on(
      actions.showIntegrations,
      (state, { showIntegrations }): TodoStateBase => ({
        ...state,
        showIntegrations,
      })
    ),
    on(actions.createTaskSuccess, (state, { todoId, googleTaskId }): TodoStateBase => {
      const todos = todosStateAdapter.updateOne({ id: todoId, changes: { googleTaskId } }, state.todos);

      return {
        ...state,
        loading: false,
        todos,
      };
    }),
    on(actions.syncTasksFailure, actions.syncTasksSuccess, (state): TodoStateBase => ({ ...state, loading: false })),
    on(
      actions.updateInline,
      (state): TodoStateBase => ({
        ...state,
      })
    ),
    on(actions.updateInlineSuccess, (state, { todo }): TodoStateBase => {
      const todos = todosStateAdapter.updateOne({ id: todo._id, changes: todo }, state.todos);
      return {
        ...state,
        todos,
        completionTooltip: SharedReducerFunctions.getCompletionTooltip(todos.entities),
      };
    }),
    on(actions.select, (state, { todo }): TodoStateBase => ({ ...state, selectedTodoId: todo._id })),
    on(actions.deselect, (state): TodoStateBase => ({ ...state, selectedTodoId: null })),

    on(actions.setSelectedId, (state, { todoId }): TodoStateBase => ({ ...state, selectedTodoId: todoId })),
    on(actions.clearSelectedId, (state): TodoStateBase => ({ ...state, selectedTodoId: null })),
    on(
      actions.search,
      (state, { searchText }): TodoStateBase => ({
        ...state,
        searchText: searchText || null,
        loading: true,
        pageIndex: 0,
      })
    ),
  ];

  // This cast is necessary, although unfortunate. We've tried countless combinations of types between the arr, param,
  // and return type all to no avail.
  return arr as unknown as ReducerTypes<T, readonly ActionCreator[]>[];
}
