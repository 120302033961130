<div *ngIf="person">
  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Login Information</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="bottom-space-30">
        <div class="flex-align-center">
          <terra-form-field>
            <terra-form-label>Primary Email</terra-form-label>
            <terra-text-input
              attr.data-cy="account_person-email-{{ person.primaryEmail }}"
              [(ngModel)]="person.primaryEmail"
              placeholder="Primary Email"
              [disabled]="true">
              <terra-input-suffix>
                <terra-icon
                  icon="edit"
                  class="terra-text-input__edit"
                  data-cy="account_edit-primary-email-button"
                  aria-label="Edit Primary Email"
                  (click)="updatePrimaryEmail()"
                  *ngIf="!personIsFederated && !editingEmail && !finalizingNewPrimaryEmail && !isReauthenticating" />
              </terra-input-suffix>
            </terra-text-input>
            <terra-description>
              This email is used to log in to Ninety and for platform-related communications.</terra-description
            >
            <terra-description *ngIf="personIsFederated">
              Logged in with Google. Cannot change primary email when authenticated via Google.</terra-description
            >
          </terra-form-field>
        </div>
      </div>

      <!-- [required]="companyEnforcedMfa || personIsMfa"> -->
      <div>
        <mat-label class="muted">
          Primary Phone Number
          <small *ngIf="phoneIsVerified" data-cy="account_phone-is-verified" class="good"> (Verified)</small>
          <small *ngIf="!phoneIsVerified" data-cy="account_phone-is-not-verified" class="bad"> (Not Verified)</small>
        </mat-label>
        <div class="phone-num-container">
          <ninety-intl-tel-input #telInput [formControl]="phoneControl" class="flex-1"></ninety-intl-tel-input>
          <ng-container *ngIf="phoneControl.disabled; else editPhoneButtons">
            <ninety-button
              primary
              filled
              class="phone-field-button"
              data-cy="account_verify-number-button"
              aria-label="Verify Phone Number"
              *ngIf="!phoneIsVerified && !!phoneControl.value && !phoneControl.invalid"
              (click)="executeAction(recaptchaActions.verifyPhone)"
              [disabled]="isExecuting">
              <terra-icon icon="check" variant="bold" />
              Verify
            </ninety-button>
            <ninety-button
              stroked
              secondary
              class="phone-field-button"
              data-cy="account_edit-phone-number-button"
              aria-label="Edit Phone Number"
              [disabled]="finalizingNewPrimaryEmail || !!isReauthenticating"
              (click)="editPhone()">
              <terra-icon icon="edit" />
              Edit
            </ninety-button>
          </ng-container>
          <ng-template #editPhoneButtons>
            <ninety-button
              filled
              primary
              data-cy="account_save-and-verify-button"
              class="phone-field-button"
              aria-label="Update"
              [disabled]="phoneControl.invalid"
              (click)="updatePrimaryPhoneNumber()">
              <terra-icon icon="save" />
              Save and Verify
            </ninety-button>
            <ninety-button stroked secondary class="phone-field-button" aria-label="Cancel" (click)="cancelEditPhone()">
              <terra-icon icon="undo" />
              Cancel
            </ninety-button>
          </ng-template>
        </div>
        <mat-hint>This phone number is used for MFA authentication.</mat-hint>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Multi-Factor Authentication (MFA)</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-label class="muted">Enable MFA</mat-label>
      <div class="flex-align-center">
        <terra-switch
          data-cy="account_mfa-toggle"
          [(ngModel)]="personIsMfa"
          class="mfa-toggle"
          (ngModelChange)="toggleMfa()"
          [disabled]="!person?.primaryPhoneNumber?.length || (companyEnforcedMfa && personIsMfa)">
          Upon login, an authentication SMS will be sent to the phone number listed above. Depending on your carrier,
          SMS charges may apply.
        </terra-switch>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card" *ngIf="!personIsFederated">
    <mat-card-header>
      <mat-card-title>Change Password</mat-card-title>
      <mat-card-subtitle>
        We recommend changing your password every ninety days to keep your account secure.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ninety-button
        filled
        primary
        data-product="account__change-password-button"
        data-cy="account_change-password-button"
        aria-label="Change Password"
        (click)="openChangePasswordDialog()">
        <terra-icon icon="lock-open" />
        Change My Password
      </ninety-button>
    </mat-card-content>
  </mat-card>

  <mat-card class="ninety-card">
    <mat-card-header>
      <mat-card-title>Create a New Ninety Account</mat-card-title>
      <mat-card-subtitle>
        This account will have separate billing, users and information from any current companies you are in.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ninety-button
        filled
        primary
        data-product="account__create-new-company-button"
        aria-label="Create New Company"
        (click)="redirectToCreateCompanyUrl()"
        data-cy="account_create-company-button">
        <terra-icon icon="add" />
        Create New Company
      </ninety-button>
    </mat-card-content>
  </mat-card>
</div>
