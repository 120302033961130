<ng-container
  *ngrxLet="{
    language: language$ | async
  } as vm">
  <ng-container *ngIf="useTerra; else legacySelect">
    <!-- There are 2 Terra selects because of [multiple] not being able to be set after init, so changes need to be made in 2 places -->
    <ng-container *ngIf="multiple; else singleSelect">
      <terra-select
        class="user-select__select"
        [ngModel]="selectedUserIds"
        (ngModelChange)="multipleSelectionChange($event)"
        (openedChange)="selectToggled($event)"
        maxHeight="350"
        multiple="true"
        [disabled]="disabled || isMeeting"
        data-cy="user-select_add-user-button"
        [matTooltip]="tooltip"
        matTooltipClass="ninety-tooltip"
        matTooltipPosition="above">
        <terra-select-trigger class="user-select__select-trigger">
          <terra-icon
            *ngIf="iconBtn"
            [matBadge]="selectedUserIds.length.toString()"
            matBadgeColor="accent"
            [matBadgeHidden]="hideCountBadge || !selectedUserIds.length"
            icon="user-add" />
          <!-- Shows when a single user is selected in multimode -->
          <terra-avatar
            *ngIf="!iconBtn && user?.metadata && (!multiple || selectedUserIds.length < 2)"
            [src]="user?.metadata?.picture?.url"
            interactive="true"
            [initials]="user | userInitials" />
          <terra-icon
            icon="user-circle"
            class="avatar-icon"
            *ngIf="!iconBtn && !user && selectedUserIds.length === 1" />
          <!-- No users are selected -->
          <terra-icon
            icon="warning-triangle"
            class="user-select__warning"
            variant="fill"
            *ngIf="
              (!iconBtn && !user && !selectedUserIds.length && !userId && !accountabilityChart) || (warningBtn && !user)
            " />
          <!-- Shows when multiple users selected -->
          <terra-icon
            icon="users"
            variant="fill"
            *ngIf="multiple && !iconBtn && selectedUserIds.length > 1"
            [matBadge]="selectedUserIds.length.toString()"
            matBadgeColor="accent"
            matBadgePosition="before" />
          <div class="user-select__select-trigger-content">
            <ng-container [ngTemplateOutlet]="content" />
          </div>
        </terra-select-trigger>
        <terra-select-search>
          <terra-search-input
            [(ngModel)]="userSearchInputModel"
            placeholder="Search users"
            name="user-search"
            aria-label="user search" />
        </terra-select-search>
        <terra-option
          *ngFor="
            let u of filteredUsers | searchUserFilter : userSearchInputModel;
            trackBy: trackByUserId;
            let i = index
          "
          data-cy="user-select_card-select-user"
          attr.data-cy-item="user-select_name-{{ u?.metadata.name | displayFullName }}"
          [value]="u._id"
          [disabled]="u.isUnconfirmed || u.hasNoSeat">
          <terra-avatar *terraOptionPrefix [src]="u.metadata?.picture?.url" [initials]="u | userInitials" />
          {{ u?.metadata.name | displayFullName }}
          <span *terraOptionSuffix>
            {{ u.isUnconfirmed ? 'Unconfirmed user' : u.hasNoSeat ? 'Not in a ' + vm.language.acChart.seat : '' }}
          </span>
        </terra-option>
      </terra-select>
    </ng-container>
    <ng-template #singleSelect>
      <terra-select
        class="user-select__select"
        [ngModel]="useAsMenu ? undefinedUser : user"
        (ngModelChange)="onSelect($event)"
        maxHeight="350"
        [disabled]="disabled || isMeeting"
        data-cy="user-select_add-user-button"
        [matTooltip]="tooltip"
        matTooltipClass="ninety-tooltip"
        [compareWith]="compareUser"
        matTooltipPosition="above">
        <terra-select-trigger class="user-select__select-trigger">
          <!-- Selected user avatar/initials -->
          <terra-avatar
            *ngIf="!iconBtn && user?.metadata && (!multiple || selectedUserIds.length < 2)"
            [src]="user?.metadata?.picture?.url"
            interactive="true"
            [initials]="user | userInitials" />
          <!-- How to see this? -->
          <terra-icon
            *ngIf="iconBtn"
            [matBadge]="selectedUserIds.length.toString()"
            matBadgeColor="accent"
            [matBadgeHidden]="hideCountBadge || !selectedUserIds.length"
            icon="user-add" />
          <!-- How to see this? -->
          <terra-icon
            icon="user-circle"
            class="avatar-icon"
            *ngIf="!iconBtn && !user && selectedUserIds.length === 1" />
          <!-- No user -->
          <terra-icon
            icon="warning-triangle"
            variant="fill"
            class="user-select__warning"
            *ngIf="
              (!iconBtn && !user && !selectedUserIds.length && !userId && !accountabilityChart) || (warningBtn && !user)
            " />
          <terra-icon *ngIf="accountabilityChart && !user && !userId" icon="user-add" class="person-add" />
          <div class="user-select__select-trigger-content">
            <ng-container [ngTemplateOutlet]="content" />
          </div>
          <terra-icon
            *ngIf="showRemoveBtn"
            class="user-select__remove-btn"
            size="20"
            (click)="removeUser.emit()"
            matTooltip="Remove {{ vm.language.acChart.seatHolder }}"
            matTooltipPosition="above"
            ninetyStopPropagation
            icon="minus-circle" />
        </terra-select-trigger>
        <terra-select-search>
          <terra-search-input
            [(ngModel)]="userSearchInputModel"
            placeholder="Search users"
            name="user-search"
            aria-label="user search" />
        </terra-select-search>
        <terra-option
          *ngFor="
            let u of filteredUsers | searchUserFilter : userSearchInputModel;
            trackBy: trackByUserId;
            let i = index
          "
          data-cy="user-select_card-select-user"
          attr.data-cy-item="user-select_name-{{ u?.metadata.name | displayFullName }}"
          [value]="u"
          [disabled]="u.isUnconfirmed || u.hasNoSeat">
          <terra-avatar *terraOptionPrefix [src]="u.metadata?.picture?.url" [initials]="u | userInitials" />
          {{ u?.metadata.name | displayFullName }}
          <span *terraOptionSuffix>
            {{ u.isUnconfirmed ? 'Unconfirmed user' : u.hasNoSeat ? 'Not in a ' + vm.language.acChart.seat : '' }}
          </span>
        </terra-option>
      </terra-select>
    </ng-template>
  </ng-container>
  <!-- Legacy Select -->
  <ng-template #legacySelect>
    <ninety-button
      *ngIf="showRemoveBtn"
      flat
      icon
      class="remove-btn"
      [style.display]="removeBtnDisplay"
      (mouseenter)="removeBtnDisplay = 'inherit'"
      (mouseleave)="removeBtnDisplay = 'none'"
      (click)="removeUser.emit()"
      matTooltip="Remove {{ vm.language.acChart.seatHolder }}"
      matTooltipPosition="above">
      <terra-icon icon="minus-circle" />
    </ninety-button>
    <ninety-button
      #addUserButton
      [size]="addButtonSize"
      flat
      [matMenuTriggerFor]="userSelectMenu"
      class="menu-btn user"
      data-cy="user-select_add-user-button"
      (mouseenter)="removeBtnDisplay = 'inherit'"
      (mouseleave)="removeBtnDisplay = 'none'"
      [disabled]="disabled || isMeeting"
      [matTooltip]="tooltip"
      [class.small]="accountabilityChart && !user && !userId"
      matTooltipClass="ninety-tooltip"
      matTooltipPosition="above"
      [class.no-user]="!user"
      [class.opaque]="disabled && !isMeeting">
      <terra-avatar
        *ngIf="!iconBtn && user?.metadata && (!multiple || selectedUserIds.length < 2)"
        [src]="user?.metadata?.picture?.url"
        [initials]="user | userInitials" />
      <terra-icon
        *ngIf="iconBtn"
        [matBadge]="selectedUserIds.length.toString()"
        matBadgeColor="accent"
        [matBadgeHidden]="hideCountBadge || !selectedUserIds.length"
        icon="user-add" />
      <terra-icon icon="user-circle" class="avatar-icon" *ngIf="!iconBtn && !user && selectedUserIds.length === 1" />
      <terra-icon
        icon="warning-triangle"
        variant="fill"
        class="orange"
        *ngIf="
          (!iconBtn && !user && !selectedUserIds.length && !userId && !accountabilityChart) || (warningBtn && !user)
        " />
      <terra-icon
        icon="users"
        variant="fill"
        class="avatar-icon"
        *ngIf="multiple && !iconBtn && selectedUserIds.length > 1"
        [matBadge]="selectedUserIds.length.toString()"
        matBadgeColor="accent"
        matBadgePosition="before" />

      <terra-icon *ngIf="accountabilityChart && !user && !userId" icon="user-add" class="person-add" />

      <ng-container [ngTemplateOutlet]="content" />
    </ninety-button>

    <mat-menu #userSelectMenu="matMenu" class="user-select-menu" (closed)="saveOnClose()">
      <ng-template matMenuContent>
        <div class="user-search">
          <mat-form-field class="user-search-field">
            <input
              #userSearchInput
              matInput
              placeholder="Search users"
              value=""
              ninetyStopPropagation
              type="text"
              name="user-search"
              aria-label="user search"
              (change)="userSearchInput.focus()" />
          </mat-form-field>
        </div>
        <div class="multi-user-select-options" ninetyStopPropagation [stopPropagation]="multiple">
          <ninety-button
            data-cy="user-select_card-select-user"
            mat-menu-item
            attr.data-cy-item="user-select_name-{{ u?.metadata.name | displayFullName }}"
            (click)="onSelect(u)"
            [ngClass]="{ 'row-reverse': multiple }"
            *ngFor="
              let u of filteredUsers | searchUserFilter : userSearchInput.value;
              trackBy: trackByUserId;
              let i = index
            "
            ninetyStopPropagation
            [stopPropagation]="multiple"
            [disabled]="u.isUnconfirmed || u.hasNoSeat">
            <terra-avatar [src]="u.metadata?.picture?.url" [initials]="u | userInitials" />
            <span [id]="'user-name-label' + i" class="user-name">
              {{ u?.metadata.name | displayFullName }}
              <span *ngIf="u.isUnconfirmed; else checkNotInSeat">(unconfirmed user)</span>
              <ng-template #checkNotInSeat>
                <span *ngIf="u.hasNoSeat"> (not in a {{ vm.language.acChart.seat }}) </span>
              </ng-template>
            </span>
            <terra-checkbox
              (change)="onSelect(u)"
              [ngModel]="isSelected(u)"
              [aria-labelledby]="'user-name-label' + i"
              *ngIf="multiple"
              [disabled]="u.isUnconfirmed || u.hasNoSeat"
              ninetyStopPropagation></terra-checkbox>
          </ninety-button>
        </div>
        <div class="dflex">
          <ninety-button
            mat-menu-item
            (click)="selectedUserIds = []; onSelectUsers(); removeUser.emit()"
            [disabled]="!selectedUserIds.length"
            class="cancel-btn"
            *ngIf="multiple"
            ninetyStopPropagation>
            Clear
          </ninety-button>
          <ninety-button
            mat-menu-item
            data-cy="user-select_ok-button"
            (click)="onSelectUsers()"
            [disabled]="!selectedUserIds.length"
            class="ok-btn branded-primary-color"
            *ngIf="multiple">
            OK
          </ninety-button>
        </div>
      </ng-template>
    </mat-menu>
  </ng-template>
</ng-container>
<!-- Needed because Angular assigns to first ng-content it finds even if that slot turns off later with an ngIf  -->
<ng-template #content>
  <ng-content />
</ng-template>
